/**
 * @param {import("three").Object3D} obj3D
 */
export function findObj3DName(obj3D)
{
    if(findInObj3InDNames(obj3D?.name))
        return obj3D?.name;
    else
    {
        if(obj3D?.parent)
           return findObj3DName(obj3D.parent);
        else
            return null;
    }
}

const NAMES=['planetInventions3_model',
'planetWorldofArt2_model',
'planetEPO2_model',

'hudSprites/hud-galleryentrance_infotext',
"hudSprites/close_infotext",

'planetInventions3_spriteanimGroup',    
'planetWorldofArt2_spriteanimGroup', 
'planetEPO2_spriteanimGroup', 
'screen7_infotext', 'screen8_infotext',
'floor',

'LODButton_Sign_Lietner', 
'LODButton_phong28',
'LODButton_EPOArtcollection_wall_poster',
'LODButton_sustainability_wall_poster',

'LODButton_Sign_Panamarenko', 
'LODButton_sign_Dawar_phong11',
'LODButton_sign_Kintera',
'LODButton_sign_TueGreenfort',
"LODButton_TueGreenFortSculptureSignMat",

'LODButton_sign_Birisdottir', 
'LODButton_sign_Kwade',
'LODButton_sign_wesley',
'LODButton_sign_Belin1_phong10',

'LODButton_Sign_SIgner',

'artwork_Signer',
'artwork_Signer2',
'artwork_Kwade',
'artwork_Dawar',
'artwork_TueGreenfort',
'artwork_Belin1',
'artwork_Belin2',
'artwork_Wesley',

'artwork_artcollection',
'artwork_sustainability',

'awClickbox_Signer',
'awClickbox_Signer2',
'awClickbox_Kwade',
'awClickbox_Dawar',
'awClickbox_TueGreenfort',
'awClickbox_Belin1',
'awClickbox_Belin2',
'awClickbox_Wesley',

'awClickbox_artcollection',
'awClickbox_sustainability',

'Birgisdottir_simplemodel',
'Birgisdottir1',
'awClickbox_Birgisdottir_simplemodel',
'awClickbox_Birgisdottir1',
'awClickbox_Birgisdottir',
 'Kintera',
'awClickbox_Kintera',            
'sculpture_simple',
'awClickbox_sculpture',

'TueGreenfortSculpture1',
'awClickbox_TueGreenfortSculpture1',

'Panamarenko_flyingcarpet',
'awClickbox_Panamarenko',

'Artwork_Leitner',
'awClickbox_Artwork',

'Saraceno',
'awClickbox_Saraceno'

];  

/**
 * @param {string} name
 */
function findInObj3InDNames(name)
{
    return NAMES.indexOf(name)!==-1
}



export function nameAliases(name)
{
    switch(name)
    {
        case 'planetWorldofArt2-1stpageC':
            return 'artgallery';
        case 'planetInventions3B':
        case 'planetWorldofArt2B':
        case 'planetEPO2B':
            return 'flytotheplanet';
        default:
            return name;
    } 

} 