import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

/*  <p><span>Panamarenko</span></p><p><span>
Magic (Flying) Carpet</span><span>, 
1979</span></p>
 <p><span>Stainless steel, wood, aluminium, 12V motors, 
 nickel-cadmium batteries</span></p><p><span>390 x 233 x 15 cm</span> */

export default function Panamarenko()
{
    const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
        	<ArtWorkHeader  
				artistname="Panamarenko"
                title="Magic (Flying) Carpet"
                year="1979"
                material="Stainless steel, wood, aluminium, 12V motors, nickel-cadmium batteries"
                measurements="390 x 233 x 15 cm" 
            />
            <div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</div>
    </div>
}