import React, {useContext, useState} from 'react';
import {View, Text} from '../../multiEnvComponents/rnwComponents.web';

import { Image } from 'react-native';

import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import InfoContext from '../../InfoContext';
 
import { zoompageScrollAction } from '../getCameraCoordsBasedOnScroll.web';

import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

import "./EPOSmallLogo.css";

import { CLIENT_PATH } from  '../../config/serverURLPath.web';  

const MenuBtn =styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    `

const mb={
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 };

/**
 * 
 * 
 * @export
 * @returns 
 */
export default function EPOSmallLogo()
{
     const cntx = React.useContext(InfoContext);
    
     const mobile= rsp(mb, cntx?.r);    

    const cntxt =  useContext(InfoContext);
    const setanimHomepage=cntxt?.animpage?.setanimHomepage;

    const [opened, setOpened]=useState(false);

    const s ={
        text:{
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: mobile ? "12px": "18px",
            lineHeight: mobile ? "16px":  "24px",
            display: "flex",
            alignSelf: "center",
            color: "#FFFFFF",
            marginLeft: 5,
            cursor: "pointer"      
        }
    };




   const  clickA  = () => { 
        if(cntxt?.vr?.setEnabledVR)
            cntxt.vr.setEnabledVR(false);

        if(cntxt?.setZoompage)
                cntxt.setZoompage('home');
        
                
        setanimHomepage(2);

        if(cntxt?.scrollBarRef)       
            setTimeout(()=>{
            zoompageScrollAction("home", cntxt.scrollBarRef);
            }, 50);  
        }


    const logoClick=()=>{  
        if( !opened )
        {
                    window.open("https://epo.org", "_blank", "noopener");
                    setOpened(true);
                    setTimeout( ()=>setOpened(false) , 500)
        }
    }



    console.log("smallLogo3D uri "+CLIENT_PATH+'images/epo_logo.svg');

    // @ts-ignore
    return <View className="smallLogo3D" 
                nativeID="smallLogo3D" style={{flexDirection: "row", 
                        justifyContent: "left", display: "flex",
                 }}>  
                <MenuBtn onClick={logoClick}><Image 
                    source={{ uri: CLIENT_PATH+'images/epo_logo.svg' }}
                    // @ts-ignore
                    style={{ width:  mobile ? 96 : 133, height:  mobile ?  47 : 65,  cursor: "pointer"}}
                   
                /></MenuBtn>
                <MenuBtn onClick={()=>clickA()}>
                    <Text 
                    // @ts-ignore
                        style={s.text}  >EPO virtual universe</Text>
                </MenuBtn>
            </View>
}



