import React, { useState, useEffect, useRef} from 'react';

//import {View} from 'react-native';
import { View, Text, /* Image  , ImageBackground  */  } from '../multiEnvComponents/rnwComponents.web';


// import useOnScreen from './useOnScreen';
// import { useSpring, animated } from 'react-spring';


export default function OnScreenParallax({layer1, layer2, scroll, top})
{
  const ref = useRef()
 // const isVisible = useOnScreen(ref.current)
  
  // const [tempScroll, setTempScroll]=useState(scroll);
  // const [upUsed, setUpUsed]=useState(false)

  // const [styles, api] = useSpring(() => ({
  //   from: { y: -50},
  // }));


  // @ts-ignore
  return <View nativeID="parallax" ref_={ref} style={{ width:"90vw"}} >
          {/* <animated.div style={styles}> */}
            <View nativeID='layer1'
            // @ts-ignore
            style={{position: "absolute"}}>
              {layer1}
            </View>
            {/* </animated.div> */}
            <View style={{top}} nativeID='layer2'>
              {layer2}  
            </View>
       </View>
}
