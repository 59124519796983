import { Color, FrontSide, BackSide, MeshPhysicalMaterial, Vector3 } from "three";

const animList=[
        [
           { "action": "resetscale"},    
            { "action": "noscaling4"},
            { "action": "noscaling2"},
            { "action": "noscaling3"},
            { "action": "noscaling1"},
            // {"name": "LeitnerBulbShine4", "action": "hide"}, 
            {"name": "LeitnerLamp4", "action": "noshine"},
            // {"name": "LeitnerBulbShine3", "action": "hide"}, 
            {"name": "LeitnerLamp3", "action": "noshine"},
            // {"name": "LeitnerBulbShine2", "action": "hide"}, 
            {"name": "LeitnerLamp2", "action": "noshine"},
            // {"name": "LeitnerBulbShine1", "action": "hide"}, 
            {"name": "LeitnerLamp1", "action": "noshine"} 
        ],
        [
            
            { "action": "scaling4"},
            { "action": "noscaling2"},
            { "action": "noscaling3"},
            { "action": "noscaling1"},
            // {"name": "LeitnerBulbShine4", "action": "hide"}, 
            {"name": "LeitnerLamp4", "action": "noshine"},
            // {"name": "LeitnerBulbShine3", "action": "hide"}, 
            {"name": "LeitnerLamp3", "action": "noshine"},
            // {"name": "LeitnerBulbShine2", "action": "hide"}, 
            {"name": "LeitnerLamp2", "action": "noshine"},
            // {"name": "LeitnerBulbShine1", "action": "show"}, 
            {"name": "LeitnerLamp1", "action": "shine"} 
        ],
           [
            { "action": "scaling3"},
            { "action": "scaling4"},
            { "action": "noscaling2"},
            { "action": "noscaling1"},
            // {"name": "LeitnerBulbShine1", "action": "show"}, 
            {"name": "LeitnerLamp1", "action": "shine"},
            // {"name": "LeitnerBulbShine2", "action": "show"}, 
            {"name": "LeitnerLamp2", "action": "shine"},
            // {"name": "LeitnerBulbShine4", "action": "hide"}, 
            {"name": "LeitnerLamp4", "action": "noshine"},
            // {"name": "LeitnerBulbShine3", "action": "hide"}, 
            {"name": "LeitnerLamp3", "action": "noshine"},
           ],
           [
            { "action": "scaling2"},
            { "action": "scaling3"},
            { "action": "scaling4"},
            { "action": "noscaling1"},
            // {"name": "LeitnerBulbShine1", "action": "show"}, 
            {"name": "LeitnerLamp1", "action": "shine"},
            // {"name": "LeitnerBulbShine2", "action": "show"}, 
            {"name": "LeitnerLamp2", "action": "shine"},
            // {"name": "LeitnerBulbShine3", "action": "show"}, 
            {"name": "LeitnerLamp3", "action": "shine"},
            // {"name": "LeitnerBulbShine4", "action": "hide"}, 
            {"name": "LeitnerLamp4", "action": "noshine"}
           ],
           [
            { "action": "scaling1"},
            { "action": "scaling2"},
            { "action": "scaling3"},
            { "action": "scaling4"},
            // {"name": "LeitnerBulbShine1", "action": "show"}, 
            {"name": "LeitnerLamp1", "action": "shine"},
            // {"name": "LeitnerBulbShine2", "action": "show"}, 
            {"name": "LeitnerLamp2", "action": "shine"},
            // {"name": "LeitnerBulbShine3", "action": "show"}, 
            {"name": "LeitnerLamp3", "action": "shine"},
            // {"name": "LeitnerBulbShine4", "action": "show"}, 
            {"name": "LeitnerLamp4", "action": "shine"}
            ],
            [],
            [],
            [                
                //  {"name": "LeitnerBulbShine4", "action": "hide"}, 
                 {"name": "LeitnerLamp4", "action": "noshine"},
                //  {"name": "LeitnerBulbShine3", "action": "hide"}, 
                 {"name": "LeitnerLamp3", "action": "noshine"},
                //  {"name": "LeitnerBulbShine2", "action": "hide"}, 
                 {"name": "LeitnerLamp2", "action": "noshine"},
                //  {"name": "LeitnerBulbShine1", "action": "hide"}, 
                 {"name": "LeitnerLamp1", "action": "noshine"}, 
                 { "action": "revscaling1"},
                 { "action": "revscaling2"},
                 { "action": "revscaling3"},
                 { "action": "revscaling4"},
             ],
             [],
             [],
             [],
             [],
             [],
             [],
             [],
             [ {"action": "pause"}],
]


const startInit=  [
            { "action": "scaling1"},
            { "action": "scaling2"},
            { "action": "scaling3"},
            { "action": "scaling4"},
            {"name": "LeitnerBulbShine1", "action": "show"}, {"name": "LeitnerLamp1", "action": "shine"},
            {"name": "LeitnerBulbShine2", "action": "show"}, {"name": "LeitnerLamp2", "action": "shine"},
            {"name": "LeitnerBulbShine3", "action": "show"}, {"name": "LeitnerLamp3", "action": "shine"},
            {"name": "LeitnerBulbShine4", "action": "show"}, {"name": "LeitnerLamp4", "action": "shine"}
            ];

const finishInit= [
           { "action": "resetscale"},    
            { "action": "noscaling4"},
            { "action": "noscaling2"},
            { "action": "noscaling3"},
            { "action": "noscaling1"},
            {"name": "LeitnerBulbShine4", "action": "hide"}, {"name": "LeitnerLamp4", "action": "noshine"},
            {"name": "LeitnerBulbShine3", "action": "hide"}, {"name": "LeitnerLamp3", "action": "noshine"},
            {"name": "LeitnerBulbShine2", "action": "hide"}, {"name": "LeitnerLamp2", "action": "noshine"},
            {"name": "LeitnerBulbShine1", "action": "hide"}, {"name": "LeitnerLamp1", "action": "noshine"} 
        ]


// const scaleAlcoholLength="scaleAlcohol".length;


/**
 * The factory for the anim object
 * 
 * @param {Map} animObjs
 * @param {Array} animationScaleObjs
 */
function Anim(animObjs, animationScaleObjs, cllbck)
{
    this.animPosition=0;

    this.frames=0; 

    this.frames1=0; 
    this.frames2=0; 
    this.frames3=0; 
    this.frames4=0;

    this.scale1=1;
    this.scale2=1;
    this.scale3=1;
    this.scale4=1;

        
    this.getObj=(/** @type {string} */ name)=> animObjs.get(name);

    
    this.resetScale= () =>
    {
            this.frames1=0; 
            this.frames2=0; 
            this.frames3=0; 
            this.frames4=0;
       
          this.scale1=1;
          this.scale2=1;
          this.scale3=1;
          this.scale4=1;

        console.time("reset");
         if(animationScaleObjs.length>0)  
         { 
            for(let n=0; n<52;n++)
            {
                if( animationScaleObjs?.[n]?.obj)
                {
                    
                    animationScaleObjs[n].obj.scale.z=0;
                    animationScaleObjs[n].obj.position.z=1.5;
                }
                else
                    console.error(animationScaleObjs?.[n]);
            }
        }
        console.timeEnd("reset");
    }



    this.doAction=(animAction)=>{

        if(animAction)
        {
            for(let a of animAction)
            {
                    const name=a.name;

                    let theObj;

                    if(name)
                        theObj= this.getObj(name);

                    if(theObj)
                    {
                        if(name)
                        {
                            switch(a.action)
                            {
                                case "show":
                                    console.time("show");
                                    theObj.visible=true;
                                    console.timeEnd("show");
                                break;
                                case "hide":
                                    console.time("hide");
                                    theObj.visible=false;
                                    console.timeEnd("hide");
                                break;
                                case "shine":
                                    console.time("shine");
                                    theObj.material=new MeshPhysicalMaterial({});
                                    theObj.material.emissive=new Color(0xffff00);
                                    theObj.material.color=new Color(0xffff00);
                                    theObj.material.transparent=false;
                                    theObj.material.side=BackSide;
                                    theObj.material.roughness=0.9;
                                    theObj.material.opacity=1;
                                    theObj.material.reflectivity=0;
                                    theObj.material.needsUpdate = true;
                                    console.timeEnd("shine");
                                break;
                                case "noshine":
                                     console.time("noshine");
                                    theObj.material=new MeshPhysicalMaterial({});
                                    theObj.material.emissive=new Color(0xff0000);
                                    theObj.material.color=new Color(0xff0000);
                                    theObj.material.transparency=true;
                                    theObj.material.roughness=0.2;
                                    theObj.material.opacity=0.3;
                               //     theObj.material.side=BackSide;
                                    theObj.material.reflectivity=1;
                                    theObj.material.needsUpdate = true;
                                    console.timeEnd("noshine");
                                break;
                                default:
                            }
                        }
                    }
                    else
                    if(!name)
                    {
                        switch(a.action)
                        {     
                            case "resetscale":    
                            this.resetScale();
                                break; 
                            case "scaling1":
                                this.scale1=1;
                                break; 
                            case "noscaling1":
                                this.scale1=0;
                                break; 
                            case "revscaling1":
                                this.scale1=-0.5;
                                break; 
                            case "scaling2":
                                this.scale2=1;
                            break; 
                            case "noscaling2":
                                this.scale2=0;
                            break; 
                            case "revscaling2":
                                this.scale2=-0.5;
                                break; 
                            case "scaling3":
                                this.scale3=1;
                            break; 
                            case "noscaling3":
                                this.scale3=0;
                                break; 
                            case "revscaling3":
                                this.scale3=-0.5;
                                    break; 
                            case "scaling4":
                                this.scale4=1;
                            break; 
                            case "noscaling4":
                                this.scale4=0;
                            break; 
                            case "revscaling4":
                                this.scale4=-0.5;
                                break; 
                            case "pause":
                                cllbck();
                                    break; 
                            default:
                        }
                    }
                    else
                    {
                        console.error("Anim not found "+name);
                    }


                }
            }
            else
                console.error("animAction error");
    }


    this.getTheChange= () =>
    {
        if( this.animPosition>animList.length)
             this.animPosition=0;

        return animList[this.animPosition++]
    }


    this.doTheScaling= (scale, range) =>
    {
       let st=0, en=51;

        switch(range)
        {
            case 1:
                st=1;
                en=14;
                break;
            case 2:
                st=14;
                en=25;
                break;
            case 3:
                st=25;
                en=37;
                break;
            case 4:
                st=37;
                en=52;
                break;
            default:
        }


         if(animationScaleObjs.length>0)  
         { 
          
            for(let n=st; n<en;n++)
            {
              //  console.dir( animationScaleObjs[n] );
              
                if( animationScaleObjs?.[n]?.obj)
                {
                    animationScaleObjs[n].obj.scale.z=scale/150;
                    animationScaleObjs[n].obj.position.z=1;
                }
               else
               {
                    console.error("animationScaleObjs?.[n]"+n);
                } 
            }
            
        }
    }

        this.doTheChange= () =>
        {
            const animAction=this.getTheChange();

            this.doAction(animAction);
        }
    }


export function initAnimate(occulsion, camera, cllbck)
{
    const b=new Anim(occulsion.animations.animationObjects, occulsion.animations.animationScaleObjects, cllbck);
    b.doAction(startInit);
    setTimeout( ()=>    b.doAction(finishInit) ,1000)
}


let a;

export function animate(occulsion, camera, cllbck)
{
    if(!a)
        a=new Anim(occulsion.animations.animationObjects, occulsion.animations.animationScaleObjects, cllbck);

    if(a.frames++ > 360)  //  3600 
    {
            a.doTheChange();
            a.frames=0;            
    }

    if(a.scale1)
    {    
        if(a.frames1>2000)
            a.frames1=2000;
        else
        if(a.frames1<0)
            a.frames1=0;
        else 
            a.frames1+=a.scale1;
    }

    if(a.scale2)
    {
        if(a.frames2>2000)
            a.frames2=2000;
        else
        if(a.frames2<0)
            a.frames2=0;
        else
            a.frames2+=a.scale2;
    }

    if(a.scale3)
    {
        if(a.frames3>2000)
            a.frames3=2000;
        if(a.frames3<0)
            a.frames3=0;
        else
            a.frames3+=a.scale3;
    }

    if(a.scale4)
    {
        if(a.frames4>2000)
            a.frames4=2000;
        else
        if(a.frames4<0)
            a.frames4=0;
        else
            a.frames4+=a.scale4;
    }

    if(a.scale1)
    {
    //    if(a.frames1 % 100 === 0)
            a.doTheScaling(a.frames1/10,1);
    }
    if(a.scale2)
    {
        // if(a.frames2 % 100 === 0)
            a.doTheScaling(a.frames2/10,2);
    }
    if(a.scale3)
    {
    //    if(a.frames3 % 100 === 0)
            a.doTheScaling(a.frames3/10,3);
    }
    if(a.scale4)
    {
    //   if(a.frames4 % 100 === 0)
            a.doTheScaling(a.frames4/10,4);
    }
  
}