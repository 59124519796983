import React, {useState} from "react";
import useModal from "./useModal.web";
// @ts-ignore
import Modal from "./modal.web";

let ModalContext;
let { Provider } = (ModalContext = React.createContext({}));

let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent } = useModal();

  const [modalStyle, setModalStyle] =useState({});
  const [trigger, setTrigger] =useState(0);

  return (
    <Provider value={{ modal, handleModal, modalContent, modalStyle, setModalStyle, getModal: ()=>modal,  setTrigger }}>
      <Modal modalStyle={modalStyle}  trigger={trigger} />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
