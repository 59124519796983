// @ts-ignore
//- import { zoompageScrollAction } from "./getCameraCoordsBasedOnScroll";
//- import { setURL } from "./widgets/TopMenu";

import { setCameraFrom2DPos } from './widgets3D/hud';

import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

/**
 * 3D Raycast based mouse or touch click actions
 * 
 * @param {string} firstname
 * @param {string} zoompage
 * @param {function} setZoompage
 * @param {object} scrollBarRef
 * @param {any} pos
 * @param {boolean} mobile
 * @param {function} [setCameraGoal]
 * @param {function} [camera]
 * @param {object} [subPageR]
 * @param {object} [controls]
 * @param {object} [setControllerGoal]
 * @param {object} [various]
 * @param {object} [r]  the responsiveness breakpoint
 */
export default function clickEventAction(firstname, zoompage, setZoompage, 
                scrollBarRef, raycastEvents,  pos={x:0, y:0}, mobile, 
                setCameraGoal, camera, subPageR, controls, setControllerGoal, various, r)
{
    switch(firstname)
    {
        case "hudSprites/hud-galleryentrance":
                setCameraFrom2DPos(pos,  controls);

        if(rsp({
            sp:  1, 
            sl:  1, 
            mp: 1,
            tp:  0,
            mtl: 1, 
            tl: 1, 
            sll: 0, 
            lll: 0, 
            dl: 0 }, r))
                    { various.setShowHUD(false);}
                break;
        case "hudSprites/close":
                various.setShowHUD(false);
                break;
        default:    
        if(rsp({
            sp:  1, 
            sl:  1, 
            mp: 1,
            tp:  0,
            mtl: 1, 
            tl: 1, 
            sll: 0, 
            lll: 0, 
            dl: 0 }, r))
                 { 
                      various.setShowHUD(false) }     
    }

}


/**
 * @param {string} name
 * @param {string} [zoompage]
 */
export function openModelFromText(name, zoompage)
{
    return [{name: 'screen4', zoompage: 'home'}, 
    {       name: 'planetWorldofArt2-1stpageC', 
            zoompage:'planetWorldofArt2'},
        ].find(n=> n.name===name && 
            n.zoompage===zoompage); 
}

