import React,{useContext} from 'react';

// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';
import ModelViewer from '../../components3D/multiEnv/ModelViewer.web';
// import Info from './Info';
import InfoContext from './../../InfoContext';


export default function AR_Radio()
{
    const {r, contentModel: {infoboxes}, l} =  useContext(InfoContext);

    const { contentModel} =  useContext(InfoContext);

    return <>
        {/* <Info qr={infoboxes?.radio?.qr} link={infoboxes?.radio?.link} />     */}
        <ModelViewer src={URL3D+"AR/radio.glb"}
        iosSrc={URL3D+"AR/radio.usdz"}
        alt="model viewer"
        ar={true}
        loading="lazy"
        cameraControls={true}
        poster={URL3D+'AR/radio.png'}
        autoplay={true}
        style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: black;"}
        />
</>
}