const envProduction=[ 
     ['SERVER_PATH_URL_', '/'],
     ['CLIENT_PATH_URL', '/'],
     ['CLIENT_STATIC_PATH_URL', '/'],
     ['PROJECT_PATH', '/'],
     ['STATIC_PATH_','/']
   ];

   const envProductionDocker=[ 
     ['SERVER_PATH_URL_', '/'],
     ['CLIENT_PATH_URL', '/'],
     ['CLIENT_STATIC_PATH_URL', '/'],
     ['PROJECT_PATH', '/'],
     ['STATIC_PATH_','/']
   ];

   const envDevelopment=[
     ['SERVER_PATH_URL_', 'http://localhost:5000/'],
     ['CLIENT_PATH_URL', 'http://localhost:3000/'],
     ['CLIENT_STATIC_PATH_URL', 'http://localhost:3000/'],
     ['PROJECT_PATH', '/'],
     ['STATIC_PATH_','/']
    ];

    module.exports ={envProduction:envProduction, envDevelopment:envDevelopment, envProductionDocker}