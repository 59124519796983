// @ts-nocheck
import React,{ useContext} from 'react';

import { View, Text } from '../../../multiEnvComponents/rnwComponents.web';

import { H1 } from '../../../multiEnvComponents/texttags.web';

import { Image } from 'react-native';

import   Button  from '../../../multiEnvComponents/ButtonA.web';

import FooterFooter from '../../styledComponents/footerfooter.web';   // 'widgets/styledComponents/footerfooter';


import StyleSheet from 'react-native-media-query';

import InfoContext from '../../../InfoContext';

import rsp from "../htmlcomponents/useResponsiveBrk";

import OnScreenParallax from '../../../multiEnvComponents/OnScreenParallax'

import { CLIENT_PATH } from '../../../config/serverURLPath.web';

const {ids, styles} = StyleSheet.create({
    image2: {
        marginTop: "100px",
        '@media (max-width: 1200px)': {
            marginTop: "0"
        },
        marginBottom: "20",
    },
    aboutPatents: {
        "display": "flex",
        "alignItems": "center",
        "textAlign": "center",
        marginTop: 200
    },
    title: {
        "fontFamily":"Source Sans Pro","fontSize":"42px","fontStyle":"normal",
        "fontWeight":"600","lineHeight":"64px","letterSpacing":"0px",
        "textAlign":"center", color: "white", width: "50%",alignSelf: "center"
    },
    subTitle: {"fontFamily":"Source Sans Pro","fontSize":"24px","fontStyle":"normal","fontWeight":"400",
    "lineHeight":"30px","letterSpacing":"0px","textAlign":"center", color: "white",  width: "50%", 
    alignSelf: "center", flexBasis: 1}
});


const Title = ({title, r}) =>  {
    const titleStyle= {"fontSize": rsp({sp:  32, 
            sl:  32, 
            mp: 32,
            tp:  32,
            mtl: 32, 
            tl: 42, 
            sll: 42, 
            lll: 42, 
            dl: 52 }, r) ,
            "lineHeight":   rsp({sp:  "40px", 
            sl:  "40px", 
                mp: "40px",
                tp:  "40px",
                mtl: "40px", 
                tl: "56px", 
                sll: "56px", 
                lll: "56px", 
                dl: "56px"}, r)}

    // @ts-ignore
    return <H1 n="FooterTitle" style={{ ...styles.title, ...titleStyle }} dataSet={{ media: ids.title }} >
        {title}
</H1>;
}

// @ts-ignore
const SubTitle = ({title, r}) => {
    
    const titleStyle= {"fontSize": rsp({
        sp:  18, 
        sl:  18, 
        mp: 18,
        tp:  18,
        mtl: 18, 
        tl: 22, 
        sll: 22, 
        lll: 22, 
        dl: 24 }, r) ,
        "lineHeight":   rsp({
            sp:  "30px", 
            sl:  "30px", 
            mp: "30px",
            tp:  "30px",
            mtl: "30px", 
            tl: "30px", 
            sll: "30px", 
            lll: "30px", 
            dl: "30px"}, r)}
    
    // @ts-ignore
    return <Text  n="FooterSubTitle"  
                style={{ ...styles.title, ...titleStyle, marginTop: 24 }}  
                // @ts-ignore
                dataSet={{ media: ids.SubTitle }} >
                        {title}
                </Text>}


/**
 * @param {object} props 
 * @param {object} [props.extrastyle] 
 * @param {object} [props.adjust] 
 * @param {object} [props.mobile] 
 */
const PlanetImage = ({extrastyle, adjust, mobile }) =>  <View  nativeID="planetView_Footer_subpagePlanetImage1" style={{width: "357px", height: "323px"}}>
                                            <View  nativeID="planetView_Footer_subpagePlanetImage2"
                                                // @ts-ignore
                                            style={
                                                    {  "filter": "drop-shadow(0px 4px 204px rgba(174, 153, 182, 0.63))"}
                                            }>            
                                                        <Image source={{uri: "graphics2D/planetwireframe.svg"}} 
                                                                    style={{  width: "327px" , height: "323px" }}  
                                                                    containerStyle={{ width: "327px" , height: "323px",
                                                                    marginTop:  adjust ?  mobile ? "40vh":"30vh": 0
                                                                     }}
                                                                    />  
                                            </View>            
                        </View>


export default function AboutPatents({scroll2State, setScroll2, url="https://epo.org", title, subTitle, mainPage=false})
{
    const {r, contentModel: {subpages}, l, variousStates} =  useContext(InfoContext);

    if(!title)
         title=subpages?.footer?.title[l];

    if(!subTitle)     
        subTitle=subpages?.footer?.subtitle[l];

 const right1= rsp({sp: -189, 
    sl:  -189, 
    mp: -189,
    tp:  -189,
    mtl: -189, 
    tl: -149, 
    sll: -149, 
    lll: -149, 
    dl: -149 },
r);

const top1right= rsp({sp: -189, 
    sl:  -189, 
    mp: -189,
    tp:  -189,
    mtl: 100, 
    tl: 100, 
    sll: 100, 
    lll: 100, 
    dl: 100 },
r);

const bottom1uparrow= rsp({sp: 200, 
    sl: 120, 
    mp: 120,
    tp: 120,
    mtl: 120, 
    tl: 120, 
    sll: 140, 
    lll: 140, 
    dl: 140 },    
        r);

const mobile= rsp({
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 0, 
        tl: 0, 
        sll: 0, 
        lll: 0, 
        dl: 0 }, r);   

    const mrgnTop= rsp({
            sp:  100, 
            sl:  100, 
            mp:  170,
            tp:  170,
            mtl: 170, 
            tl:  170, 
            sll: 200, 
            lll: 200, 
            dl:  200 }, r);

  

    return <View style={styles.aboutPatents}
    // @ts-ignore
        dataSet={{ media: ids.aboutPatents }}  nativeID="footerSubPage" >  
       <OnScreenParallax top={mrgnTop}
            layer1={   <View nativeID="footer_layer1" >
                            <View  style={{left: -180}}  nativeID="planetLeftView_Footer">
                                <View   nativeID="footerSt"  style={{ top: -100}}>
                                    <PlanetImage adjust={false} mobile={mobile} />
                                </View>       
                            </View>
                            <View style={{right: right1,  width: "95vw", flexDirection: "row-reverse"}} 
                                nativeID="planetRightView">
                                    <View style={{ top: top1right }}>
                                        <PlanetImage  adjust={mobile}  mobile={mobile} />
                                    </View>    
                            </View>
                </View>} 

            layer2={
                <View style={{alignSelf: "center", width: "100vw"}}  nativeID="Footer_layer2" >
                    <Title title={title} r={r} />
                    <SubTitle title={subTitle} r={r} />
                    <Button title={subpages?.footer?.button[l]} 
                            buttonStyle={{
                                alignSelf: "center",   
                                marginTop: "50px",
                                "fontFamily": "Source Sans Pro",
                                "fontStyle": "normal",
                                "fontWeight": "600",    
                                "letterSpacing": "1px",  
                        }}
                        onPress={()=> window.open(url,"_blank", "noopener") }
                        />
                        <View  nativeID='mysticalHeight' style={{ width: "100vw"}} >
                            <View nativeID="copyrightSubpage" style={{color: "white", 
                                            marginTop: 200 , paddingTop: 5, paddingBottom: 20, // mrgnTop
                                            backgroundColor: "#23212C", ...((  
                                                    ((!mainPage && scroll2State >  2200) || 
                                                       (mainPage && scroll2State > 2000)   //  variousStates?.scrollHeight
                                                    )
                                                     && !mobile) ? {
                                                position: "fixed", bottom: 0,
                                                width:  "calc(100vw - 18px)",
                                                left:0} 
                                             : {})   }}>
                                              <View nativeID='mysticalFill1' style={{color: "white", marginTop: mobile ? 100: 0}}>
                                                </View>
                                             <FooterFooter mainpage={mainPage} /></View>
                        </View>
                        <View nativeID='mysticalFill2' style={{color: "white", marginTop: mobile ? 0: (mainPage ? 600: 300 )}}>
                        </View>
                     
            </View>}
             scroll={scroll2State}
        />

        { scroll2State >2500 && !mainPage &&
            <View nativeID="UP arrow" 
                onClick={()=>setScroll2(0) }
            // @ts-ignore
                style={{position: mobile ? "absolute" :"fixed", width: 80, height: 80, zIndex: 2000, bottom: bottom1uparrow, right:60}}>
                <Image source={{uri: CLIENT_PATH+'graphics2D/uparrowRing.svg'}} style={{ 
                    width: 80, height: 80
                }}/>
            </View> }
        <View>

        </View>
    </View>

}