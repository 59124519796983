import React from 'react';
import { C } from  '../../../widgets/subPages/htmlcomponents/blocktags'; 
import {  ImageSVG } from  '../../../multiEnvComponents/Image.web'; 
import { CLIENT_PATH } from  '../../../config/serverURLPath.web'; 
import rsp from "../../../widgets/subPages/htmlcomponents/useResponsiveBrk";


import InfoContext from '../../../InfoContext';

export default function MapButton({various, clickinfo, marginLeft})
{    
    const showing= various?.showHUD;
    const setShowing= various?.setShowHUD;

    let { r} = React.useContext(InfoContext);


    return <C n="mapbutton" st={{marginLeft}}>
            {
                !showing &&    
                        <ImageSVG source={{uri: CLIENT_PATH+
                                "graphics2D/artgallery/map/map.svg"}} 
                            hoverImageSource={{uri: CLIENT_PATH+
                                "graphics2D/artgallery/map/map_hover.svg"}} 
                        width={60} 
                        height={60} 
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            if(rsp({
                                sp:  1, 
                                sl:  1, 
                                mp: 1,
                                tp:  0,
                                mtl: 1, 
                                tl: 0, 
                                sll: 0, 
                                lll: 0, 
                                dl: 0 }, r))
                                setTimeout( ()=>setShowing(v=>!v) , 100);
                             else
                                    setShowing(v=>!v)
                            }
                        
                        }
                    />
            }
           </C>
}