import React,{useState, useContext} from 'react';

import InfoComp from './InfoComp';
import InfoContext from '../../InfoContext';

import { clickList } from './VRInfo';

export default function VRInfo({infoBoxState})
{
    const {r, contentModel: {infoboxes}, l, setZoompage} =  useContext(InfoContext);

    return <InfoComp  image={infoboxes.ar.image}  icon={infoboxes?.ar?.icon} 
                    title={infoboxes?.ar?.title?.[l]} 
                    p1={infoboxes?.ar?.first?.[l]}
                        buttonTopText={infoboxes?.ar?.buttonText?.[l]}
                        buttonText={infoboxes.ar?.button?.[l]}
                        buttonClick={()=>{setZoompage('planetInventions3')}}
                        arrowLeftClick={()=>{infoBoxState.setInfoBoxState('vrinfo')}}
                        arrowRightClick={()=>{infoBoxState.setInfoBoxState('v3dinfo')}}
                        whichOne={2}
                        clickList={clickList}
                        infoBoxState={infoBoxState}
/>
}

