import React, {useState, useContext} from 'react';
import {Image} from 'react-native';

// @ts-ignore
import { CLIENT_PATH } from  '../../../config/serverURLPath.web';  

import { Vw, C,R } from '../htmlcomponents/blocktags';
// @ts-ignore
import {H2,  H4,  P} from '../htmlcomponents/texttags';
//- import { Button } from 'react-native-elements';
import  Button  from '../../../multiEnvComponents/ButtonA.web';

import rsp from "../htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../InfoContext';

import TransitionArray from './ImageListAnimation';

import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

const MenuBtn =styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
`

export function ListImage({i, marginBetween, width, height, setZoomPage, n, extraTextStyle })
{
    const [hover, setHover]=useState(false);


    return <C n={'listCol_'+i} key={i} st={{marginLeft: marginBetween, marginRight: marginBetween, 
        marginBottom: 10, width: width || (336/2)}}>
                <div className="hoverImage"
                // @ts-ignore
                onClick={()=>{ 
                    if(setZoomPage)  setZoomPage( n.zoomPage  || n.img)}}  onMouseOver={()=> {  setHover(true);  } }  
                    onMouseOut={()=>setHover(false)}>
                { hover?  <Image source={{uri: CLIENT_PATH+'images/'+n.img_hover}} 
                        style={{height: height || (336/2),                                       
                        width: width ||  (336/2)}} resizeMode="stretch"  />
                        :
                        <Image source={{uri: CLIENT_PATH+'images/'+n.img}} 
                        style={{height: height || (336/2),                                       
                        width: width ||  (336/2)}} resizeMode="stretch"  />
                }

                </div>
                <P st={{textAlign: "center", ...extraTextStyle}}>{n.txt}</P>
    </C>

}


/**
 * An animated image list in a row
 * 
 * @param {object} props
 * @param {number} props.type 
 * @param {Array<object>} props.imageList 
 * @param {number} [props.width] the width of each image
 * @param {number} [props.height] the height of each image
 * @param {number} [props.marginBetween] space between each image
 * @param {object} [props.extraTextStyle] 
 * @param {object} [props.r] 
 * @param {object} [props.scroll2State] driven by scroll
 * @param {object} [props.transition] should it do transition animation
 * @param {object} [props.setZoomPage] the click action
 * @param {object} [props.w] width of list
 * @param {object} [props.w] width of list
 * @param {number} [props.tabIndex] higher than 0 the buttons are selectable by tab 
 * @returns 
 */
 export function ImageList({type=0, imageList, width, height, marginBetween=15, 
    extraTextStyle, r, scroll2State, transition=true,setZoomPage, w, tabIndex})
 {
     switch(type)
     {
         case 0:
             return <R st={{marginTop: 5, 
             flexWrap: rsp({sp: 'wrap', 
                 sl:  'wrap',  mp: 'wrap',
                 tp:  'wrap', mtl: 'nowrap', 
                 tl: 'nowrap', sll: 'nowrap', 
                 lll: 'nowrap', dl: 'nowrap' },
                     r),  
                     marginLeft: 0, 
                     }}>
               {
                 imageList && 
                 <TransitionArray w={w} transition={transition}   itemsA={
                        imageList.map((n, i)=>{
                            
                            return <C n={"transition_"+i} key={i} st={{marginLeft: marginBetween, 
                                marginRight: marginBetween, marginBottom: 10, width: width || (336/2)}}>
                                            <MenuBtn tabIndex={ (tabIndex > 0 ) ? tabIndex+i : -1   }
                                            // @ts-ignore
                                            onClick={()=>{ setZoomPage(n.img)}}>
                                                <Image source={{uri: CLIENT_PATH+'images/AR/'+n.img+'.png'}} style={{height: height || (336/2),
                                            
                                                width: width ||  (336/2)}} resizeMode="stretch"  />
                                            </MenuBtn>
                                            <P st={{textAlign: "center", ...extraTextStyle}}>{n.txt}</P>
                                        </C>})
                            }  
                            scroll2State={scroll2State}
                    />
             }
             </R> 
        case 1:
            return <R n="ImageListRow" st={{marginTop: 5, flexWrap: rsp({sp: 'nowrap',  // 'wrap'
            sl:  'nowrap',  mp: 'nowrap',
            tp:  'nowrap', mtl: 'nowrap', 
            tl: 'nowrap', sll: 'nowrap', 
            lll: 'nowrap', dl: 'nowrap' },
                r),  
                marginLeft: rsp({sp: 0, 
                    sl:  25,  mp: 0,
                    tp:  25, mtl: 0, 
                    tl: 0, sll: 0, 
                    lll: 0, dl: 0 },
                r), 
                }}>
          { imageList &&       
                  imageList.map((n, i)=>  <ListImage i={i} key={n+i}  marginBetween={marginBetween} width={width} height={height} 
                                                setZoomPage={setZoomPage} n={n} extraTextStyle={extraTextStyle}  />)} 
                    </R> 
         default:
     }
 }




export function ImageListPart({title, subTitle, buttonTitle, imageList, scroll2State  })
{
    const {r, setZoompage} =  useContext(InfoContext);

    const w=rsp({sp: 350, 
        sl:  350, 
        mp: 350,
        tp:  500,
        mtl: 600, 
        tl: 640, 
        sll: 640, 
        lll: 800, 
        dl: 1024 },
            r);
            
    const wh4=rsp({sp: 350, 
        sl:  350, 
        mp: 350,
        tp:  500,
        mtl: 600, 
        tl: 640, 
        sll: 640, 
        lll: 800, 
        dl: 1024 },
            r);

    const flex1=rsp({sp: 0, 
        sl:  0, 
        mp: 0,
        tp:  0,
        mtl: 0, 
        tl: 1, 
        sll: 1, 
        lll: 1, 
        dl: 1 },
            r);


    return <Vw st={{alignSelf: "center", marginTop: 200}}>
                <C n="transcol" st={{alignSelf: "center"}}>
                    <H2 n="transitiontitle" st={{alignSelf: "center", marginBottom: 15}}>{title}</H2>
                    <R n="transitionrow" st={{marginTop: 10,  flexDirection: flex1 ? "row": "column" }}>
                {  subTitle &&  <H4 n="wh4" st={{width:  wh4, marginBottom: 15, marginRight: 15}} >{subTitle}</H4> }
                   { buttonTitle && <Button disabled title={buttonTitle} containerStyle={{ alignSelf: "center", marginBottom: 10 }} /> }
                    </R>
                </C>
                <Vw n="w" st={{alignSelf: "center", width: w, }}><ImageList type={0} imageList={imageList} 
                        scroll2State={scroll2State} r={r} setZoomPage={setZoompage}  w={w} /></Vw>
            </Vw>           
}