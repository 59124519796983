import React,{useContext} from 'react';

import InfoContext from '../../../../InfoContext';

import TopVideo from '../../widgetComponents/topVideoPart'; 

export default function TopTop()
{
    const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return <TopVideo title={subpages?.worldart?.p1?.title[l]} 
            subtitle={subpages?.worldart?.p1?.subtitle[l]} 
            link={subpages?.worldart?.p1?.video[l]?.link}
            planetLeft={true}
             />
}