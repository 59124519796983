import { useReducer} from 'react';

/**
 * The small redux alternative
 * 
 * @param {any} state 
 * @param {any} action 
 * @returns 
 */
function reducer(state, action) {
    switch (action.type) {
        case 'setSiteMapOpened': 
          return {siteMapOpened: true}
        case 'setSiteMapClosed': 
          return {siteMapOpened: false}
        case 'setScrollHeight':
            return {scrollHeight: action?.payload};
      default:
    }
}

export const rdxInitValue={
     siteMapOpened: false,
    scrollHeight: 0
};


export function useVarious(initValue)
{  
  return useReducer(reducer, initValue);
}

