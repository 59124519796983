// @ts-nocheck
import {
	EventDispatcher,
	Quaternion,
	Vector3, 
	//  Euler, 
	//  Matrix4
} from 'three';

class FlyControls extends EventDispatcher {

	constructor( object, domElement ) {

		super();
		
		if ( domElement === undefined ) {

			console.warn( 'THREE.FlyControls: The second parameter "domElement" is now mandatory.' );
			domElement = document;

		}

		this.object = object;
		this.domElement = domElement;

		// API

		this.movementSpeed = 1.0;
		this.rollSpeed = 0.005;

		this.dragToLook = false;
		this.autoForward = false;

		// internals

		const scope = this;

		this.tmpQuaternion = new Quaternion();

		this.mouseStatus = 0;

		this.moveState = { up: 0, down: 0, left: 0, right: 0, forward: 0, back: 0, 
			pitchUp: 0, pitchDown: 0, yawLeft: 0, yawRight: 0, rollLeft: 0, rollRight: 0 };
		this.moveVector = new Vector3( 0, 0, 0 );
		this.moveVectorHorizontal = new Vector3( 0, 0, 0 );
		this.rotationVector = new Vector3( 0, 0, 0 );


		this.checkPitch = ()=>
		{
				let vct=new Vector3();
				
				vct=this.camera.current.getWorldDirection(vct);
				
				vct.projectOnPlane(new Vector3(0,1,0));
	
				vct.normalize();

				vct.multiplyScalar(0.01);
				return vct;
		}


		this.moveHorisontal =(dir, amount=10) => {
				const ptch=this.checkPitch();

				const backwardPtch= ptch.clone().negate();

				switch(dir)
				{
					case "f":
						for(let n=0; n<amount; n++)
							this.camera.current.position.add(ptch);
						break;
					case "b":
						for(let n=0; n<amount; n++)
							this.camera.current.position.add(backwardPtch);
						break;
					default:
				}
		}


		this.keydown = function ( event ) {

			this.kdown=true;

			if ( event.altKey ) {

				return;

			}

			switch ( event.code ) {

				case 'ShiftLeft':
				case 'ShiftRight': this.movementSpeedMultiplier = .1; break;

				case 'KeyW': this.moveState.forward = 60;
				break;
				case 'KeyS': this.moveState.back = 20;  break;

				case 'KeyA': this.moveState.left = 8; break;
				case 'KeyD': this.moveState.right = 8; break;

				case 'KeyR': this.moveState.up = 10; break;
				case 'KeyF': this.moveState.down = 10; break;

				case 'KeyT': this.moveState.pitchUp = 10; break;
				case 'KeyG': this.moveState.pitchDown = 10; break; 

				case 'ArrowUp': this.moveHorisontal("f", 60); break;
				case 'ArrowDown': this.moveHorisontal("b", 60); break;

				case 'ArrowLeft':  this.moveState.yawLeft = 6;  break;
				case 'ArrowRight': this.moveState.yawRight = 6; break;

				default:

			}

			this.updateMovementVector();
			this.updateRotationVector();
		};

	


		this.keyup = function ( event ) {

			this.kdown=false;

			switch ( event.code ) {

				case 'ShiftLeft':
				case 'ShiftRight': this.movementSpeedMultiplier = 1; break;

				case 'KeyW': this.moveState.forward = 0; break;
				case 'KeyS': this.moveState.back = 0; break;

				case 'KeyA': this.moveState.left = 0; break;
				case 'KeyD': this.moveState.right = 0; break;

				case 'KeyR': this.moveState.up = 0; break;
				case 'KeyF': this.moveState.down = 0; break;

		 		case 'KeyT': this.moveState.pitchUp = 0; break;
				case 'KeyG': this.moveState.pitchDown = 0; break; 
				case 'ArrowUp': this.moveState.forward = 0; break;
				case 'ArrowDown': this.moveState.back = 0; break;

				case 'ArrowLeft': this.moveState.yawLeft = 0; break;
				case 'ArrowRight': this.moveState.yawRight = 0; break;

	/* 			case 'ArrowLeft': this.moveState.left = 0; break;
				case 'ArrowRight': this.moveState.right = 0; break; */

				default:
			}

			this.updateMovementVector();
			this.updateRotationVector();

		};

		const MOVEMENTFACTOR=2;

		this.mousemove = function ( event, mStatus, mouseMoveEvent ) {
			this.kdown=true;

			if ( mStatus) {
				this.moveState.yawLeft =  mouseMoveEvent.movementX*MOVEMENTFACTOR;   /* -   (( ( event.pageX - container.offset[ 0 ] ) - halfWidth ) / halfWidth) */
				this.moveState.pitchDown = -mouseMoveEvent.movementY*MOVEMENTFACTOR;   // change 
			
				this.updateRotationVector();
			 }
		};

		this.mouseup = function ( event ) {
			this.kdown=false;

			this.moveState.forward = 0; 
			this.moveState.back = 0;

			this.moveState.yawLeft = 0;
			this.moveState.yawRight = 0;

			this.mouseStatus=0;
			this.moveState.yawLeft = this.moveState.pitchDown = 0;

			this.updateRotationVector();
		};

		this.update = function ( delta ) {

			const moveMult = delta * scope.movementSpeed;
			const rotMult = delta * scope.rollSpeed;

			scope.object.translateX( scope.moveVector.x * moveMult );
			scope.object.translateY( scope.moveVector.y * moveMult );
			scope.object.translateZ( scope.moveVector.z * moveMult );

			scope.object.rotateOnWorldAxis ( new Vector3(0,1,0),  scope.rotationVector.x*rotMult*Math.PI*2 ); 
			scope.object.rotateOnAxis ( new Vector3(1,0,0),  scope.rotationVector.y*rotMult*Math.PI ); 
		};

		this.updateMovementVector = function () {

			const forward =  this.moveState.forward; 

			this.moveVector.x = ( - this.moveState.left + this.moveState.right );
			this.moveVector.y = ( - this.moveState.down + this.moveState.up );


			this.moveVector.z = ( - forward + this.moveState.back );
		};

		this.updateRotationVector = function () {
			this.rotationVector.y = ( - this.moveState.pitchDown + this.moveState.pitchUp );
			this.rotationVector.x = ( - this.moveState.yawRight + this.moveState.yawLeft );
		};

		this.getContainerDimensions = function () {

			if ( this.domElement != document ) {

				return {
					size: [ this.domElement.offsetWidth, this.domElement.offsetHeight ],
					offset: [ this.domElement.offsetLeft, this.domElement.offsetTop ]
				};

			} else {

				return {
					size: [ window.innerWidth, window.innerHeight ],
					offset: [ 0, 0 ]
				};

			}

		};

		this.dispose = function () {

			window.removeEventListener( 'keydown', _keydown );
			window.removeEventListener( 'keyup', _keyup );

		};

		const _keydown = this.keydown.bind( this );
		const _keyup = this.keyup.bind( this );

		window.addEventListener( 'keydown', _keydown );
		window.addEventListener( 'keyup', _keyup );



		this.updateMovementVector();
		this.updateRotationVector();

	}

}


export { FlyControls };
