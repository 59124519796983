import React, {useContext/* , useState */} from 'react';

//import {Image} from 'react-native';

import { Image as ImageS } from '../../../multiEnvComponents/Image.web';
import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

import { Vw, C ,R  } from  '../../../multiEnvComponents/blocktags'; // '../htmlcomponents/blocktags';
// @ts-ignore
import {/* H2, */ H3, H4/* , H5 */, P } from '../../../multiEnvComponents/texttags.web';    // '../htmlcomponents/texttags.web';
// import StyleSheet from 'react-native-media-query';

import rsp from "../htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../InfoContext';

import OnScreenFadeInAnim from '../../../multiEnvComponents/OnScreenFadeIn';

// import ModelViewer from '../../../components3D/multiEnv/ModelViewer.web';

import { widthVideoBrk } from './topVideoPart';

// @ts-ignore
import { CLIENT_PATH } from '../../../config/serverURLPath.web';




// @ts-ignore
const DateInfo  =styled(R).attrs({
	className: "animated_buttn"
})`
  font-size: 18px;   // dl
  height: 26px;

   @media (max-width: 1919px) { // sll
          font-size: 16px;
          lineheight: 22px;
      }

  @media (max-width: 800px) {  // sp, mp
            font-size: 14px;
          lineheight: 22px;
      }
    `


const flx_={sp:  "", 
sl: "", mp: "", tp: "", mtl: "1 1 50%", tl: "1 1 50%", sll: "1 1 50%", lll: "1 1 50%", 
dl:  "1 1 50%" };

const marginRight1_= {sp:  0, 
 sl: 0, mp: 0, tp: 100, mtl: 50, tl: 150, sll: 100, lll: 0, 
    dl: 0 }

const showPlanet_={sp:  false, 
 sl: false, mp: false, tp: true, mtl: true, tl: true, sll: true, lll: true, 
    dl: true };

const  marginTop1_= {sp:  1024, 
 sl: 800, mp: 800, tp: 2000, mtl: 2000, tl: 1700, sll: 1700, lll: 1180, 
    dl: 1180 };

const paneWidth_= {sp:  350, 
 sl: 500, mp: 550, tp: 650, mtl: 450, tl: 450, sll: 800, lll: 800, 
    dl: 800 };

const mobile_={
 sp: 1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0, 
    dl: 0 };


const mobileWidth_={
 sp: 1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0, 
        dl: 0 };   



const cntr_={
 sp: 0, sl: 0, mp: 1, tp: 1, mtl: 0, tl: 0, sll: 0, lll: 0, 
    dl: 0 };


    const tl_={
 sp: 0, sl: 1, mp: 0, tp: 0, mtl: 1, tl: 1, sll: 0, lll: 0, 
        dl: 0 };


/**
 * 
 * 
 * @export
 * @param {object} props
 * @param {string} props.planetName
 * @param {object} props.planetLeft
 * @param {object} props.TopPane
 * @param {object} [props.BottomPane]
 * @param {object} [props.SpecialExtraPane]
 * @param {boolean} props.planetFixed
 * @param {boolean} props.TopTopPane
 * @param {string} [props.navigationFooterName]
 * @param {string} [props.grad]
 * @param {number} [props.scroll2State]
 * @param {string} [props.navigationFooterLeftPage] when user clicks on left arrow
 * @param {string} [props.navigationFooterRightPage] when user clicks on right arrow
 */
export function TopComponent({planetName, planetLeft, TopPane, BottomPane, planetFixed, SpecialExtraPane,
                                TopTopPane, 
                                grad="linear-gradient(180deg, rgba(216, 94, 124, 0) 0%, #D85E7C 100%)", 
                            })
{
    const {r} =  useContext(InfoContext);
    const flx=rsp(flx_,r);
    const marginRight1= rsp(marginRight1_,r);
    const showPlanet= rsp(showPlanet_,r); 
    const marginTop1 = rsp(marginTop1_,r);
    const paneWidth = rsp(paneWidth_,r);
    const mobile= rsp(mobile_, r);   
    const mobileWidth= rsp(mobileWidth_, r); 

    const tl= rsp(tl_, r);  
    const paneW=mobile ? {} : {width: paneWidth};  
    const cntr=rsp(mobileWidth, r) ? {alignSelf: "center", alignItems: "center", width: "100vw"} : {} ;  


    return <Vw st={{flexDirection:  mobile ? "column" : (planetLeft? "row": "row-reverse"), zIndex: 2000, display: "flex"}}  
                n="topcomponent_2" >
               
                <Vw n="grad_3" st={{"position": "absolute", 
                                                        width: "100vw",
                                                        "height": "484px",
                                                         "left": "0px",
                                                            "top": "0px", 
                                                                "background": grad}}>                                
                                            </Vw>  

              { showPlanet  ?   <Vw n="top flex"  st={{marginLeft: mobile ? 0 : (planetLeft? 100:50)}}> 
                                        <Vw n="planet" 
                                            st={{
                                            zIndex: -1,
                                            alignSelf: "center", 
                                            justifySelf:"flex-end", 
                                            position: planetFixed ? 'fixed' : 'static',
                                            flex:  mobile ? "1 1 100%" : "1 1 50%",
                                            
                                            marginRight:  marginRight1,

                                            marginTop: planetFixed? -120: marginTop1 }}>                                                                                 
                                         </Vw>
                                    </Vw> : <></> }
                <Vw n="vwFlex_2"  st={{marginRight: mobile ? 0:25, overflow: "visible", flex: flx }}> 
                        <C  n="flexCol" st={{marginLeft: planetLeft? 0: ((mobile && !tl) ? 0 : 100), overflow: "visible", marginTop: (mobile ? 100 : 0) }} >
                            <OnScreenFadeInAnim st={{alignSelf: mobile? "center":"start"}} n="TopPaneAnim">
                                   {TopPane ? <Vw n="TopPane_pane" st={{marginTop: mobile ? 0 : 100, 
                                                            ...paneW, ...cntr , overflow:"visible", width: mobile ? "" : "55vw"}}>{TopPane}
                                    </Vw> :  <Vw n="TopPane_pane" st={{marginTop: mobile ? 0 : 100}}></Vw> }
                            </OnScreenFadeInAnim>
                            {BottomPane && <OnScreenFadeInAnim st={{alignSelf: mobile? "center":"start", marginTop: 0}}  n="BottomPaneAnim">
                                        <Vw n="BottomPaneVw" st={{...paneW, ...cntr  }}>
                                            {BottomPane}
                                        </Vw>
                            </OnScreenFadeInAnim> }
                            <OnScreenFadeInAnim st={{alignSelf: mobile? "center":"start"}}  n="TopTopPaneAnim">
                                        <Vw n="TopTopPane" st={{...paneW, ...cntr , overflow: "visible",  marginLeft:0 }}>
                                            {TopTopPane}
                                        </Vw>
                            </OnScreenFadeInAnim>
                           {SpecialExtraPane && <OnScreenFadeInAnim st={{alignSelf: mobile? "center":"start", marginTop: 0}}  n="SpecialExtraPaneAnim">
                                        <Vw n="SpecialExtraPane" st={{...paneW, ...cntr  }}>
                                            {SpecialExtraPane}
                                        </Vw>
                            </OnScreenFadeInAnim> }
                        </C>
                </Vw>
            </Vw>
}



const paneDiv_={sp:  false, 
    sl:  false, 
    mp: false,
    tp:  false,
    mtl: false, 
    tl: false, 
    sll: false, 
    lll: true, 
    dl: true };



const marginBottom1_={sp:  10, sl:  3,  mp: 3, tp:  3,
    mtl: 3, tl: 6, sll: 16, lll: 16, dl: 16 };

const show1_={sp:  false, sl:  false,  mp: false, tp:  false,
    mtl: false, tl: false, sll: true, lll: true, dl: true };

const marginBottom2_ ={sp:  12, sl:  12,  mp: 12, tp:  12,
    mtl: 12, tl: 32, sll: 32, lll: 32, dl: 32 };

// const marginLeft2_={sp:  0, sl:  0, mp: 0,
//     tp:  0, mtl: 0, tl: 0, sll: 15, lll: 10, dl: 10 };

// const cntr1_= {
//         sp:  0, 
//         sl:  0, 
//         mp: 1,
//         tp:  1,
//         mtl: 0, 
//         tl: 1, 
//         sll: 0, 
//         lll: 0, 
//         dl: 0 }


/**
 * 
 * @param {object} props 
 * @param {object} [props.dateInfo]
 * @param {object} props.title
 * @param {object} [props.subTitle]  
 * @param {object} [props.sideInfo] 
 * @param {object} props.imageName 
 * @param {object} [props.imageStyle] 
 * @param {object} [props.planetLeft] 
 * @param {object} [props.imageAlt] 

 * @returns JSX
 */
export function Pane({dateInfo="", title, subTitle="", sideInfo, imageName, imageAlt="",
                       /*  imageStyle={width: "100%", height:"100%"}, */ planetLeft=false })
{
   // console.log("imageAlt "+imageAlt);

    const {r} =  useContext(InfoContext);

    const paneDiv=rsp(paneDiv_, r)? "row": "column";
//    const marginLeft1 =rsp(marginLeft1_,r);
    const marginBottom1=rsp(marginBottom1_, r);
    const marginBottom2=rsp(marginBottom2_,r);
    const show1=rsp(show1_,r); 
//    const marginLeft2 =rsp(marginLeft2_,r)
    const mobile= rsp({
            sp:  1, 
            sl:  1, 
            mp: 1,
            tp:  1,
            mtl: planetLeft ? 1 : 0, 
            tl: planetLeft ? 1 : 0, 
            sll: 0, 
            lll: 0, 
            dl: 0 }, r);   

    const cntr=rsp(cntr_, r) ? {alignSelf: "center", alignItems: "center", 
                                            textAlign: "center",  flexGrow: 10} :  (mobile ? { alignSelf: "center" } : {} )  ;  

    const widthVideo= rsp(widthVideoBrk, r);
// flex={flx}
   return <Vw n="topofPane" st={{marginTop:  mobile? 0: 50, ...cntr, 
                                overflow: "visible", alignSelf: mobile ? 'center' : 'start' /*,  height: mobile ? "100vh" : "" */ }}>
                 <DateInfo n="dateInfo"  st={{flexDirection: mobile? "column": "row", overflow: "visible", 
                       "fontFamily": "Source Sans Pro",
                         "fontStyle": "normal",
                        "fontWeight": "400",
                        color: "white", alignSelf: mobile ? 'center' : 'start' }}>
                    {dateInfo}
                 </DateInfo>
                <R  n="r_" st={{flexDirection: mobile? "column": "row", overflow: "visible", marginBottom: mobile ? 10 : 0 }}>
                        <C n="topofPane_first_column" st={{...cntr,   alignSelf: mobile ? 'center' : 'start',  textAlign: mobile ? 'center' : 'start'}}>                        
                            <H3 n="topofPane_h3" st={{ marginBottom: marginBottom1, ...cntr, maxWidth:mobile? "80vw":"25vw"}}>{title}</H3>
                        { show1 &&  <H4 st={{width: 505, marginBottom: marginBottom2, ...cntr}} >
                                {subTitle}</H4> }
                        </C>
                        <C n={"paneDiv2ndColum_"+title} st={{overflow: "visible",   ...cntr, flexGrow: 1}}  >
                                {sideInfo}
                            </C>
                   
                </R>

                <Vw n="paneDiv" st={{flexDirection: paneDiv,  ...cntr, marginBottom: mobile ? "10vh": "15vh"}} >
                    <C n={"paneDiv_firstColum_"+title}  st={{...cntr, overflow: "visible"}}>
                        <ImageS source= {{uri: CLIENT_PATH+'images/'+imageName}} width={widthVideo}    imageAlt={imageAlt} 
                            containerStyle={{width: widthVideo}}  
                            imgClassName="rectangularBackgroundBlur2"                 
                         />
                    </C>
                  
                </Vw>
    </Vw>

}

