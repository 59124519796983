import React from 'react';

import styled from 'styled-components';


// @ts-ignore
import ModelViewer from './ModelViewer';

import InfoContext from '../../InfoContext';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';

import Saraceno from '../widgets/galleryInfo/Saraceno';
import Leitner from '../widgets/galleryInfo/Leitner';
import Belin from '../widgets/galleryInfo/Belin';

import { R, Vw, Vw2  } from  '../../widgets/subPages/htmlcomponents/blocktags';

import Birgisdottir from '../widgets/galleryInfo/Birgisdottir';
import Davar from '../widgets/galleryInfo/Davar';
import Greenfort from '../widgets/galleryInfo/Greenfort';

import GreenfortSculpture from '../widgets/galleryInfo/GreenfortSculpture';

import Kintera from '../widgets/galleryInfo/Kintera';
import Kwade from '../widgets/galleryInfo/Kwade';
import Panamarenko from '../widgets/galleryInfo/Panamarenko';

import Signer from '../widgets/galleryInfo/Signer';
import Wesely from '../widgets/galleryInfo/Wesely';

import EPOArtcollectionWallPoster from '../widgets/galleryInfo/EPOArtcollectionWallPoster';
import SustainabilityWallPoster from '../widgets/galleryInfo/SustainabilityWallPoster';


import InfoVR from '../../screens/info/VRInfo';
import InfoAR from '../../screens/info/ARInfo';
import InfoV3D from '../../screens/info/v3DInfo';

import InfoArtGallery from '../../screens/choices/InfoArtGallery';
import InfoMarconiAR from '../../screens/choices/InfoMarconiAR';
import InfoHoF from '../../screens/choices/InfoHoF';

import ArtGalleryInfoText from '../../screens/artgallery/ArtGalleryInfoText';
import ArtGalleryInstructionText from '../../screens/artgallery/ArtGalleryInstructionText';

import ArtGallerySettings from '../../screens/artgallery/ArtGallerySettings';

import {Image} from '../../multiEnvComponents/Image.web';

// import Sitemap from '../../widgets/styledComponents/Sitemap';


export default function ModalContent(name, position)
{
    let modelname="";
    if(typeof name==='string')
        modelname=name;
    else
        modelname=name?.name;

    return <ModelViewer src={URL3D+modelname+".glb"}
            iosSrc={URL3D+modelname+".usdz"}
            alt="model viewer"
            ar={true}
            loading="lazy"
            cameraControls={true}
            poster={URL3D+modelname+'.png'}
            autoplay={true}
            // eslint-disable-next-line react/style-prop-object
            style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: black;"}
            cameraTarget={position}
            /> 
}

/**
 * Styled Responsive box for art work info box
 *
 * @param {*} Span2.props
 */
const Span2 =
        // @ts-ignore
        styled(Vw2).attrs({
            className: "ArtworkSpan"
        })`
  
        display: flex;

        height: 60vh;

    //    overflow-y: auto;

        @media only screen and (max-height: 600px)
        {
            height: 40vh;

       //     overflow-y: scroll;
        }



        @media only screen and (min-height: 600px) and (max-height: 400px)
        {
            height: 32vh;

            overflow-y: scroll;
        }

        flex-flow: ${/**  @param {object} props
                          @param {boolean} props.rowCh row chooser */ 
                            // @ts-ignore
                            props => props.rowCh ? "column" :"row"};

   //     margin-left: "3vw";
`;

const respScale_={
    sp:  0.1, 
    sl:  0.1, 
    mp: 0.25,
    tp:  0.25,
    mtl: 0.3, 
    tl: 0.5, 
    sll: 0.7, 
    lll: 1, 
    dl: 1 }


const rowCh_={
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 0, 
        tl: 0, 
        sll: 0, 
        lll: 0, 
        dl: 0 }

    /**
     * Art Work Provider creates a box with image besides the art work info box content
     * 
     * @param {object} props
     * @param {object} props.children info box content
     * @param {string} [props.name] name of image
     * @param {number} [props.artwidth] image width
     * @param {number} [props.artheight] image height
     * @param {boolean} [props.rightMargin] a margin right?
     * @returns 
     */
function ArtWorkProvider({children, name, artwidth=400, artheight=300, rightMargin=true})
{
    const {r} = React.useContext(InfoContext);

    const respScale= rsp(  respScale_, r);  
    const rowCh= rsp(  rowCh_, r);  

    // @ts-ignore
    return  <Span2 rowCh={rowCh} n="Artworkprovider Span1" st={{margin: 15}} onMouseButtonDown={ e=>{
        
            e.preventDefault();
            e.stopPropagation();
    }}>
                        { name && <Vw2 st={{overflow: "none"}}>
                            <Image source={{uri: URL3D+"artworkInfo/"+name+".jpg"}} 
                                    containStyle={{width: artwidth*respScale, 
                                    height:artheight*respScale}} 
                                    style={{width: artwidth*respScale, 
                                            height: artheight*respScale, 
                                            marginLeft:rowCh ? "5vw": 0 }} />
                        </Vw2> }
                        <Vw2 n="Artworkprovider span2" st={{marginLeft: "3rem", marginTop: rowCh? 50 : 0, marginRight: rightMargin ?  "3rem": 0}}>
                                {children}
                        </Vw2>
    </Span2>
}

function GalleryInfoBoxProvider({children})
{
    return <Vw2 onWheel={e=>{e.stopPropagation()} } >
        {children}
    </Vw2>
}


export function createModalTextContent(name, camera, infoBoxState)
{ 
    switch (name)
    {
        case "LODButton_phong28": 
            return <ArtWorkProvider name={name} artwidth={1920/4} artheight={1080/4} rightMargin={false}><Saraceno /></ArtWorkProvider>
        case "LODButton_Sign_Lietner": 
            return <ArtWorkProvider name={name} artwidth={679} artheight={458}><Leitner /></ArtWorkProvider>
        case "LODButton_sign_Belin1_phong10": 
            return <ArtWorkProvider name={name} artwidth={644} artheight={429}><Belin /></ArtWorkProvider>
        case "LODButton_sign_Birisdottir": 
            return <ArtWorkProvider name={name}  artwidth={611} artheight={292}><Birgisdottir /></ArtWorkProvider>
        case "LODButton_sign_Dawar_phong11": 
            return <ArtWorkProvider name={name}  artwidth={600} artheight={390}><Davar /></ArtWorkProvider>
        case "LODButton_sign_TueGreenfort": 
            return <ArtWorkProvider name={name}  artwidth={1268/2} artheight={904/2}><Greenfort /></ArtWorkProvider>
        case "LODButton_TueGreenFortSculptureSignMat": 
            return <ArtWorkProvider name={name}  artwidth={478} artheight={444}><GreenfortSculpture /></ArtWorkProvider>

       case "LODButton_sign_Kintera": 
            return <ArtWorkProvider name={name}  artwidth={393} artheight={600}><Kintera /></ArtWorkProvider>
        case "LODButton_sign_Kwade": 
            return <ArtWorkProvider name={name}  artwidth={945/2} artheight={1187/2}><Kwade /></ArtWorkProvider>
        case "LODButton_Sign_Panamarenko": 
            return <ArtWorkProvider name={name} artwidth={939/2} artheight={629/2}><Panamarenko /></ArtWorkProvider>

        case "LODButton_Sign_SIgner": 
            return <ArtWorkProvider name={name} artwidth={741} artheight={251}><Signer /></ArtWorkProvider>
        case "LODButton_sign_wesley": 
            return <ArtWorkProvider name={name} artwidth={557/1.5} artheight={437/1.5}><Wesely /></ArtWorkProvider>

        case "LODButton_EPOArtcollection_wall_poster": 
            return <ArtWorkProvider ><EPOArtcollectionWallPoster /></ArtWorkProvider>
        case "LODButton_sustainability_wall_poster": 
            return <ArtWorkProvider ><SustainabilityWallPoster /></ArtWorkProvider>

        case "InteractiveUniverse_arinfo":
            return <InfoAR infoBoxState={infoBoxState} />
        case "InteractiveUniverse_vrinfo":
            return <InfoVR  infoBoxState={infoBoxState} />
        case "InteractiveUniverse_v3dinfo":
            return <InfoV3D  infoBoxState={infoBoxState} />

        case "info_artGallery":
            return <GalleryInfoBoxProvider><InfoArtGallery infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
         case "info_MarconiAR":
            return <GalleryInfoBoxProvider><InfoMarconiAR infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
        case "info_HoF":
            return <GalleryInfoBoxProvider><InfoHoF infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
        case "artgallery_InfoText":
            return <GalleryInfoBoxProvider><ArtGalleryInfoText infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
        case "artgallery_InstructionText":
            return <GalleryInfoBoxProvider><ArtGalleryInstructionText infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
        case "artgallery_Settings":
            return  <GalleryInfoBoxProvider><ArtGallerySettings infoBoxState={infoBoxState} /></GalleryInfoBoxProvider>
        default:
    }
}