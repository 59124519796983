import React from 'react';
import "./rnwComponents.css"

import InfoContext from '../InfoContext';

/**
 * Simulates React Native Web
 * 
 * @export
 * @param {object} props
 * @param {object} props.children
 * @param {object} [props.style]
 * @param {string} [props.nativeID]
 * @param {string} [props.className]
 * @param {function} [props.onClick]
 * @param {any} [props.ref_]
 *  
 * @returns 
 */
export function View({children, style, nativeID, className, onClick, ref_})
{
    const cntxt = React.useContext(InfoContext);
    // @ts-ignore
    return <div ref={ref_} className={ (className || "")+ " css-view-rnw rsp_"+cntxt?.r} style={style} 
                // @ts-ignore
                nativeid={nativeID} onClick={onClick}>{children}</div>
}


/**
 * Simulates React Native Web
 * 
 * @export
 * @param {object} props
 * @param {object} props.children
 * @param {object} [props.style]
 * @param {string} [props.nativeID]
 * @param {string} [props.className]
 *  @param {string} [props.onClick] 
 * 
 * @returns 
 */
export function Text({children, style, nativeID, className, onClick})
{
    const cntxt = React.useContext(InfoContext);
    // @ts-ignore
    return <p className={ (className || "")+" css-view-rnw rsp_"+cntxt?.r} style={style} nativeid={nativeID} onClick={onClick}>{children}</p>
}