// @ts-nocheck
import React /*,{useState, useEffect, useRef} */ from 'react';
import {Vw} from '../multiEnvComponents/blocktags';

import styled from 'styled-components';

const Compo1  =
styled(Vw).attrs({
    className: "Compo1"    
})`
    width :${p=> p?.width};
  //  background-color: red;
    display: block;
    align-self: center;
    justify-self: center;
`;


const Compo2  =
styled(Vw).attrs({
    className: "Compo2"    
})`
    width :${p=> p?.width};
    z-index: 30000;

   margin-top: -${p=> p?.verticaladjustment};
   display: block;


   position: relative;
`;


export default function Overlay({comp1, comp2, width, verticaladjustment})
{
    return <Vw n="Overlay"  st={{alignSelf: "baseline"}}>
                <Compo1>{comp1}</Compo1>
                <Compo2 width={width} height={width} verticaladjustment={verticaladjustment}>{comp2}</Compo2>
            </Vw>
}