import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./modalProvider.web";
import {Image} from "react-native";

//- import rsp from  "../widgets/subPages/htmlcomponents/useResponsiveBrk";

import InfoContext from '../InfoContext';

import "./modalwindow.css";


const large_={
  sp:  0, 
  sl:  0, 
  mp: 0,
  tp:  0,
  mtl: 0, 
  tl: 0, 
  sll: 0, 
  lll: 1, 
  dl: 1 };

/**
 * Opens popup windows 
 *
 * @param   {object}  props 
 * @param   {object}  [props.simple]
 * @param   {object}  [props.modalStyle]  
 * @param   {object}  [props.trigger]          
 *
 * @return  JSX
 */
const Modal = ({simple, modalStyle, trigger}) => {
  const [expand, setExpand] = useState(false);
  const [scroll, setScroll] = useState(false);

  let { modalContent, handleModal, modal } = React.useContext(ModalContext);
  const {r, finishedLoad: {finishedLoading}} =  React.useContext(InfoContext);

  const large= 0;  //rsp(large_,r); 
  
  React.useEffect(() => {

    function handleResize() { 
          const  aRatio = window.innerWidth / window.innerHeight > 2;
          setScroll( aRatio);  
      }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize)
     }

  }, []);

//   useEffect(
//     ()=>{console.log("trigger "+r); console.table(modalStyle.outer1); console.table(modalStyle.outer2); },
//     [r]
// )

  if (modal ===1) {
    return ReactDOM.createPortal(
      <div className="modal-win-outer" id="modal-win-outer" 
        style={{width: expand? "100vw": "25vw", 
                left: expand? 0 : "75vw", 
        }}  >
        <div style={{display: "flex", flexDirection: "column"}}>
            <div id="modalnearlytop" style={{flexDirection: "row", marginTop: 5, justifyContent:"start", marginBottom: 20}}>
                <div  onClick={() => handleModal(false,true)}>
                  <Image 
                          // @ts-ignore
                          nativeID="x" 
                          source={{
                                        uri: 'graphics2D/x.svg',
                                    }}  
                                    style={{ width: 15, height: 15, marginTop:5, marginLeft:5}} />
                  </div>
                  <div>
                   <button style={{marginLeft: 15, width: 100, color:"white",   "background": "#066AE3",
                    "borderRadius": "4px",   "fontFamily": "Source Sans Pro",
                     // @ts-ignore
                      "fontStyle": "normal", "fontWeight": "600", border: "none"}}
                      onClick={() => setExpand(e=> !e) }>{expand ? "Shrink width": "Expand width"}
                    </button> 
                  </div>
              </div>
            <div className="content">{modalContent}</div>
        </div>
      </div>,
      document.querySelector("#modal-root")
    );
  } else 
  if (modal === 2) {
 //  console.log("finishedLoading Portal: "+finishedLoading);
    return ReactDOM.createPortal(
      <div className="modal-win-outer2-backdrop" id={"modal-win-outer2-backdrop"+trigger}  style={{display: finishedLoading===1?'none': 'flex'}}>
        <div className="modal-win-outer2" id="modal-win-outer2" style={modalStyle.outer1}>
          <div  id="below_modal-win-outer2" style={{display: "flex", 
                      flexDirection: "column", width:"81vw", ...modalStyle.outer2}}>
              <div style={{flexDirection: "row", marginTop: 10, display: "flex",
                            justifyContent:"start", alignSelf: "end", 
                            marginBottom: (!finishedLoading || finishedLoading===2) ? 20:30, zIndex: 3000,
                            marginRight: 10}}>
                  {(!finishedLoading || finishedLoading===2) && <div id="clickdiv" onClick={() => handleModal(false,true, 0)}>
                  <Image 
                            // @ts-ignore
                            nativeID="xImage" 
                            source={{
                                          uri: 'graphics2D/x.svg',
                                      }}  
                                      style={{ width: 15, height: 15, marginTop:5, marginLeft:5}} />
                    </div>}
                </div>

              <div   id="modalnearlytop" className="content2modal"  style={{ height: "100%", ...modalStyle?.modalnearlytop,
              // @ts-ignore
              overflowY: ((large && !scroll) || modalStyle?.noscroll ? " hidden": "auto"), marginBottom: 20, marginRight: 3, marginLeft: 3}}>{modalContent}</div>
          </div>
        </div>
      </div>,
      document.querySelector("#modal-root2")
    );
  }
  else return null;
};

export default Modal;


