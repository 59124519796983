import React, {useRef, useEffect, useContext} from "react";

import InfoContext from '../InfoContext';

// import "./anim2d.css";
/**
 * @author JO (w42.se)
 * 
 * @export Lottie Player React Adaption: 
 * 
 * @param {object} props
 * @param {string} props.src a json file containing the animation and SVG
 * @param {string} [props.style] ordinary CSS style
 * @param {boolean} [props.autoplay]
 * @param {boolean} [props.loop]
 * @param {boolean} [props.open] changes to this trigger play animation
 * @param {string} [props.speed] 
 * @param {boolean} [props.rewindTrigg] 
 * @returns JSX element
 */
export default function AnimPlayer({src="", autoplay, loop=false, style, open, speed="1", rewindTrigg })
{
  //  let { zoompage } = React.useContext(InfoContext);

    const vRef=useRef();
 
    useEffect(()=>{
        // @ts-ignore
        if(vRef?.current?.style)
        {
          // @ts-ignore
          vRef.current.style=style;
        }
    },
    []
    )

    useEffect(()=>{
        // @ts-ignore
        if(vRef?.current?.setDirection && vRef?.current?.play)
        {
             // @ts-ignore
            vRef.current.setDirection(open ? -1 : 1 );
            // @ts-ignore
            vRef.current.play();
        }

        // @ts-ignore
        if(vRef?.current?.style)
        {
          // @ts-ignore
          vRef.current.style=style;
        }

    },
    [open]
    )

    useEffect(()=>{
      // @ts-ignore
      if(vRef?.current?.setDirection && vRef?.current?.play)
      {
           // @ts-ignore
          vRef.current.setDirection( open ? -1 :  1  );
          // @ts-ignore
          vRef.current.play();
      }
  },
  [rewindTrigg]
  )



    useEffect(()=>
        {
            // @ts-ignore
            if(vRef?.current?.setDirection)
            {            
                // @ts-ignore
              vRef.current.style=style;
              // @ts-ignore
              vRef.current.src=src;
                 // @ts-ignore
              vRef.current.loop=loop;
                // @ts-ignore
              vRef.current.setDirection(-1);
               // @ts-ignore
              vRef.current.play();
            }
        }
            ,[style, src])

    // eslint-disable-next-line react/style-prop-object
    return  <div id="anim2dplayer"  style={{ color: "#ffffff"}} ><lottie-player ref={vRef} src={src}   background="transparent" speed={speed} 
                  loop={loop} autoplay={autoplay} preserveAspectRatio="none">
                  </lottie-player></div>
}

