import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

export default function Greenfort()
{
     const {contentModel: {infoboxes}, l} =  useContext(InfoContext);
    return <div>
        	<ArtWorkHeader  
				artistname="Tue Greenfort"
                title="PET bottles, fused"
                year=""
                material="PET (polyethylene terephthalate)"
                measurements="40 x 30 x 30 cm" 
        />
        <div>
        {infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_TueGreenfort.text[l]}
        </div>
        </div>
}