import React, { useState, useEffect } from 'react';
// import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

import {Vw, C } from  '../../../multiEnvComponents/blocktags';  // '../htmlcomponents/blocktags';

// import TourButton from './TourButton';
//  import ArtGalleryMenu from './ArtGalleryMenu';
import MapButton from './MapButton';

import { ImageSVG } from  '../../../multiEnvComponents/Image.web' 
import { CLIENT_PATH } from  '../../../config/serverURLPath.web'; 

// import {BtnC} from './ArtGalleryInterface';
import { ModalContext } from '../../../multiEnvComponents/modalProvider.web';  
import  {createModalTextContent}  from   '../../../components3D/multiEnv/createModalContent.web' 
import SoundBtn from './sound';


import rsp from "../../../widgets/subPages/htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../InfoContext'; 

const t={top:0, marginBottom: 20, marginRight: 5}



export default function ArtGalleryInterface({various, clickinfo})
{
      let {r, finishedLoad: {finishedLoading}} = React.useContext(InfoContext);

      const mobile= rsp({
            sp: 1, sl: 1, mp: 1, tp: 0, mtl: 1, 
            tl: 1, sll: 0, lll: 0, dl: 0 }, r);    
       
      let { handleModal, setModalStyle} = React.useContext(ModalContext);

              const [infoBox, setInfoBoxState]=useState("");
              
              const [menuState, setMenuState]=useState(false);

              const help=()=>{
                      setModalStyle({
                        outer1: mobile? {width: "20rem", maxWidth: "95vw", height: "30rex"} : {height: "", width: "25rem"},  
                        outer2: mobile? { width: "20rem", maxWidth: "95vw"} : { width: "25rem" },
                    });

                    handleModal(
                        createModalTextContent(  "artgallery_InstructionText" , false, {infoBox, setInfoBoxState} ), 
                        false, 2);      
              }

              useEffect(
                ()=>{
                  if(finishedLoading)
                    setTimeout(() => {
                        help();
                    }, 1500); 
                },
                [various?.setShowHUD]
              );

              const info=e=>{
                e.preventDefault();
                e.stopPropagation();

                setModalStyle({
                    outer1: { width: "80vw", height: "80vh" },
                     outer2: { width: "80vw", height: "80vh" }
                 });
                 
                 handleModal(
                      createModalTextContent(  "artgallery_InfoText" , false, {infoBox, setInfoBoxState} ), 
                                                  false, 2);      
                } 




    return <C n="art_gallery_interface" st={{marginLeft: 30, marginTop: 110, height: "80vh"}}>
                { mobile ? <Vw n="mystiskaMenu"  st={{...t}}>                              
                              <ImageSVG source={{uri: CLIENT_PATH+"graphics2D/artgallery/extramenubuttons/extramenu.svg" }} 
                                  hoverImageSource={{uri: CLIENT_PATH+ "graphics2D/artgallery/extramenubuttons/extramenu_hover.svg"}} 
                                  width={60} 
                                  height={60} 
                                  onClick={ e=> {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setMenuState(v=>!v)} }  
                                />
                        </Vw>:<></> }
              { (!mobile || menuState) && 
              <>
              <C st={{flexDirection: mobile ? "row": "column"}}>                               
                  <Vw st={{...t}}  n="about" >
                              <ImageSVG  source={{uri: CLIENT_PATH+"graphics2D/artgallery/extramenubuttons/extramenu/about.svg" }} 
                                 hoverImageSource={{uri: CLIENT_PATH+ "graphics2D/artgallery/extramenubuttons/extramenu/about_hover.svg"}} 
                                aria={{
                                    haspopup: "true"
                                  }}

                                width={60} 
                                height={60} 
                                onClick={ e=>info(e)}
                                />
                            </Vw>
                            <Vw st={{...t}} n="help">
                              <ImageSVG  source={{uri: CLIENT_PATH+"graphics2D/artgallery/extramenubuttons/extramenu/help.svg" }} 
                                hoverImageSource={{uri: CLIENT_PATH+ "graphics2D/artgallery/extramenubuttons/extramenu/help_hover.svg"}} 
                                width={60} 
                                height={60} 
                                onClick={ e=>{
                                          e.preventDefault();
                                          e.stopPropagation();

                                        help()
                                    } 
                                }
                                aria={{
                                    haspopup: "true"
                                  }}
                                />
                            </Vw>
                            <Vw st={{...t}}  n="settings">
                              <ImageSVG  source={{uri: CLIENT_PATH+"graphics2D/artgallery/extramenubuttons/extramenu/settings.svg" }} 
                                width={60} 
                                height={60} 
                                onClick={ e=>{
                                          e.preventDefault();
                                          e.stopPropagation();

                                          setModalStyle({
                                              outer1: { width: mobile? "90vw": "30vw", maxHeight: "50vh" },
                                               outer2: { width: mobile? "90vw":"30vw", maxHeight: "50vh" },
                                               modalnearlytop: { display: "flex"}
                                           });
                                           
                                           handleModal(
                                                createModalTextContent(  "artgallery_Settings" , false, {infoBox, setInfoBoxState} ), 
                                                                            false, 2);      
                                    } 
                                }
                                aria={{
                                    haspopup: "true"
                                  }}
                                />
                            </Vw>
                            <Vw st={{...t}} n="sound">
                                <SoundBtn  />
                            </Vw>
                      
              </C>
              <C st={mobile ? {}: {flex: 1, justifyContent: "end",}}> 
                  <MapButton various={various} clickinfo={clickinfo}  marginLeft={mobile?  "96px" :0} />
              </C>
              </>}
           </C>
}