import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";
/*
     <p><span>Tue Greenfort</span></p>
     <p><span>Rain I</span><span>, 2008</span></p>
     <p><span>pH indicator strips, rain</span></p>
     <p><span>70 x 100 cm</span></p>
*/
export default function Greenfort()
{
      const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
        	<ArtWorkHeader  
				artistname="Tue Greenfort"
                title="Rain I"
                year="2008"
                material="pH indicator strips, rain"
                measurements="70 x 100 cm" 
        />
         <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_TueGreenfort.text[l]}</p>
    </div>
}