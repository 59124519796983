
import React, {  useState,  useRef, useEffect} from 'react';

import InfoContext from '../../InfoContext';

import {Euler, TextureLoader,  Vector3 /*, RepeatWrapping, Color, 
    MeshStandardMaterial, MeshPhysicalMaterial, BufferAttribute,
     CubeTextureLoader, 
NearestFilter,  NearestMipmapNearestFilter, Euler, MeshPhongMaterial, MeshBasicMaterial,  */
} from 'three'; 

import {useFrame } from '@react-three/fiber';
import { useXRFrame  } from '@react-three/xr';

import { URL3D } from '../../config/serverURLPath.web.js';

const Tloader = new TextureLoader();

let notalready=true;


/**
 * This is the 3D component for the click marker plane
 * 
 * @export
 * @param {object} props 
 * @param {object} props.clickMarkerRef  the ref to this 3D object, declared in the above component
 * @param {object} props.various 
 * @returns 
 */
export default function ClickMarker({clickMarkerRef, various})
{
  /**
   * Context property
  * @property {object} vr - documentation for isLoading
  */
    let { vr } = React.useContext(InfoContext);

    const enabledVR=vr?.enabledVR;

    useEffect(()=>{
        if(enabledVR!==void 0)
                notalready=true;
     },[enabledVR])


     const frm=()=>   {
        if(notalready)
        {
            Tloader.load(
                // resource URL
                URL3D+'navigation_ring.png',
                texture=> {
                    if( clickMarkerRef?.current?.material)
                    {
                        clickMarkerRef.current.material.map=texture;

                        Tloader.load(
                            // resource URL
                            URL3D+'navigation_ring_alpha.png',
                            texture2=> {
                                if( clickMarkerRef?.current?.material)
                                {
                                    clickMarkerRef.current.material.transparent=true;
                                    clickMarkerRef.current.material.alphaMap=texture2;
                                    clickMarkerRef.current.material.needsUpdate=true;      
                                }
                            });
                    }
                });

          //      console.log("ClickMarker init");
                
                if(various?.camera?.current?.position?.set)
                {  
                    various.camera.current.position.set(0,-1,8);
                }
           
                notalready=false;
        }
    }

          
     if(vr && clickMarkerRef?.current?.scale?.set)
     {
        clickMarkerRef.current.scale.set(20,20,20);
    }
    else
    if(clickMarkerRef?.current?.scale?.set)
    {
         clickMarkerRef.current.scale.set(1,1,1);
    }

   
    useFrame(()=>frm());
   

        return <mesh name="clickMarker" position={new Vector3(0.1, enabledVR && process.env.NODE_ENV === 'development' ? -0.5:  0.1, 0.1)} 
                scale={  enabledVR  ? new Vector3(10, 10, 10) :  new Vector3(0.5, 0.5, 0.5)} 
                ref={clickMarkerRef} rotation={new Euler( -Math.PI/2, 0,0, "XYZ")}>
                    <planeGeometry name="clickMarkerPlane" />
                </mesh>
}




 