const log=console.log;

let prevX, prevY;

/** @type {(boolean | object)} */
let touchDown=false  ; 

/** @type {(boolean | object)} */
let touchMove=false; ; 

let isArtGAllery=false;

let notDone=true;


export function setisArtGAllery(v)
{
    isArtGAllery=v;
}


let controls;

export function touchMovement(control, invalidate, zoomArtSetter)
{
    if(control?.current)
        controls=control?.current;
    
    const mUp=e=>{

        if(Math.abs(touchMove?.movementX)> Math.abs(touchMove?.movementY))
        {
        if(touchMove?.movementX > 0)
        {               
            zoomArtSetter=false;  
            
                            // @ts-ignore
                                controls.keydown({code: 'ArrowRight'});
                                invalidate();

                                setTimeout(() => {
                                    // @ts-ignore
                                        controls.keyup({code: 'ArrowRight'});
                                        invalidate();
                                        zoomArtSetter=false;                                                                
                                }, 500);  
        } else
        if(touchMove?.movementX < 0)
        {             
            zoomArtSetter=false;       
                            // @ts-ignore
                                controls.keydown({code: 'ArrowLeft'});
                                invalidate();

                                setTimeout(() => {
                                    // @ts-ignore
                                        controls.keyup({code: 'ArrowLeft'});
                                        invalidate();
                                        zoomArtSetter=false;                                          
                                }, 500);    
        } 
    }
    else
      {
        if(touchMove?.movementY > 0)
        {              
            zoomArtSetter=false;  
            // @ts-ignore
                controls.keydown({code: 'KeyG'});
                invalidate();

                setTimeout(() => {
                    // @ts-ignore
                        controls.keyup({code: 'KeyG'});
                        invalidate();
                        zoomArtSetter=false;                                                                       
                },   500);
            
        } else
        if(touchMove?.movementY < 0)
        {              
            zoomArtSetter=false;  
            
            // @ts-ignore
                controls.keydown({code: 'KeyT'});
                invalidate();

                setTimeout(() => {
                    // @ts-ignore
                        controls.keyup({code: 'KeyT'});
                        invalidate();
                        zoomArtSetter=false;                                                                
                },   500);
            
        } 
    }
    }

    if(notDone)
    {
        notDone=false;

            window.addEventListener( 'touchmove', evt=>{
            if(isArtGAllery)
            {        
                    const touches = evt.changedTouches;
                    let moveX=0, moveY=0;

                    for (let i = 0; i < touches.length; i++) {

                        const idx =touches[i].identifier;
                                
                        if(typeof idx==='number') {
                            log(`continuing touch ${ idx }`);
                        //   log(`ctx.moveTo( ${ ongoingTouches[idx].pageX }, ${ ongoingTouches[idx].pageY } );`);
                            log(`ctx.lineTo( ${ touches[i].pageX }, ${ touches[i].pageY } );`);
                
                            if(prevX!==void 0)
                                moveX=touches[i].pageX-prevX;

                            if(prevY!==void 0)
                                moveY=touches[i].pageY-prevY;
                            
                            log("prevX: "+prevX+"  prevY: "+ prevY);

                            prevX=touches[i].pageX;
                            prevY=touches[i].pageY;

                            log("moveX: "+moveX+"  moveY: "+ moveY);

                            // @ts-ignore
                            touchMove={movementX: moveX,   movementY: moveY } 

                    } else {
                        log('can\'t figure out which touch to continue');
                    }
                }

                // evt.stopPropagation();
                // evt.preventDefault();
            }
           }, true );



                window.addEventListener( 'mousedown', e=>{
                if(isArtGAllery)
                {
                //     console.log("mouse down");
                        // @ts-ignore
                //     mouseLeftButtonDown=e; 
                        e.preventDefault();
                    }
                }, true );

                window.addEventListener( 'touchcancel', mUp, true );
                window.addEventListener("touchend", mUp, false);
      

                window.addEventListener( 'touchstart', e=> {
                        if(isArtGAllery)
                        {
                        // @ts-ignore
                            touchDown=e;

                        //   const ev=e?.touches?.[0];

                        
                    /*         if(ev?.clientY > 50 )
                                onMouseClick(ev); */

                    //       e.preventDefault();
                        }
                    }, false );
                    
                window.addEventListener( 'touchend', ()=>{ 
                        touchDown=false; 
                        touchMove=false;    
                    }, false );

        }

}