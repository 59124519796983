import React,{useContext} from 'react';
//- import {Image} from 'react-native';

import rsp from "../htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../InfoContext';
// @ts-ignore
import YouTubeComponent from '../../../multiEnvComponents/YouTubeComponent.web'; 


import { C } from '../htmlcomponents/blocktags';
// @ts-ignore
import { H1, H4} from '../htmlcomponents/texttags.web';


export const widthBrk= {sp: 200, 
    sl:  200, 
    mp: 300,
    tp:  560,
    mtl: 200, 
    tl: 460, 
    sll: 560, 
    lll: 560, 
    dl: 560 };

export const widthVideoBrk= {sp: 300, 
        sl:  300, 
        mp: 400,
        tp:  560,
        mtl: 665, 
        tl: 665,  
            sll: 665, 
            lll: 665, 
            dl: 665 };


export const heighthVideoBrk = {sp: 200, 
                sl:  200, 
                mp: 300,
                tp:  360,
                mtl: 480, 
                tl: 480, 
                sll: 480, 
                lll: 480, 
                dl: 480 };

const marginLeft1Brk= {sp: 20, 
    sl:  20, 
    mp: 0,
    tp:  20,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 };


const mobileBRK= {
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 }


const mobileBRK2= {
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 1, 
    tl: 1, 
    sll: 0, 
    lll: 0, 
    dl: 0 }




export default function TopTop({ title="", subtitle="", link="", planetLeft=false})
{
    const {r} =  useContext(InfoContext);

    const mobile= rsp(mobileBRK, r);   
    const mobile2= rsp(mobileBRK2, r);  
    const width= rsp(widthBrk,r);
   
    const widthVideo= rsp(widthVideoBrk, r);
    const heightVideo= rsp(heighthVideoBrk,r);

    const marginLeft1= rsp(marginLeft1Brk,r);

    const marginBottom1= rsp({sp: 20, 
            sl:  20, 
            mp: 20,
            tp:  20,
            mtl: 30, 
            tl: 30, 
            sll: 60, 
            lll: 60, 
            dl: 60 },
        r);

    const h1style={
                alignSelf:  mobile ? "center" : "start", 
                textAlign:  mobile ? "center" : "left",   width
            }


    return <C n="topVideoCol" st={{
                        alignSelf:  mobile2 ? "center" : "start" ,
                    marginBottom: 100,
                     marginLeft:  marginLeft1 }}  >
            <H1 n="topVideoTitle"  st={h1style} >{title}</H1>   
            <H4  n="topVideoSubTitle"  st={{width, marginTop: 15, 
            marginBottom: marginBottom1, 
                alignSelf:  mobile ? "center" :"start", 
                textAlign:  mobile ? "center" : "left"}}>{subtitle}</H4>  
            <YouTubeComponent src={link}   
                width={widthVideo} 
                height={heightVideo}   
                title={title}
            // @ts-ignore
            style={{alignSelf:  mobile ? "center" :"start"}} />
        </C>

}