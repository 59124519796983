import React from 'react';

import InfoContext from '../../InfoContext';
import Footer from '../../widgets/subPages/widgets/Footer';
import PropTypes from 'prop-types';

AboutPatents.propTypes = {
    scrollTopState: PropTypes.any.isRequired,
    scr2: PropTypes.any.isRequired,
};

export default function AboutPatents({scrollTopState, scr2})
{
        let { l, contentModel: {homepage} } = React.useContext(InfoContext);

        return  <Footer scroll2State={scrollTopState}  setScroll2={scr2?.setScroll2}  
        title={homepage?.footer?.title[l]}
        subTitle={homepage?.footer?.subtitle[l]} 
        url="https://epo.org" mainPage={true} />
}

