/**
 * Zoom UI
 * 
 * @module  ExtraScreen
 * @exports ExtraScreen
 */

import React from 'react';
import { View } from 'react-native';

// @ts-ignore
import ZoomUIComponent from '../components3D/zoomUI.web';


export default function ExtraScreen({inSubPage=false, subPageRef, backgroundState, 
                                      setBackgroundState, scroll2State, various}) {
     
      return <View nativeID="extraScreen" >     
                <View style={{ height: "100vh", width: "100%"}} nativeID="ExtraScreenResponsiveWidth" > 
                   <ZoomUIComponent  backgroundState={backgroundState}                                                             
                                     scroll2State={scroll2State} various={various} />
                </View>               
              </View>;
    }

ExtraScreen.navigationOptions = {
  title: 'Extra',
};
