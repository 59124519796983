import React, { useRef, useState, useEffect } from 'react';

import { PositionalAudio, AudioLoader, AudioListener, Group, Light, Quaternion, SpotLight, /*  Object3D, Mesh, CullFaceBack, PMREMGenerator,  LinearToneMapping  */   /* TextureLoader, CubeTextureLoader,  */ 
Euler} from 'three';

import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
//+ import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import { URL3D } from '../config/serverURLPath.web.js';
import { useFrame, createPortal } from '@react-three/fiber';
//-import { useXRFrame } from '@react-three/xr';
import InfoContext from '../InfoContext';

import {HDRCubeTextureLoader} from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

//+ import generateCubeMap from './shading/generateCubeMap.js';
import additionalMaterialSettings from './shading/additionalMaterialSettings';
import additionalMaterialSettingsSculptures from './shading/additionalMaterialSettingsSculptures';

import loadOcculsionBoxes, {retrieveOcculsionObjects} from './optimization/loadOcculsionBoxes.js';

import {ProgressBar2D} from './widgets/progressBar2D'

import createFPSdetectObject from './optimization/createFPSdetectObject'; 

//+ import {initAnimate} from './animation/animate';
//+ import { useVarious } from 'components/rdx.js';



/// Instantiate a loader
const loader = new GLTFLoader();

//+ Optional: Provide a DRACOLoader instance to decode compressed mesh data
//+ const dracoLoader = new DRACOLoader();
//+ dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
//+ loader.setDRACOLoader( dracoLoader );



const audioLoader = new AudioLoader();
 
const hloader = new HDRCubeTextureLoader();


let progressGroup=new Group();

let sound;
const setSoundState=snd=>sound=snd;

let soundOccupied=false;
let filenames= new Map();


let a=0;

function initCamera(cam, zoompage, various)
{
    const init= ()=>{
                        if(cam?.current?.position?.set)
                                    cam.current.position.set( 1.145379772207843,  0.001, 0.000010562623311016067);         
                                    
                                const quat= new Quaternion(0.024360313051228773,0.29862367225555586,-0.007625095394396246,0.9540295254534197);

                                const eul= new Euler();
                                eul.setFromQuaternion(quat);

                                    if(cam?.current?.rotation?.set)
                                        cam.current.rotation.set(eul.x, eul.y, eul.z, "XYZ");
                                    else
                                        console.error("  cam.current.rotation.set error ");
                        }

    if(zoompage==="galleryentrance" || zoompage==="galleryentrancevr")
    {
        // @ts-ignore
        if(various?.setControllerGoal  )
        {
                 setTimeout(
                    ()=> {
                            if(cam?.current?.position?.set)
                                cam.current.position.set( 1.145379772207843,  0.001, 0.000010562623311016067);         
                                    
                            if(cam?.current?.rotation?.set)
                            {
                                     const quat= new Quaternion(-0.00013287425409354409,-0.9994167819705895,-0.003914680460262272,0.033922758391375665);

                                    const eul= new Euler();
                                    eul.setFromQuaternion(quat);

                                    cam.current.rotation.set(eul.x, eul.y, eul.z, "XYZ");

                      /*               if(various?.occulsion)
                                        initAnimate(various?.occulsion, cam, ()=>{}); 
                                    else
                                        console.error("couldn't initAnimate") */

                                    for(let yn= -Math.PI; yn<Math.PI; yn+=0.01 )
                                    {
                                            cam.current.rotation.set(eul.x, yn, eul.z, "XYZ");
                                    }
                            }

                             init(); 
                    }
                    , 10000 );
        }
        else
        {
             init();
        }

    
    }
}




let loadingTimes=0
/**
 * Creates a 3D component out of a GLTF/GLB file
 * 
 * @export
 * @param {object} props
 * @param {string | Array} props.filename
 * @param {object} props.scene
 * @param {object} props.gl
 * @param {object} props.camera
 * @param {boolean} props.progressBarChoosen show progressbar for this file?
 * @param {boolean} props.galleryFeatures use the art gallery features
 * @param {object} props.progressBarContent the texts etc
 * @param {number} [props.amountOfSubModels] the texts etc
    @param {object} [props.various] extra
 * @returns 
 */
export default function GLTFLoaderComponent({filename, scene, gl, camera:cam, 
                                                    progressBarChoosen, galleryFeatures,  
                                                    progressBarContent, amountOfSubModels=1, 
                                                    various
                                                })
{
    let { vr, zoompage, /* setZoompage,  */ fcs, /* floorHoverClick, */ 
                soundButtonState: {sndBtnState}, graphicsResolution: { gr /* , setGraphicsResolution, setIx */}, 
                occulsion, finishedLoad: {finishedLoading, setFinishedLoading}, fps, zoomart: { zoomArtSetter }} = React.useContext(InfoContext);

    const el=useRef(null);
    const [loadState, setLoadState]=useState(false);
    const [loadingState, setLoadingState]=useState(0);
    const [modelState, setModelState]=useState(false);
    const [triggerReLoad, setTriggerReLoad]=useState(true);

    const [progrss,setProgrss]=useState(0);

    const [listener, setListenerState]=useState({});

    various.zoomArtSetter=zoomArtSetter;
    various.occulsion=occulsion;

    useEffect(
         ()=>{
         
             if(gr >5 && scene && !scene.environment && galleryFeatures)
             {
               hloader.setPath( URL3D+'textures/env_maps/' )
                .load([
                        'nx.hdr',
                        'nz.hdr',
                        'ny.hdr',
                        'py.hdr',
                        'nz.hdr',
                        'nx.hdr'
                    ], texture => { 
                        
                             /*        gl.toneMapping = LinearToneMapping;
                                    gl.toneMappingExposure = 1; */

                                    scene.environment=texture;
                                    console.log("loads env texture");

                                    initCamera(cam, zoompage, various);
                                  }, 
                                  void 0  ,
                                  err => console.error("JO Env textur : ",err) ); 
            }
         }
         ,[gr]
     );
   
        
    useEffect(()=> {   
            const aL=new AudioListener();
            setListenerState(aL);

            if(fcs?.unFocused)
                fcs.setUnFocused=false; 

            if(zoompage!=="galleryentrance" && cam?.remove)
                    cam.remove(listener);

            if(zoompage!=="galleryentrance" && fcs && 
                 // @ts-ignore
                fcs?.unFocused && sound?.stop)
                {
                    // @ts-ignore
                    if( sound?.stop)
                    // @ts-ignore
                        sound.stop()
                    
                    cam.remove(listener);
                    setListenerState(null);
                    setSoundState(null);
                } 
            
            return ()=> {
                setListenerState(null);
                setSoundState(null);
            }
        }
        ,[zoompage]
        );


    useEffect(
        ()=>{           
            const cm=cam?.current;

            if(!vr?.enabledVR && (!cm?.children ||  
                (cm?.children 
                    && !cm.children.find(n=>
                        n.name==="progressBar") )  ) )
                            cm.add( progressGroup );   
            
            if(loadState && galleryFeatures
                && cam?.current?.type=== "PerspectiveCamera" 
                && listener )
            {        
                if(cam?.current?.add)   
                    cam.current.add( listener );
            } else
            {
                if(scene)
                    scene.environment=null;
            }

            return ()=>{
                if(!vr && cam?.current?.remove)
                {
                    // @ts-ignore
                    sound.stop() 
                    cam.remove(listener);
                   

                    if(modelState)
                         // @ts-ignore
                         el.current.remove(modelState);

                    setListenerState(null);
                    setSoundState(null);
                }
                    
            } 
        },[loadState, modelState]
    );

    useEffect(()=>{
        // @ts-ignore
        if(sound?.pause && sound?.play && fcs)
        {
            if(fcs?.unFocused || !sndBtnState)
                // @ts-ignore
                sound.pause();
            else
                // @ts-ignore
                if(zoompage==="galleryentrance" && loadingState>=10  && (sndBtnState || vr?.enabledVR))
                    sound.play();
        }
    },[fcs?.unFocused, sndBtnState]);

    // let cllbck=()=>{};
    // const setCllBack=f=> cllbck =f;

    const getId=(filena, inx)=> {
            switch(filena)
            {
                case "sculptures/sculptures":
                    return 1+inx;
                case "galleryentrance":
                    return 3+inx;
                default:
                    return 5;
            }
    }

    const sndCllBck=obj=> {
            if(loadState && modelState && !soundOccupied)
            {                           
                        if(obj.name==="kintera")
                        {              
                                                          
                            // @ts-ignore
                            const sound=new PositionalAudio( listener )   

                            soundOccupied=true;

                            setSoundState(sound);

                            // load a sound and set it as the PositionalAudio object's buffer
                
                            audioLoader.load(  URL3D+'audio/INTRO.mp3', function( buffer ) {
                                        
                                sound.setBuffer( buffer );
                            
                                sound.setRefDistance( 1 );
                            
                                sound.setMaxDistance( 0.1 );
                            
                                sound.setRolloffFactor(4.2);
                            
                                sound.setDistanceModel("exponential");
                        //+     sound.setDirectionalCone ( 1, 1, 1 ); 
                        
                                if(/* zoompage==="galleryentrance" */ galleryFeatures && (sndBtnState || vr?.enabledVR) )
                                    sound.play();

                                setInterval(()=> {
                                    if(/* zoompage==="galleryentrance"  */ galleryFeatures && (sndBtnState || vr?.enabledVR) && loadingState>=10)
                                        // @ts-ignore
                                        if(fcs && fcs?.unFocused!==true)
                                        sound.play()}
                                , 114000  );
                            });

                            obj.add(sound);
                        }                    
            }
    }


    const load = filename=>         //    filename="planetEPO2"
    {
        setFinishedLoading(1);
        loadingTimes++;
        console.log("loadingTimes "+loadingTimes+" filename "+filename);

        const d = new Date();
        let time1 = d.getTime();

        const timingMaterialSetting=()=>{
            const d2 = new Date();
            let time4 = d2.getTime();
            let time5=  time4 - time1;

             // @ts-ignore
            window.urlChange({customName: "Time parsing "+filename, customData: time5, indx: getId(filename, 0)});
              
            if(fps?.getMaxFPS)
                // @ts-ignore
                 window.urlChange({customName: "Avg FPS "+filename, customData: fps.getMaxFPS(), indx: 5});

            setLoadState(true);
        }

        return new Promise( (rslv, rjct)=>

        loader.load( 
                // resource URL
                URL3D+filename+".glb",
             
                function ( gltf ) {    
                    
                    console.log("LOAD "+filename);
        
                    const d3 = new Date();                            
                    let time2 = d3.getTime();
                    let time3=  time2 - time1;
                     // @ts-ignore
                    window.urlChange({customName: "without parsing "+filename ,customData: time3, id: getId(filename, 1)});


                    filenames.set(filename, filename);
                    //+ gltf.animations; // Array<THREE.AnimationClip>
                    //+ gltf.scene; // THREE.Group
                    //+ gltf.scenes; // Array<THREE.Group>
                    //+ gltf.cameras; // Array<THREE.Camera>
                    //+ gltf.asset; // Object
                    if(galleryFeatures)
                    {
                        let materials= new Map();
                        let objs= new Map();
                        let lights= new Map();
                                         
                        if(filename==="sculptures/sculpturesOptimised")
                        {
                            console.log("sculpturesOptimised !!!!!!!!");
                            timingMaterialSetting();
                        }
                        else
                        if(filename==="sculptures/sculptures")
                        {
                            loadOcculsionBoxes(occulsion);

                            gltf.scene.traverse(obj => {                             
                                // @ts-ignore
                                materials.set(obj?.material?.name, obj?.material?.name);                // for debugging purposes               
                                objs.set(obj.name, obj.name);

                                if(obj instanceof Light)
                                {                                    
                                    lights.set(obj.name, obj.name);
                                    obj.intensity=0; 
                                }

                                // @ts-ignore
                                retrieveOcculsionObjects(obj, occulsion);
                                
                               additionalMaterialSettingsSculptures(obj, cam, null, gr,  scene, sndCllBck );
                            });

                            timingMaterialSetting();
                        }
                        else if(filename==="galleryentrance")
                        {                           
                            gltf.scene.traverse(obj => {                             
                                // @ts-ignore
                                materials.set(obj?.material?.name, obj?.material?.name);                // for debugging purposes               
                                objs.set(obj.name, obj.name);
                               
                                if(obj instanceof Light)
                                {
                                    lights.set(obj.name, obj.name);
                                    obj.intensity=0; 
                                }

                                a+=(0.0001/(a?a:1));
                            
                                // @ts-ignore
                               
                                additionalMaterialSettings(obj, cam, null, gr, filename, occulsion, scene, ()=>{ 
                                                                                                                loadingTimes=0;
                                                                                                                setFinishedLoading(2); 
                                                                                                                 setTriggerReLoad(false);   
                                                                                                                 setTriggerReLoad(true);
                                                                                                                 initCamera(cam, zoompage, various);
                                                                                
                                                                                                            }, vr?.enabledVR);
                            });

                            timingMaterialSetting();
                        }
                        
                        else
                        {     
                            timingMaterialSetting();
                            loadingTimes=0;
                            setFinishedLoading(2);
                            initCamera(cam, zoompage, various);
                        }

                        console.log("SCENE MATERIALS");
                        console.dir(materials);
                        console.dir(objs);
                        console.dir(lights);
                        console.dir(occulsion);
                        console.dir(filenames);
                    }
                    else
                    {
                        if(filename==="planetInventions3")
                        {
                               createFPSdetectObject(gltf.scene, fps);             
                        }
                        

                        timingMaterialSetting();
                        loadingTimes=0;
                        setFinishedLoading(2);
                        initCamera(cam, zoompage, various);
                    }                   
                    
                    rslv(gltf);
            },
            // called while loading is progressing
            function ( xhr ) {               
             //   console.log( " xhr.loaded  "+xhr.loaded+" "+xhr.total  ); 
                a+=(0.0001/(a?a:1));
            },
            // called when loading has errors
            function ( error ) {
                console.dir(URL3D);

                console.error("filename "+ URL3D+filename+".glb");
                console.error( 'An error happened: '+error );
               
                if(error.toString()==='TypeError: Failed to fetch')
                {
                    setTimeout(()=>
                    {
                        console.error("fetch problem... reloading");
                        load(filename);
                    } 
                    ,2000);
                }
                else
                    rjct("load error");
            })
        )
    
    };


    const loadModel=()=>{
        if(!loadState &&  (amountOfSubModels===1 || loadingTimes<amountOfSubModels) )
        {
                const prm=load(filename);

                prm.then(value=> {
                        setModelState(value?.scene);
                        setLoadingState(10);
                    }
                ).catch(e=>console.error("GLTF Load promise error"+e));
        }
    }


    useEffect(() => { 

        if((filename && gr && gr!==7) || !galleryFeatures )
        {
            
            // @ts-ignore
            if(modelState)
            {
                // @ts-ignore
                modelState.traverse(obj => {    
                    // @ts-ignore 
                    if(obj?.geometry?.dispose)
                    // @ts-ignore      
                        obj.geometry.dispose();

                    // @ts-ignore
                    if(obj?.material?.dispose)   
                    {             
                        // @ts-ignore 
                        obj.material.dispose();
                    }
                });
            }

            // @ts-ignore
            setModelState(false);


            if(el?.current)
            el.current.children=[];

            
            // Load a glTF resource

            if(filename && vr)
            {           
                loadModel();          
            }
        }

       
        return ()=>{ 
            scene.traverse(obj => {    
                // @ts-ignore 
                if(obj?.geometry?.dispose)
                // @ts-ignore      
                    obj.geometry.dispose();

                // @ts-ignore
                if(obj?.material?.dispose)   
                {             
                    // @ts-ignore 
                    obj.material.dispose();
                }
            });

            scene=[];

            if(el?.current)
                el.current.children=[]; }
    }, [filename, vr?.enabledVR]);


    useEffect(()=>{
        console.log("GR ----> Graphic mode was changed to: "+ gr);

        if(typeof +gr === "number" && gr===0)
            setFinishedLoading(2);
        else
        if(typeof +gr === "number" && gr!==7)
        {
              // @ts-ignore
              if(modelState)
              {
                  // @ts-ignore
                  modelState.traverse(obj => {    
                      // @ts-ignore 
                      if(obj?.geometry?.dispose)
                      // @ts-ignore      
                          obj.geometry.dispose();
  
                      // @ts-ignore
                      if(obj?.material?.dispose)   
                      {             
                          // @ts-ignore 
                          obj.material.dispose();
                      }
                  });
              }

            setTriggerReLoad(false);
            setModelState(false);

            setTimeout(()=>
            {
                    setTriggerReLoad(true);

                    if(gr && gr!==7)
                    {
                        loadModel();                          
                    }                 
            }
            , 
            2000);    
            }
        }
        ,[gr]
    );

    

 useFrame(() => {
        if(!vr?.enabledVR)
            {
            
                    if (loadState && modelState && el?.current) {
                        console.log("add to scene: "+filename);
            
                        el.current.add(modelState);
                        setLoadState(false);
                    }  else
                    if(finishedLoading!==2)
                    {
                        setProgrss(a);
                    }
                    else
                    {
                        a=0;
                    }            
            }
            else
            {
            
                    if (loadState && modelState && el?.current) {
                        el.current.children=[];
                        el.current.add(modelState);
                        setLoadState(false);
                    }
            
            }
    }  );

     const  pG=progressGroup && createPortal(<ProgressBar2D filename={filename} 
                    loadingState={  (progrss-1)*8 }
                                        progressBarContent={progressBarContent}
                                         />,  progressGroup);

    return (
            triggerReLoad ?
            (
              (modelState &&  finishedLoading===2 ) ? 
                <>     
                    <object3D ref={el} />
                </> :
                <>
                 {/*    {!vr?.enabledVR && progressBarChoosen &&  pG}  */}
                </>
            ) : <></>
        ); 
}