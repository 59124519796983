import React,{useContext} from "react";

import {Text } from 'react-native';

import rsp from "./useResponsiveBrk";

import InfoContext from '../../../InfoContext';


const common= {
                color: "white",  
                "fontFamily": "Source Sans Pro",
                "fontStyle": "normal"
            }

/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {object} [props.onClick] 
 * @param {string} [props.n] 
 * @returns 
 */
export function P({children, st, w, onClick, n})
{
     const r_ =  useContext(InfoContext);
     const r=r_?.r;

     const fontSizeP= {"fontSize": rsp({
                         sp:  16, 
                         sl:  16, 
                         mp: 16,
                         tp:  16,
                         mtl: 16, 
                         tl: 16, 
                         sll: 16, 
                         lll: 16, 
                         dl: 16 }, r) ,
                 "lineHeight": 
                    rsp({sp:  '24px', 
                         sl:  '24px', 
                         mp: '24px',
                         tp:  '24px',
                         mtl: '24px', 
                         tl: '24px', 
                         sll: '24px', 
                         lll: '24px', 
                         dl: '24px' }, r)} 


    // @ts-ignore
    return <Text nativeID={"p_"+n}  style={{ ...common,    
            "fontStyle": "normal",  
        ...fontSizeP,
        flexWrap: "wrap",
         // @ts-ignore
        ...st}} onClick={onClick}>{children}</Text>
}

/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {string} [props.n] 
 * @returns 
 */
export function H1({children, st, w,n})
{
    const {r} =  useContext(InfoContext);

   const h1s= {"fontSize": rsp({sp:  35, 
                    sl:  35, 
                    mp: 35,
                    tp:  35,
                    mtl: 35, 
                    tl: 45, 
                    sll: 45, 
                    lll: 50, 
                    dl: 50 }, r),
            "lineHeight": rsp({sp:  '40px', 
                    sl:  '40px', 
                    mp: '40px',
                    tp:  '50px',
                    mtl: '40px', 
                    tl: '55px', 
                    sll: '55px', 
                    lll: '60px', 
                    dl: '60px' }, r)
   }



    return <h1 id={"H1"+n} style={{...common, // width: w, 
          "fontWeight": "600",  
         "fontSize": "50px",
         "lineHeight": "60px",
         flexWrap: "wrap",
         ...h1s,
         ...st
    }}>{children}</h1>
}

/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {string} [props.n] 
 * @returns 
 */
export function H2({children, st, n})
{
    const {r} =  useContext(InfoContext);

    return <Text nativeID={"H2"+n} 
        style={{ ...common, // width: w, 
        "fontWeight": "600",  
        "fontSize": rsp({sp:  22, 
                           sl:  22, 
                            mp: 22,
                            tp:  22,
                            mtl: 22, 
                            tl: 22, 
                            sll: 32, 
                            lll: 42, 
                            dl: 42 }, r)
                            ,
        "lineHeight": "34px",
        flexWrap: "wrap"  ,
         ...st
    }}>{children}</Text>
}




                                
                            


/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {object} [props.w] 
 * @param {object} [props.n] 
 * @returns 
 */
export function H3({children, st, n})
{
    const cntxt =  useContext(InfoContext);
    const r = cntxt?.r ? cntxt?.r : "dl";
    
    return <Text nativeID={"H3"+n} style={{  ...common,  // width: w,  
                              "fontWeight": "600",
                            "fontSize":  rsp({sp:  22, 
                        sl:  26, 
                            mp: 26,
                            tp:  26,
                            mtl: 26, 
                            tl: 32, 
                            sll: 32, 
                            lll: 42, 
                            dl: 42 }, r),
                            "lineHeight": rsp({sp:  22, 
                        sl:  32, 
                            mp: 32,
                            tp:  32,
                            mtl: 32, 
                            tl: 32, 
                            sll: 32, 
                            lll: 46, 
                            dl: 46 }, r),  
                            flexWrap: "wrap"  , ...st                           
                }}>{children}</Text>
}

/*

*/

/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st]  
 *  @param {object} [props.n] 
 * @returns 
 */
 export function H4({children, st, n})
 {
     const {r} =  useContext(InfoContext);

     return <Text nativeID={"H4_"+n} style={{ ...common,  // width: w, 
     "fontWeight": "400", 
     "fontSize": rsp({sp:  16, 
                        sl:  16, 
                            mp: 16,
                            tp:  16,
                            mtl: 22, 
                            tl: 22, 
                            sll: 22, 
                            lll: 22, 
                            dl: 22 }, r),
     "lineHeight":   rsp({sp:  26, 
                        sl:  26, 
                            mp: 26,
                            tp:  26,
                            mtl: 28, 
                            tl: 28, 
                            sll: 28, 
                            lll: 28, 
                            dl: 28}, r),
                            flexWrap: "wrap" 
                            ,
     ...st}}>{children}</Text>
 }

/* 
                   

*/



/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {object} [props.w] 
 * @param {object} [props.n] 
 * @returns 
 */
export function H5({children, st, w, n})
{
    const {r} =  useContext(InfoContext);


    return <Text  nativeID= {"H5"+n} style={{...common, "fontWeight": "600", width: w,  
    "fontSize": rsp({sp:  12, 
                        sl:  12, 
                            mp: 12,
                            tp:  14,
                            mtl: 14, 
                            tl: 16, 
                            sll: 16, 
                            lll: 16, 
                            dl: 22 }, r),
     "lineHeight":   rsp({sp:  "22px", 
                        sl:  "22px", 
                            mp: "22px",
                            tp:  "22px",
                            mtl: "22px", 
                            tl: "22px", 
                            sll: "22px", 
                            lll: "22px", 
                            dl: "30px"}, r),
     ...st}}>{children}</Text>
}

