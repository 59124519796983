import React from 'react';
import {View} from 'react-native';
import ImageMapper from 'react-img-mapper';

export default function ImageMap({src, map, width, height, style, onClick})
{
    return  <View style={style}>
                    <ImageMapper src={src}  map={map}  width={width} height={height} onClick={e=> {
                        onClick(e) }} />
            </View>
}


///  array.filter((v,i,s) => s.indexOf(v)==i)    Unique values