import {  Color, Raycaster, Vector3,  Vector2, CylinderGeometry, MeshBasicMaterial, Mesh, Box3, BoxGeometry, Quaternion, Euler, ConeGeometry, DoubleSide, SubtractEquation} from 'three';     



import handleVRClickAction from './vrClickAction';


const raycaster = new Raycaster();

let choosedController=0;





/**
 * This function handles the click on the floor navigation marker update
 * 
 * @export
 * @param {Vector2} mouse The mouse coordinates 
 * @param {any} object 
 * @param {any} camera 
 * @param {any} clickMarkerRef this is the ref to the actual marker object
 * @param {any} floorHoverClick 
 */
export default function handleMouseMove(mouse, object, camera, clickMarkerRef, floorHoverClick)
{
    // update the picking ray with the camera and mouse position
    if(raycaster && camera?.current)
        raycaster.setFromCamera( mouse, camera?.current );

    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects( object?.children, true  );
          

    if(intersects.length>0)
    {                       
        const theObject=  intersects[0].object
        
 

        const point=   intersects[0].point
          
        if( (theObject?.name==="floor" || 
        (theObject?.name==="clickMarker" && intersects?.[1]?.object?.name==='floor') ) 
                                && clickMarkerRef?.current?.position?.set )
        {
            clickMarkerRef.current.position.set(point.x, -1.4, point.z);
   
            clickMarkerRef.current.matrixAutoUpdate=true;
        }              
    }
}

/**
 * makes the movement in VR move in the horizontal plane
 * 
 * @param {any} ctrl the controller
 * @returns 
 */
function checkPitch (choice, ctrl)
{
        choosedController=choice;

        const  vct = new Vector3( 0, -1, 0);

        let quat=new Quaternion();

        if(ctrl?.getWorldQuaternion)
            quat=ctrl.getWorldQuaternion(quat)

        if(quat)
            vct.applyQuaternion(quat);

        vct.normalize();

        vct.multiplyScalar(100);
            
        return vct;
}

// @ts-ignore
let vry=process.env.NODE_ENV !== 'development' ? -1.4 : window.virtualRealityGlobal.posY;

let markerY=0, markerYcount=0;


let infoBoxArrowAction=v=>{};
const setInfoBoxArrowAction =f=>infoBoxArrowAction=f;

/**
 * This function access the click on the floor navigation marker update in VR as well setting the movement itself in VR
 * 
 * @export
 * @param {any} object 
 * @param {any} camera 
 * @param {any} clickMarkerRef 
 * @param {any} player 
 * @param {any} controllers 
 * @param {any} various 
 */
export function handleMouseMoveVR( object, camera, clickMarkerRef, player,  controllers, various)
{

    const setChoosedController=(ch, hand)=>
    {
        console.log("choosedController "+ch+" hand "+hand);

        if(hand==="left")
            choosedController=0;
        else
        if(hand==="right")
            choosedController=1;
        else
            console.error("VR setChoosedController error");
    }


    const vrPitch= (choice, mult= 0.2)=>
    {
        setChoosedController(choice);
        various.zoomArtSetter(false);

        const ptch=checkPitch(choice, controllers?.[choice]?.grip?.children?.[2]);

        let vct=player.position.clone();

        const ptch2=ptch.clone();

        ptch2.multiplyScalar ( mult );   

        vct.add(ptch2);

        various.setControllerGoal({position: {x: vct.x, y:vry, z: vct.z}});
        various.zoomArtSetter(true);  
    }

    handleMouseMoveVRMarker( object, camera, clickMarkerRef, player,  controllers, various);

     


    if( controllers?.[0]?.inputSource?.gamepad?.buttons?.[0]?.pressed )
    {
        setChoosedController(0, controllers?.[0]?.inputSource?.handedness);
  
    }
    if( controllers?.[1]?.inputSource?.gamepad?.buttons?.[0]?.pressed )
    { 
        setChoosedController(1, controllers?.[1]?.inputSource?.handedness);

    }


    const rota=(ang, player)=> player.rotateOnWorldAxis( new Vector3(0,1,0), ang / 180 * Math.PI ); 
    

  
    if( controllers?.[0]?.inputSource?.gamepad?.buttons?.[3]?.touched )
    {
        setChoosedController(0, controllers?.[0]?.inputSource?.handedness);
  
        various.zoomArtSetter(false);
        vry += controllers?.[0]?.inputSource?.gamepad?.axes?.[3];

        const roty=controllers?.[0]?.inputSource?.gamepad?.axes?.[2];
        
        various.setControllerGoal({position: {x: player.position.x, y:vry, z: player.position.z, 
                                        quaternion: rota(roty,  player)}});

        various.zoomArtSetter(true);  
    }

    if( controllers?.[1]?.inputSource?.gamepad?.buttons?.[3]?.touched )
    { 
        setChoosedController(1, controllers?.[1]?.inputSource?.handedness);

        various.zoomArtSetter(false);
        vry += controllers?.[1]?.inputSource?.gamepad?.axes?.[3];

        rota(controllers?.[1]?.inputSource?.gamepad?.axes?.[2],  player);

        various.setControllerGoal({position: {x: player.position.x, y:vry, z: player.position.z }});

        various.zoomArtSetter(true); 
    }



    if( controllers?.[0]?.inputSource?.gamepad?.buttons?.[5]?.pressed )
    {
        vrPitch(0, 0.2);
        setChoosedController(0, controllers?.[0]?.inputSource?.handedness);
    }
    else
    if( controllers?.[1]?.inputSource?.gamepad?.buttons?.[5]?.pressed )
    {
        vrPitch(1, 0.2);
        setChoosedController(1, controllers?.[1]?.inputSource?.handedness);
    }
    if( controllers?.[0]?.inputSource?.gamepad?.buttons?.[4]?.pressed )
    {
        vrPitch(0, -0.2);
        setChoosedController(0, controllers?.[0]?.inputSource?.handedness);
    }
    else
    if( controllers?.[1]?.inputSource?.gamepad?.buttons?.[4]?.pressed )
    {
        vrPitch(1, -0.2);
        setChoosedController(1, controllers?.[1]?.inputSource?.handedness);
    }
}

    const materialbox = new MeshBasicMaterial( {color: 0xffffff} );
    const materialboxInfo = new MeshBasicMaterial( {color: 0xff0000} );
    materialboxInfo.side=DoubleSide;


function genCylinder() {
    const geometry = new CylinderGeometry( 0.01, 0.001, 199.99, 6 );
    const material = new MeshBasicMaterial( {color: 0x99eeff, transparent:true, opacity: 0.5} );

    const cylinder = new Mesh( geometry, material );
    cylinder.position.set(cylinder.position.x, cylinder.position.y-100, cylinder.position.z);

    return cylinder;
}


const boxgeometry = new BoxGeometry(0.01,0.03,0.01);
const infogeometry = new ConeGeometry(0.02, 0.01, 5, 1, false, 0, Math.PI/2);

const boxmesh= new Mesh( boxgeometry,  materialbox);
boxmesh.position.set(boxmesh.position.x+0.1, boxmesh.position.y, boxmesh.position.z);

const boxmeshInfo= new Mesh(infogeometry, materialboxInfo);

boxmeshInfo.rotation.set(-Math.PI/2,0,0,"XYZ");

boxmeshInfo.position.set(boxmesh.position.x-1, boxmesh.position.y, boxmesh.position.z-0.1);

let cylinder0;
let cylinder1;
let boxmesh0;
let boxmesh1;

let boxmeshInfo0;
let boxmeshInfo1;

/**
 * This function handles the update movement of click on the floor navigation marker in VR 
 * 
 * @param {any} object  scene
 * @param {any} camera 
 * @param {any} clickMarkerRef 
 * @param {any} player 
 * @param {any} controllers 
 * @param {any} various 
 */
function handleMouseMoveVRMarker( object, camera, clickMarkerRef, player,  controllers, various)
{
    if(!cylinder0 &&  controllers?.[0]?.grip)
    {
        controllers[0].grip.name="grip0";

        cylinder0 = genCylinder();

        cylinder0.name="cylinderBeam0";
        controllers[0].grip.add(cylinder0);  

        boxmesh0=boxmesh.clone();   
        controllers[0].grip.add(boxmesh0);

        boxmeshInfo0=boxmeshInfo.clone();   
        boxmeshInfo0.name="boxmeshInfo0";
        controllers[0].grip.add(boxmeshInfo0);


        console.log(" beam 0 were attached.");
    }

    if(!cylinder1 &&  controllers?.[1]?.grip)
    {   
        controllers[1].grip.name="grip1";

        cylinder1 = genCylinder();
        cylinder1.name="cylinderBeam1";

        controllers[1].grip.add(cylinder1);   
  
        boxmesh1=boxmesh.clone();   
        controllers[1].grip.add(boxmesh1);

        boxmeshInfo1=boxmeshInfo.clone();   
        boxmeshInfo1.name="boxmeshInfo1";
        controllers[1].grip.add(boxmeshInfo1);


       console.log(" beam 1 were attached.");
    }
    

    if(choosedController===0)
    {
        if(cylinder0?.material?.opacity)
            cylinder0.material.opacity=0.5;

        if(cylinder1?.material?.opacity)
            cylinder0.material.opacity=0.2;
    }   
    else
    if(choosedController===1)
    {
        if(cylinder0?.material?.opacity)
            cylinder0.material.opacity=0.2;

        if(cylinder1?.material?.opacity)
            cylinder0.material.opacity=0.5;
    } 


    let ctl;

    if(controllers?.[choosedController])
        ctl=controllers?.[choosedController];
    else
    {
        console.error("couldn't choose controler");
        ctl=controllers?.[0];
    }

        
    let quat=new Quaternion();
    if(ctl?.grip?.children?.[2]?.rotation?.clone)
        quat=ctl.grip.children[2].getWorldQuaternion(quat);
    else
        console.error("couldn't get rotation");
 

     if(raycaster && !raycaster.camera)
     {
         raycaster.camera=camera;
     }

    
    const dir = new Vector3( 0, -2, 0); 
    
    dir.normalize();

    if(quat)
         dir.applyQuaternion(quat);


    const dir2=new Vector3(dir.x, dir.y, dir.z);

    const beamBox=new Box3();

    if(ctl?.grip?.children?.[2])
        beamBox.expandByObject(ctl.grip.children[2]);
    else
        console.error("couldn't create BeamBox");
    
    let origin=new Vector3();
    origin=beamBox.getCenter(origin);


    if(camera?.position)
    {    
        raycaster.set(origin, dir2);
    }
 
    let intersects = []; 


    if(object?.children?.[1]?.children)
    {
        try {
            intersects= raycaster.intersectObjects(  object?.children?.[1]?.children, true  );
        }
        catch(e){
            console.log("raycaster VR error: "+e);
        }
    }

    various.player=player;

    if(intersects?.length>0)
    {     
        const theObject=  intersects[0].object;
        
        const point=   intersects[0].point;

        if( ((theObject?.name==="floor" || theObject?.name==="clickMarker") )   // click on the floor action
                && clickMarkerRef?.current?.position?.set )
        {
            clickMarkerRef.current.material.color=new Color(0xaff);
    
            if(markerY===0 && typeof point?.y ==="number")
            {
                            if(markerYcount>300)
                                markerY= point.y+1
                            else
                                markerYcount++;
            }

            clickMarkerRef.current.position.set(point.x, markerY , point.z);    // the position of the click marker
            clickMarkerRef.current.scale.set(10, 10, 10);             
              
            clickMarkerRef.current.matrixAutoUpdate=true;
         
            if( controllers?.[choosedController]?.inputSource?.gamepad?.buttons?.[0]?.pressed )
            {
                various.setControllerGoal({position: {x: point.x, y:vry, z: point.z}});
                various.zoomArtSetter(true);

                handleVRClickAction();
            }         
        }
        else
        if(theObject?.name==="infoBoxUpArrow" ) //|| theObject?.name==="infoBoxDownArrow"
        {
            if(infoBoxArrowAction)
                infoBoxArrowAction('u');
        }
        if(theObject?.name==="infoBoxDownArrow" ) //|| theObject?.name==="infoBoxDownArrow"
        {
              if(infoBoxArrowAction)
                infoBoxArrowAction('d');
        }
        else
        {
             clickMarkerRef.current.scale.set(10, 10, 10);       

            if( controllers?.[choosedController]?.inputSource?.gamepad?.buttons?.[0]?.pressed )
            {
                    let box1, box2;

                    if(player?.children?.find)
                        box1=player.children.find(n=>n?.type==="PerspectiveCamera");

                    let pos1=new Vector3();
                    let pos2=new Vector3(), quaternion2=new Quaternion();

                    if(box1?.getWorldPosition)
                    {
                        pos1=box1.getWorldPosition();
                    }

                    const childrenOfGrip=controllers?.[choosedController]?.grip.children;
                     box2=childrenOfGrip.find(n=>n.name.indexOf("boxmeshInfo")!==-1);

                    if(box2?.getWorldPosition)
                    {
                        pos2=box2.getWorldPosition();
                        quaternion2=box2.getWorldQuaternion();
                    }

                    handleVRClickAction(theObject?.name, theObject, object, 
                                            various?.contentModel, various?.l, 
                                            various?.setupdate, various, pos1, quaternion2, 
                                            {infoBoxArrowAction, setInfoBoxArrowAction});
            }
            else
            if( controllers?.[choosedController]?.inputSource?.gamepad?.buttons?.[3]?.touched ||
                controllers?.[choosedController]?.inputSource?.gamepad?.buttons?.[4]?.pressed ||
                controllers?.[choosedController]?.inputSource?.gamepad?.buttons?.[5]?.pressed)
                    handleVRClickAction();
                          
        }
    }
    else
     console.error("no intersect");
}