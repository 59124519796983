import React,{useEffect, useState} from 'react';
//- import Reactext from 'reactext-devtool';  // for debugging
import {  createRoot  } from 'react-dom/client';
//- import ReactDOM from 'react-dom';
// import { SafeAreaProvider } from 'react-native-safe-area-context';
// import { MenuProvider } from 'react-native-popup-menu';
import { Vector3, FileLoader } from 'three';

import { usePageVisibility } from 'react-page-visibility';

import { useVarious, rdxInitValue } from './components/rdx';

//import {menuProviderStyles} from './components3D/widgets/TopMenu';

// @ts-ignore
import { ModalProvider } from "./multiEnvComponents/modalProvider";

// import RaycastButtons from './components3D/raycastButton';

import InfoContext from './InfoContext';

import {getLoggedInS, setLoggedInS, getPage, setPage} from './sessionStorage';

import './index.css';
import App from './App.web';

import {getDeviceType} from './components/checkMobile'

import LoginScreen from './widgets/LoginScreen';

import { CLIENT_PATH } from  './config/serverURLPath.web';

// @ts-ignore
// Reactext(React);

const url = new URL(window.location.href);

const modelname = "../3D/AR/"+url.searchParams.get("modelname");
const page = url.searchParams.get("p");

const qu={queue: new Map()};

const loader = new FileLoader();

let root;
const getRoot=()=>root;


console.log("(RE-)Running INIT index.web.js");
  
let  FPS= 0, kdown=false, maxFPS=0; 
// @ts-ignore
const  setFPS= fps_=>FPS=fps_ ;
const  getFPS= ()=>FPS;

const  setMaxFPS= fps_=> { 
                             return fps_> maxFPS ? maxFPS=fps_: maxFPS; } 
const  getMaxFPS= ()=>maxFPS;


const setkdown= kd=> { kdown=kd  };

let animationObjects= new Map();
let occulsionBoxes= new Map();
let wallCollisionBoxes= new Map();
let occulsionLODObjects= new Map();
let animationScaleObjects= [];

function Start() { 
    const [loggedIn, setLoggedIn]= useState(false);
    const [zoompage, setZoompage]= useState('');
    const [scrollBarRef, setScrollBarRef]= useState({});

    const [scrollpage, setScrollpage]= useState(-1);
    

    const [variousStates, variousStatesDispatch]=useVarious(rdxInitValue);
    const [enabledVR, setEnabledVR]=useState(false);
    const [cameraGoal, setCameraGoal]= useState({pos: new Vector3( 0.37897+1, 0.848553, 4.20183+1), 
                                                        lookat: new Vector3(0.37897, 0.848553, 4.20183) });
      const [controls, setController]=useState(false);

    let [obj3DinFocus, setObj3DinFocus]=useState(new Vector3());

    let [testV, setTestV]=useState(new Vector3());
    let [testScale, setTestScale]=useState(1);

    const [matching, setMatching]=useState(0);

    const [contentModel, setContentModel]= useState({});
    const [configurationModel, setConfigurationModel]= useState({});
 
    const [subPageR, setSubPageR]= useState({});

   const [cam,setCam] =useState({});

    const [unFocused, setUnFocused]=useState(false);
    const [sndBtnState, setSndBtnState]=useState(false);

    const [animHomepage, setanimHomepage]=useState(0);
    const [modalC, setModalC]=useState(0);

    const [floorHoverClickRef, setFloorHoverClickRef]= useState({});

    const [gr, setGraphicsResolution]=useState(7);
    const [ix, setIx] =useState(7);
    const [triggerR, setTriggerR]=useState(false);

    const [hmOcculusBrowser, sethmOcculusBrowser]=useState(false);

    const [occulsionBoxesLoadState, setOcculsionBoxesLoadState]=useState(false);
    
    const [finishedLoading, setFinishedLoading]=useState(2);

        useEffect(()=>{
                if(!localStorage.getItem("askaboutcookies"))
                {
                        let a=window.confirm("This website uses cookies.");

                        if(a)
                        {
                                localStorage.setItem("askaboutcookies", "true");
                        }
                        else
                                window.location.href="";
                        }
        });


    let pauseClickEvent= {pause: false};

    // let hmOcculusBrowser=false;

    const setTopClasses=()=>
    {
        var element = document.getElementById("root");

        if(navigator.userAgent.indexOf("OculusBrowser")!==-1)
        {
                sethmOcculusBrowser(true);
 
                element.classList.add('OculusBrowser');
                element.style.backgroundColor="#000000";
        }

        element.classList.add(getDeviceType());
    }

    const loadContentModel=()=>
    {
        try {
                loader.load(
                        CLIENT_PATH+'JSON/contentModel.json',
                        content=>setContentModel(JSON.parse(content.toString()))
                        ,
                
                        xhr  => {
                        //     console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
                        },

                        err => {
                        console.error( 'An error happened' );
                        }
                );
        }
        catch(e)
        {
                console.log("Content Model JSOn error: "+e)
        }
    }

    const loadConfigurationtModel=()=>
    {
        loader.load(
                CLIENT_PATH+'JSON/configuration.json',
                content=>setConfigurationModel(JSON.parse(content.toString()))
                ,
           
	        xhr  => {
               
	//      console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
	        },

	          err => {
		console.error( 'An error happened' );
	        }
        );
    }


    useEffect(() => {  // (orientation: portrait)
        let sp=  window.matchMedia("(orientation: portrait) and (max-width: 375px)");  
        let mp=  window.matchMedia("(orientation: portrait) and (min-width: 376px) and (max-width: 800px)");  
        let tp=  window.matchMedia("(orientation: portrait) and (min-width: 801px)");
        let sl = window.matchMedia("((orientation: landscape) and (max-width: 375px)) or (max-height: 450px)");
        let mtl= window.matchMedia("((orientation: landscape) and (min-width: 376px) and (max-width: 800px)) or (max-height: 550px)");
        let tl=  window.matchMedia("(orientation: landscape) and (min-width: 801px) and (max-width: 1280px)");
        let sll= window.matchMedia("(orientation: landscape) and (min-width: 1281px) and (max-width: 1599px)");
        let lll= window.matchMedia("(orientation: landscape) and (min-width: 1600px) and (max-width: 1920px)");
        let dl=  window.matchMedia("(orientation: landscape) and (min-width: 1920px)  and (max-width: 19200px)");
    
        sp.addEventListener('change', () => { setMatching(1); });   
        mp.addEventListener('change', () => { setMatching(2); });   
        tp.addEventListener('change', () => { setMatching(3); });   

        sl.addEventListener('change', () => { setMatching(4); });  
        mtl.addEventListener('change', () => { setMatching(5); });  
        tl.addEventListener('change', () => { setMatching(6); });  
        sll.addEventListener('change', () => { setMatching(7); });  
        lll.addEventListener('change', () => { setMatching(8); });  
        dl.addEventListener('change', () => { setMatching(9); }); 

        if(sp.matches)
                setMatching(1);
        else
        if(mp.matches)
                setMatching(2);
        else
        if(tp.matches)
                setMatching(3);
        else
        if(sl.matches)
                setMatching(4);
        else
        if(mtl.matches)
                setMatching(5);
        else
        if(tl.matches)
                setMatching(6);
        else
        if(sll.matches)
                setMatching(7);
        else
        if(lll.matches)
                setMatching(8);
        else
        if(dl.matches)
                setMatching(9);

        loadConfigurationtModel();
        loadContentModel();


        setTopClasses();
    }, []);
    

    useEffect(()=>{
        if(!loggedIn)
        {
                if(getLoggedInS()) 
                {
                     setLoggedIn(true);

                     const zoompage_=getPage();

                     if(zoompage_)   
                        setZoompage(zoompage_);             
                }
        }
        else
                setLoggedInS();

    },[loggedIn]
    )

    useEffect(
        ()=>
        {
                setPage(zoompage);

                
                let element = document.getElementById("root");

                let cl="";
                
                 // @ts-ignore // @ts-ignore
                const values= element.classList.values();
                for(let v of values)
                        if(v.indexOf('zoompage_')!=-1)
                                cl=v;
                
                if(cl==="")
                        element.classList.add('zoompage_'+zoompage);

                if(cl!=="")
                        element.classList.replace(cl,'zoompage_'+zoompage);

                // @ts-ignore
                if(window?.urlChange)
                {
                        // @ts-ignore
                        window.urlChange();
                }
        }
        ,[zoompage]
    )

    const isVisible = usePageVisibility()

    useEffect(
        ()=>
        {
               setUnFocused(!isVisible);
        }
        ,[isVisible]
    )

    
 

    return  <>    { loggedIn 
    || 
   ( window.location.href.indexOf('galleryentrance') === -1
    && window.location.href.indexOf('galleryentrancevr') === -1
    && window.location.href.indexOf('worldart') === -1 )
     || process.env.NODE_ENV === 'development'    
 ? 
          <InfoContext.Provider value={{ modelname, zoompage, scrollp: {scrollpage, setScrollpage},
                                             setZoompage, scrollBarRef, setScrollBarRef, 
                                             animQueue: qu,
                                         //    raycastEvents: new RaycastButtons(),
                                             various: {variousStates, variousStatesDispatch},
                                             vr: {enabledVR, setEnabledVR, hmOcculusBrowser},
                                             fly: {rayCastMouseDown:()=>{}, 
                                                        rayCastMouseUp:()=>{}, controls, setController},
                                             goal: {cameraGoal, setCameraGoal},
                                             objFocus: {obj3DinFocus, setObj3DinFocus},
                                             test: {testV, setTestV, testScale, setTestScale},
                                             r: matching,
                                             contentModel,         // the content tree from JSON
                                             configurationModel,   // Configuration JSON
                                             l: "eng",
                                             subPageR: { subPageR, setSubPageR },
                                             cam: {cam,setCam},
                                             fcs: {unFocused, setUnFocused},
                                             soundButtonState: {sndBtnState, setSndBtnState},
                                             pauseClickEvent,
                                             zoomart: { 
                                                        zoomArtSetter: ()=>{},
                                                        zoomArtGetter: ()=>{}
                                             },
                                             animpage:  {animHomepage, setanimHomepage},
                                             modalCAct: {modalC, setModalC},
                                             floorHoverClick: {floorHoverClickRef, setFloorHoverClickRef},
                                             graphicsResolution: {gr, setGraphicsResolution, ix, setIx},
                                             fps: {getFPS, setFPS, triggerR, setTriggerR, kdown, setkdown, getMaxFPS, setMaxFPS},
                                             occulsion: {occulsionBoxes, occulsionLODObjects, occulsionBoxesLoadState, setOcculsionBoxesLoadState,
                                                                animations: {animationObjects, animationScaleObjects}, wallCollisionBoxes},
                                              finishedLoad: {finishedLoading, setFinishedLoading}
                                        }}>          
                    <ModalProvider>
                        <App name={page} scrollRef={{scrollRef:{scrollBarRef, setScrollBarRef}}}  
                              zoompage={zoompage} setZoompage={setZoompage}  r={matching} />
                    </ModalProvider>
            </InfoContext.Provider> 
             : 
            <LoginScreen setLoggedIn={setLoggedIn} />  } 
            </>

}
let urlS= window.location.href;

 if(urlS.indexOf('http://')!==-1  && process.env.NODE_ENV !== 'development' ) 
 { 
     urlS=urlS.replace('http://','https://');
     window.location.href=urlS;  
 }
else
{
        const container = document.getElementById('root');
        root = createRoot(container);
         root.render(<AppWithCallbackAfterRender />);
 }
 
            

// After
function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log('rendered');
  });

  return <Start/>
}

            
