/**
 * @author J-O Jansson w42.se
 */

 import React, {  useState,  useRef, useEffect  } from 'react';
 import { Vector3,  Color, /*  Fog, */  Euler } from 'three';
 

import {  PerspectiveCamera } from '@react-three/drei';

import {FlyControls } from './flycontrol/FlyControls';

import { useFrame, useThree, invalidate } from '@react-three/fiber';
import { XR, useXR  } from '@react-three/xr';



// @ts-ignore
import ZoomUIModel from "./ZoomUIcomponents"

// @ts-ignore
import { getCameraCoordsBasedOnScroll } from './getCameraCoordsBasedOnScroll.web';

import { calcLerp, calcLerpControls,  GetElementFromAnimList} from './flycontrol/anim/calcLerp';
import { galleryAnimList} from './flycontrol/anim/galleryAnimList';

import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";

import InfoContext from '../InfoContext';

import Hud,{setMarkerFromCameraVR} from "./widgets3D/hud";

import ClickMarker from './clickControl/clickMarker.js';

import {checkOcculsionLOD, initOcculsion} from './optimization/occulsion';
import {animate} from './animation/animate';

import {checkGraphicsLevel} from "./shading/getWebGLspec"

import {setisArtGAllery, touchMovement} from './flycontrol/mobile/touchmove';






const elementFromAnimList=GetElementFromAnimList(galleryAnimList);
 const getElement=elementFromAnimList.getElement;
 let controllerGoal =getElement();





 let animation=false;

 const setAnimation=v=>animation=v;

/** Main 3D component, maintains a scroll or fly controller 
 * 
 * @export
 * 
 * @param {object} props
 * @param {boolean} props.vr  chooses XR or OrbitControl
 * @param {function} props.handleModal  chooses XR or OrbitControl
 * @param {number} props.scrollTopState 
 * @param {boolean} props.releaseControl  set if go to "fly by your self"
 * @param {object} props.currentPage   Front page or a subpage
 * @param {object} props.animQueue   
 * @param {object} props.backgroundState    
 * @param {object} [props.clickinfo]  
 * @param {object} [props.various]  
 * @param {object} [props.fpsdiv]  
*/
export default function Content2({vr, handleModal, scrollTopState, fpsdiv,
                        releaseControl, currentPage, animQueue,  
                        backgroundState, clickinfo={clickinfo: {}}, various
                      }) 
{
    const { zoompage, setZoompage, fly, goal, objFocus: { setObj3DinFocus}, cam: {setCam}, r, 
                  zoomart, fps, occulsion, vr: {setEnabledVR}, configurationModel, soundButtonState: {sndBtnState}, 
                  finishedLoad: { setFinishedLoading}, graphicsResolution } = React.useContext(InfoContext);



                                                  
    const camera = useRef();
    const controls = useRef();
    const hudref = useRef();

    let  scene, gl;

    const {  player } = useXR();

    const clickMarkerRef=various?.clickMarkerRef;
   
     const T =  useThree();
     scene=T.scene;
     gl=T.gl;
    


    const [initLookat, setInitLookat] = useState(true);

    const  runPathAnimation=various?.pathAnimation?.runPathAnimation;
    const  setRunPathAnimation=various?.pathAnimation?.setRunPathAnimation;

     const {cameraGoal, setCameraGoal}=goal;
 
     const [markerRef, setMarkerRef]=useState({});

     /** @type {Boolean | object} */
     const [occulsionState, setOcculsionState]=useState(false);
 


    useEffect(() => {
      if(!vr && camera?.current)
      {
     
          // @ts-ignore
         camera.current.up.set(  0, 0, 1 ).normalize();

        if(currentPage.currentP==="home")
             // @ts-ignore
             camera.current.position.set(0, -1, 6);
        else
          // @ts-ignore
             camera.current.position.set(0, 0, 0);

         // @ts-ignore
         camera.current.rotation= new Euler(0,0,0, "XYZ");
        
         if(scene)
         {
            if(currentPage.currentP!=="galleryentrance" && currentPage.currentP!=="galleryentrancevr")
            {
              gl.alpha=true;


              
               // @ts-ignore
              if(controls?.current?.position?.set)
              // @ts-ignore
                controls.current.position.set(0, 0, 0);
            }

               if(player?.children)
              {
                  player.children[0].position.set(0.6128788635204312, 0.0006280361198397935,5.6929631507839185);
              }
              else
                console.error("couldn't set player init position");

        }
      }

      // @ts-ignore
      setCameraGoal(getCameraCoordsBasedOnScroll(1));

    } , []);


    useEffect(()=>
    {
      if(scene)
      {
        if(currentPage.currentP!=="home")
        {    
          scene.background=null;
          gl.alpha=true;
          gl.setClearColor( 0x000000, 0 ); 
         
        }
        else
        if(!backgroundState)
        {
          scene.background= new Color(0x2D2A38); 
        }
        else
        {
          if(currentPage.currentP==="galleryentrance" || currentPage.currentP==="galleryentrancevr")
          {  
            scene.background= new Color(0xffffff); 
            gl.alpha=false;
          }
          else
          {
            scene.background= new Color(0x000000);
            gl.alpha=true;
          } 
        }
      }
    }, [backgroundState]
    );


    useEffect(()=>{
       if(occulsion?.occulsionBoxesLoadState)
       {
           setOcculsionState(initOcculsion(occulsion?.occulsionBoxesLoadState, configurationModel) );
       }
    }, [occulsion?.occulsionBoxesLoadState]);

  const mobile= rsp({
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 }, r);     

    let frameNr=0, reached=false, zoomArt=false;

    const zoomArtSetter=t=> zoomArt=t;
    const zoomArtGetter=()=>zoomArt;

    zoomart.zoomArtSetter=zoomArtSetter;
    zoomart.zoomArtGetter=zoomArtGetter;
    
    useEffect(
      ()=>{
        if(currentPage.currentP==="galleryentrancevr")
        {     
          setEnabledVR(true);
        }
        else      
        if(currentPage.currentP==="galleryentrance")
        {  
             setEnabledVR(false);
          }
        else
          setFinishedLoading(2);

      },[currentPage.currentP]
    );

      
    useEffect(
      ()=> {
        if(scene)
        {
          if(currentPage?.currentP==="galleryentrance" || currentPage?.currentP==="galleryentrancevr")
          {
                let maxFPS=0;
              
                if(zoompage!=="home")
                {
                    maxFPS= +localStorage.getItem("maxfps");
                
                    if(!maxFPS || typeof maxFPS !=="number" || isNaN(maxFPS) )
                    {
                      if(zoompage!=="home")
                          setTimeout  (()=>{
                                  if(vr)
                                    setZoompage('galleryentrancevr')
                                  else
                                    setZoompage('galleryentrance');
                              },
                              4500);

                        setZoompage('home');               
                    }
                    else
                        checkGraphicsLevel(maxFPS, graphicsResolution.setGraphicsResolution, graphicsResolution.setIx );
                }
                else
                {
                  console.log("Home ");
                }


           if(zoompage==="galleryentrance" && mobile)
            {
                    setisArtGAllery(true);
                    touchMovement(controls, invalidate, zoomArtSetter);
            }   


    
            scene.background = new Color(255, 255, 255);

            setCam(camera.current);      

            if(fly?.setController)
              fly.setController(controls);
          }
   
            scene.background = null;
        }

  

      }, [currentPage?.currentP, zoompage, mobile, controls?.current]
    )

    useEffect(() => {
      if(!vr && camera?.current)
      {
          // @ts-ignore
         camera.current.up.set(  0, 0, 1 ).normalize();
          // @ts-ignore  
        camera.current.setRotationFromEuler( new Euler(0, 0,0, "XYZ") );
      }  
    }, [releaseControl]);


    useEffect(
      ()=>{ 
        if(various?.camLerp?.setCameraLerpReached)
                various.camLerp.setCameraLerpReached(false) },
      [cameraGoal]
    )
 

    useEffect(
      ()=>{
          // @ts-ignore
          console.log('{position: {x:'+controls?.current?.camera?.current?.position?.x+','
          // @ts-ignore
        +'y:'+controls?.current?.camera?.current?.position?.y+','
        // @ts-ignore
          +'z:'+controls?.current?.camera?.current?.position?.z+'},'
          // @ts-ignore
          +'quaternion:{x:'+controls?.current?.camera?.current?.quaternion?.x+','
        // @ts-ignore
          +'y:'+controls?.current?.camera?.current?.quaternion?.y+','
            // @ts-ignore
          +'z:'+controls?.current?.camera?.current?.quaternion?.z+','
          // @ts-ignore
          +'w:'+controls?.current?.camera?.current?.quaternion?.w
          // @ts-ignore 
          +'}},');  
      }
      ,[sndBtnState]
    );

      const checkProximity=(proximityKey, thekey,cllbck)=> {
            if(proximityKey===thekey )
                cllbck();
      }

     let fps_=0;

 
      useFrame(() => { 
      
            if(!vr)
            {
                  // @ts-ignore
                  if(controls?.current && controls?.current?.kdown)
                  {
                    fps.setkdown(true);
                  }
                  else
                  {
                    fps.setkdown(false);
                  }

                  if(various?.animStepFunction)
                        various.animStepFunction()

                  if(initLookat && !releaseControl && camera.current && cameraGoal) 
                  {
                    setInitLookat(false);
                  }

                  
                  if(fpsdiv?.current && fps?.getFPS) {
                      fps_=fps.getFPS();

                      if(fps_>1 && window.location.hostname!=="vworlds.epo.org")
                        fpsdiv.current.innerHTML=" => "+(fps_).toPrecision(2)+" fps";
                      fpsdiv.current.style.color="white";
                    }

                
                  if(camera.current && cameraGoal && !releaseControl)
                  {
                        calcLerp(camera.current, cameraGoal.pos, ()=>{ 
                            if(various?.camLerp?.setCameraLerpReached)
                                    various.camLerp.setCameraLerpReached(true) });
                    }
              

       
              if(currentPage?.currentP!=="galleryentrancevr")
              {
                if(( fps.getMaxFPS() ) < fps_ )
                    fps.setMaxFPS(fps_);
              }
            }
      
   
            
            const proximityKey=checkOcculsionLOD(occulsionState, camera.current);  // HÄR
            
            checkProximity(proximityKey, "occulsionBox6",  ()=>animation=true);   
           
            if(animation)
                animate(occulsion, camera, ()=>animation=false);


          // do something on each frame of an active XR session
          if(player?.children[0]?.position)
          {
                  if(frameNr++>24*10 && reached)
                  {
                      frameNr=0;
                
                      reached=false;               
                 }   

                 if(currentPage?.currentP==="galleryentrancevr" &&  player)
                 {
                   setMarkerFromCameraVR(markerRef, player, cameraGoal);
          
                   if(runPathAnimation || zoomArt)
                   {
                   // @ts-ignore
                        calcLerpControls( player, controllerGoal, ()=>{
                          reached=true;
                          zoomArt=false;
                        }, reached, 0.01, 1);
                      }
                 }   
          } 

      }   
      );
  
      const animStep=inc=>{
        const nr=elementFromAnimList.getElmentWithoutInc(inc);
        const el=galleryAnimList[nr || 0];

        // @ts-ignore
        if(controls?.current?.camera?.current?.position?.set && el)
        {
            // @ts-ignore
            controls.current.camera.current.position.set(el.position.x, el.position.y, el.position.z);
            // @ts-ignore
            controls.current.camera.current.quaternion.set(el.quaternion.x, el.quaternion.y, el.quaternion.z, el.quaternion.w);
            controllerGoal =el;
          }
      }

      clickinfo.clickinfo=(choice)=> {
        if(choice==="stepForwardAnim")
        {
          elementFromAnimList.forwardElement();
          animStep(1)
          
        }
        else
        if(choice==="stepBackwardAnim")
        {
          elementFromAnimList.backwardElement();
          animStep(-1)
        }
        else
        if(choice==="pause")
        {
          elementFromAnimList.setAnimForward(false)
        }
        else
        if(choice==="play")
        {
          elementFromAnimList.setAnimForward(true)
        }
        else
        {
              setRunPathAnimation(true);

            // @ts-ignore
              console.log('{position: {x:'+controls?.current?.camera?.current?.position?.x+','
              // @ts-ignore
            +'y:'+controls?.current?.camera?.current?.position?.y+','
            // @ts-ignore
              +'z:'+controls?.current?.camera?.current?.position?.z+'},'
              // @ts-ignore
               +'quaternion:{x:'+controls?.current?.camera?.current?.quaternion?.x+','
            // @ts-ignore
              +'y:'+controls?.current?.camera?.current?.quaternion?.y+','
                // @ts-ignore
              +'z:'+controls?.current?.camera?.current?.quaternion?.z+','
              // @ts-ignore
              +'w:'+controls?.current?.camera?.current?.quaternion?.w
              // @ts-ignore 
              +'}},');  

 // @ts-ignore
            console.log(" {x: "+controls?.current?.camera?.current?.position?.x+
             // @ts-ignore
                ",y:"+ controls?.current?.camera?.current?.position?.y,
                     // @ts-ignore
                ",z: "+ controls?.current?.camera?.current?.position?.z,
                 // @ts-ignore
                ",r:  new Euler( "+controls?.current?.camera?.current?.rotation?.x +"," 
                  // @ts-ignore
                +controls?.current?.camera?.current?.rotation?.y +","
                  // @ts-ignore
                +controls?.current?.camera?.current?.rotation?.z , ",'XYZ')}");  
        }
      }

      let mouseButtonWasUp=false;

      fly.rayCastMouseDown =()=>{    
                zoomArt=false;
      }

      fly.rayCastMouseUp =()=>{    
              // @ts-ignore
              if(!mouseButtonWasUp && controls?.current?.moveState)
              {
                // @ts-ignore
                controls.current.moveVector=new Vector3(0,0,0);
                mouseButtonWasUp=true;
              }
      }

    return (
      <XR>
      <group>
          <group>   
            <ClickMarker clickMarkerRef={clickMarkerRef}  various={{camera,...various}} />
        { !vr &&   <PerspectiveCamera ref={camera} makeDefault>
                         { various?.showHUD &&  <Hud hudref={hudref} currentPage={currentPage?.currentP} setMarkerRef={setMarkerRef} vr={false} /> }
                          { zoompage==="galleryentrance"  && <pointLight />}                             
                  </PerspectiveCamera>  } 
        

            <ZoomUIModel camera={camera} controls={controls} handleModal={handleModal}  
              various={{setControllerGoal: goal=>{
                  controllerGoal=goal;
                  zoomArt=true;
              } , zoomArtSetter,
                  zoomArtGetter,
              ...various, setAnimation}}   
              scrollTopState={ scrollTopState} />                          
             
                <>
                  {/*
                // @ts-ignore */}
                  <FlyControls camera={camera} 
                    ref={controls} 
                    movementSpeed={0.03}
                    rollSpeed ={0.01}
                    autoForward={false}
                    dragToLook ={true}
                    />
                </>
                
          </group>
       </group>
      </XR> 
    )
  }