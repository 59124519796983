import React,{useState, useContext} from 'react';
import styled from 'styled-components';

import InfoContext from '../../InfoContext';

 import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import {  Vw, R } from '../../multiEnvComponents/blocktags';
// @ts-ignore
import { H3, P} from '../../multiEnvComponents/texttags.web';

 // @ts-ignore
 import {CLIENT_PATH} from '../../config/serverURLPath.web';
 import Button from '../../multiEnvComponents/ButtonA.web'; 

 import { StandardPlanet_ as StandardPlanet} from '../../components3D/widgets/ExploreThePlanets';
 import {  Image, ImageSVG } from '../../multiEnvComponents/Image.web';

 import Overlay from '../../components/overlay';

 // import './infocomp.css';



let clickNext=true;
const setClickNext=s=>clickNext=s;

const mobileRR={
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 1, 
    tl: 1, 
    sll: 0, 
    lll: 0, 
    dl: 0 };


    // const smallmobile_={
    //     sp:  1, 
    //     sl:  0, 
    //     mp: 1,
    //     tp:  0,
    //     mtl: 0, 
    //     tl: 0, 
    //     sll: 0, 
    //     lll: 0, 
    //     dl: 0 };


const gridAreaR={
    sp:  "6 / 10 / 12 / 14", 
    sl:  "6 / 10 / 12 / 14", 
    mp: "6 / 10 / 12 / 14",
    tp:  "6 / 10 / 12 / 14",
    mtl: "6 / 10 / 12 / 14", 
    tl: "6 / 10 / 14 / 14", 
    sll: "6 / 8 / 14 / 14", 
    lll: "6 / 8 / 12 / 12",  
    dl: "6 / 8 / 12 / 12", 
}

const imageSize1R={
    sp:  120, 
    sl:  120, 
    mp: 120,
    tp:  120,
    mtl: 160, 
    tl: 160, 
    sll: 380, 
    lll: 380, 
    dl: 380 }


    const Plan= ({children}) => <Vw>{children}</Vw>



/**
 * 
 * 
 * @export
 * @param {object} props
 * @param {string} props.image
 * @param {string} props.icon
 * @param {string} props.title
 * @param {object} [props.p1]
 * @param {object} [props.p2]
 * @param {object} [props.buttonTopText]
 * @param {object} [props.buttonText]
 * @param {function} [props.buttonClick]
 * @param {function} props.arrowLeftClick
 * @param {function} props.arrowRightClick
 * @param {number} props.whichOne
 * @param {array} [props.clickList]
 * @param {any} [props.infoBoxState]
 * 
 * @returns 
 */
function InfoCompDesktop({ image, icon , title, p1, p2, buttonTopText,
                                 buttonText, buttonClick, arrowLeftClick=()=>{}, 
                                 arrowRightClick=()=>{}, whichOne, clickList, infoBoxState  })
{
    const {r} =  useContext(InfoContext);

    const [showing, setShowing]=useState(true);

    const mobileR= rsp(mobileRR,r);   


    


    const gridL={
        marginTop: mobileR ? 45: -45,
        display:  mobileR ? "flex" : "grid",
        "gridTemplateColumns": "repeat(15, [col-start] 1fr)",
        "gridTemplateRows": "repeat(15, [row-start] 6.65%)",
        height: "79.5vh",
        width: "80vw"
    };



    const centerText={textAlign: mobileR ? "center" :"left", marginBottom: 5}
    const h4w={ "fontWeight": "600",
                "fontSize": "32px",
                "lineHeight": "36px"}
    const marginP={marginTop: 30}



   const gridArea=rsp(gridAreaR, r);

    const menuAction=({ choice })=>{
            clickList[choice-1](infoBoxState.setInfoBoxState);}



    const clckNxt=dir=> {
            
        if(clickNext)
        {
            if(dir==='l')
                { //console.log("left click"); 
                arrowLeftClick()}
            else
                { // console.log("right click");
                arrowRightClick()}
        }

        setClickNext(false);
    }

    return <>{ showing && 
      <Vw st={{overflow: "visible", ...gridL}}>
                                        <Vw n="columnArrowLeft" st={{ zIndex: 200,
                                             gridColumn: mobileR? 4 : "1", 
                                            gridRow: mobileR ? 2 :  "8", 
                                            justifySelf: "left", marginLeft: "20px",  
                                            alignSelf: "center" }}>
                                                <Image  n="arrowleft"   source={{uri: CLIENT_PATH+"images/info/arrowleft.png"}}  
                                                        style={{width: 15, height: 25, zIndex: 200}} onClick={()=>clckNxt('l')}  
                                                                                        onMouseUp={()=>setClickNext(true)} />
                                        </Vw>  
                                                                              
                                        <Vw n="column1" st={{overflow: "visible", 
                                                    gridColumn: mobileR ? 6: 2,  
                                                    gridRow: mobileR ? 2 :8, 
                                                    zIndex: mobileR ? -1: 0,
                                                    alignSelf:"center",
                                                    width: "10vw"}}>
                                                        <Image n="infoImage" imgClassName="maskedByCircle" 
                                                                    source={{uri: CLIENT_PATH+"images/info/"+image}}  
                                                                        style={{width: 600, 
                                                                                height: 385,
                                                                                marginLeft: -100
                                                                            }}   /> 
                                        </Vw>



                                            <Image n={"iconicon"+mobileR+infoBoxState?.infoBox}  
                                                    source={{uri: icon}} 
                                                    // @ts-ignore
                                                style={{width: 90, height: 90, 
                                                            marginLeft: mobileR ? 0: -20,
                                                            marginTop: mobileR ? 0: -20,
                                                            alignSelf: "center"
                                                             }} 
                                                containerStyle={{  
                                                    "gridRowStart": mobileR ? 2 :"4",
                                                    "gridColumnStart": "8",
                                                    "gridRowEnd": "auto",
                                                    "gridColumnEnd": "auto",
                                                    alignSelf: "center" }}  />




                                          <Vw n="textInfo" st={{
                                                                    gridArea: gridArea,
                                                                    marginLeft: -4,
                                                                    overflow: "visible",
                                                                    }}  >
                                          
                                                     <H3  n="title" st={{...h4w,...centerText}} >{title}</H3>

                                                <P n="p_text" st={{...marginP,...centerText}}>{p1}</P>
                                                <P st={{...centerText, marginBottom: 25}}>{p2}</P> 
                                                {buttonClick ? 
                                                    <>
                                                        <P st={{...centerText, fontSize: 12}}>{buttonTopText}</P>
                                                        <Button title={buttonText} onPress={buttonClick } buttonStyle={{alignSelf: "start"}} />
                                                    </>:
                                                    <>
                                                        <P st={{...marginP,...centerText}}>COMING SOON.</P>
                                                        <P st={centerText}>There is not access to the Augmented reality features for now. Stay tuned.</P>
                                                    </>
                                                }
                                            </Vw> 
                                       
                                            
                                         <Vw n="columnArrowRight" st={{   
                                                    "gridRowStart":  mobileR ? 2 :  "8",
                                                    "gridColumnStart":   mobileR? 12 : "15",
                                                    "gridRowEnd": "auto",
                                                    "gridColumnEnd": "auto",
                                                    marginRight: 15,
                                                    alignSelf: "center", 
                                                    "justifySelf": "end"}}>
                                                <Image  source={{uri: CLIENT_PATH+"images/info/arrowRight.png"}}  
                                                        n="arrowRight"  style={{width: 15, height: 25}}  
                                                        onClick={ ()=>{ console.log("right click"); clckNxt('r');  } }  
                                                        onMouseUp={()=>setClickNext(true)} />
                                        </Vw>  
                                    <R st={{    "gridRowStart": "15",
                                                "gridColumnStart": "8",
                                                "gridRowEnd": "auto",
                                                "gridColumnEnd": "auto", justifySelf: "center", 
                                                 display: "flex"
                                                }}                                             
                                                >
                                            <Plan>
                                                { (whichOne===1) ? 
                                                    <StandardPlanet choosen={true} margin={15} />
                                                    :
                                                    <StandardPlanet choosen={false}  menuA={{menuAction}} choice={1}  margin={15} />
                                                }
                                            </Plan>
                                            <Plan>
                                                { (whichOne===2) ? 
                                                    <StandardPlanet choosen={true} margin={15} />
                                                    : <StandardPlanet choosen={false}  menuA={{ menuAction}} choice={2}  margin={15} />
                                                }
                                            </Plan>
                                            <Plan>
                                                { (whichOne===3) ? 
                                                    <StandardPlanet choosen={true} margin={15} />
                                                    :
                                                    <StandardPlanet choosen={false} menuA={{menuAction}} choice={3}  margin={15} />
                                                }
                                            </Plan>
                                </R>
          </Vw> 
          } </>
}



const ArrowGroup =styled(R).attrs({
	className: "ArrowGroup"
})`
			display: flex;
            justify-content: center;
            align-items: center;
`;


const ArrowLeft =styled(Vw).attrs({
	className: "ArrowLeft"
})`
    margin-right: 5vw;
`;


const ArrowRight =styled(Vw).attrs({
	className: "ArrowRight"
})`
	 margin-left: 5vw;
`;


const IconIcon =styled(ImageSVG).attrs({
	className: "IconIcon"
})`
    width: 90; 
    height: 90;
`;



const overlayVerticalAdjust_={
    sp:  "108px", 
    sl:  "108px", 
    mp: "108px",
    tp:  "108px",
    mtl: "108px", 
    tl: "125px", 
    sll: "108px", 
    lll: "108px", 
    dl: "108px" }



/**
 * 
 * 
 * @export
 * @param {object} props
 * @param {string} props.image
 * @param {string} props.icon
 * @param {string} props.title
 * @param {object} [props.p1]
 * @param {object} [props.p2]
 * @param {object} [props.buttonTopText]
 * @param {object} [props.buttonText]
 * @param {function} [props.buttonClick]
 * @param {function} props.arrowLeftClick
 * @param {function} props.arrowRightClick
 * @param {number} props.whichOne
 * @param {array} [props.clickList]
 * @param {any} [props.infoBoxState]
 * 
 * @returns 
 */
 function InfoCompMobile({ image, icon , title, p1, p2, buttonTopText,
    buttonText, buttonClick, arrowLeftClick=()=>{}, 
    arrowRightClick=()=>{}, whichOne, clickList, infoBoxState  })
{
const {r,   /* setZoompage, scrollp */} =  useContext(InfoContext);

const [showing, setShowing]=useState(true);

// const mobileR= rsp(mobileRR,r);   

const overlayVerticalAdjust= rsp(overlayVerticalAdjust_,r); 



const centerText={textAlign: "left", marginBottom: 5}
const imageSize1=rsp(imageSize1R,r);
const menuAction=({ choice })=>{
clickList[choice-1](infoBoxState.setInfoBoxState);}

const clckNxt=dir=> {

if(clickNext)
{
    if(dir==='l')
        {console.log("left click"); arrowLeftClick()}
    else
        {console.log("right click"); arrowRightClick()}
}

setClickNext(false);
}

return <>{ showing && 
<Vw n="InfocompMobile" st={{overflow: "visible", marginLeft: "1rem", marginRight: "1rem"}}>
            <ArrowGroup>
                    <ArrowLeft>
                            <Image  n="arrowleft"   source={{uri: CLIENT_PATH+"images/info/arrowleft.png"}}  
                                    style={{width: 15, height: 25, zIndex: 200}} onClick={()=>clckNxt('l')}  
                                                                    onMouseUp={()=>setClickNext(true)} />
                    </ArrowLeft>  



                    <Overlay 
                    comp1={<Vw n="column1" st={{overflow: "visible", 
                                        alignSelf:"center"}}>
                                    <Image n="infoImage" imgClassName="maskedByCircle" 
                                                source={{uri: CLIENT_PATH+"images/info/"+image}}  
                                                    style={{width: imageSize1, 
                                                            height: imageSize1
                                                        }}   /> 
                                    </Vw>}
                                                
                                comp2={ <IconIcon n={"iconicon1"+infoBoxState?.infoBox}  
                                        source={{uri: icon}} 
                                        containerStyle={{ zIndex: 30000 }} 
                                        style={{width: 90, height: 90, marginLeft: 34, alignSelf: "center"}} />
                                    } 
                                    width={"160px"}
                                    verticaladjustment={overlayVerticalAdjust}
                                         />

                        <ArrowRight>
                                <Image  source={{uri: CLIENT_PATH+"images/info/arrowRight.png"}}  
                                        n="arrowRight"  style={{width: 15, height: 25}}  
                                        onClick={ ()=>{ console.log("right click"); clckNxt('r');  } }  
                                        onMouseUp={()=>setClickNext(true)} />
                        </ArrowRight> 
                </ArrowGroup>

               <Vw n="textInfo" st={{ 
                                       marginLeft: mobileRR ? -4: 10,
                                       overflow: "visible",
                                       textAlign: mobileRR ? "center": "left",                                     
                                       marginRight: 10 }}  >
                   <H3  n="title" st={{alignSelf: "center", marginTop: "1rem"}} >{title}</H3>
                   <P n="p_text" st={{alignSelf: "center"}}>{p1}</P>
                   <P st={centerText}>{p2}</P>
                   {buttonClick ? 
                       <>
                           <P st={{...centerText, fontSize: 12}}>{buttonTopText}</P>
                           <Button title={buttonText} onPress={buttonClick } buttonStyle={{  alignSelf: "center" , 
                                                                        height: buttonText.length>25 ? 52 : 42 }}/>
                       </>:
                       <>
                           <P st={{}}>Coming soon.</P>
                           <P st={centerText}>There is not access to the Augmented reality features for now. Stay tuned.</P>
                       </>
                   }
               </Vw>
          
               
        
            <R st={{ display: "flex",  marginTop: "3vh", alignSelf: "center"}}>
                    <Plan>
                        { (whichOne===1) ? 
                            <StandardPlanet choosen={true} margin={15} />
                            :
                            <StandardPlanet choosen={false}  menuA={{menuAction}} choice={1}  margin={15} />
                        }
                    </Plan>
                    <Plan>
                        { (whichOne===2) ? 
                            <StandardPlanet choosen={true} margin={15} />
                            : <StandardPlanet choosen={false}  menuA={{ menuAction}} choice={2}  margin={15} />
                        }
                    </Plan>
                    <Plan>
                        { (whichOne===3) ? 
                            <StandardPlanet choosen={true} margin={15} />
                            :
                            <StandardPlanet choosen={false} menuA={{menuAction}} choice={3}  margin={15} />
                        }
                    </Plan>
        </R>
</Vw> 
} </>
}





/**
 * 
 * 
 * @export
 * @param {object} props
 * @param {string} props.image
 * @param {string} props.icon
 * @param {string} props.title
 * @param {object} [props.p1]
 * @param {object} [props.p2]
 * @param {object} [props.buttonTopText]
 * @param {object} [props.buttonText]
 * @param {function} [props.buttonClick]
 * @param {function} props.arrowLeftClick
 * @param {function} props.arrowRightClick
 * @param {number} props.whichOne
 * @param {array} [props.clickList]
 * @param {any} [props.infoBoxState]
 * 
 * @returns 
 */
 export default function InfoComp(props)
{
        const {r,   /* setZoompage, scrollp */} =  useContext(InfoContext);
        const mobileR= rsp(mobileRR,r);   

        if(!mobileR)
                return <InfoCompDesktop {...props} />
            else
                return <InfoCompMobile {...props} />
    }