import React from 'react';

// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';
import ModelViewer from '../../components3D/multiEnv/ModelViewer.web';
import Info from './Info';

export default function AR_Saraceno()
{
    return <>
    <Info qr="images/QR/Saraceno.png" link="https://vworlds-dev01.azurewebsites.net/?p=saraceno" /> 
    <ModelViewer src={URL3D+"AR/saraceno.glb"}
    iosSrc={URL3D+"AR/saraceno.usdz"}
    alt="model viewer"
    ar={true}
    loading="lazy"
    cameraControls={true}
    poster={URL3D+'AR/saraceno.png'}
    autoplay={true}
    style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: lightblue;"}
/></>
}