import React, {useContext,useEffect} from "react";
import InfoContext from  '../InfoContext';       // '../../InfoContext';


export default () => {
  let [modal, setModal] = React.useState(0);
  let [modalContent, setModalContent] = React.useState("I'm the Modal Content");
  const {zoompage, modalCAct} =  useContext(InfoContext);

  useEffect(() => {
    setModal(0);
  }, [zoompage, modalCAct?.modalC]);

  let handleModal = (content = false, cl=false, extra=0) => {

    if(cl)
    {
       setTimeout(() => {
          setModal(0)
       }, 200);
    
    }
    else
    {
      if(extra===0)
        setModal(1);
      else
        setModal(2);
    }

    if (content) {
      // @ts-ignore
      setModalContent(content);
    }

    return ()=> modal;
  };

  return { modal, handleModal, modalContent };
}