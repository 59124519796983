import React, {useState, useEffect, useRef, useContext} from 'react';
import { useFrame } from '@react-three/fiber';
import { Vector3, DoubleSide /* , Euler, Quaternion, Vector2, Box3 */ } from 'three';

import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
import InfoContext from '../../InfoContext';


import {InfoSprite} from '../Screen3D.web';
   
let tabPressed=false;

let keyUp=false;

window.addEventListener( 'keydown', event=>{
  if( event.code==="m")
      tabPressed=true;
      keyUp=false;
} );

window.addEventListener( 'keyup', event=>{
  if( event.code==="m")
      tabPressed=false;

      keyUp=true;
}  );


const hudScreenList=['galleryentrance'];

export default function Hud({currentPage, setMarkerRef, vr=false, hudref})
{
  const {r, setTriggerR} =  useContext(InfoContext);    // trigger unoptimize in fpsLOD.js  nothing to do with HUD

    const markerRef=useRef();

    const [tabPressedS, setTabpressedS] = useState(false);

    const mobile= rsp({
      sp: 1, sl: 1, mp: 1, tp: 1, mtl: 0, tl: 0, sll: 0, lll: 0, 
           dl: 0 }, r);  

    let xPos=-0.08, yPos=mobile?-0.01 :-0.03;
   
    

    useEffect(() => {
        if(setMarkerRef)
        {
             setMarkerRef(markerRef);
        }
     }, [setMarkerRef]);  


      useFrame(()=>{

        if(!vr)
        {
          if(tabPressed)
            setTabpressedS(true);
          else
          if(tabPressedS)
            setTabpressedS(false);

          if(keyUp)     // trigger unoptimize in fpsLOD.js  nothing to do with HUD
          {
            if(setTriggerR)
              setTriggerR(t=>!t);  
            keyUp=false;
          }


          const intViewportWidth = window.innerWidth;
          const intViewportHeight = window.innerHeight;
        
          xPos=(0.03-intViewportWidth/intViewportHeight * 0.05);  //placement of the map hud
  
          if(hudref?.current?.position?.set)
              hudref.current.position.set(xPos, yPos, tabPressedS ?  -0.3 : -0.1);

        }
      });
    


     if(hudScreenList.indexOf(currentPage) !==-1)  
       return <group ref={hudref} name="hud" 
              scale={tabPressedS ? new Vector3(6,6,1): new Vector3(1,1,1)}>
               <InfoSprite filename={ "hudSprites/close"} 
                      spriteScale={ tabPressedS ?  0.000005 : 0.0001 } 
                      spritePosition={ new Vector3( 0, 0.020, 0 )  }
                      />  
                <InfoSprite filename={tabPressedS ?  "hudSprites/hudbig-"+currentPage: "hudSprites/hud-"+currentPage} 
                  spriteScale={ tabPressedS ?  0.000025 : 0.00005}   />  
                <mesh ref={markerRef} scale={new Vector3(0.001, 0.001, 0.001)} name="HUD"
                translate={tabPressedS ? new Vector3(0,  0, 0.01): new Vector3(0,  0, 0.001)} > 
                    <coneGeometry args={[0.5, 2, 6]} />
                  <meshStandardMaterial  color={"red"} side={DoubleSide} />
              </mesh>
            </group>
    else
        return <></>
}



const corner1=new Vector3(
  -5.456560005049342,  // x 
  -1.3845150042614693,  // y
  8.213784802760951  // z
);

const corner2=new Vector3(
    16.141013400541155,
   -1.3845120558900963,
   -13.840666287236592
  )

  const xCornerDistance=Math.abs(corner2.x-corner1.x);
  const zCornerDistance=Math.abs(corner2.z-corner1.z);

/**
 * @param {any} pos
 * @param {any} controls
 */
 export function setCameraFrom2DPos(pos, controls)
 {
     if(controls?.current?.camera?.current)
     {
        const x=  corner1.x + (pos.x * xCornerDistance);
        const y=  0;
        const z=  (corner1.z  -  (pos.y * zCornerDistance));

        controls.current.camera.current.position.set( x, y, z);

        controls.current.camera.current.rotation.x=0;
        controls.current.camera.current.rotation.z=0;

    }
 }



  const Z= 0;


 /**
 * @param {any} markerRef
 * @param {any} controls
 */
  export function setMarkerFromCamera(markerRef, controls)
  {
      const map3DSize=new Vector3(0.03333333,0.03333333,0);

    if(markerRef?.current && markerRef?.current?.visible!==true)
            markerRef.current.visible=true;

      if(controls?.current?.camera?.current?.position 
        && markerRef?.current?.position)
      {
        const p3dx= controls?.current?.camera?.current?.position?.x;
        const p3dz= controls?.current?.camera?.current?.position?.z;

        const px=  (p3dx - corner1.x) / xCornerDistance;
        const x=  px * map3DSize.x- map3DSize.y/2; 

        const py=  (p3dz - corner1.z) / zCornerDistance;
        const y= -py * map3DSize.y -  map3DSize.y/2; 

       markerRef.current.position.set(x, y, Z);

        const rt=  controls?.current?.camera?.current?.quaternion;
        markerRef.current.quaternion.set(0, 0, rt.y,  rt.w);
      } else
      {
        if(markerRef?.current)
            markerRef.current.visible=false;
      }

  }


 /**
 * @param {any} markerRef
 */
  export function setMarkerFromCameraVR(markerRef, position, quaternion)
  {
      const map3DSize=new Vector3(0.03333333,0.03333333,0);

    if(markerRef?.current && markerRef?.current?.visible!==true)
            markerRef.current.visible=true;

      if(markerRef?.current?.position)
      {
        const p3dx= position?.x;
        const p3dz= position?.z;

        const px=  (p3dx - corner1.x) / xCornerDistance;
        const x=  px * map3DSize.x- map3DSize.y/2; 

        const py=  (p3dz - corner1.z) / zCornerDistance;
        const y= -py * map3DSize.y -  map3DSize.y/2; 

       markerRef.current.position.set(x, y, Z);

        const rt=  quaternion;
        markerRef.current.quaternion.set(0, 0, rt.y,  rt.w);
      } else
      {
        if(markerRef?.current)
            markerRef.current.visible=false;
      }

  }





