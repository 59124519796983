// @ts-nocheck

import VideoMultiEnv from "../../multiEnvComponents/VideoMultiEnv.web";
import React, { useEffect, useRef} from 'react';
import { View} from 'react-native';

import  { Text } from '../../multiEnvComponents/rnwComponents.web';

import  { H1 } from '../../multiEnvComponents/texttags.web';

import StyleSheet from 'react-native-media-query';

import  Anim2dPlayer from  "../../multiEnvComponents/Anim2dPlayer.web"
import { CLIENT_PATH } from '../../config/serverURLPath.web';

import InfoContext from '../../InfoContext';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

const videoMask={
    "maskImage": "url(graphics2D/background_grad.svg)",
    "maskPosition": "center center",
    "maskRepeat": "no-repeat",
    "maskSize": "cover",
    "maskMode": "alpha, match-source"
}


// @ts-ignore
export default function VideoIntroBody({show=true, loop=true})
{

    // @ts-ignore
    const {r, /* l, */  contentModel: {homepage}, vr: {hmOcculusBrowser}} = React.useContext(InfoContext);

    const titleStyleSubtitleAbove= {"fontSize": rsp({
        sp:  18, 
        sl:  18, 
        mp: 18,
        tp:  18,
        mtl: 42, 
        tl: 42, 
        sll: 42, 
        lll: 42, 
        dl: 56 }, r) ,
        "lineHeight":   rsp({
            sp:  "30px", 
            sl:  "30px", 
            mp: "30px",
            tp:  "30px",
            mtl: "64px", 
            tl: "64px", 
            sll: "64px", 
            lll: "64px", 
            dl: "64px"}, r)}

    
        const scrollIconLabelS= {"fontSize": rsp({
                sp:  18, 
                sl:  18, 
                mp: 18,
                tp:  18,
                mtl: 18, 
                tl: 16, 
                sll: 16, 
                lll: 16, 
                dl: 18 }, r) ,
                "lineHeight":   rsp({
                    sp:  "25px", 
                    sl:  "25px", 
                    mp: "25px",
                    tp:  "25px",
                    mtl: "25px", 
                    tl: "22px", 
                    sll: "22px", 
                    lll: "22px", 
                    dl: "25px"}, r),
                    "letterSpacing": rsp({
                        sp:  "1px", 
                        sl:  "1px", 
                        mp: "1px",
                        tp:  "1px",
                        mtl: "1px", 
                        tl: "", 
                        sll: "", 
                        lll: "", 
                        dl: "1px"}, r)
                }



    const titleStyleSubtitleBelow= {"fontSize": rsp({
                sp:  18, 
                sl:  18, 
                mp: 18,
                tp:  18,
                mtl: 22, 
                tl: 22, 
                sll: 22, 
                lll: 22, 
                dl: 24 }, r) ,
                "lineHeight":   rsp({
                    sp:  "30px", 
                    sl:  "30px", 
                    mp: "30px",
                    tp:  "30px",
                    mtl: "28px", 
                    tl: "28px", 
                    sll: "28px", 
                    lll: "28px", 
                    dl: "30px"}, r)}


        const styles ={
            overlayOnVideo: {
                display: "flex",
                position: "absolute",
                flexDisplay: "column",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                top: 0,
                zIndex: 9999,
            } ,
            subTitleAbove: {
                marginTop: "29vh",
                "fontFamily": "Source Sans Pro, sanserif",
                "fontStyle": "normal",
                "fontWeight": "600",
               ...titleStyleSubtitleAbove,
                "textAlign": "center",
                "letterSpacing": "1px",
                "color": "#FFFFFF",
                selfAlign: "center"
            },
            title:{
                "fontFamily": "Source Sans Pro, sanserif",
                "fontStyle": "normal",
                "fontWeight": "600",
                "fontSize": "72px",
                "lineHeight": "76px",
                "textAlign": "center",
                "letterSpacing": "1px",
                "color": "#FFFFFF",
                "width": "60%",
                alignSelf: "center"
            },
            subTitleBelow: {
                "fontFamily": "Source Sans Pro, sanserif",
                "fontStyle": "normal",
                "fontWeight": "normal",
                ...titleStyleSubtitleBelow,
                "textAlign": "center",
                "color": "#FFFFFF",
                selfAlign: "center",
                width: "50vw"
            },
            scrollIconLabel: {
                "fontFamily": "Source Sans Pro, sanserif",
                "fontStyle": "normal",
                "fontWeight": "300",
                ...scrollIconLabelS,
                "textAlign": "center",
                "color": "#FFFFFF"
            },
            image: {
                justifyContent: "flex-start",
                width: "100%",
                height: "100vh"
            }

        };


        const mobile = StyleSheet.create({
            overlayOnVideo: {
                display: "flex",
                position: "absolute",
                // @ts-ignore
                flexDisplay: "column",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                top: "10%",
                zIndex: 9999,
        //      background: "rgba(27, 6, 28, 0.55)"
            } ,
            subTitleAbove: {
                "fontFamily": "\"Source Sans Pro\"",
                // @ts-ignore
                "fontSize": "24px",
                // @ts-ignore
                "fontStyle": "\"normal\"",
                "fontWeight": "300",
                // @ts-ignore
                "lineHeight": "35px",
                // @ts-ignore
                "letterSpacing": "1px",
                "textAlign": "center",
                "color": "white",
                "marginTop": "25vh",
                selfAlign: "center"
            },
            title:{
            "fontFamily": "Source Sans Pro, sanserif",
                // @ts-ignore
                "fontSize": "35px",
                // @ts-ignore
                "fontStyle": "\"normal\"",
                "fontWeight": "600",
                // @ts-ignore
                "lineHeight": "35px",
                // @ts-ignore
                "letterSpacing": "0px",
                "textAlign": "center",
                "color": "white",
                marginBottom: 35
            },
            subTitleBelow: {
                "fontFamily": "Source Sans Pro",
                "fontStyle": "normal",
                "fontWeight": "normal",
                // @ts-ignore
                "fontSize": "16px",
                // @ts-ignore
                "lineHeight": "24px",
                "textAlign": "center",
                "color": "#FFFFFF",
                width: "fit-content",
                marginLeft: "4vw",
                marginRight: "4vw",
            },
            scrollIconLabel: {
                "fontFamily": "Source Sans Pro",
                "fontStyle": "normal",
                "fontWeight": "300",
                // @ts-ignore
                "fontSize": "18px",
                // @ts-ignore
                "lineHeight": "25px",
                "textAlign": "center",
                // @ts-ignore
                "letterSpacing": "1px",
                "color": "#FFFFFF"
            },
            image: {
                justifyContent: "flex-start",
                width: "100vw",
                height: "100vh"
            }
        });




     const mobileR= rsp({
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 1, 
        tl: 0, 
        sll: 0, 
        lll: 0, 
        dl: 0 }, r);  


        const ref= useRef();

        useEffect(() => {
            return () => {
                // @ts-ignore
                if(ref?.current?.style?.visibility)
                // @ts-ignore
                    ref.current.style.visibility=false;
            }
        });

        return  <View ref={ref} style={{width: "100%", /* height: "100vh", */ right: mobileR ? 0: 10, flexDirection:"column"}} nativeID="videointrobody">                       
                    <View 
// @ts-ignore
                    style={!mobileR ? styles.overlayOnVideo : mobile.styles.overlayOnVideo } >
                                <View style={{ 
                                    // @ts-ignore
                                    justifyItems: "stretch", justifyContent: "space-between", height: "100vh" }}  >
                                    <View nativeID="introtexts" style={{width: mobileR ? "90vw": "70vw", alignSelf: "center"}}>
                                            <Text nativeID="subtitleAbove" style={!mobileR ? styles.subTitleAbove : 
                                                                    mobile.styles.subTitleAbove}>{homepage?.landingpagetitleabove}</Text>
                                        <H1 n="title" st={!mobileR ? styles.title : 
                                                                        mobile.styles.title}>{homepage?.landingpagetitle}</H1>
                                        <Text nativeID="subTitleBelow" style={{ alignSelf: "center",  
                                            ...(!mobileR ? styles.subTitleBelow   : mobile.styles.subTitleBelow)} }
                                       >{homepage?.landingpagesubtitle}</Text>
                                        
                                                      
                                    </View>

                                    <View>                
                                                        { mobileR ?  <Text nativeID="explore" 
                                                        // @ts-ignore
                                                            style={{...styles.scrollIconLabel}}>Explore the universe</Text> : <></>  }        
                                                             { !mobileR && <Text nativeID="scrollinfo" 
                                                                    // @ts-ignore
                                                                        style={styles.scrollIconLabel} >Scroll to explore the universe</Text>  }                                                                      
                                                                    <View nativeID="scrollIcon" style={{ alignSelf: "center", marginBottom: mobileR ? "10rem": "5rem" }} >  
                                                                        <Anim2dPlayer src={mobileR ? CLIENT_PATH+"graphics2D/anim2d/mobileAnimIcon.json" : 
                                                                            // eslint-disable-next-line react/style-prop-object
                                                                            CLIENT_PATH+"graphics2D/anim2d/mouseAnimIcon.json"}   
                                                                            autoplay loop style='width: 48px; height: 48px;' />
                                                            </View>            
                                                     </View>
                                </View> 
                           
                            


                    </View>
                    
                    <View nativeID="videoVw" style={ hmOcculusBrowser? {height: "calc(100vh - 1px)"} : {height: "calc(100vh - 1px)", 
                        backgroundColor: "#2D2A38", ...videoMask}}   >
                        {  <VideoMultiEnv src={{
                                                            webm: "images/video/video.webm",    
                                                            mp4: "images/video/video.m4v",
                                                            mov: "images/video/video.mov",
                                                            ogg: "images/video/video.ogg"
                                                        }}  
                                            size={{width: mobileR ?  "200%":  "100%", height: mobileR ?  "200%": "100%"}} loop={loop} />         
                        } 

                    </View>
                             
                           
            </View>
     
}


