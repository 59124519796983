import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

export default function Kwade()
{
     const {contentModel: {infoboxes}, l} =  useContext(InfoContext);
    return <div>
    <ArtWorkHeader  
				artistname="Alicja Kwade"
                title="Stand by (Ab2)"
                year="2002"
                material="C-print, dibond aluminium"
                measurements="30 x 25 cm" 
        />
        <div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</div>
    </div>
}