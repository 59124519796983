import React,{useState, useContext} from 'react';

import InfoComp from './InfoComp';
import InfoContext from '../../InfoContext';

import { clickList } from './VRInfo';

export default function VRInfo({infoBoxState})
{
    const {r, contentModel: {infoboxes}, l,  setZoompage} =  useContext(InfoContext);

    return <InfoComp  image={infoboxes.v3d.image}  icon={infoboxes?.v3d?.icon} 
                    title={infoboxes.v3d.title?.[l]}
                    p1={infoboxes.v3d?.first?.[l]}
                    buttonTopText={infoboxes?.v3d?.buttonText?.[l]}
                    buttonText={infoboxes?.v3d?.button?.[l]}
                    buttonClick={()=>{setZoompage('planetWorldofArt2')}}
                    arrowLeftClick={()=>{infoBoxState.setInfoBoxState('arinfo')}}
                    arrowRightClick={()=>{infoBoxState.setInfoBoxState('vrinfo')}}
                    whichOne={3}
                    clickList={clickList}
                    infoBoxState={infoBoxState}
/>
}

