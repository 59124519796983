
import React, { useState, useEffect, useRef, useContext} from 'react';
import { useSpring, animated } from 'react-spring';

import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";
import InfoContext from '../InfoContext';

import {View} from 'react-native';

import useOnScreen from './useOnScreen';

/**
 * Fade component animation
 * 
 * @export
 * @param {object} props
 * @param {object} props.children
 * @param {object} [props.st] style
 * @param {object} [props.n] id
 * @param {object} [props.animY] animY
 * @returns 
 */
export default function OnScreenFadeInAnim({children, st, n, animY})
{
  const [show, set] = useState(false);

  const ref = useRef();
  const isVisible = useOnScreen(ref);

//  const {r} =  useContext(InfoContext);   

 
  useEffect(() => {
      set(isVisible);
  }, [isVisible]);

const props = useSpring(animY  ?
                                { to: { opacity:  show ? 1 : 0, translateY: show ?  animY : 0 }, from: { opacity: 0, translateY: animY }, delay:200 }
                                :{ to: { opacity:  show ? 1 : 0  }, from: { opacity: 0 }, delay:200 });
  return <View ref={ref} style={{...st }} nativeID={n}>   
            <animated.div id="animated div" style={props}>
                  {children}
            </animated.div>
        </View>;
}


// show ? <>{children}</> :   alignSelf: align? "center": "start"