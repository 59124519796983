import { useContext} from 'react';

import InfoContext from "../../InfoContext";

import { setURL } from '../../App.web';

import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import { C,R } from '../../widgets/subPages/htmlcomponents/blocktags';
// @ts-ignore
import { H4, P} from '../../widgets/subPages/htmlcomponents/texttags.web';
import { Image } from  '../../multiEnvComponents/Image.web'; 
import Button from '../../multiEnvComponents/ButtonA.web';
import PropTypes from 'prop-types';



const h4w={width: "40%"}
const marginP={marginTop: 30}

const tr1= {sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1, 
    lll: 1, 
    dl: 1 };



export default function ARInfo({infoBoxState})
{
    const {r, contentModel: {infoboxes}, l} =  useContext(InfoContext);
    const tR=rsp(tr1,r);

    const centerText={textAlign: "center", marginBottom: 5, width: tR ? "" : "80vw"}

    const mrgns={margin: 5,  alignSelf: "start", alignContent: "center", 
        justifyItems: "center", width:  tR? "50%": "100%", placeItems: "center" };


   return <R n="infoMarconiARchoices" st={{ flexDirection: tR? "row": "column", alignSelf: "center"}} >
    <C st={mrgns}>
        <Image  source={{uri: infoboxes?.ar?.icon}}  style={{width: 77, height: 77}}   />
        <H4 st={{...h4w,...centerText}} >{infoboxes?.ar?.title[l]}</H4>
        <P st={{...marginP,...centerText}}>{infoboxes?.ar?.first[l]}</P>
        <C st={{ display: tR? "flex": "none", alignSelf: "center"}}>
                <Image source={{uri: infoboxes?.radio?.qr}} style={{width: 121, height: 118}} containerStyle={{alignSelf: "center"}}  />
                <P st={centerText}>{infoboxes?.ar?.second[l]}</P>
                <P st={centerText}>{infoboxes?.radio?.link}</P>
        </C>
      <Button title={infoboxes?.radio?.button1[l]} onPress={()=> {
                            setURL(""); 
                            window.location.href ="ar_markerless/markerless.html?modelname=radio"; 

                            if(infoBoxState?.setInfoBoxState)
                                infoBoxState.setInfoBoxState(false); } } 
                                buttonStyle={{marginTop: 30, width: tr1 ?  "": "80vw"}}/> 
    </C>
    <C st={mrgns}>
        <Image  source={{uri: infoboxes?.v3d?.icon}} style={{width: 77, height: 77}}   />
        <H4 st={{...h4w,...centerText}}>{infoboxes?.v3d?.title[l]}</H4>
        <P st={{...marginP,...centerText}}>{infoboxes?.v3d?.first[l]}</P>
        <Button title={infoboxes?.radio?.button[l]} onPress={()=> {
                            setURL(""); 
                            window.location.href="ar_markerless/markerless.html?modelname=radio"; 

                            if(infoBoxState?.setInfoBoxState)
                                infoBoxState.setInfoBoxState(false); } } />
    </C>
</R>
}


ARInfo.propTypes = {
    infoBoxState: PropTypes.shape({
        setInfoBoxState: PropTypes.func.isRequired
    })
};