import React,{useState, useContext} from "react";

import styled from 'styled-components';

// import {Text } from 'react-native';

import rsp from  '../widgets/subPages/htmlcomponents/useResponsiveBrk'  //  "./useResponsiveBrk";
import InfoContext from '../InfoContext';

import "./rnwComponents.css";

const common= {
                "fontFamily": "Source Sans Pro",
                "fontStyle": "normal",
                display: "flex"
            }

const commonCss="color: white;";


const P1 =
        styled.p.attrs({
            className: "P1b"
        })`
    ${commonCss}

    font-size: 16px;
    line-height: 24px;
`;






/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {object} [props.onClick] 
 * @param {string} [props.n] 
 * @param {string} [props.className] 
 * @returns 
 */
export function P({children, st, w, onClick, n, className})
{
  //  const r_ =  useContext(InfoContext);
 //    const r=r_?.r;

    // @ts-ignore
    return <P1 nativeid={"p_"+n}  style={{ ...common,    
            "fontStyle": "normal",  
            flexWrap: "wrap",
         // @ts-ignore
        ...st}} onClick={onClick} className={"css-view-rnw "+className}>{children}</P1>
}




const H1b =
        styled.h1.attrs({
            className: "H1b"
})`
    ${commonCss}
`;


/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {string} [props.n] 
 * @param {string} [props.className] 
 * @returns 
 */
export function H1({children, st, w,n, className})
{
    const {r} =  useContext(InfoContext);

   const h1s= {"fontSize": rsp({sp:  35, 
                    sl:  35, 
                    mp: 35,
                    tp:  35,
                    mtl: 35, 
                    tl: 45, 
                    sll: 45, 
                    lll: 50, 
                    dl: 50 }, r),
            "lineHeight": rsp({sp:  '40px', 
                    sl:  '40px', 
                    mp: '40px',
                    tp:  '50px',
                    mtl: '40px', 
                    tl: '55px', 
                    sll: '55px', 
                    lll: '60px', 
                    dl: '60px' }, r)
   }


    // @ts-ignore
    return <H1b  className={"css-view-rnw "+className} id={"H1"+n} style={{...common, // width: w, 
          "fontWeight": "600",  
         "fontSize": "50px",
         "lineHeight": "60px",
         flexWrap: "wrap",
         ...h1s,
         ...st
    }} >{children}</H1b>
}


const H2b =
        styled.h2.attrs({
            className: "H2b"
})`
    ${commonCss}
`;

/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {number} [props.w] 
 * @param {string} [props.n] 
 *  @param {string} [props.className] 
 * @returns 
 */
export function H2({children, st, n, className})
{
    const {r} =  useContext(InfoContext);

    // eslint-disable-next-line jsx-a11y/heading-has-content

    // @ts-ignore
    return <H2b className={"css-view-rnw "+className} nativeID={"H2"+n} 
        style={{ ...common, // width: w, 
        "fontWeight": "600",  
        "fontSize": rsp({sp:  22, 
                           sl:  22, 
                            mp: 22,
                            tp:  22,
                            mtl: 22, 
                            tl: 22, 
                            sll: 32, 
                            lll: 42, 
                            dl: 42 }, r)
                            ,
        "lineHeight": "34px",
        flexWrap: "wrap"  ,
         ...st
    }}>{children}</H2b>
}




const H3b =
styled.h3.attrs({
    className: "H3b"
})`
${commonCss}
`;                              
                            


/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {object} [props.w] 
 * @param {object} [props.n] 
 *  @param {string} [props.className] 
 * @returns 
 */
export function H3({children, st, n, className})
{
    const cntxt =  useContext(InfoContext);
    const r = cntxt?.r ? cntxt?.r : "dl";
    
    // @ts-ignore
    return <H3b nativeid={"H3"+n}
        // @ts-ignore
        className={"css-view-rnw "+className}
        style={{  ...common,  // width: w,  
                              "fontWeight": "600",
                            "fontSize":  rsp({sp:  22, 
                        sl:  26, 
                            mp: 26,
                            tp:  26,
                            mtl: 26, 
                            tl: 32, 
                            sll: 32, 
                            lll: 42, 
                            dl: 42 }, r),
                            "lineHeight": rsp({sp:  22, 
                        sl:  32, 
                            mp: 32,
                            tp:  32,
                            mtl: 32, 
                            tl: 32, 
                            sll: 32, 
                            lll: 46, 
                            dl: 46 }, r)+"px",  
                            flexWrap: "wrap"  , ...st                           
                }}>{children}</H3b>
}

const H4b =
styled.h4.attrs({
    className: "H3b"
})`
${commonCss}
`;    


/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st]  
 * @param {object} [props.n] 
 * @param {string} [props.className] 
 * @param {string} [props.onClick] 
 * @returns 
 */
 export function H4({children, st, n,  className, onClick})
 {
     const {r} =  useContext(InfoContext);

  
     // @ts-ignore
     return <H4b className={"css-view-rnw "+className}
        // @ts-ignore
        nativeid={"H4_"+n} style={{ ...common,  // width: w, 
     "fontWeight": "400", 
     "fontSize": rsp({sp:  16, 
                        sl:  16, 
                            mp: 16,
                            tp:  16,
                            mtl: 22, 
                            tl: 22, 
                            sll: 22, 
                            lll: 22, 
                            dl: 22 }, r),
     "lineHeight":   rsp({sp:  26, 
                        sl:  26, 
                            mp: 26,
                            tp:  26,
                            mtl: 28, 
                            tl: 28, 
                            sll: 28, 
                            lll: 28, 
                            dl: 28}, r)+"px",
                            flexWrap: "wrap" 
                            ,
     ...st}}  onClick={onClick}>{children}</H4b>
 }

 const H5b =
 styled.h5.attrs({
     className: "H3b"
 })`
 ${commonCss}
 `;    



/**
 * 
 * @param {object} [props] 
 * @param {object} [props.children] 
 * @param {object} [props.st] 
 * @param {object} [props.w] 
 * @param {object} [props.n] 
 *  @param {string} [props.className] 
 * @returns 
 */
export function H5({children, st, w, n, className})
{
    // @ts-ignore
    return <H5b className={"css-view-rnw "+className} nativeid= {"H5"+n} style={{...common, "fontWeight": "600", width: w,  
    "fontSize": "14px",   
    "lineHeight": "30px",
     ...st}}>{children}</H5b>
}



function useRadioButtons(name, cllbck, initValue) {
    const [value, setState] = useState(initValue);
  
    const handleChange = e => {
      setState(e.target.value);
      cllbck(e.target.value);
    };

    const inputProps = {
      name,
      type: "radio",
      onChange: handleChange
    };
  
    return [value, inputProps];
  }



export function Radio({radioGroupName, st, items, selectedIndex, setSelectedIndex, itemStyle})
{
    const [platformValue, plaftormInputProps] = useRadioButtons(radioGroupName, v=> setSelectedIndex(v), selectedIndex);
 
    return  <div style={st}>
               { items.map((el, indx)=> {   return <div key={el?.key}>
                                                <input style={itemStyle}
                                                    value={el?.val}                                       
                                                    checked={platformValue==el?.val} 
                                                    {...plaftormInputProps}  /> 
                                                    <label htmlFor={el?.text}>{el?.text}
                                                    </label>
                                                </div>
               }
                                                 ) }
             </div>

}
                                
                            


