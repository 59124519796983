/**
 * @author J-O Jansson w42.se
 */

import React, {useRef} from 'react';
import { Vector3, Euler} from 'three';

import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";
import Screen3D from './Screen3D.web';

// @ts-ignore
import { getCameraCoordsBasedOnScroll } from './getCameraCoordsBasedOnScroll.web';

import InfoContext from '../InfoContext';




function translateCoords(nr, zoompage, animQueue)
{
    // @ts-ignore
    return getCameraCoordsBasedOnScroll(nr, 0, zoompage, animQueue)?.lookat;
}

export const SPRITEGROUPSCALE= 0.0001;
export const htmlspriteScaleMobile=0.06;



export default function ZoomUIComponents({camera, controls,  handleModal, various, scrollTopState})
{
    let { zoompage, animQueue, r } = React.useContext(InfoContext);                 

    const groupRef=useRef();

    const entrance=useRef();

    let lightIntensity=0.6;

    if(zoompage==="galleryentrance")
        lightIntensity=0.6;
    else
        lightIntensity=1.6;


    animQueue.group= groupRef?.current ;

    
    const modelScalFact=0.5;
    const yModelFactor=0;


    console.log(" various?.planet?.choosedPlanet :  "+various?.planet?.choosedPlanet);

    const mobile= rsp({
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 0, 
        tl: 0, 
        sll: 0, 
        lll: 0, 
        dl: 0 }, r); 

    const planetZMobile=0.2; 
    const planetZDesktop=0.15;

    various.vrMultiplier =     (zoompage==="galleryentrancevr"  && process.env.NODE_ENV !== 'development') ? 
                                            // @ts-ignore
                                            window.virtualRealityGlobal.sceneScale : (zoompage==="galleryentrancevr" ? 50 :  false);   

   return <group>
     { (zoompage==="galleryentrance" || zoompage==="galleryentrancevr") && <>
               <ambientLight intensity={0.4} />  
           <hemisphereLight args={[0xffffff, 0x080820, 0.3]}  />
         <pointLight position={new Vector3(1000,-1000,1000)} castShadow={false} intensity={lightIntensity}  decay={0} />
            <pointLight position={new Vector3(1000,1000,-1000)} castShadow={false} intensity={lightIntensity} decay={0} />
           <pointLight position={new Vector3(-1000,1000,1000)} castShadow={false}  intensity={lightIntensity} decay={0} /> 
            <pointLight position={new Vector3(1000,-1000,-1000)} castShadow={false}  intensity={lightIntensity}  decay={0} />
            <pointLight position={new Vector3(-1000,1000,-1000)} castShadow={false}  intensity={lightIntensity} decay={0} />
            <pointLight position={new Vector3(-1000,-1000,1000)} castShadow={false}  intensity={lightIntensity} decay={0} /> 
            <pointLight position={new Vector3(1000,1000,1000)} castShadow={false}  intensity={lightIntensity} decay={0} />  
            <pointLight position={new Vector3(-1000,-1000,-1000)} castShadow={false}  intensity={lightIntensity} decay={0} />  
            <pointLight position={new Vector3(-4.522107743337385,0.04517989102606177,1.491986844427668)} castShadow={false}  intensity={lightIntensity*5} decay={15} />   
        </>
    }  

 { (zoompage!=="galleryentrance" && zoompage!=="galleryentrancevr") && <>
    <pointLight position={new Vector3(100,0,0)} castShadow={false} intensity={lightIntensity} decay={0} />
    <pointLight position={new Vector3(0,100,0)} castShadow={false}  intensity={lightIntensity} decay={0} />
    <pointLight position={new Vector3(0,0,100)} castShadow={false} intensity={lightIntensity} decay={0} /> 
    </>
    } 
        <group name="IDzoom">
            <group ref={groupRef}>
                   {
                 (  (various?.planet?.choosedPlanet===0 || zoompage!=="home")  && (zoompage==="home" || (zoompage==="planetInventions3" && !mobile  ) ) ) &&
                    <group name="IDplanetInventions3">
                        <group >
                            <group name="IDplanetInventions3">
                                <Screen3D filename={[ {filename: "planetInventions3",
                                                        fpsmin: 0}                                     
                                ]} showmodel={true} 
                                    camera={camera} controls={controls} various={various}  handleModal={handleModal}  
                            
                                    modelTransform={
                                        {
                                            modelPosition:  new Vector3(0, yModelFactor, mobile ? planetZMobile  :planetZDesktop),  
                                            modelRotation: new Euler( Math.PI*0.2, Math.PI*0.2, 0, 'XYZ'),  //rotation
                                            modelScale: new Vector3(0.028*modelScalFact,0.028*modelScalFact,0.028*modelScalFact)   // scal
                                        }    
                                    }

                                    forceFocusHTML={ scrollTopState===1 ? 2 : 1 }
                                />
                            </group>
                        </group>
                    </group>
                }   
                   {
                    ( (various?.planet?.choosedPlanet===1 || zoompage!=="home")  && (zoompage==="home" || ( zoompage==="planetWorldofArt2"   && !mobile )  ) ) &&
                        <group name="IDplanetWorldofArt2">
                            <group>
                                <group name="IDnurbsPlane2_geometry">
                                    <Screen3D filename="planetWorldofArt2" showmodel={true} showSprite={zoompage==="home"}
                                        camera={camera} controls={controls} various={various}  handleModal={handleModal}
                                      

                                        modelTransform={     // planetWorldofArt2
                                            {
                                                modelPosition:  new Vector3(0, yModelFactor,  mobile ? planetZMobile : planetZDesktop),  
                                                modelRotation: new Euler( Math.PI*0.2, Math.PI*0.2, 0, 'XYZ'),  //rotation
                                                modelScale: new Vector3(0.028*modelScalFact,0.028*modelScalFact,0.028*modelScalFact)   // scal
                                            }   
                                        }

                                        forceFocusHTML={ scrollTopState===2 ? 2 : 1 }
                                    />
                                </group>
                            </group>
                        </group>
                }   
                {
                ( (various?.planet?.choosedPlanet===2 || zoompage!=="home") && (zoompage==="home" || (zoompage==="planetEPO2"  && !mobile )  ) ) &&
                    <group name="IDplanetEPO2">
                        <group >
                            <group name="IDplanetEPO2">
                                <Screen3D filename= {[ {filename: "planetEPO2",
                                                        fpsmin: 0}
                                                      ]}
                                    showmodel={true} 
                                    camera={camera} controls={controls} handleModal={handleModal}  various={various} showSprite={zoompage==="home"}
                 

                                    modelTransform={
                                        {
                                            modelPosition:  new Vector3(0,yModelFactor,  mobile ? planetZMobile : planetZDesktop),  
                                                            modelRotation: new Euler( Math.PI*0.2, Math.PI*0.2, 0, 'XYZ'),  //rotation
                                                            modelScale: new Vector3(0.028*modelScalFact,0.028*modelScalFact,0.028*modelScalFact)   // scal
                                        }     
                                    }

                                    forceFocusHTML={ scrollTopState===3 ? 2 : 1 }
                                />
                            </group>
                        </group>
                    </group>
                }                        
                        {  
                                        (zoompage==="galleryentrance" ||  zoompage==="galleryentrancevr") &&
                                        <group name="IDgalleryentrance" ref={entrance} 
                                        position={translateCoords(0, "galleryentrance", animQueue)}>
                                                <group >
                                                    <group name="IDnurbsPlane1_geometry">
                                                        <Screen3D filename= {[ {filename:   
                                                                "galleryentrance",
                                                                fpsmin: 0}
                                                                ,
                                                                {filename:   "sculptures/sculpturesOptimised",
                                                                fpsmin: 1,
                                                                fpsmax: 8},   
                                                            {filename:   "sculptures/sculptures",
                                                           
                                                                fpsmin: 8}                                                        
                                                                ]}
                                                                                                       
                                                                    showmodel={true} 
                                                                    modelRotate={false} showSprite={false}
                                                                        camera={camera} controls={controls} handleModal={handleModal}  
                                                                        various={various} 
                                                                        spriteTransform={{
                                                                            spritePosition:  new Vector3(0,0,0),  
                                                                            spriteRotation:  new Euler(0, 0, 0, "XYZ"),  
                                                                            spriteScale:  SPRITEGROUPSCALE   // scale
                                                                        }}
                                                                        modelTransform={
                                                                            {
                                                                                modelPosition:  new Vector3(0,0,0),  
                                                                                modelRotation: new Euler( 0, 0, 0, 'XYZ'),  //rotation
                                                                                modelScale:   (zoompage==="galleryentrancevr"  && process.env.NODE_ENV !== 'development') ? 
                                                                                                                                    // @ts-ignore
                                                                                                                                    new Vector3(window.virtualRealityGlobal.sceneScale,
                                                                                                                                                // @ts-ignore
                                                                                                                                                window.virtualRealityGlobal.sceneScale,
                                                                                                                                                // @ts-ignore
                                                                                                                                                window.virtualRealityGlobal.sceneScale) : 
                                                                                                                                                (zoompage==="galleryentrancevr" ? new Vector3(50,50,50) :  
                                                                                                                                                        new Vector3(10,10,10) )  // scale
                                                                            }         
                                                                        }

                                                                        keepDetails={false}
                                                                        galleryFeatures={true}
                                                   

                                                                        progressBarContent={
                                                                            {
                                                                                    title: "EPO art collection",
                                                                                    subTitle: "Sustaining life: Art in the climate emergency",
                                                                                    image: 'textures/screens/loaderSplashImages/galleryentrance.png',
                                                                                    barText: 'The 3D experience is loading, please wait...',
                                                                                    percentagetext: 'is loaded'
                                                                            }
                                                                        }

                                                                    />
                                                                </group>
                                                            </group>
                                                        </group> }
                            </group>
                        </group>       
                    </group>  
}





