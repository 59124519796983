import React, { useRef,useContext, useEffect} from 'react';

import { View } from 'react-native';

// @ts-ignore
import ImageMap from '../../../multiEnvComponents/ImageMap.web';

// import { Vw } from '../htmlcomponents/blocktags';
import { Vw } from '../../../multiEnvComponents/blocktags';
// import TopMenu from '../../../components3D/widgets/TopMenu';
import rsp from "../htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../InfoContext';

import OnScreenFadeInAnim from '../../../multiEnvComponents/OnScreenFadeIn';

// @ts-ignore
import { /* URL3D,  */CLIENT_PATH } from '../../../config/serverURLPath.web';


export const SCROLL_DIVIDER=250;

let referenceScroll={rfrs: 0};



/**
 * Subpage is the top level subpage frame
 * 
 * @param {object} props 
 * @param {object} [props.children]
 * @param {object} [props.scrollRef]
 * @param {function} [props.scrollTopAction]
 * @param {function} [props.setScroll2State]
 * @param {number} [props.scroll2]
 * @param {function} [props.setScroll2]
 * @param {function} [props.scroll2State]
 * @param {string} [props.navigationFooterName] name of the popup footer navigation
 * @param {string} [props.navigationFooterLeftPage]
 * @param {string} [props.navigationFooterRightPage]
 * @returns 
 */
export default function SubPage({children, scrollRef, scrollTopAction, 
    setScroll2State=()=>{}, scroll2State, scroll2, setScroll2, navigationFooterName,  
    navigationFooterLeftPage, navigationFooterRightPage})
{
    const {r, zoompage, setZoompage,  variousStatesDispatch} =  useContext(InfoContext);

  //  const [scrlHeight, setScrollHeight] = useState(0);

    const scrollRef2 =useRef();
    const subpageRef =useRef();

    useEffect(() => {    
        // @ts-ignore
        if(  typeof scrollRef2?.current?.scrollTop ==='number'/*  && scroll2>=0 */)
        {
            // @ts-ignore
            const dir= scrollRef2.current.scrollTop - scroll2;

            const rf= document.getElementById('SubPageRoot');
            
            // @ts-ignore
            if(subpageRef?.current?.style?.transform || rf)
            {
                // @ts-ignore
                subpageRef.current.style.animationDuration = "3s";
                // @ts-ignore
                subpageRef.current.style.transform = `translateY(-${dir}px)`;
            }
            else
                { console.error("translateY(${dir}px)"); }
             

             // @ts-ignore
            scrollRef2.current.scrollTop=scroll2;

            setScroll2(-1);    
        }
    }, [scroll2])


    useEffect(()=>{
        if(zoompage!=="home")
        {
            const el=document.getElementById("transf");
            el.style.transform=null;
        }
    },[zoompage])

    const scrollE=(scrollRef2)=>
    {
    // @ts-ignore
        const scrllTp=  (Math.floor(scrollRef2?.current?.scrollTop));
        
        if( scrllTp> rsp({sp:  1024, 
            sl:  800, 
            mp: 800,
            tp:  2000,
            mtl: 2000, 
            tl: 1700, 
            sll: 1700, 
            lll: 1200, 
            dl: 1200 },
                r) &&  scrollTopAction)
                {  
                    scrollTopAction(1);
                }   
                else
                {
                    scrollTopAction(0);
                }

       
        setScroll2State(scrllTp);
    }

const mobile=rsp({sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 },
        r)
    
    const navigatorMap={
   
        name: "navigator",
        areas: [
            {
                shape: "circle",
                coords:  mobile ? [31/1.1,30/1.1,28/1.1] :  [31,30,28],   //   [31,30,28],
            } ,
            {
                shape: "circle",
                coords:  mobile ? [341/1.1,30/1.1,28/1.1] :  [341,30,28],
            }             
        ]
}
    const imgMapEvent=e=> {
        if(e?.center?.[0]< 300)
            setZoompage(navigationFooterLeftPage);
        else
            setZoompage(navigationFooterRightPage);
    }

    const scrollAct = (scrlState, rScroll) =>
    {     
        let rt=false;

        if(scrlState > rScroll.rfrs)       
            rt=false;
        else
            rt=true;
        
        rScroll.rfrs =scrlState;

        return rt;
    }

    let k=0;

    return <Vw n="subpage root" ref_={subpageRef} st={{ animation: "scrollup 5s linear 2s infinite alternate"}}>
                 <Vw n="Navigation footer imagemap" st={{"position": "fixed", bottom: 50,
                                                         zIndex: 200, alignSelf: "center"}}>             
                                                    { ( scrollAct(scroll2State, referenceScroll) ) &&
                                                        <OnScreenFadeInAnim animY={32}><ImageMap src={ CLIENT_PATH+'images/navigationfooter/'+navigationFooterName}
                                                                    map={navigatorMap} 
                                                                    width= {373 / (mobile ? 1.1 : 1 ) }  height={60 /  (mobile ? 1.1 : 1 )} 
                                                                    style={{ alignSelf: "center" }}                                                          
                                                                    onClick={e=> imgMapEvent(e)} /></OnScreenFadeInAnim>  }
                </Vw>
                <View nativeID="SubPageRoot" style={{height: "100vh",
                // @ts-ignore
                     overflowY: "scroll", overflowX: "hidden",  
                     backgroundImage: "linear-gradient(180deg, #302D3A 2.08%, #23212C 50.54%, #000000 100%)"}} 
                onScroll={()=>scrollE(scrollRef2)} ref={scrollRef2} >
                    {children.map(n=>(<OnScreenFadeInAnim key={k++}>{n}</OnScreenFadeInAnim>))}
                </View>
            </Vw>
}



  /*      */