import React,{useState, useContext} from 'react';
import  Button from '../../multiEnvComponents/ButtonA.web';

// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';
import ModelViewer from '../../components3D/multiEnv/ModelViewer.web';
// import Info from './Info';
import InfoContext from './../../InfoContext';

import ImageMap from  '../../multiEnvComponents/ImageMap.web';   // '../../../multiEnvComponents/ImageMap.web';

const navigatorMap={
   
    name: "navigator",
    areas: [
        { shape:"rect", coords:[2,160,227,415], model: URL3D+"AR/rooms/room1.glb" },
        { shape:"rect", coords:[61,5,193,153],  model: URL3D+"AR/argallery.glb"},
        { shape: "poly", coords:[238,152,237,239,438,246,435,0,205,5,204,155,238,155],  model:  URL3D+"AR/argallery.glb" },
        { shape: "rect", coords:[288,262,667,650],  model: URL3D+"AR/argallery.glb"},
        { shape: "rect", coords:[8,443,264,666],  model: URL3D+"AR/argallery.glb" }
    ]
}

function aCmp(a1, a2)
{
    for(let n in a1)
    {
        if(a1[n] !== a2[n])
            return false;
    }
    return true;
}


export default function AR_Gallery()
{
    const {r, contentModel: {infoboxes}, l} =  useContext(InfoContext);

  //  const { contentModel} =  useContext(InfoContext);

    const imgMapEvent=e=> {
            setShowMap(false);
            const c=e.coords;
            

            const a=navigatorMap.areas;

            const mdl=a.find(n=>aCmp(n.coords, c) ).model;
        
            setWhichModel( mdl);
     }


    const [whichModel, setWhichModel] = useState(URL3D+"AR/argallery.glb");
    const [showmap, setShowMap] = useState(true); 

    return <>
        { showmap ?
        <><ImageMap src={ URL3D+'textures/screens/hudSprites/hud-galleryentrance.png'}
                                            map={navigatorMap} 
                                            width= {671}  height={675} 
                                            style={{ marginTop: 150, alignSelf: "center" }}                                                          
                                            onClick={e=> imgMapEvent(e)} />
             <Button buttonStyle={{marginTop: 10}} 
                                onPress={()=>{
                                     setShowMap(false);
                                    setWhichModel(URL3D+"AR/argallery.glb")}} 
                                                title="show complete model"></Button>                                   
        </>
        :
        <><ModelViewer src={whichModel}
        iosSrc={URL3D+"AR/argallery.usdz"}
        alt="model viewer"
        ar={true}
        loading="lazy"
        cameraControls={true}
        poster={URL3D+'AR/artgalleryImage.jpg'}
        autoplay={true}
        style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: black;"}
        cameraTarget="0m 0m 0m"
        />
        <Button onPress={()=>setShowMap(true)} title="show map"></Button>
        </>}
</>
}









// const navigatorMap={
   
//     name: "navigator",
//     areas: [
//         {
//             shape: "rect",
//             coords: [0,4,128,59],
//         } ,
//         {
//             shape: "rect",
//             coords: [254,1,374,58],
//         },
        
//         {
//             shape: "rect",
//             coords: [139,0,237,35],
//         } ,
//         {
//             shape: "rect",
//             coords: [141,38,234,56]
//         }     
//     ]
// }


// export default function Arrows({mobile, various})
// {
//     let { fly: {controls}, pauseClickEvent, zoomart } = React.useContext(InfoContext);

//     const imgMapEvent=e=> {
//         if(controls?.current)
//         {
//             const cntrl = controls.current;

//             pauseClickEvent.pause=true;

//             if(zoomart?.zoomArtSetter)
//                     zoomart.zoomArtSetter(false);

//             switch(e.center[0])
//             {
//                 case 188:
//                     cntrl.keydown({code: 'KeyW'});  // forward
//                     setTimeout( ()=> { cntrl.keyup({code: 'KeyW'});   pauseClickEvent.pause=false;} ,200);
//                     break;
//                 case 187.5:
//                     cntrl.keydown({code: 'KeyS'});  // backward
//                     setTimeout( ()=> { cntrl.keyup({code: 'KeyS'});   pauseClickEvent.pause=false;} ,200);
//                     break;
//                 case 64:
//                     cntrl.keydown({code: 'ArrowLeft'});  // left
//                     setTimeout( ()=> { cntrl.keyup({code: 'ArrowLeft'});   pauseClickEvent.pause=false;} ,100);
//                     break;
//                 case 314:
//                     cntrl.keydown({code: 'ArrowRight'});  // right
//                     setTimeout( ()=> { cntrl.keyup({code: 'ArrowRight'});   pauseClickEvent.pause=false;} ,100);
//                     break;
//                 default:
//             }
//         }


//     }

//         return <View // @ts-ignore
//         nativeID="NavigationArrows" style={{width: "20em", marginBottom: 5, bottom: mobile ? 0: 90, 
//             position: 'fixed', zIndex: 29998, marginLeft: mobile? 20: 500, 
//             backgroundColor: "rgba(0.5, 0.5, 0.5, 0.1)",
//             padding:5, borderRadius: 3}}>
//                     <ImageMap src={ URL3D+'textures/screens/hudSprites/arrows2.png'}
//                                             map={navigatorMap} 
//                                             width= {373}  height={60} 
//                                             style={{ alignSelf: "center" }}                                                          
//                                             onClick={e=> imgMapEvent(e)} />
//         </View>
// }