import paths from './paths';

let SERVER_PATH_URL="";
let URL3D="/";
let workerURL="/";
let CLIENT_PATH="/";
let STATIC_PATH="/";

if(process.env.NODE_ENV === 'development')
{
    SERVER_PATH_URL="/";

    CLIENT_PATH= paths.envDevelopment[1][1]
    URL3D=paths.envDevelopment[1][1]+"3D/";
}
else
{
    CLIENT_PATH= paths.envProduction[1][1]
    URL3D=paths.envProduction[1][1]+"3D/";
}

STATIC_PATH=CLIENT_PATH;

export { SERVER_PATH_URL, URL3D, workerURL, CLIENT_PATH, STATIC_PATH };
