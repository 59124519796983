import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

import InfoContext from '../../../InfoContext';

import { CLIENT_PATH } from  '../../../config/serverURLPath.web';
import ArtWorkHeader from './ArtWorkHeader';

import "./style.css";

export default function Leitner()
{
	const { soundButtonState: {sndBtnState, setSndBtnState}, contentModel: {infoboxes}, l} = React.useContext(InfoContext);

    return <div style={{margin: 5}}>
		<ArtWorkHeader  
				artistname="Paul Leitner"
                title="Untitled (thermometer)"
                year="2016"
                material="50 thermometers, steel-brass frame, 4 light bulbs, cables, presence detector, timer clock"
                measurements="30 x 60 x 13 cm" 
        />
         <div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</div>
         <p style={{marginBottom: 5}}>Listen to sound</p>
            <ReactAudioPlayer
                src={CLIENT_PATH+"sounds/LEITNER.mp3"}
                controls
				muted={!sndBtnState}
                />      
    </div>
}