import React, { useContext } from 'react';
import { ImageSVG } from  '../../../multiEnvComponents/Image.web' 
import { CLIENT_PATH } from  '../../../config/serverURLPath.web'; 

import InfoContext from "../../../InfoContext";

export default function SoundBtn()
{
    const {soundButtonState: {sndBtnState, setSndBtnState}  } =  useContext(InfoContext);
    
    return  <ImageSVG source={{uri: CLIENT_PATH+
                    "graphics2D/artgallery/extramenubuttons/extramenu/"+ (sndBtnState 
                    ?  "sound.svg" : "soundOff.svg") }} 

                    hoverImageSource={{uri: CLIENT_PATH+ "graphics2D/artgallery/extramenubuttons/extramenu/"+ (sndBtnState 
                    ?  "sound_hover.svg" : "soundOff_hover.svg")}}     
                width={60} 
                height={60} 
                onClick={e=>{ e.preventDefault();
                                e.stopPropagation(); 
                                 setSndBtnState(o=> !o); }}

                aria={{
                        expanded: {sndBtnState}
                    }}
            />
}