import React,{useContext} from 'react';

// import {openInNewTab} from '../../../../components3D/scrollAction';
import rsp from "../../htmlcomponents/useResponsiveBrk";

import {TopComponent, Pane} from '../../widgetComponents/TopComponent';
import InfoContext from '../../../../InfoContext';

import { COLORS } from '../../../../components/colors';

import SideInfo from '../../widgetComponents/SideInfo'; 


function SideInfo1() {
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return <SideInfo 
    title={subpages?.epoatwork?.p2?.SideInfo1?.title[l]} 
    p_1="" 
    imagelist={{ /* ar:true, */ v3d: true}}
    buttontext={subpages?.epoatwork?.p2?.SideInfo1?.button?.[l]}
    buttonaction={()=>
            {
              window.open(subpages?.epoatwork?.p2?.toppane?.link?.[l]); 

                // setModalStyle({
                //     outer1: { left: "10vw" },  
                //     outer2: { width: "80vw" }
                // });

                // handleModal(
                //      createModalTextContent(  "info_artGallery" , false, {infoBox, setInfoBoxState} ), 
                // false, 2);      
            }
            //setZoompage('galleryentrance')
    
    }
    />
}
    

function SideInfo2(){
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return  <SideInfo comingsoon={true} 
    title={subpages?.epoatwork?.p2?.SideInfo2?.title[l]} 
    p_1="" 
    imagelist={{}}
    buttontext={subpages?.epoatwork?.p2?.SideInfo2?.button[l]}
    />
}

function EaWTopPane(){
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return <Pane
                    dateInfo={subpages?.epoatwork?.p2?.toppane?.dateInfo?.[l]}
                  title={subpages?.epoatwork?.p2?.toppane?.title[l]} 
                    subTitle={subpages?.epoatwork?.p2?.toppane?.subtitle[l]} 
                    imageName={subpages?.epoatwork?.p2?.toppane?.image[l]?.link} sideInfo={<SideInfo1 />}
                    imageStyle={{width: subpages?.epoatwork?.p2?.toppane?.image?.[l]?.width,
                                height: subpages?.epoatwork?.p2?.toppane?.image?.[l]?.height,
                                marginTop: 12}} 
                                imageAlt={subpages?.epoatwork?.p2?.toppane?.image?.[l]?.imageAlt}
                                 /> 
}

const mrgn1={
  sp:  0, 
  sl:  0, 
  mp: 0,
  tp:  0,
  mtl: 23, 
  tl: 23, 
  sll: 23, 
  lll: 23, 
  dl: 23 };


function EaWBottomPane(){
   const {contentModel: {subpages}, l,r} =  useContext(InfoContext);

   const mrgn= rsp(mrgn1, r);     

    return <Pane
                  dateInfo={subpages?.epoatwork?.p2?.bottompane?.dateInfo?.[l]}
               title={subpages?.epoatwork?.p2?.bottompane?.title[l]} 
                subTitle={subpages?.epoatwork?.p2?.bottompane?.subtitle[l]} 
                imageName={subpages?.epoatwork?.p2?.bottompane?.image[l]?.link} 
                sideInfo={<SideInfo2 />}  
                imageStyle={{width: subpages?.epoatwork?.p2?.bottompane?.image[l]?.width || 458*1.1,
                            height: subpages?.epoatwork?.p2?.bottompane?.image[l]?.height || 294*1.1, 
                            marginLeft: -mrgn, marginTop: -5, marginRight: mrgn }} 
                imageAlt={subpages?.epoatwork?.p2?.bottompane?.image?.[l]?.imageAlt}
                             /> 
}


function EaWBottomBottomPane(){
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

   return <Pane 
    dateInfo={subpages?.epoatwork?.p2?.bottombottompane?.dateInfo?.[l]}
   title={subpages?.epoatwork?.p2?.bottombottompane?.title?.[l]} 
   subTitle={subpages?.epoatwork?.p2?.bottombottompane?.subtitle?.[l]}  
   imageName={subpages?.epoatwork?.p2?.bottombottompane?.image?.[l].link}  
   imageStyle={{width: subpages?.epoatwork?.p2?.bottombottompane?.image?.[l].width ||  458*1.1, 
                height: subpages?.epoatwork?.p2?.bottombottompane?.image?.[l].height ||  
                     294*1.1, marginLeft: -23, marginTop: -5, marginRight: 23, }}
                     imageAlt={subpages?.epoatwork?.p2?.bottombottompane?.image[l]?.imageAlt  || "superTest3"}
                       /> 
                       }



export default function EaW_Top({planetFixed, third, scroll2State}) {
   const {contentModel: {subpages}, l} =  useContext(InfoContext);

  return  <TopComponent planetName="planetEPO2" planetLeft={false} 
    TopPane={subpages?.epoatwork?.p2?.toppane && <EaWTopPane />}
    BottomPane={subpages?.epoatwork?.p2?.bottompane && <EaWBottomPane />}
    planetFixed={planetFixed}
    TopTopPane={third}

      grad={"linear-gradient(180deg, rgba(216, 94, 124, 0) 0%, "+COLORS.woe+" 100%)"}
      scroll2State={scroll2State}

       SpecialExtraPane={subpages?.epoatwork?.p2?.bottombottompane && <EaWBottomBottomPane />}
     />
} 