import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ReactAudioPlayer from 'react-audio-player';

import ArtWorkHeader from './ArtWorkHeader';

import VideoMultiEnv from '../../../multiEnvComponents/VideoMultiEnv.web';

import { CLIENT_PATH } from  '../../../config/serverURLPath.web';


import "./style.css";

/* 	<p><span>Tomàs Saraceno</span></p>
	<p><span>Flying Garden (M32)</span><span>, 2007</span></p>
	<p><span>Balloons, rope, quartz sand, tubes, compressor</span><span><br /></span><span>Ø ca. 400 cm</span></p>     */

	export default function Saraceno()
{
	const { soundButtonState: {sndBtnState, setSndBtnState}, contentModel: {infoboxes}, l} = React.useContext(InfoContext);

    return <div style={{marginLeft: 5, marginRight: 5, padding: 5, height: "59vh"}} onMouseDown={ e=>e.stopPropagation() } >
		<ArtWorkHeader  
				artistname="Tomàs Saraceno"
                title="Flying Garden (M32)"
                year="2007"
                material="Balloons, rope, quartz sand, tubes, compressor"
                measurements=">Ø ca. 400 cm" 
        />
	<div>
	<div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</div>
	</div>
			<VideoMultiEnv src={{
                         //       webm: "images/video/video.webm",    
                                mp4: "videos/Saraceno.mp4",
                          //      mov: "images/video/video.mov",
                           //     ogg: "images/video/video.ogg"
                                }}  
            poster="images/pexels_mentatdgt_1167134_1.png" 
            size={{width: "100%", height: "100%"}} controls />
            <p style={{marginTop: 10, marginBottom: 5}}>Listen to sound</p>
            <ReactAudioPlayer
                src={CLIENT_PATH+"sounds/SARACENO.mp3"}
                controls
				muted={!sndBtnState}
                />       
    </div>
}