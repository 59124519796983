import React,{useRef,useContext} from 'react';
 import {View} from 'react-native';
// import { View, /*  Text, Image  , ImageBackground  */  } from '../../../multiEnvComponents/rnwComponents.web';


/**
 * @param {object} [props]
 * @param {any} [props.children]
 * @param {any} [props.s]
 * @param {any} [props.n]
 * @param {any} [props.st] 
 * @param {any} [props.flex] 
 * @param {any} [props.ref_] 
 * @param {any} [props.overflowHidden] 
 */
export function Vw({children, st, n, s={styles:[""],ids:[] }, flex, ref_, overflowHidden=true})
{
    return <View nativeID={n} style={{overflow: overflowHidden ? "hidden" : "", flex, ...st}}>
        {children}
        </View>
}


/**
 * @param {object} [props]
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.s]
 * @param {any} [props.n]
 * @param {any} [props.st] style
 * @param {any} [props.flex] 
 */
export function C({children, w, h, st,s={styles:[""],ids:[] }, n , flex })
{
    // @ts-ignore
    return <View nativeID={n}  style={{flexDirection:"column", overflow: "hidden", width: w, height: h, ...s?.styles[n], flex ,...st}}  dataSet={{ media: s.ids[n] }}>
             {children}
        </View>
}


/**
 * @param {object} [props]
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.n]
 * @param {any} [props.st] style
 * @param {any} [props.flex] 
 * @param {any} [props.className] 
 */
export function R({children,  w, h, st,  n , flex, className})
{
    // @ts-ignore
    return <View nativeID={n}  className={className}
    style={{flexDirection:"row",  overflow: "hidden",width: w, height: h, flex, ...st}} 
            >
            {children}
        </View>
}



/**
 * A React Native'ish View alternative tag component
 * 
 * @param {object} props
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.n] identifier
 * @param {any} [props.st] style
 * @param {any} [props.flex] 
 * @param {any} [props.className] 
 * @param {any} [props.onMouseButtonDown] 
 * @param {any} [props.onWheel] 
 */
 export function Vw2({children,  w, h, st,  n , flex, className, onMouseButtonDown, onWheel})
 {
     // @ts-ignore
     return <div nativeid={n}  className={className} onMouseButtonDown={onMouseButtonDown} onWheel={onWheel}
     style={{width: w, height: h, flex, ...st}} >
             {children}
         </div>
 }