import React from 'react';
import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

import {C} from '../../../multiEnvComponents/blocktags.js';
import {P} from '../../../multiEnvComponents/texttags.web.js';


/**
 * Styled Responsive box for art work info box
 *
 * @param {*} Span2.props
 */
 const Co =
    // @ts-ignore
    styled(C).attrs({
        className: "Co"
    })`
       margin-bottom: 2ex;
`;

/**
 * Styled Responsive box for art work info box
 *
 * @param {*} Span2.props
 */
 const Title =
    // @ts-ignore
    styled(P).attrs({
        className: "Title"
    })`
      font-weight: bold;
    `;




/*    @media (max-width: 925px) { 
           
        }

        @media (max-width: 400px) { 
        
        }  */


/**
 * Art work data section
 * 
 * @export
 * @param {object} props 
 * @param {object} [props.artistname] 
 * @param {object} [props.title]  the name of the art work
 * @param {object} [props.subtitle]
 * @param {object} [props.year]
 * @param {object} [props.material] the type of artwork, canvas painting or photograph etc
 * @param {object} [props.measurements]  sizes etc
 * @returns 
 */
export default function ArtWorkHeader({artistname, title, subtitle, year, material, measurements})
{
    return <Co>
        {artistname &&  <Title>
            {artistname}
        </Title> }
        {(title && !artistname) && <Title>
            {title}{year && ", "}{year}
        </Title>  } 
        {(title && artistname) && <P>
            {title}{year && ", "}{year}
        </P> } 
        {subtitle &&  <P>
            {subtitle}
        </P> }
         {material &&  <P>
            {material}
        </P> }
          {measurements &&  <P>
            {measurements}
        </P> }
    </Co>
}