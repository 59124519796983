import React, {useState} from 'react';

import SubPage from '../../widgets/subPages/widgetComponents/SubPage'
import EaWTop from '../../widgets/subPages/widgets/EPOatWorkSubPage/EaW_TopComponent';
import WoTopTop from '../../widgets/subPages/widgets/EPOatWorkSubPage/TopTop';
import AreYouInventor from '../../widgets/subPages/widgets/AreYouInventor';
import Footer from '../../widgets/subPages/widgets/Footer';

import { CLIENT_PATH } from  '../../config/serverURLPath.web';

import InfoContext from '../../InfoContext';
import PropTypes from 'prop-types';

export default function EaWsubPage({scrollRef, scroll2State, setScroll2State})
{
  let { l,  contentModel: {subpages} } = React.useContext(InfoContext);

    const [planetFixed, setPlanetFixed]=useState(true);
    const [scroll2, setScroll2]=useState(-1);


    const scrollTpA=ch=>ch === 0 ? setPlanetFixed(true) : setPlanetFixed(false);

    return <SubPage scrollRef={scrollRef}  scrollTopAction={scrollTpA} scroll2State={scroll2State} 
              setScroll2State={setScroll2State} scroll2={scroll2} setScroll2={setScroll2} 
                navigationFooterName="eaw.png"
                navigationFooterLeftPage="planetWorldofArt2"
                navigationFooterRightPage="planetInventions3">
                <EaWTop planetFixed={planetFixed} third={ <WoTopTop />} scroll2State={scroll2State}  />
               {/*  <EaWImageListPart scroll2State={scroll2State} />    */}  
                <AreYouInventor  title={subpages?.epoatwork?.testimonials?.title?.[l]}
                      subtitle={subpages?.epoatwork?.testimonials?.subtitle?.[l]}
                      firstperson={{
                          link: CLIENT_PATH+subpages?.epoatwork?.testimonials?.firstpersonimage?.[l].link || "",
                          width: subpages?.epoatwork?.testimonials?.firstpersonimage?.[l]?.width || 140,
                          height: subpages?.epoatwork?.testimonials?.firstpersonimage?.[l]?.height || 140,
                          title: subpages?.epoatwork?.testimonials?.firstpersontitle?.[l],
                          subtitle: subpages?.epoatwork?.testimonials?.firstpersonsubtitle?.[l],
                          bubbletext: subpages?.epoatwork?.testimonials?.firstpersonp?.[l],
                          verticalpositionadjustment:  subpages?.epoatwork?.testimonials?.firstpersonverticalpositionadjustment,
                          imageAlt: subpages?.epoatwork?.testimonials?.firstpersonimage?.[l]?.imageAlt                         
                      }}

                      secondperson={{
                          link: CLIENT_PATH+subpages?.epoatwork?.testimonials?.secondpersonimage?.[l].link || "",
                          width: subpages?.epoatwork?.testimonials?.secondpersonimage?.[l]?.width || 140,
                          height: subpages?.epoatwork?.testimonials?.secondpersonimage?.[l]?.height || 140,
                          title: subpages?.epoatwork?.testimonials?.secondpersontitle?.[l],
                          subtitle: subpages?.epoatwork?.testimonials?.secondpersonsubtitle?.[l],
                          bubbletext: subpages?.epoatwork?.testimonials?.secondpersonp?.[l],
                          verticalpositionadjustment:  subpages?.epoatwork?.testimonials?.secondpersonverticalpositionadjustment,
                          imageAlt: subpages?.epoatwork?.testimonials?.secondpersonimage?.[l]?.imageAlt
                      }}

                      topImage={{
                        link:  CLIENT_PATH+subpages?.epoatwork?.testimonials?.topImage?.link,
                        imageAlt: subpages?.epoatwork?.testimonials?.topImage?.[l]?.imageAlt
                      }}

                      bottomImage={{
                        link:  CLIENT_PATH+subpages?.epoatwork?.testimonials?.bottomImage?.link,
                        imageAlt: subpages?.epoatwork?.testimonials?.bottomImage?.[l]?.imageAlt
                      }} />  
                <Footer scroll2State={scroll2State} setScroll2={setScroll2} 
                  title={subpages?.epoatwork?.footer?.title?.[l]}
                  subTitle={subpages?.epoatwork?.footer?.subTitle?.[l]} 
                  url={subpages?.epoatwork?.footer?.url?.[l]} />               
            </SubPage>
}

EaWsubPage.propTypes = {
  scrollRef: PropTypes.object.isRequired,
  scroll2State: PropTypes.number.isRequired,
  setScroll2State: PropTypes.func.isRequired,
};