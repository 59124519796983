import React, {useRef, useEffect} from "react";


 
/**
 * @author JO (w42.se)
 * 
 * @export A Google Model-viewer React Adaption: 
 * 
 * @param {object} props
 * @param {string} props.src  Android etc
 * @param {string} [props.iosSrc] Iphone etc
 * @param {string} [props.alt]  Alt. text
 * @param {boolean} [props.ar]
 * @param {boolean | string} props.loading
 * @param {string} [props.style] ordinary CSS style
 * @param {boolean} [props.cameraControls] turn on Camera controls
 * @param {string} [props.poster]  background image path
 * @param {boolean} [props.autoplay]
 * @param {boolean} [props.interactStart] If user have to click on the component or if it starts automatically 
 * @param {string} [props.cameraTarget] position
 * @returns JSX element
 */
export default function ModelViewer({src, iosSrc, alt, ar, loading, 
    cameraControls, poster, autoplay, style, interactStart, cameraTarget= "0m 0m 0m"  })
{
    const vRef=useRef();
    
    useEffect(()=>
        {
            if(vRef.current)
            // @ts-ignore
              vRef.current.style=style;
        }
            ,[style])

    return  <model-viewer
                        ref={vRef}
                        src={src}
                        ios-src={iosSrc}
                        alt={alt}
                        ar={ar}
                        camera-target={cameraTarget}
                        loading={loading}
                        preload
                        reveal-when-loaded
                        camera-controls   
                        poster={poster}
                        autoplay={autoplay}
                        background-color="transparent"
                        auto-rotate={false}
                        reveal={interactStart? "interaction" : "auto"}
                        >                      
            </model-viewer>
}