import {Box2, Box3, Vector2, Vector3} from 'three';


let lookP= 
[
    {a: 'occulsionBox1', v: ['sculpture_simple'], n: []  }, 
    {a: 'occulsionBox2', v: ['Birgisdottir1','sculpture_simple', 'Artwork_Leitner','Panamarenko_flyingcarpet','Saraceno'],  n: [] }, 
    {a: 'occulsionBox3', v:['Panamarenko_flyingcarpet','Saraceno'], n: ['Artwork_Leitner'] }, 
    {a: 'occulsionBox4', v:['Saraceno'], n: ['Artwork_Leitner','Panamarenko_flyingcarpet']}, 
    {a: 'occulsionBox5', v:['Panamarenko_flyingcarpet','sculpture_simple','Artwork_Leitner'], n: ['sculpture_simple'] }, 
    {a: 'occulsionBox6', v: ['Saraceno', 'Panamarenko_flyingcarpet'], n: ['sculpture_simple','Birgisdottir1']}, 
    {a: 'occulsionBox7', v: ['Artwork_Leitner'], n: ['sculpture_simple','Birgisdottir1'] }, 
    {a: 'occulsionBox8', v:['Panamarenko_flyingcarpet'], n: ['Artwork_Leitner','sculpture_simple','Birgisdottir1']}, 
    {a: 'occulsionBox9', v:['Artwork_Leitner','Panamarenko_flyingcarpet','Saraceno'], n: [] }, 
    {a: 'occulsionBox10', v:['Birgisdottir1','sculpture_simple', 'Artwork_Leitner','Panamarenko_flyingcarpet','Saraceno'], n: ['Artwork_Leitner']}
]

// 'newbench31'

const mp=(u, objs) =>  {                     
                         return objs.find(p=> p?.[0]=== u) }


function getIdx(idx, objs, boxObj)
{
    const e= lookP.find(n=> n.a ===idx);

    return {
          v: (e?.v?.map ?  e.v.map(u=> mp(u,objs)) : [] ), 
          n: (e?.n?.map ?  e.n.map(u=> mp(u,objs)) : [] ),
          ...boxObj
    }
}

function get2d(obj)
{
    return new Box2(
         new Vector2(obj?.max?.x, obj?.max?.y), 
         new Vector2(obj?.min?.x, obj?.min?.y)    // y is along floor in this case, z is the vertical axis in this case...
    );
}

function createLookupTable(occulsionBoxes, occulsionLODObjects)
{
//    const lookupTable3D=new Map();
    const lookupTable2D=new Map();

    const oLOarr=Array.from(occulsionLODObjects);

    for(let [idx, obj] of occulsionBoxes)
    {
     //+   lookupTable3D.set(  idx, getIdx(idx, oLOarr.slice(), obj )   ); 
        lookupTable2D.set(  idx, getIdx(idx, oLOarr.slice(), get2d(obj) )   ); 
    }

    return {/*lookupTable3D,*/ lookupTable2D};
}


export function initOcculsion(occulsionData, configurationModel)
{
 //+   lookP= configurationModel?.v3d?.optimization?.occulsionBoxes;
 
    if(occulsionData)
    {
        const occulsionBoxes=occulsionData.occulsionBoxes.entries();
        const occulsionLODObjects=occulsionData.occulsionLODObjects.entries();

        const lookupTable=createLookupTable(occulsionBoxes, occulsionLODObjects);

        const wallCollisionBoxes_=Array.from(occulsionData.wallCollisionBoxes.entries());

        let wallCollisionBoxes=[];
        for(let w1 of wallCollisionBoxes_)
        {
            let w=w1[1];
          
            const wallFactor=0.1;
              let size=new Vector3();

              size= w.getSize(size);
            
              if(size.y > size.x)
              {
                    wallCollisionBoxes.push(new Box2(new Vector2(w.max.x+wallFactor, w.max.y), new Vector2(w.min.x-wallFactor, w.min.y)) )
              }
              else
              {
                    wallCollisionBoxes.push(new Box2(new Vector2(w.max.x, w.max.y+wallFactor), new Vector2(w.min.x, w.min.y-wallFactor)) )
              }


             
         //-   wallCollisionBoxes.push(new Box2(new Vector2(w.max.x, w.max.y), new Vector2(w.min.x, w.min.y)) )
        }

   
        return { occulsionBoxes, occulsionLODObjects, lookupTable, wallCollisionBoxes };    /// this return value... ->
    }
}



/////////////////////////
/////////////////////////
/////////////////////////
/////////////////////////
/////////////////////////


// function lookUp(idx, lookupTable)
// {
//     const l=  lookupTable.get(idx);

//     l.v.forEach((v/* , index, array */) => {
//             if(v?.[1])
//             {
//                 console.log("working show "+v?.[0]);
//           //      v[1].visible=true;
//             }
//         })   
//     l.n.forEach((v) => {
//         if(v?.[1])
//         {
//             console.log("working hide "+v?.[0]);
//           //  v[1].visible=false;
//         }
//     })          
// }





function inside(box, pos)
{
    return box.containsPoint(pos);
}

// let debugCamPos=new Vector3();

let alreadyLookup="";

export function  checkOcculsionLOD(occulsionState, camera, wallCollisionCllbck=()=>{})   /// is this first parameter
{
    if(occulsionState?.lookupTable)
    {
   
        const lktbl=occulsionState?.lookupTable?.lookupTable2D;

        for(let [key, value] of lktbl)
        {
          
                if(inside(  new Box2( value?.max,  value?.min ) , new Vector2(camera?.position?.x, camera?.position?.z)))     // z is along floor for camera, y is the vertical axis in this case...
                {               
                    if(key!==alreadyLookup)
                    {
                   //     lookUp( key,  occulsionState?.lookupTable?.lookupTable2D );
                        alreadyLookup=key;

                      //  console.log("lookup: "+key);
                    }

                    return key;
                }   
        }

     /*    const wallcoll=occulsionState?.wallCollisionBoxes;

        if(wallcoll?.length>0)
            for(let value of wallcoll)
            {
            
                    if(inside(  new Box2( value?.max,  value?.min ) , new Vector2(camera?.position?.x, camera?.position?.y)))     // z is along floor for camera, y is the vertical axis in this case...
                    {         
                        wallCollisionCllbck();
                    }   
            } */



    //    debugCamPos=camera.position.clone();
    }

 
}