import React from 'react';
import {View} from 'react-native';

/**
 * @param {object} props
 * @param {any} props.children
 * @param {any} [props.s] style
 * @param {any} [props.i] id
 * @param {any} [props.b] flex basis
 * @param {any} [props.w] width
 */
export function Co({children, s, i, b="auto", w}) {
    return <View style={{flexDirection: 'column', ...s,  
    flexBasis: b, width: w }} nativeID={i} >{children}</View>
}

/**
 * @param {object} props
 * @param {any} props.children
 * @param {any} [props.s] style
 * @param {any} [props.i] id
 * @param {any} [props.b] flex-basis
 * @param {any} [props.w] width
 */
export function  Row({children, s, i, b="auto", w})
{
    return <View style={{ flexDirection: 'row',  ...s,
    flexBasis: b, width: w}} nativeID={i}>{children}</View>
}