import React, {useContext} from 'react';
import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";
import InfoContext from '../../../InfoContext';

export default function Kintera()
{
    const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
       	<ArtWorkHeader  
		artistname="Krištof Kintera"
                title="Homegrown No. 1"
                year="2003"
                material="Beer cans, aluminium, polyurethane foam"
                measurements="246 x 60 x 60 cm" 
        />
        <div>
        {infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kintera.text[l]}
        </div>
</div>
}