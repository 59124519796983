import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

export default function Sustainability()
{
        const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div style={{margin: 15}}>
        	<ArtWorkHeader  
                title="Sustaining life: Art, creativity and innovation"
                subtitle="" 
        />
        <div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</div>
</div>
}