import React, { useRef,  useState,   useEffect, useMemo  } from 'react';
import { Euler, TextureLoader, Vector3, Raycaster, Vector2,
  Quaternion, Matrix4} from 'three';
import { nameAliases } from './findObj3DName';

import Screen3D_plain from './Screen3D_plain.web';
import Screen3D_XR from './Screen3D_XR.web ';

 import { URL3D } from '../config/serverURLPath.web';

export function InfoSprite({filename, spritePosition = new Vector3(), 
    spriteRotation=new Euler(), spriteScale= 1, gl=void 0, extraText=""})
{   
    const spriteRef=useRef();
 
    filename=nameAliases(filename);

    const texture = new TextureLoader().load( URL3D+"textures/screens/"+ filename+".png", 
    ()=> {
   
        if( texture && texture?.image && texture?.image?.width && spriteRef?.current) {
                      
                const v=new Vector3(texture?.image?.width*spriteScale, 
                    texture?.image?.height*spriteScale, 
                   1);
                // @ts-ignore
                spriteRef.current.scale.set(v.x, v.y, v.z);
            }
        } 
    );

    if(gl)
        texture.anisotropy = gl?.capabilities?.getMaxAnisotropy();

    texture.repeat.set( 1, 1 );

   return  <mesh  ref={spriteRef}   name={filename+"_infotext"+extraText} 
        position={spritePosition}
        rotation={spriteRotation}>
            <sprite name={filename+"_infotext"+extraText} >
                    <spriteMaterial  map = {texture}   />
            </sprite>
        </mesh>   
}


export default function Screen3D(props)
{
    return <>
        (
            props?.vr?.enabledVR ?

            <Screen3D_XR {...props}  />
            :
            <Screen3D_plain {...props}  />
        )
    </> 
}