import React, { useRef, useState, useEffect } from 'react';

import {View} from "react-native";

import useOnScreen from '../../../multiEnvComponents/useOnScreen'

function AnimateItem({item, wait, amount, brk=1700})
{
  let op=0;
  if( (amount+wait) < brk) 
      op=0;
  if(  (amount+wait) > brk && (amount+wait) < (brk+200))
  {
      op= (((amount+wait) - brk) / 200 ) 
    //  console.log('op: '+op);
  }
  else
  if( (amount+wait) > (brk+200))
      op=1;

  // @ts-ignore
 
 
  return <View style={{opacity: op, transform: `translate3d(0,  ${op*100-100}px,0)`, zIndex: 200 }} >{item}</View>
 
} 


export default function TransitionArray({itemsA, scroll2State, transition, w})
{  
  const ref = useRef()

  const isOnS= useOnScreen(ref)
  const [brk, setBrk]=useState();

  useEffect(()=>
    {
        if(isOnS)
        {
          setBrk(scroll2State+380);
        }
    }
    ,[isOnS]
  );

  if(transition)  
    return  <View nativeID="TransitionArray" style={{flexDirection: "row", flexWrap: "wrap", width: w}} ref={ref}>{itemsA.map((el,index)=> 
        (<AnimateItem key={index} item={el} wait={ (itemsA.length-index)*100}  amount={scroll2State} brk={brk} />)  )}</View>;
  else
    return itemsA;
}


