import React from 'react';

// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';
import ModelViewer from '../../components3D/multiEnv/ModelViewer.web';
import Info from './Info';

export default function AR_Kintera()
{
    return <>
    <Info qr="images/QR/Kintera.png" link="https://vworlds-dev01.azurewebsites.net/?p=kintera" />     
    <ModelViewer src={URL3D+"AR/kintera.glb"}
    iosSrc={URL3D+"AR/kintera.usdz"}
    alt="model viewer"
    ar={true}
    loading="lazy"
    cameraControls={true}
    poster={URL3D+'AR/kintera.png'}
    autoplay={true}
    style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: black;"}
/></>
}