import {TextureLoader, Color, MeshStandardMaterial, PointLight, MeshPhysicalMaterial, DoubleSide, RepeatWrapping,
    BackSide, FrontSide, MeshPhongMaterial} from 'three';
    
 import {HDRCubeTextureLoader} from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

import { URL3D } from '../../config/serverURLPath.web.js';



const Tloader = new TextureLoader();

const hloader = new HDRCubeTextureLoader();

let glassballExtraKickerLightIsSet= false;

let cubeTexture=null;

let floorEnv= Tloader.load( URL3D+'textures/env_maps/floor_env.png', function (texture){                                               
                    floorEnv = texture;                   
                });

let goldTextureLeitner=   Tloader.load( URL3D+'textures/Leitner/goldTexture.png', function (texture){                                               
                   goldTextureLeitner=texture
                });

let steelframeLeitner=   Tloader.load( URL3D+'textures/Leitner/steelframe.png', function (texture){                                               
                   steelframeLeitner=texture
                });
let t_alcoholLeitner=   Tloader.load( URL3D+'textures/Leitner/t_alcohol.png', function (texture){                                               
                   t_alcoholLeitner=texture
                });


 let coverMeshColorInvertHigh= Tloader.load(URL3D+    'textures/Panamarenko/coverMeshColorInvert.png',
                         texture3 => {    
                                coverMeshColorInvertHigh=texture3
                        },
                        progress=> console.log(progress),
                        error=> console.error(error));

 let coverMeshColorInvertLow= Tloader.load(URL3D+     'textures/Panamarenko/coverMeshColorInvertLow.png',
                         texture3 => {    
                                coverMeshColorInvertLow=texture3
                        },
                        progress=> console.log(progress),
                        error=> console.error(error));

 let coverMeshBump =    Tloader.load(
                            // resource URL
                            URL3D+'textures/Panamarenko/coverMeshBump.png',
                            function (texture3) {    
                                    coverMeshBump=texture3
                               
                            },
                            progress=> console.log(progress),
                            error=> console.error(error)); 

 let  glitter3=   Tloader.load(
            // resource URL
            URL3D+'textures/Panamarenko/glitter3.png',
            function (texture3) {    
                glitter3=texture3
            },
            progress=> console.log(progress),
            error=> console.error("glitter "+ error.message)); 

 let glitter3alpha=    Tloader.load(
                // resource URL
                URL3D+'textures/Panamarenko/glitter3alpha.png',
                function (texture3) {        
                   glitter3alpha=texture3
                },
                progress=> console.log(progress),
                error=> console.error("glitter alpha "+ error.message)); 




function glassBall(scene, obj, color, envInt=0.1)
{
    obj.material=new MeshPhysicalMaterial({
        color: new Color(color),
       transparent: true,
        opacity: 0.7,
        roughness: 0,
        metalness:0.8,
        clearcoat: 1,
        clearcoatRoughness: 0.3,
        reflectivity: 1,
        envMap:  scene?.environment,
        envMapIntensity:envInt,
        sheen: new Color (0xaaaaff)
    });
    obj.material.name="glassball";
    obj.material.specular=new Color(0xaaaaff);
    obj.material.specularIntensity =0.5;

    obj.material.thickness=2.5;

    obj.material.transmission =0.8; 

    if(scene?.environment)
        obj.material.envMap = scene?.environment;
    else
    if(scene && !scene.environment && !cubeTexture)
    {
        hloader.setPath( URL3D+'textures/env_maps/' )
        .load([
                'nx.hdr',
                'nz.hdr',
                'ny.hdr',
                'py.hdr',
                'nz.hdr',
                'nx.hdr'
            ], texture => {      
                            scene.environment=texture;
                            console.log("loads env texture");
                          }, 
                          void 0  ,
                          err => console.error("JO Env textur : ",err) ); 
    }
    else
    if(!scene)
    {
        hloader.setPath( URL3D+'textures/env_maps/' )
        .load([
                'nx.hdr',
                'nz.hdr',
                'ny.hdr',
                'py.hdr',
                'nz.hdr',
                'nx.hdr'
            ], texture => {           
                            obj.material.envMap=texture; 
                                        
                            console.log("loads env texture");
                          }, 
                          void 0  ,
                          err => console.error("JO Env textur : ",err) ); 
    }
    else
    if(cubeTexture)
    {
        obj.material.envMap=cubeTexture;
    }
}

function glassBallLight(obj, color)
{
 
    obj.material=new MeshPhongMaterial({
        color: new Color(color),
        //color: new Color(0xeef),
       transparent: true,
        opacity: 0.5,

        reflectivity: 1
    });
    obj.material.name="glassball";

    obj.material.specular=new Color(0xeff);
    obj.material.specularIntensity =1;
    obj.material.shininess =100;

    obj.material.thickness=2.5;
    obj.material.side=FrontSide;
}



export default function additionalMaterialSettingsSulptures(obj, cam, generatedEnvMaps, gr, scene, sndCllBck=(_)=>{})
 {
        if(obj.name==="kintera")
            sndCllBck(obj);
        else
        if(obj?.material?.name==="glassball" || obj?.material?.name==="phong3SG8.001" )
        {
            if(!glassballExtraKickerLightIsSet)
            {
                const kickerLight=new PointLight();
                kickerLight.position.set(0.5,0.5,0.5);
                kickerLight.distance=1;
                kickerLight.decay=2.5;
                kickerLight.intensity=5;

                obj.add(kickerLight);
                glassballExtraKickerLightIsSet=true;
            }    

            if(gr>5)
               glassBall(scene, obj, 0xe0cccc, 1);
            else
                glassBallLight(obj, 0x333344,);
        }
        else
        if(obj?.material?.name==="glassballFloor" )
        {
            if(gr>5)
                glassBall(scene, obj, 0xeeeeff, 0.3);
            else
                glassBallLight( obj, 0x333355);
        }
        else
        if(obj?.material?.name==="wireInner_phong4")
        {
             obj.material.color=new Color(0x333333);  
        }
        else
        if(obj?.material?.name==="wirephong2")
        {
            obj.material.color=new Color(0x111111);  
        }
        else
        if(obj?.material?.name==="thermometer_glass" || obj?.material?.name==="ceiling_glass")
        {
            obj.material=new MeshStandardMaterial();
            obj.material.transparent=true;
            obj.material.opacity=0.3;
            obj.material.roughness=0.1;
            obj.material.metalness=0.5;

            obj.material.color=new Color(0xccffcc);     // (0,20,0);

            console.log("name "+obj.material.name);
        }
        else
        if(obj?.material?.name==="goldframe_Leitner")
        {
               obj.material.roughness=0.2;
    
            if(gr>5)
            {              
                obj.material.envMap = floorEnv;
                obj.material.needsUpdate = true;
                 obj.material.map = goldTextureLeitner;
                obj.material.roughnessMap = goldTextureLeitner;
                obj.material.needsUpdate = true; 
            }
        }
        else
        if(obj?.material?.name==="frame_Leitner")
        {
               obj.material.roughness=0.2;

               obj.material.color=new Color(0x999999);

               if(gr>5)
               {
                                                           
                        obj.material.envMap = floorEnv;
                        obj.material.needsUpdate = true;
                                                                             
                        obj.material.map = steelframeLeitner;
                        obj.material.roughnessMap = steelframeLeitner;
                        obj.material.needsUpdate = true;
                        obj.material.side=DoubleSide;        
                }
        }
        else
        if(obj?.material?.name==="glass" || obj?.material?.name==="phong51") 
        {
            obj.material.transparent=true;
            obj.material.opacity=0.4;
            obj.material.color=new Color(0x555555);   
            obj.material.roughness=0.2;
        }
        else
        if(obj?.material?.name==="phong43") // 
        {
            obj.material.color=new Color(0x555555);
        }
        else
        if(obj?.material?.name==="walls3") // 
        {
            obj.material.color=new Color(0xcccccc);
            obj.material.roughness=0.2;
        } 
        else
        if(obj?.material?.name==="red_alcohol_thermometerLeitner") // 
        {
            if(gr>5)
            {
                obj.material=new MeshPhysicalMaterial({});
                obj.material.clearcoat=0.5;
                obj.material.clearcoatRoughness=0.5;
            }
            else
            {
                obj.material=new MeshStandardMaterial({});
            }

            obj.material.name="red_alcohol_thermometerLeitner";
            obj.material.roughness=0.3;
            obj.material.metalness=0.986;
        
            obj.material.specular=new Color(0x660509);
  
            obj.material.map = t_alcoholLeitner;
                obj.material.needsUpdate = true;
        } 
      else
        if(obj?.material?.name==="bulbShine") // 
        {
            Tloader.load( URL3D+'textures/Leitner/bulbshine.png', function (texture){                                               
                         obj.material.map = texture;
                         obj.material.side=BackSide;
                         obj.material.color=new Color(0xff6600);
                         obj.material.emissiveMap=obj.material.map;
                         obj.material.alphaMap=obj.material.map;

                         obj.material.needsUpdate = true;

            
                         if(obj.children.length<=0)
                         {
                            const l= new PointLight(0xffff00, 3, 0.3);
                            l.castShadow = true;
                            obj.add(l);
                        }

                        obj.visible=false;
                     });
        }       

        if(obj?.material?.name==="exibition_frame_Birgisdottir")
        {
            obj.material.color=new Color(0.1,0.1,0.1);
            obj.material.roughness=0.2;
            obj.material.metalness=0.3;

        }
        else
        if(obj?.material?.name==="exhibition_glass_Birgisdottir")
        {
            obj.material=new MeshStandardMaterial();
            obj.material.transparent=true;
            obj.material.opacity=0.3;
            obj.material.roughness=0;
            obj.material.metalness=0.1;
        }
        else
            if(obj?.material?.name==="flyingcarpet_coverMesh")
            {
                obj.material=new MeshStandardMaterial();
                obj.material.roughness=0.2;
                obj.material.metalness=0.8;
    
                obj.material.transparent=true;
            
    
                obj.material.side=DoubleSide;


                if(gr > 5)
                        obj.material.alphaMap=coverMeshColorInvertHigh;
                else
                        obj.material.alphaMap=coverMeshColorInvertLow;
                
                obj.material.needsUpdate = true;

                if(gr > 5)
                {         
                    obj.material.bumpMap=coverMeshBump;
                    obj.material.needsUpdate = true;
                    }
                }
        else
    if(obj?.material?.name==="glitter")
    {
        console.log("glitter.001");

        if(gr>5)
            obj.material=new MeshPhysicalMaterial({});
        else
            obj.material=new MeshStandardMaterial({});


        obj.material.roughness=0.2;
        obj.material.metalness=0.8;
        obj.material.transparent=true;

        obj.material.side=DoubleSide;
        obj.material.name="glitter";

        obj.material.map=glitter3;
                   
        if(gr > 5)
        {    
                obj.material.bumpMap=glitter3;
            
                obj.material.bumpMap.wrapS =RepeatWrapping ;
                obj.material.bumpMap.wrapT =RepeatWrapping ;
                obj.material.bumpMap.repeat.set(1,20); 
        }

         obj.material.alphaMap=glitter3alpha;
                            
        obj.material.needsUpdate = true;

   
    }
    else
    if(obj?.material?.name==="phong51")
    {
        obj.material.transparent=true;
        obj.material.opacity=0.3;
    }    
     else
    if(obj?.material?.name==="Label1")
    {
        obj.material.side=FrontSide;      
    }   

}