import React, { /* , useState,  useEffect */ } from 'react';
//import {View, Text, Image} from 'react-native';

import {View, Text} from '../../multiEnvComponents/rnwComponents.web';
import { Image} from '../../multiEnvComponents/Image.web';
import ProgressBar from '../multiEnv/progressBar.web';

import {Html} from '../drei/drei/web/Html'; 

import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import InfoContext from '../../InfoContext';
import { URL3D } from '../../config/serverURLPath.web';

import { useFrame, useThree } from '@react-three/fiber';
import { Vector3 } from 'three';

// @ts-ignore
import {InfoSprite} from '../Screen3D';

let  t, threeProps;

function ProgressBar3D({filename, loadingState})
{
return <group name="progressBar" position={new Vector3(0.07,-0.03,-2.3)}>
                <InfoSprite  filename={"loaderSplashImages/"+filename+".jpg"} spritePosition={new Vector3(0,0,0)} spriteScale={0.01}  /> 
                <mesh scale={new Vector3(28, 28,28)}  position={new Vector3(0,0,0)}  >
                    <boxGeometry /> 
                    <meshStandardMaterial
                        attach="material" // How the element should attach itself to its parent   
                        color="#7aaaaa" // The color of the material
                        transparent // Defines whether this material is transparent. This has an effect on rendering as transparent objects need special treatment and are rendered after non-transparent objects. When set to true, the extent to which the material is transparent is controlled by setting it's .opacity property.
                        roughness={0.1} // The roughness of the material - Defaults to 1
                        metalness={0.1} // The metalness of the material - Defaults to 0
                        opacity={0.2}
                        />
                </mesh>
                <mesh scale={new Vector3(loadingState*3, 0.19, 0.19)} position={new Vector3((-loadingState/2*3)+0.5, 0.19, 0.19)} >
                    <boxGeometry  />       
                    <meshStandardMaterial
                        attach="material" // How the element should attach itself to its parent
                        color="#720000" // The color of the material
                        roughness={0.1} // The roughness of the material - Defaults to 1
                        metalness={0.1} // The metalness of the material - Defaults to 0
                        />
                </mesh> 
            </group>
}

function ProgressBar2DHTML({filename, p, progressBarContent})
{

  let { r } = React.useContext(InfoContext);

  const mobile= rsp({
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 }, r);     

    return  <Html
    transform 
    sprite 
    position={new Vector3(0.07,-0.03,-1)}  

    scale={new Vector3(0.05,0.05,0.05)}
    // @ts-ignore
    threeProps={threeProps}
    threeUseFrame={useFrame}   
>
<View nativeID="progressbar" style={{backgroundColor:"black", padding:6,  borderRadius: 6,  justifyContent: "center", cursor:"progress"}}>   
    <Text 
        // @ts-ignore
        style={{
        color:"white", "fontFamily": "Source Sans Pro",
        "fontStyle": "normal",
        "fontWeight": 600,
        "fontSize": 15,
        "lineHeight": "36px", alignSelf: "center"}}>{progressBarContent?.title}</Text>
    <Text 
        // @ts-ignore
        style={{
        color:"white", "fontFamily": "Source Sans Pro",
        "fontStyle": "normal",
        "fontWeight": 600,
        "fontSize": 32,
        "lineHeight": "36px", alignSelf: "center"}}>{progressBarContent?.subTitle}</Text>
          {progressBarContent?.image && <Image source={{uri:URL3D+progressBarContent?.image}} style={{width: "25vw", height:"25vh", marginLeft: mobile? "25vw":"0", alignSelf: "center", 
                                                                                                        marginTop: "5vh", marginBottom: "5vh"}}  /> }
         <Text 
        // @ts-ignore
        style={{
        color:"white", "fontFamily": "Source Sans Pro",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": 12,
        "lineHeight": "24px", alignSelf: "center"}}>{progressBarContent?.bartext}</Text>
    <ProgressBar value={p} color="#B3E5FC" style={{marginTop: -10, width: "50%", alignSelf: "center"}} />
    <Text 
        // @ts-ignore
        style={{color:"white", "fontFamily": "Source Sans Pro",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": 12,
        "lineHeight": "24px", alignSelf: "center"}}>{p.toPrecision(2)}% {progressBarContent?.percentagetext}</Text>
  
</View>
</Html> 

}


export function ProgressBar2D({filename, loadingState, progressBarContent})
{
    let { vr } = React.useContext(InfoContext);

    t = useThree();
    threeProps=t;

    let p=0;

    if(loadingState>0)
        p=loadingState*100<99 ? loadingState*100: 99;

         return <>
            {vr?.enabledVR ?
            <ProgressBar3D filename={filename} loadingState={loadingState} />
            :
            <ProgressBar2DHTML filename={filename} p={p} progressBarContent={progressBarContent} />}
         </>
}