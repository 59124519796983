import React,{useContext} from 'react';

import {  C } from '../htmlcomponents/blocktags';
// @ts-ignore
import { H5, P} from  '../htmlcomponents/texttags.web' // './htmlcomponents/texttags.web';

// import InfoContext from '../../../InfoContext'; 
import IconList from   '../widgetComponents/iconlist';
import Button from '../../../multiEnvComponents/ButtonA.web'; 

import InfoContext from '../../../InfoContext';
import rsp from "../../../widgets/subPages/htmlcomponents/useResponsiveBrk";

/**
 * Side info is the side panel besides the title text in top pane
 * 
 * @export
 * @param {object} props
 * @param {boolean} [props.comingsoon]
 * @param {string} props.title
 * @param {string} [props.p_1]
 * @param {object} props.imagelist
 * @param {string} props.buttontext
 * @param {function} [props.buttonaction]
 * @param {string} [props.side]
 * @returns 
 */
export default function SideInfo({comingsoon=false, title, p_1, 
      imagelist, buttontext, buttonaction, side="right" })
{
    const {r} =  useContext(InfoContext);


    const mobile= rsp({
      sp:  1, 
      sl:  1, 
      mp: 1,
      tp:  1,
      mtl:  side==="left"? 1: 0, 
      tl: 0, 
      sll: 0, 
      lll: 0, 
      dl: 0 }, r);  


      const laptop= rsp({
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 1, 
        tl: 1, 
        sll: 1, 
        lll: 0, 
        dl: 0 }, r);  

      const wdth= rsp({
        sp:  "100%", 
        sl:  "15vw", 
        mp: "80vw",
        tp:  "80vw",
        mtl: "80vw", 
        tl: side==="left" ? "30vw":  "16vw", 
        sll: side==="left" ? "30vw":  "15vw", 
        lll: "15vw", 
        dl: "15vw" }, r);  


        const mtl= rsp({
          sp:  0, 
          sl:  0, 
          mp: 0,
          tp:  0,
          mtl: 1, 
          tl: 1, 
          sll: 0, 
          lll: 0, 
          dl: 0 }, r);  

    const sideInfo_column_style={
          overflow: "visible",
          marginTop: laptop ? "2vh" : 0,
          alignItems: (mobile || mtl) ? "center" :  (side==="left" ?  "start" : "end"),
          alignSelf: (mobile || mtl) ? "center" :  (side==="left" ?  "start" : "end")
    }


    const  leftExtra= side==="left" ? {width: wdth} : {} 


    return  <C n={"sideInfoColumn_"+title} st={{...sideInfo_column_style, ...leftExtra, width: `calc( ${buttontext?.length}ex + 50px  )`  }}>                 
               {!comingsoon && <Button title={buttontext} 
                onPress={buttonaction}
                  /> }
                <P n={"sideInfo_p_1_"+title} st={{marginTop: 15}}>{p_1}</P>
                <IconList  ar={imagelist?.ar} vr={imagelist?.vr} v3d={imagelist?.v3d} />
            </C>
}