import { Euler, TextureLoader, Vector3, Raycaster, Vector2, Box3,
    Mesh, BoxGeometry, MeshBasicMaterial,
Color, Quaternion, Matrix4} from 'three';

import {getArtworkCameraPosFromList} from '../getCameraCoordsBasedOnScroll.web';

import clickBoxSculpture from './clickBoxSculpture';


/**
 * Opens clickable boxes for art work zoom in
 * 
 * @param {any} name_ 
 * @param {any} obj 
 * @param {any} controls 
 * @param {any} openBox 
 * @param {function} openBoxSetter 
 * @param {any} various 
 *  @param {any} scene 
 * @returns 
 */
export default function clickBox(name_, obj, controls,  openBox, openBoxSetter, various, scene)
{
        if( name_[0].indexOf("artwork")!==-1  )
        {
            console.log("art work click"+name_[0]);
            console.dir(obj);
            
            const clkBx=obj.parent.children.find(n=>n.name.indexOf("awClickbox_")!==-1);
            if(!clkBx)
            {
                if(openBox)
                    openBox.visible=false;

                const boxMesh= new Mesh( new BoxGeometry(1,1, 1), 
                                    new MeshBasicMaterial({color: new Color(0xddddff),
                                    transparent: true, opacity:0.5 }) );
                
                boxMesh.name="awClickbox_"+name_[1];
            //   
                obj.geometry.computeBoundingBox();
                const box= new Box3();
                box.copy( obj.geometry.boundingBox );
                let pos, size; 
                pos= box.getCenter (pos);
                size= box.getSize(size); 
                boxMesh.position.copy(pos);
                boxMesh.scale.set(size.x+0.2, size.y+0.2, size.z+0.2);

            //   obj.parent.add( boxMesh )
            setTimeout( ()=> {
                    obj.add( boxMesh );
                    openBox=boxMesh;
                    openBoxSetter(openBox);

                    setTimeout( ()=> {
                                boxMesh.visible=false; 
                                openBox=false
                                openBoxSetter(openBox);

                                return true;
                            }, 5000);
                }, 200)   
                }
                else
                {

                }

                return true;
            }
            else
           if( clickBoxSculpture(name_, obj, controls,  openBox, openBoxSetter, various, scene) )
           {
            
           }    
           else 
                if( name_[0].indexOf("awClickbox")!==-1 && obj?.visible===true)
                {
                    console.log(" awClickbox name_[0] "+ name_[0]);

                    obj.visible=false;
                    openBox=false;

                    if(controls?.current?.camera?.current) {
                        console.log("camera coords "+name_[1]);
                        console.dir(controls?.current?.camera?.current?.position);  //  { controls: { current: {camera: {current}} } }
                        console.dir(controls?.current?.camera?.current?.rotation);

                        const p=getArtworkCameraPosFromList( name_[1], various?.configurationModel);

                        if(p?.r)
                        {
                                const q=new Quaternion();
                                q.setFromEuler(new Euler(p.r.x, p.r.y, p.r.z, "XYZ")); 

                                let vrm;
                                    vrm = various?.vrMultiplier;
                                if(vrm)
                                {
                                     if(various?.setControllerGoal)
                                                various.setControllerGoal({position: {x: p.x*vrm, y: p.y*vrm, z: p.z*vrm} , 
                                                    quaternion: {x: q.x, y: q.y, z: q.z, w: q.w}});
                                }
                                else
                                if(various?.setControllerGoal)
                                    various.setControllerGoal({position: {x: p.x, y: p.y, z: p.z} , 
                                        quaternion: {x: q.x, y: q.y, z: q.z, w: q.w}});
                        }
                    }

                    console.dir(various);  
                    return true;         
                }
                else
                if( name_[0].indexOf("awClickbox")!==-1 && obj?.visible===false)
                {
                    setTimeout( ()=> {
                        obj.visible=true;
                        openBox=obj;
                        openBoxSetter(openBox);

                        setTimeout( ()=> {
                                obj.visible=false; 
                                openBox=false
                                openBoxSetter(openBox);
                               
                            }, 20000);
                    }, 200); 

                    return true;
                }
                
    

    return false;
}




