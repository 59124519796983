import React,{useState, useContext} from 'react';

import InfoContext from '../../../../InfoContext';

import {TopComponent, Pane} from '../../widgetComponents/TopComponent';

import SideInfo from '../../widgetComponents/SideInfo'; 

import {COLORS} from '../../../../components/colors';

import  { createModalTextContent}  from   '../../../../components3D/multiEnv/createModalContent.web'   // "../multiEnv/createModalContent.web";
import { ModalContext } from '../../../../multiEnvComponents/modalProvider.web'    //   "../../../multiEnvComponents/modalProvider";

import rsp from   "../../htmlcomponents/useResponsiveBrk";  


function SideInfo1() {
  const {r, contentModel: {subpages}, l, setZoompage} =  useContext(InfoContext);

  let { handleModal, setModalStyle} = React.useContext(ModalContext);
  const [infoBox, setInfoBoxState]=useState("");
  const mobile=rsp({sp:  1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0, dl: 0 }, r);
  
    return  <SideInfo comingsoon={false} 
        title={subpages?.worldinventions?.p2?.SideInfo1?.title?.[l]} 
        p_1={subpages?.worldinventions?.p2?.SideInfo1?.p1?.[l]} 
        imagelist={{ v3d: true}}
        buttontext={subpages?.worldinventions?.p2?.SideInfo1?.button?.[l]}
        buttonaction={()=>{
              setModalStyle({
                    outer1:  mobile ? { width: "92vw", maxWidth: "70rem" ,height:  "90vh"}:  {width: "35rem", height: "25rem"},  
                    outer2:  mobile ? { width: "92vw", maxWidth: "70rem", height:  "90vh"}:  {width: "35rem", height: "25rem"}, 
                    trigger: r,
                    modalnearlytop: {overflowY: "", overflowX: "hidden"}
                 });

                 handleModal(
                      createModalTextContent(  "info_HoF" , false, {infoBox, setInfoBoxState} ), 
                 false, 2);      
    }}
    />

}



function SideInfo2() {
  const {r, contentModel: {subpages}, l, setZoompage} =  useContext(InfoContext);

  let { handleModal, setModalStyle} = React.useContext(ModalContext);
  const [infoBox, setInfoBoxState]=useState("");
  const mobile=rsp({sp:  1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0, dl: 0 }, r);
   
    return  <SideInfo comingsoon={false} 
        title={subpages?.worldinventions?.p2?.SideInfo2?.title?.[l]} 
        p_1={subpages?.worldinventions?.p2?.SideInfo2?.p1?.[l]} 
        imagelist={{ ar:true,  v3d: true}}
        buttontext={subpages?.worldinventions?.p2?.SideInfo2?.button?.[l]}
        buttonaction={()=>{
              setModalStyle({
                    outer1:  mobile ? { width: "92vw", maxWidth: "70rem" ,height:  "90vh"}:  {width: "70rem", height: "30rem"},  
                    outer2:  mobile ? { width: "92vw", maxWidth: "70rem", height:  "90vh"}:  {width: "70rem", height: "30rem"}, 
                    trigger: r,
                    modalnearlytop: {overflowY: "", overflowX: "hidden"}
                 });

                 handleModal(
                      createModalTextContent(  "info_MarconiAR" , false, {infoBox, setInfoBoxState} ), 
                 false, 2);      
    }}
    />

}

function WoITopPane() {
    const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return <Pane 
                    dateInfo={subpages?.worldinventions?.p2?.toppane?.dateInfo?.[l]}
                    title={subpages?.worldinventions?.p2?.toppane?.title?.[l]} 
                    subTitle={subpages?.worldinventions?.p2?.toppane?.subtitle?.[l]}  
                    imageName={subpages?.worldinventions?.p2?.toppane?.image?.[l].link}  
                    imageStyle={{width: subpages?.worldinventions?.p2?.toppane?.image?.[l].width || 442,
                                height: subpages?.worldinventions?.p2?.toppane?.image?.[l].height || 276,
                                marginTop: 12}} 
                                imageAlt={subpages?.worldinventions?.p2?.toppane?.image[l]?.imageAlt || "superTest"}     // subpages.worldinventions.p2.toppane.image.eng.imageAlt
                                 /> 
}


function WoIBottomPane(){
   const {contentModel: {subpages}, l} =  useContext(InfoContext);

    return <Pane 
    dateInfo={subpages?.worldinventions?.p2?.bottompane?.dateInfo?.[l]}
    title={subpages?.worldinventions?.p2?.bottompane?.title?.[l]} 
    subTitle={subpages?.worldinventions?.p2?.bottompane?.subtitle?.[l]}  
    imageName={subpages?.worldinventions?.p2?.bottompane?.image?.[l].link} sideInfo={<SideInfo2 />}  
    imageStyle={{width: subpages?.worldinventions?.p2?.bottompane?.image?.[l].width ||  458*1.1, 
                 height: subpages?.worldinventions?.p2?.bottompane?.image?.[l].height ||  
                      294*1.1, marginLeft: -23, marginTop: -5, marginRight: 23, }} 
                      imageAlt={subpages?.worldinventions?.p2?.bottompane?.image[l]?.imageAlt  || "superTest2"}
                       /> 
}


export function WoITopPaneThird(){
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

  return <Pane 
                  dateInfo={subpages?.worldinventions?.p2?.third?.dateInfo?.[l]}
                  title={subpages?.worldinventions?.p2?.third?.title?.[l]} 
                  subTitle={subpages?.worldinventions?.p2?.third?.subtitle?.[l]}  
                  imageName={subpages?.worldinventions?.p2?.third?.image?.[l].link} 
                  imageStyle={{width: subpages?.worldinventions?.p2?.third?.image?.[l].width || 442,
                              height: subpages?.worldinventions?.p2?.third?.image?.[l].height || 276,
                              marginTop: 12}} 
                              imageAlt={subpages?.worldinventions?.p2?.third?.image[l]?.imageAlt || "superTest"}     // subpages.worldinventions.p2.toppane.image.eng.imageAlt
                               /> 
}


function WoIBottomBottomPane(){
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

   return <Pane 
    dateInfo={subpages?.worldinventions?.p2?.bottombottompane?.dateInfo?.[l]}
   title={subpages?.worldinventions?.p2?.bottombottompane?.title?.[l]} 
   subTitle={subpages?.worldinventions?.p2?.bottombottompane?.subtitle?.[l]}  
   imageName={subpages?.worldinventions?.p2?.bottombottompane?.image?.[l].link}  sideInfo={<SideInfo1 />}
   imageStyle={{width: subpages?.worldinventions?.p2?.bottombottompane?.image?.[l].width ||  458*1.1, 
                height: subpages?.worldinventions?.p2?.bottombottompane?.image?.[l].height ||  
                     294*1.1, marginLeft: -23, marginTop: -5, marginRight: 23, }}
                     imageAlt={subpages?.worldinventions?.p2?.bottombottompane?.image[l]?.imageAlt  || "superTest3"}
                       /> 
}



export default function WoI_Top({planetFixed, third, scroll2State}) {
  const {contentModel: {subpages}, l} =  useContext(InfoContext);

  return  <TopComponent planetName="planetInventions3" 
    planetLeft={false} 
    TopTopPane={third}
    TopPane={subpages?.worldinventions?.p2?.toppane &&<WoITopPane />} 
    BottomPane= {subpages?.worldinventions?.p2?.bottompane &&  <WoIBottomPane />}
    SpecialExtraPane={subpages?.worldinventions?.p2?.bottombottompane && <WoIBottomBottomPane />}
    planetFixed={planetFixed}
  
      grad={"linear-gradient(180deg, rgba(216, 94, 124, 0) 0%, "+COLORS.woi+" 100%)"}
      scroll2State={scroll2State}
     />
} // #066AE3