const sessionStorage = window.sessionStorage;


export function setLoggedInS()
{
    var d = new Date();
    var time = d.getTime();

    sessionStorage.setItem('loggedin', time+"");
}


export function getLoggedInS()
{
     const stime=sessionStorage.getItem('loggedin');

     if(stime)
     {
        var d = new Date();
        var time = d.getTime();

        if(time  >  +(stime)+1000000 )
            return false;
        else
            return true;
    }
}

 
  export function getPage()      
  {
            return sessionStorage.getItem('page');
  }


  /**
 * @param {string} page
 */
  export function setPage(page)      
  {
            return sessionStorage.setItem('page',page);
  }


