/**
 * @author J-O Jansson w42.se
 */



import React, {  useState,  useRef, useEffect} from 'react';
import { View } from 'react-native';

import {Color} from "three";

import { XR } from '@react-three/xr';
import { Canvas } from '@react-three/fiber';

import { useContextBridge } from '@react-three/drei';



import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";

import Content2 from './Content2';
import Content2XR from './Content2_XR';

import "./zoomUI.css";


// @ts-ignore
import { ModalContext } from "../multiEnvComponents/modalProvider";

import  HomePage from "./widgets/VideoIntro";


// import FooterMenu from "./widgets/footerMenu";

import InfoContext from '../InfoContext';


import ArtGalleryInterface from  './widgets/galleryWidgets/ArtGalleryInterface';  




export const SCROLL_DIVIDER=250;

export const  topPagespointerEventsE = (zoompage) =>  {
  switch(zoompage)
  {
    case "home": return "auto";
    case "artgallery": return "auto";
    case "galleryentrance": return "auto";
    default: return "none";
  }
}

let animStepFunction=()=>{};
//+ const setAnimStepFunction= f => { animStepFunction=f; }

let cnvs;



/**
 * The 2D component that keeps the canvas (both regular 3D and VR)
 * 
 * @export
 * 
 * @param {object} props
 * @param {object} props.backgroundState 
 * @param {object} props.scroll2State 
 * @param {object} props.various   // various data
 * 
 * @returns 
 */
export default function Content({ backgroundState,  scroll2State, various}) 
{
    let { handleModal } = React.useContext(ModalContext);
 
    const ContextBridge = useContextBridge(ModalContext, InfoContext)

    const scrollRef =useRef();
    const clickMarkerRef =useRef();
    const vrBeamRef =useRef();
  

    const fpsdiv=useRef();

    const [releaseControl, setReleaseControl]=useState(false);

    const [runPathAnimation, setRunPathAnimation]=useState(false);
    const [cameraLerpReached, setCameraLerpReached]=useState(false);
    const [choosedPlanet, setChoosedPlanet] = useState(0);
    const [planetHorisontalScrollDirection, setPlanetHorisontalScrollDirection] = useState('');

    const [scrollTopState, setScrollTopState]=useState(1);

    const [mrgnTop, setMrgnTop] = React.useState(0);


    const [showHUD, setShowHUD]=useState(true);

    let { setScrollBarRef, animQueue, zoompage, setZoompage, 
      vr, r, fcs } = React.useContext(InfoContext);

      

    const mobile= rsp({
 sp: 1, sl: 1, mp: 1, tp: 1, mtl: 0, tl: 0, sll: 0, lll: 0, 
      dl: 0 }, r);  


      const mobile2= rsp({
 sp: 1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 0, sll: 0, lll: 0, 
        dl: 0 }, r);  


    React.useEffect(() => {

      function handleResize() { 
          let aRatio;
          
          if(window.innerWidth<920)
            aRatio = (window.innerWidth / window.innerHeight * 12)-120;
          setMrgnTop( aRatio);  
        }

      window.addEventListener('resize', handleResize);

      handleResize();
  
      return _ => {
        window.removeEventListener('resize', handleResize)
      }

    }, []);


    useEffect(()=>{
       setScrollTopState(1);
    },[]);
 
    

    const enabledVR=vr?.enabledVR;
    const setEnabledVR=vr?.setEnabledVR;


 
    
    useEffect(
      ()=>
      {       
        // @ts-ignore
        if(typeof scrollRef?.current?.scrollTop === "number" 
        // @ts-ignore
            && scrollRef?.current?.scrollTop !==  (scrollTopState*SCROLL_DIVIDER)  )
        {        
          // console.log("scrollTopState"+scrollTopState);
              // @ts-ignore
             scrollRef.current.scrollTop=(scrollTopState*SCROLL_DIVIDER);
        }   
      }
      ,
      [scrollTopState]
    );


    useEffect(  // change to fly mode in Art Gallery
      ()=>{
        if(zoompage==="galleryentrance")
          setTimeout(()=>
          {
            setReleaseControl(true);
          }, 500
          )
        else
        {
          setReleaseControl(false);

          if(setEnabledVR)
            setEnabledVR(false);
        }
        
        // @ts-ignore
        window.urlChange();

          // @ts-ignore
       window.urlChange({customName: "path" ,customData: zoompage});

        return ()=>setVRButton()
      },
      [zoompage]
    );


    useEffect(() => {
      setScrollBarRef(scrollRef);
    }, [scrollRef]);


    useEffect(
      ()=>
      {
        if(various?.vrTour)
          setRunPathAnimation(true);
        else
          setRunPathAnimation(false);
      }
      ,
      [various?.vrTour]
    );

    

      function setVRButton()
      {
        const vrbutton= document.getElementById('VRButton');

        if(vrbutton)
        {
          if(enabledVR)
          {
              vrbutton.style.visibility="hidden";
              vrbutton.style.display="none";
              vrbutton.parentNode.removeChild(vrbutton);
          }
          else 
          {
              vrbutton.style.visibility = "visible";       
              vrbutton.style.display = "block";  
          }
        }
      }
    
  
 
  



     
     const currentPage={currentP: zoompage, setCurrentP: setZoompage};



    let clickinfo={clickInfo: ()=> {}};

    const planetMask={
     }


    const Cnvs=    <View  nativeID="AboveAboveCanvas"
    // @ts-ignore
          style={{  height: '100vh', width: (zoompage==="home" ||  zoompage==="galleryentrance") ? '100%' :
           "calc(100% - 16px)" , zIndex: releaseControl ? 25000 : 0,   
              // @ts-ignore
              pointerEvents: 'none', right:  0,     
              position:  "absolute",
                top: zoompage==="galleryentrance" ? 0  : (mobile2 ? "8vh" : "23vh") }}>   
              <div id="AboveCanvas" style={{ width: '100%',
              // @ts-ignore
              pointerEvents:  (zoompage==='galleryentrance'  || zoompage==="galleryentrancevr")? 'auto':'none', 
              ...(zoompage==="home"  && !mobile ?  planetMask : {})         // planet mask fading in
               }}   className={"canvas3D zoompage_"+zoompage}   
                >
                    {                       
                              <Canvas style={{ width: (zoompage==="galleryentrance" || zoompage==="galleryentrancevr" ) ? "100vw" :'100%',
                                  height: ( zoompage!=='home'? '100vh':  '50vh'),
                            
                                  pointerEvents:  (zoompage==="galleryentrance" || zoompage==="galleryentrancevr" ) ? 'auto':"none",
                                  background: (zoompage==="galleryentrance" || zoompage==="galleryentrancevr" ) ? "white":"",
                                  }}  
                                gl ={{antialias: true, alpha: true }}

                                onCreated={({ gl, scene }) => {
                                          gl.toneMappingExposure = 1
                                          gl.setClearColor( 0x000000, 0 ); 

                                        if(topPagespointerEventsE(zoompage)==="auto")
                                        {         
                                          scene.background = null;
                                        }
                                        
                                        if(zoompage==="galleryentrance")
                                        {                                     
                                            scene.background = new Color(255,255, 255);
                                        }
                                    }}

                                      legacy={true}
                                      camera={{ manual: true }}
                                  >                                
                                  <ContextBridge>                                   
                                      { enabledVR ?
                                        <XR>
                                       <Content2XR vr={true}  fpsdiv={fpsdiv}
                                        handleModal={handleModal} 
                                        scrollTopState={scrollTopState} 
                                        releaseControl={releaseControl} 
                                        currentPage={currentPage} 
                                        animQueue={animQueue} 
                                        backgroundState={backgroundState}
                                        clickinfo={clickinfo}
                                        various={{scroll2State,  pathAnimation:{runPathAnimation, setRunPathAnimation},
                                            camLerp: {cameraLerpReached, setCameraLerpReached}, 
                                            planet: {choosedPlanet, setChoosedPlanet, planetHorisontalScrollDirection, setPlanetHorisontalScrollDirection},  
                                            animStepFunction, showHUD, setShowHUD,
                                            ...various, clickMarkerRef }}                                       
                                        />     
                                        </XR>           
                                        :
                                      <Content2 vr={false}  fpsdiv={fpsdiv}
                                        handleModal={handleModal} 
                                        scrollTopState={scrollTopState} 
                                        releaseControl={releaseControl} 
                                        currentPage={currentPage} 
                                        animQueue={animQueue} 
                                        backgroundState={backgroundState}
                                        clickinfo={clickinfo}
                                        various={{scroll2State,  pathAnimation:{runPathAnimation, setRunPathAnimation},
                                            camLerp: {cameraLerpReached, setCameraLerpReached}, 
                                            planet: {choosedPlanet, setChoosedPlanet, planetHorisontalScrollDirection, setPlanetHorisontalScrollDirection},  
                                            animStepFunction, showHUD, setShowHUD,
                                            ...various, clickMarkerRef }}
                                        /> }                    
                                  </ContextBridge>              
                              </Canvas>              
                  }                 
            </div> 
        <div ref={fpsdiv} 
                style={{position: "fixed", top: "60px", left:300, zIndex: 200, backgroundColor: "#000000"}}></div>
           
        </View>  

//   { window.location.hostname!=="vworlds.epo.org" &&


     return (
      <> 
      <View  nativeID="zoomUI" style={{flexDirection: "column", 
          backgroundColor: backgroundState ? "black": "#2D2A38", 
      // @ts-ignore
       pointerEvents: 'auto'}}   
       // @ts-ignore
       onBlur={()=>{ if(fcs?.unFocused)
                          fcs.setUnFocused=true }}
                          onFocus={()=>{ if(fcs?.unFocused)
                                            fcs.setUnFocused=false }}>  
          <>       
            <View  nativeID="zoomUI2" style={ zoompage==="home" ?  {} : { width:0, height: 0}  } >
            <HomePage loop={true} scrollTopState={scrollTopState}    
                  various={{planet: {choosedPlanet, setChoosedPlanet, 
                            planetHorisontalScrollDirection, setPlanetHorisontalScrollDirection},  
                            handleModal,  ...various}}            
                  TheCanvas={Cnvs}
                  
                  // touchOrWheel={touchOrWheel} 
                  // touchOrWheelPlanet={touchOrWheelPlanet}

                  planetMarginTop={mrgnTop}
                  />        
              </View>
              {zoompage==="galleryentrance" && !enabledVR && <>
                              <ArtGalleryInterface  various={{showHUD, setShowHUD, runPathAnimation, setRunPathAnimation,...various}} clickinfo={clickinfo} />
                          </> 
              }
            </>              
       </View>                 
    </>);
   }