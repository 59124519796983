import React,{useContext} from 'react';



import {WoITopPaneThird} from '../../widgets/WorldofInventorsSubPage/WoI_TopComponent';

export default function TopTop()
{


    return <WoITopPaneThird/>
}