import {TextureLoader, RepeatWrapping, Color, 
    MeshStandardMaterial, MeshPhysicalMaterial, MeshPhongMaterial,  BufferAttribute, Box3,
   /*   CubeTextureLoader, , LOD  , FrontSide, Mesh, */
     NearestFilter,  NearestMipmapNearestFilter} from 'three';

import { URL3D } from '../../config/serverURLPath.web.js';

import attachLODButton from './attachLODButton';

import emissiveTextureShader from './emissiveTextureShader';


const Tloader = new TextureLoader();






/**
 * Post load material settings
 * 
 * @param {any} obj the 3D object
 * @param {any} cam the camera
 * @param {any[]} generatedEnvMaps
 * @param {number} gr 
 * @param {string} filename  the filename of the 3D model
 * @param {object} occulsion In this case this is used for walls collision detection
 *  @param {any} [scene]
 * @param {function} [cllbck] this runs when loading is done
 * @param {boolean} [vr] 
 * @returns {any[] | void | (() => void)}
 */
export default function additionalMaterialSettings(obj, cam, generatedEnvMaps, gr, filename, {wallCollisionBoxes}, scene, cllbck, vr ) {       
        const tempColor=obj?.material?.color;

        if (filename==="galleryentrance") {           
            if (obj?.material?.name==="floor_") {
                    if (gr>2) {
                        if (obj?.geometry?.attributes && !obj.geometry.attributes.hasOwnProperty('uv2')) {
                                    obj.material=new MeshPhysicalMaterial({});
                                    obj.material.name="floor_";
                                    let uvs = obj.geometry.attributes.uv.array;            
                                    obj.geometry.setAttribute( 'uv2', new BufferAttribute( uvs, 2 ) );
                                    obj.material.reflectivity =0.9;   
                        }
                    } else {
                                    obj.material=new MeshPhongMaterial();
                                    obj.material.name="floor_";
                                    obj.material.color=new Color(0xffbb88);
                    }
                                                     
                    if (gr>1) {  
                                Tloader.load(
                                    // resource URL
                                  
                                    URL3D+'textures/floor.jpg'
                                    ,
                                    texture=> {
                                        
                                        obj.material.map=texture;                                   
                                        obj.material.map.flipY = false;  
                                
                                        obj.material.map.wrapS =RepeatWrapping ;
                                            
                                        obj.material.map.wrapT =RepeatWrapping ;
                            
                                        obj.material.map.repeat.set(8,16);
                                    }, null, e=>console.error(e));
                        }             

                        if (gr>5) {
                                Tloader.load(
                                    // resource URL
                                    URL3D+'textures/floor_bumpMap.jpg',
                                
                                    // onLoad callback
                                function ( texture ) {                              
                                        obj.material.color=new Color("#333333");
                              
                                        obj.material.bumpMap=texture;                                   
                                        obj.material.bumpMap.flipY = false;  
                                            
                                        obj.material.bumpMap.wrapS =RepeatWrapping ;                                                        
                                        obj.material.bumpMap.wrapT =RepeatWrapping ;
                                        
                                        obj.material.bumpMap.repeat.set(8,16);
                                        obj.material.bumpScale =0.002;

                                        obj.material.metalness=0.2;
                                        obj.material.roughness=0.2;      
                                            
                                        obj.material.reflectivity =0.5;      
                                            
                                        obj.material.envMapIntensity=0.2;
                                        obj.material.envMap = scene?.environment;
                                        
                                    obj.material.needsUpdate = true;
                            },
                                        progress=> console.log(progress),
                                        error=> console.error(error)
                                    );
                                                                                          
                                    } else {
                                        console.log("floor bump map not loaded");
                                        if (gr>2) {
                                            obj.material=new MeshStandardMaterial({});
                                            obj.material.name="floor_";
                                            obj.material.color= tempColor;
                                        }
                                    }
                                   

                                if (gr>8) {
                                    Tloader.load(
                                    // resource URL
                                    URL3D+'textures/layer3_baked_beauty_floorShape.jpg',
                                    texture3 => {
                                        obj.material.aoMap=texture3;
                                        obj.material.aoMap.anisotropy =  32;     
                                        obj.material.aoMap.magFilter = NearestFilter;
                                        obj.material.aoMap.minFilter = NearestMipmapNearestFilter;
                                        obj.material.aoMap.flipY = false;
                                        
                                        obj.material.needsUpdate = true;
                                    });


                                    Tloader.load(
                                        // resource URL
                                    URL3D+'textures/layer3_baked_beauty_floorShape.jpg',
                                    texture3 => {
                                         obj.material.lightMap=texture3;
                                        obj.material.lightMap.anisotropy =  32;   
                                        obj.material.lightMap.magFilter = NearestFilter;
                                        obj.material.lightMap.minFilter = NearestMipmapNearestFilter;
                                        obj.material.lightMap.flipY = false;
                                        
                                        obj.material.needsUpdate = true;
                                    });
                                } else {
                                    Tloader.load(
                                    // resource URL
                                    URL3D+'textures/layer3_baked_beauty_floorShapeLow.jpg',
                                    texture3 => {
                                        obj.material.aoMap=texture3;
                                        obj.material.aoMap.anisotropy =  32;     
                                        obj.material.aoMap.magFilter = NearestFilter;
                                        obj.material.aoMap.minFilter = NearestMipmapNearestFilter;
                                        obj.material.aoMap.flipY = false;
                                        
                                        obj.material.needsUpdate = true;
                                    });


                                    Tloader.load(
                                        // resource URL
                                    URL3D+'textures/layer3_baked_beauty_floorShapeLow.jpg',);
                                }
            } else if (obj?.material?.name==="walls3") {                   
                                        if (gr>5) {
                                            if (obj?.geometry?.attributes && !obj.geometry.attributes.hasOwnProperty('uv2')) {
                                                obj.material=new MeshPhysicalMaterial({});
                                                obj.material.name="walls3";

                                                let uvs = obj.geometry.attributes.uv.array;            
                                                obj.geometry.setAttribute( 'uv2', 
                                                      new BufferAttribute( uvs, 2 ) );

                                                obj.material.roughness=0.6;
                                                obj.material.metalness=0.1;
                                                obj.material.specularColor =new Color(0xeeeeee);
                                                obj.material.color=new Color(0x333333);

                                                if (gr<=7) {
                                                    obj.material.color=new Color(0.2,0.2,0.2);
                                                }
                                            }
                                        } else {
                                            obj.material=new MeshPhongMaterial({});
                                            obj.material.name="walls3";
                                            obj.material.color=new Color(0x333333);
                                        }

                                      if (gr>=6) {
                                            Tloader.load(
                                    
                                            URL3D+'textures/layer3_baked_beauty_walls5Shape.jpg' ,
                                                function(texture2)   {
                                        //   output the text to the console
                                        //  @ts-ignore
                                    
                                                    obj.material.lightMap=texture2;
                                                    
                                                    obj.material.lightMap.flipY = false;
                                                    obj.material.lightMapIntensity =1;
                                                
                                                    obj.material.needsUpdate = true;
                                                }); 

                                            

                                                Tloader.load(
                                                    // resource URL
                                                    URL3D+'textures/layer3_baked_beauty_walls5Shape.jpg',
                                                    function (texture3) {    
                                                    
                                                        
                                                        obj.material.aoMap=texture3;
                                                            
                                                        obj.material.aoMapIntensity=1;

                                                        obj.material.aoMap.flipY = false;
                                                        
                                                        obj.material.needsUpdate = true;
                                                    },
                                                    progress=> console.log(progress),
                                                    error=> console.error(error)); 
                                        

                                                    Tloader.load( URL3D+'textures/env_maps/floor_env.png', function (texture){
                                
                                                    
                                                            obj.material.envMap = texture;
                                                            obj.material.needsUpdate = true;
                                                        });

                                        }
            } else if( obj?.material?.name==="walls1"  ) {                   
                                        if(obj?.geometry?.attributes && !obj.geometry.attributes.hasOwnProperty('uv2')) {
                                            obj.material=new MeshStandardMaterial();
                                            obj.material.name="walls1";

                                            let uvs = obj.geometry.attributes.uv.array;            
                                            obj.geometry.setAttribute( 'uv2', 
                                                  new BufferAttribute( uvs, 2 ) );
                                            obj.material.roughness=0.6;
                                            obj.material.color=new Color(0xcccccc);
                                        }

                               if (gr>=6) {                                                                                                                    
                                  Tloader.load(
                        
                                   URL3D+'textures/layer3_baked_beauty_walls5Shape.jpg' ,
                                    function(texture2)   {
                                        obj.material.lightMap=texture2;
                                        
                                        obj.material.lightMap.flipY = false;
                                        obj.material.lightMapIntensity =1;
                                    
                                        obj.material.needsUpdate = true;
                                    }); 



                                    Tloader.load(
                                        // resource URL
                                        URL3D+'textures/layer3_baked_beauty_walls5Shape.jpg',
                                        function (texture3) {    
                                           
                                            
                                            obj.material.aoMap=texture3;
                                                
                                            obj.material.aoMapIntensity=1;

                                            obj.material.aoMap.flipY = false;
                                            
                                            obj.material.needsUpdate = true;
                                        },
                                        progress=> console.log(progress),
                                        error=> console.error(error)); 
                               

                                        Tloader.load( URL3D+'textures/env_maps/floor_env.png', function (texture){
                    
                                           
                                                obj.material.envMap = texture;
                                                obj.material.needsUpdate = true;
                                            });
                                } // if
            } else if (obj?.material?.name==="nurbsPlane1SG6" ) {                   
                                if (gr>5) {
                                        if (obj?.geometry?.attributes && !obj.geometry.attributes.hasOwnProperty('uv2')) {
                                            obj.material=new MeshStandardMaterial();
                                            obj.material.name="nurbsPlane1SG6";

                                            let uvs = obj.geometry.attributes.uv.array;            
                                            obj.geometry.setAttribute( 'uv2', 
                                                  new BufferAttribute( uvs, 2 ) );

                                            obj.material.roughness=0.1;

                                        }
                                } else {
                                    obj.material=new MeshPhongMaterial();
                                    obj.material.name="nurbsPlane1SG6";
                                }

                                if (gr>=6) {         
                                        obj.material.color=new Color(0xeeeeee);
                                      Tloader.load(
                                            // resource URL
                                            URL3D+'textures/stairs.jpg',
                                        
                                            // onLoad callback
                                        function ( texture ) {
                                            
                                                // output the text to the console        phong2  white_wall_phong7
                                          
                                            obj.material.map=texture;
                                            
                                           obj.material.map.flipY = false;  
                                                                                    
                                            obj.material.metalness=0.1;            
                                            
                                        
                                            obj.material.needsUpdate = true;
                                            }); 
                                        
                                    Tloader.load(
                                        // resource URL
                                        URL3D+'textures/stairsRougness.jpg',
                                    
                                        // onLoad callback
                                            function ( texture ) {
                                                
                                                    // output the text to the console        phong2  white_wall_phong7
                                                
                                                obj.material.roughnessMap=texture;
                                                obj.material.specularIntensityMap =texture;
                                                obj.material.needsUpdate = true;
                                                }); 

                                    Tloader.load( URL3D+'textures/env_maps/floor_env.png', function (texture){
                
                                        
                                            obj.material.envMap = texture;
                                            obj.material.needsUpdate = true;
                                        });
                                }
            } else {
                            if (gr>=6) {
                                for(let n=22; n<=41; n++ ) {
                                    if( obj?.material?.name==="RAMP_w"+n )
                                    {        
                                            let box=new Box3();
                                            box.expandByObject(obj);
                                            wallCollisionBoxes.set(obj.name, box );

                                            let wallbump;


                                            if(obj?.geometry?.attributes && !obj.geometry.attributes.hasOwnProperty('uv2')) {
                                                obj.material=new MeshStandardMaterial();
                                                obj.material.name="RAMP_w"+n;

                                                let uvs = obj.geometry.attributes.uv.array;            
                                                obj.geometry.setAttribute( 'uv2', 
                                                      new BufferAttribute( uvs, 2 ) );
    
                                                obj.material.roughness=0.6;

                                                if(gr>7) {
                                                    obj.material.color=new Color(0.9,0.9,0.8);
                                                } else {
                                                    obj.material.color=new Color(0.6,0.6,0.6);
                                                }
                                            }

                                                if (gr>5) {
                                                      if (gr>8) {
                                                                Tloader.load(
                                                                        // resource URL
                                                                        URL3D+'textures/'+ (gr>2 ?  'w' : 'w2')+ '/layer3_baked_beauty_pCube_wShape'+n+'.jpg',
                                                                    
                                                                        // onLoad callback
                                                                    function ( texture ) {                                                                            
                                                                                    obj.material.lightMap=texture;                                                                            
                                                                                    obj.material.lightMap.flipY = false;  
                                                                                    obj.material.lightMapIntensity=1;

                                                                                    obj.material.aoMap=texture;                                                                            
                                                                                    obj.material.aoMap.flipY = false;  
                                                                                    obj.material.aoMapIntensity=1;                                                                                                                            
                                                                                    obj.material.needsUpdate = true;
                                                                                
                                                                        },
                                                                        ()=>{console.log("_") },
                                                                        e=> {
                                                                                console.error("walls texture error:  url  "+ URL3D+'textures/'+ (gr>8 ?  'w' : 'w2')+ '/layer3_baked_beauty_pCube_wShape'+n+'.jpg');
                                                                                console.dir(e);
                                                                            }                                          
                                                                        ); 
                                                                }

                                                                if (!wallbump) {
                                                                        Tloader.load(
                                                                            // resource URL
                                                                            URL3D+'textures/w/wallbump.png',
                                                                        
                                                                            // onLoad callback
                                                                        function ( texture ) {                  
                                                                                        obj.material.bumpMap=texture;                                                                            
                                                                                        obj.material.bumpMap.flipY = false;  
                                                                                        obj.material.bumpScale = 0.002;                         
                                                                                        obj.material.bumpMap.flipY = false;  
                                                                                        obj.material.bumpMap.wrapS =RepeatWrapping ;
                                                                                        obj.material.bumpMap.wrapT =RepeatWrapping ;
                                                                                        obj.material.bumpMap.repeat.set(20,30);
                                                                                        obj.material.bumpMap.magFilter=NearestMipmapNearestFilter;

                                                                                        wallbump=obj.material.bumpMap;                                                                           
                                                                            },
                                                                            ()=>{console.log("_") },
                                                                            e=> {
                                                                                    console.error("walls texture error:  url  "+  URL3D+'textures/w/wallbump.png');
                                                                                    console.dir(e);
                                                                                }                                          
                                                                            ); 
                                                                }
                                                                else
                                                                    obj.material.bumpMap=wallbump;                          
                                                }
                                        }                                   
                                }
                            }
                                if (gr>=6) {
                                    for(let n=1; n<=2; n++ ) {
                                        if( obj?.material?.name==="ceilingBar_phong"+n )
                                        {        
                                             if( obj?.material?.name==="ceilingBar_phong1")
                                                    Tloader.load(
                                                        // resource URL
                                                        URL3D+'textures/ceiling/Birch-Plywood-Architextures.jpg',
                                                    
                                                        // onLoad callback
                                                    function ( texture ) {
                                                        
                                                                // output the text to the console        phong2  white_wall_phong7
                                                            
                                                            obj.material.map=texture;
                                                            
                                                            obj.material.map.flipY = false;  
                                                            
                                                            obj.material.map.wrapS =RepeatWrapping ;
                                                    
                                                            obj.material.map.wrapT =RepeatWrapping ;
                                                            
                                                            obj.material.map.repeat.set(28,28);

                                                            obj.material.metalness=0.6;            
                                                            
                                                            obj.material.color=new Color(0.4,0.4,0.4);
                                                            obj.material.needsUpdate = true;


                                                        }); 
                                         
                                            Tloader.load(
                                                    // resource URL
                                                    URL3D+'textures/ceiling/layer3_baked_beauty_ceilingBar'+n+'Shape.jpg',
                                                
                                                    // onLoad callback
                                                function ( texture ) {
                                                    
                                                        // output the text to the console        phong2  white_wall_phong7
                                                
                                                    obj.material.lightMap=texture;                                                   
                                                    obj.material.lightMap.flipY = false;  

                                                    obj.material.aoMap=texture;                                                   
                                                    obj.material.aoMap.flipY = false;  
                                                            
                                                    obj.material.metalness=0.1;            
                                                    
                                                
                                                    obj.material.needsUpdate = true;
                                                    }); 
                                            }                                           
                                    }
                                }
            }
                            
                    
            if (obj?.material?.name==="D1metal3") {
                obj.material.rougness=0.1;
                obj.material.metalness=1;
                console.log("D1metal3 obj?.name "+obj?.name ); // D1metal3
            } else if (obj?.material?.name==="EPOArtCollection") {
                obj.material.rougness=1;
                obj.material.metalness=0;
                obj.material.emissive=new Color(0xffffff)
                obj.material.color=new Color(0xffffff)
                console.log("D1metal3 obj?.name "+obj?.name ); // D1metal3
            } else if (obj?.material?.name==="artcollection_glow1") {
                obj.material.rougness=0.1;
                obj.material.metalness=1;
                obj.material.emissive=new Color(0xffffff)
                Tloader.load(
                    URL3D+'textures/fakeglow.png',
                    texture=> {
                        obj.material.map=texture;                                   
                        obj.material.map.flipY = false;  
                    });
            } else if (["firstroomcarpet", "ndroomcarpet", "thirdroom_carpet", "fourthroom_carpet"].indexOf(obj?.material?.name)!==-1) {       
                obj.name="floor";
                obj.material.roughness=1;
                if(obj?.material?.name==="firstroomcarpet") {
                        Tloader.load(
                            // resource URL
                            URL3D+'textures/carpet1_alphaMap.png',
                        
                            // onLoad callback
                        function ( texture ) {    
                                    
                                obj.material.transparent=true;                                  
                                obj.material.alphaMap=texture;
                        //        obj.material.bumpMap=texture;
                                obj.material.alphaMap.anisotropy =  16;  
                                //   obj.material.bumpMap.anisotropy =  16;
                                obj.material.needsUpdate = true;


                                //-   signClone(obj)
                            });    
                } else if (obj?.material?.name==="ndroomcarpet") {        
                    Tloader.load(
                        // resource URL
                        URL3D+'textures/climatechangeandnature_alphaMap.png',
                    
                        // onLoad callback
                    function ( texture ) {    
                            
                            obj.material.transparent=true;                                      
                            obj.material.alphaMap=texture;
                            obj.material.alphaMap.anisotropy =  16;
                        //      obj.material.bumpMap=texture; 
                        //     obj.material.bumpMap.anisotropy =  16;
                            obj.material.needsUpdate = true;

                            //-  signClone(obj)
                        });   
                }
                else if (obj?.material?.name==="thirdroom_carpet") {
                        Tloader.load(
                            // resource URL
                            URL3D+'textures/InnovationAndMobility_alphaMap.png',
                        
                            // onLoad callback
                        function ( texture ) {      
                                    
                                obj.material.transparent=true;                                  
                                obj.material.alphaMap=texture;
                                obj.material.alphaMap.anisotropy =  16;  
                                obj.material.alphaMap.flipY = false;  
                            //      obj.material.bumpMap=texture; 
                            //      obj.material.bumpMap.flipY = false;  
                            //      obj.material.bumpMap.anisotropy =  16;
                                obj.material.needsUpdate = true;

                                //-   signClone(obj)
                            });   
                } else if (obj?.material?.name==="fourthroom_carpet") {     
                        Tloader.load(
                            // resource URL
                            URL3D+'textures/Newwaysoflife.png',
                        
                            // onLoad callback
                        function ( texture ) {    
                            
                            
                                obj.material.map=texture;
                                obj.material.map.anisotropy =  16;  
                                obj.material.map.flipY = false;  
                                obj.material.needsUpdate = true;
                            });   

                        Tloader.load(
                            // resource URL
                            URL3D+'textures/Newwaysoflife_alphaMap.png',
                        
                            // onLoad callback
                        function ( texture ) {         
                                obj.material.transparent=true;                                     
                                obj.material.alphaMap=texture;
                                obj.material.alphaMap.anisotropy =  16;  
                                obj.material.alphaMap.flipY = false;  
                                obj.material.bumpMap=texture; 
                                obj.material.bumpMap.anisotropy =  16;
                                obj.material.bumpMap.flipY = false;  
                                obj.material.needsUpdate = true;
                            //-       signClone(obj)
                                cllbck();  // this sets loading done!!!
                            });   
                }
            } else if (obj?.material?.name && obj?.material?.name.indexOf  && obj.material.name.indexOf('artwork')!==-1) {        
                obj.material.roughness=0.9;
                obj.material.metalness=0;
            } else if(obj?.material?.name==="glass") {
                obj.material=new MeshStandardMaterial();
                obj.material.name="glass";
                obj.material.transparent=true;
                obj.material.opacity=0.6;
                obj.material.roughness=0.1;
            } else if(obj?.material?.name==="ceiling_glass") {
                obj.material=new MeshStandardMaterial();
                obj.material.name="ceiling_glass";
                obj.material.transparent=true;
                obj.material.opacity=0.6;
                obj.material.roughness=0.1;
            } else if (["phong28", "Sign_Lietner", "EPOArtcollection_wall_poster", "sustainability_wall_poster", "Sign_Panamarenko","sign_Belin1_phong10","sign_Kintera","sign_TueGreenfort", "sign_Dawar_phong11","sign_Birisdottir", "sign_Kwade", "sign_wesley", "Sign_SIgner", "TueGreenFortSculptureSignMat"  ].indexOf(obj?.material?.name)!==-1) {
                const map = obj.material.map;
                const Mat=  emissiveTextureShader(obj.material.map);
                const name_=obj.material.name;
                obj.material=Mat;
                obj.material.name=name_;
                obj.material.map= map;
                /** @type { object } */ 
                let t=  false, c=1; 
                if(["EPOArtcollection_wall_poster", "sustainability_wall_poster"].indexOf(obj?.material?.name)!==-1) {
                    obj.name='LODButton_'+ obj?.material?.name;                                           
                    } else {
                    attachLODButton(obj, Tloader, t, c, vr);
                }   
            } 
        }
}


