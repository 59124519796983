import React,{useContext} from 'react';

import {Vw, C} from '../../multiEnvComponents/blocktags';
import {H1, H4} from '../../multiEnvComponents/texttags.web';
import styled from 'styled-components';

import {ImageSVG}  from '../../multiEnvComponents/Image.web';

import { CLIENT_PATH } from  '../../config/serverURLPath.web';

import InfoContext from '../../InfoContext';

import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

/**
 * Styled Responsive box for art work info box
 *
 * @param {*} Span2.props
 */
 const Title1 =
    // @ts-ignore
    styled(H4).attrs({
        className: "H4"
    })`
        display: flex;

        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        /* or 127% */


        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF;
        margin-top: 3rem;
        cursor: pointer;


  ${'' /*       width: ${p=>  p.
                        // @ts-ignore
                        width }; */}

        @media (max-width: 925px) { 
            font-size: 12px;
            line-height: 12px;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }

        @media (max-width: 400px) { 
            font-size: 8px;
            line-height: 8px;
            margin-left: 0.3em;
            margin-right: 0.3em;
        }
`;


const Title=({ children, onClick, width})=> {
    const {r} = useContext(InfoContext);

    const mobile=rsp({sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl:1, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 },
        r);

       return <Title1 st={{flexDirection: "row", justifyContent: "space-between" }} 
          // @ts-ignore
                width={width} onClick={onClick}>
            {children}
            <ImageSVG source={{uri: CLIENT_PATH+"graphics2D/arrow_forward.svg" }} 
                width={mobile?"10px":"30px"} height={mobile?"10px":"30px"} containerStyle={{marginBottom: 6, marginRight: 30}} >                    
            </ImageSVG>
        </Title1>};

/**
 * Styled Responsive box for art work info box
 *
 * @param {*} Span2.props
 */
 const Subtitle =
    styled(H4).attrs({
        className: "H4_"
    })`
        /* XL2 / Link */

        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    
        color: #B3E5FC;
        margin-top: 1rem;
        margin-left: 6rem;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;

        @media (max-width: 925px) { 
            font-size: 12px;
            line-height: 12px;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }

        @media (max-width: 400px) { 
            font-size: 8px;
            line-height: 8px;
            margin-left: 0.3em;
            margin-right: 0.3em;
        }
`;



/**
 * Each Row
 * 
 * @export
 * @param {object} props 
 * @param {number} props.indent
 * @param {string} props.title 
 * @param {string} [props.zoompage] 
 * @param {object} [props.subtitles] 
 * @returns 
 */
export function SitemapRow({indent, title, zoompage, subtitles=[]})
{    
    let {  setZoompage, various: {variousStatesDispatch}} = React.useContext(InfoContext);

    return <C st={{marginLeft: indent+"em"}} >
        <Title width={ (indent > 0) ? "80vw": "90vw" }   
            onClick={()=> {variousStatesDispatch({type: 'setSiteMapClosed'});
                            setZoompage(zoompage);  } }>{title}</Title>
                    {subtitles.map((s, i)=><Subtitle key={i} 
                    // @ts-ignore
                    onClick={()=>{
                        variousStatesDispatch({type: 'setSiteMapClosed'});

                        if(s.subpage)
                            setZoompage(s.subpage)
                        else
                        if(s.link)
                            window.open(s.link)
                        
            } }>{s.title}</Subtitle>)}
    </C>

}

export default function SiteMap()
{
    return <Vw st={{marginLeft: "3rem"}}>
        <H1>Site Map</H1>
        <SitemapRow  indent={0}  title="Home" zoompage="home" />
        <SitemapRow  indent={10}  title="World of inventions" zoompage="home"  subtitles={[ { title: "· Wireless communication" , subpage: "radio"  }]} />
        <SitemapRow  indent={10}  title="World of art"    zoompage="home" subtitles={[ {title: "· Virtual gallery",  subpage: "galleryentrance"  } ]} />
        <SitemapRow  indent={10}  title="World of EPO"  zoompage="home" subtitles={[ {title: "· Spatial chat" , link: "https://spatial.chat/s/hola333" } ]} />
    </Vw>

}