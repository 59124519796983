import React,{useState, useContext} from 'react';
import InfoContext from '../../InfoContext';
import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
import {  Vw, C,R } from '../../multiEnvComponents/blocktags';
// @ts-ignore
import { H4, P} from '../../widgets/subPages/htmlcomponents/texttags.web';

import  { Image } from 'react-native';


import Button from '../../multiEnvComponents/ButtonA.web';
import PropTypes from 'prop-types';


export default function InfoArtGallery({setVRTour=(val)=>{console.log(val)} })     //  ,
{
    const {r, contentModel: {infoboxes}, l, vr: {setEnabledVR}, setZoompage,
                graphicsResolution: {gr}
            } =  useContext(InfoContext);


   const [showing, setShowing]=useState(true);

    const s = {
        display: "block",
        zIndex: 100000,
        justifyContent: "center",
        alignItems: "center",
        right: 0
    };


    const centerText={textAlign: "center", marginBottom: 5}
    const h4w={width: "40%"}
    const marginP={marginTop: 30}


    const tR=rsp({sp:  0, 
         sl: 0, mp: 0, tp: 0, mtl: 0, tl: 0, sll: 1, lll: 1, dl: 1 }, r);


    const mobile=rsp({sp:  1, 
        sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0,  dl: 0 }, r);


    const buttonSt={marginBottom: "5ex"};



    const mrgns={margin: 5,  alignSelf: mobile? "center": "start", alignContent: "center", 
                  justifyItems: "center",  placeItems: "center",  width: mobile ? "" : "20rem"}; 


    return <>{ showing && <Vw n="coverArt" st={s}>
                        <Vw st={{alignContent: "center" }}>
                                    <R n="marginTop1_WorldArt" st={{flexDirection: tR? "row": "column", alignSelf: "center" }}>
                                      <C n="column1_WorldArt" st={{  ...mrgns, marginLeft: tR ? "5px" : 0}}>
                                            <Image  source={{uri: infoboxes?.vr?.icon}}  style={{width: 77, height: 77}}   />
                                            <H4 st={{...h4w,...centerText}} >{infoboxes?.vr?.title[l]}</H4>
                                            <P n="pcol1_first__WorldArt" st={{...marginP,...centerText, width: "80%"}}>{infoboxes?.vr?.first[l]}</P>
                                            <P st={centerText}>{infoboxes?.vr?.second[l]}</P>

                                            <Button title={infoboxes?.vr?.button?.[l]} onPress={()=> 
                                                {
                                                    setShowing(false); 
                                                    setEnabledVR(true);  
                                                    
                                                    const vrbtn= document.getElementById('VRButton');
                                                    
                                                    if(vrbtn?.style?.display)
                                                        vrbtn.style.display='block';

                                                    setVRTour(false); 
                                                    setZoompage('galleryentrancevr');  } }  buttonStyle={buttonSt} />
                                        </C>
  
                                                    <C n="column2_WorldArt" st={mrgns}>
                                                        <Image  source={{uri: infoboxes?.v3d?.icon}} style={{width: 77, height: 77}}   />
                                                        <H4 st={{...h4w,...centerText}}>{infoboxes?.v3d?.title[l]}</H4>
                                                        <P n="pcol2_first_WorldArt" st={{...marginP,...centerText, width: "80%"}}>{infoboxes?.v3d?.first[l]}</P>
                                                        {/* (gr>0) || !mobile  */}
                                                       { (gr>0) || !mobile ?
                                                            <Button title={infoboxes?.v3d?.button[l]} onPress={()=>  {setShowing(false); 
                                                                                                                setEnabledVR(false);  setVRTour(false); setZoompage('galleryentrance');
                                                                                                            } }  buttonStyle={buttonSt} />  
                                                            :  
                                                            <>     
                                                            <P>Too low 3D performance device for 3D  navigation:</P>  
                                                            
                                                            
                                                                {/*     <Button title={infoboxes?.vr?.button?.[l]} onPress={()=> 
                                                                        {
                                                                        //  setShowing(false); 
                                                                            
                                                                                window.location.pathname="optArtGallery/optArtGallery.html" 
                                                                        
                                                                        
                                                                            } }  buttonStyle={buttonSt} />  */}
                                                                </>
                                                        }
                                        </C>

                                    </R>
          </Vw> </Vw>  } </>
}

InfoArtGallery.propTypes = {
    setVRTour: PropTypes.func,
};

