import React, {   useRef, useEffect  } from 'react';

import { useFrame } from '@react-three/fiber';

import InfoContext from '../InfoContext';


/**
 * object that Calculates the navigation frame rate
 * 
 * @returns 
 */
export function getfpsO() {
	let  prevTime=0, fps=0, framesFPS=0;

	const update=()=>{
		framesFPS++;

		const time = ( performance || Date ).now();

		if ( time >= prevTime + 1000 ) {			
			fps= ( framesFPS * 1000 ) / ( time - prevTime );

			prevTime = time;
			framesFPS = 0; 
		}

		return fps;
	}

	return {fps, update}
}


let hiddenReverseCount=0;
const setReverseHiddenCount=r=>hiddenReverseCount=r;

let lowModelRef={}


function frm({groupRef,setFPS, kdown, zoomArtGetter, hiddenCount, setHiddenCount, fpsObj, framerate, fpsmin, fpsmax})
{
		if(fpsObj?.update)
			framerate=fpsObj.update();

		setFPS(framerate);

		if(fpsmax)
		{
			lowModelRef=groupRef.current;
		}
		
		if((hiddenCount<2 && (framerate > 2 ? framerate : 30)>=fpsmin) || fpsmin===0 || (!kdown && !zoomArtGetter() ) )
		{
			// @ts-ignore
			if(groupRef?.current)
			{
				// @ts-ignore
				groupRef.current.visible=true;


			//	console.log(" Visa VAnliga  ");
				if(lowModelRef)
					lowModelRef.visible=false;
			}
		}
		else
		{
			if(hiddenCount<2)
			{
				setHiddenCount(c=>c+1);
			}
			else
			{
				if(groupRef?.current)
				{
				// @ts-ignore
					groupRef.current.visible=false;	

					if(lowModelRef)
						lowModelRef.visible=true;
				}		
			}
		}

		if(( (framerate > 2 ? framerate : 30)>=fpsmin))
		{
			setReverseHiddenCount(hiddenReverseCount+0.001);
		} 

		if(hiddenReverseCount>1)
		{
			setReverseHiddenCount(0);
			setHiddenCount(0);
		}


	}


	/**
	 * 
	 * 
	 * @param {object} props
	 *  @param {object} props.children
	 *  @param {object} props.fpsmin
	 *  @param {object} [props.fpsmax]
	 *  @param {object} [props.name]
	 * @returns 
	 */
function VisibilityGroup({children, fpsmin=0, fpsmax, name})
{
	let { fps: {setFPS, triggerR, kdown, setkdown}, zoomart: {zoomArtGetter}, vr: {enabledVR} } = React.useContext(InfoContext);

	// const [hiddenCount, setHiddenCount]=useState(0);
	let hiddenCount=0;
	const setHiddenCount=v=>hiddenCount=v;

	const groupRef=useRef();
//	 const altgroupRef=useRef();

	const fpsObj=getfpsO();
	let framerate=0;

	useEffect(
	()=>
	{
		// @ts-ignore
		if(groupRef?.current?.visible)
			// @ts-ignore
			groupRef.current.visible=true;
		
		setHiddenCount(0);
	},
	[triggerR]
);


	/* if(!enabledVR)
	{ */
	useFrame(()=>
		frm({groupRef, setFPS, kdown, zoomArtGetter, hiddenCount, setHiddenCount, fpsObj, framerate, fpsmin, fpsmax})
	);


	// }
	// else
	// {
	// 	useXRFrame((/*+ time, xrFrame */) => 	frm({groupRef, setFPS, kdown, zoomArtGetter, hiddenCount, setHiddenCount, fpsObj, framerate, fpsmin, fpsmax}));
	// }


	return <>
			<group name={name} ref={groupRef} >{children}</group>
	</>
}



/**
 * 
 * Creates LOD based on the navigation FPS
 * 
 * 
 * @export
 * @param {object} props
 * @param {Array} props.featureList  the FPS feature LOD list 
 * @param {boolean} props.keepDetails   FPS feature LOD'ing in mobile

 * @returns 
 */
export function FPSFeatureAdditionLOD({ featureList, keepDetails })
{		
	const fl=featureList.map(
		(n,index)=>{
			return <VisibilityGroup name={n?.feature?.props?.filename}	key={n?.feature?.props?.filename+'_'+index} fpsmin={n.fpsmin} fpsmax={n.fpsmax} >{n.feature}</VisibilityGroup>
		}
	)

	return <group name="featurelist">
		{fl}
		</group>


}