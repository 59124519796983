import { useState, useLayoutEffect } from 'react';

function call(ref, value) {
  if (typeof ref === 'function') ref(value);else if (ref != null) ref.current = value;
}

function useEffectfulState(fn, cb, deps = []) {
  const [state, setState] = useState();
  useLayoutEffect(() => {
    const value = fn();
    setState(value);
    call(cb, value);
    return () => call(cb, null); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return state;
}

export default useEffectfulState;
