import React from "react";
import { View, Image } from "react-native";

import { CLIENT_PATH } from  '../../../config/serverURLPath.web';

const imageStyle={ width: 50, height: 50, marginRight:5, marginBottom: 25, marginTop: 0, color: "white" }

export default function IconList({  ar=false, vr=false, v3d=false   })
{
    // @ts-ignore
    return <View nativeID="IconList" style={{flexDirection: "row"}}>
        { ar && <Image source={{ uri: CLIENT_PATH+"graphics2D/icons/iconARsmall.svg" }} style={imageStyle} resizeMode="stretch" /> }
        { vr && <Image source={{ uri: CLIENT_PATH+"graphics2D/icons/iconVRsmall.svg" }} style={imageStyle} resizeMode="stretch" /> }
        { v3d && <Image source={{ uri: CLIENT_PATH+"graphics2D/icons/icon3Dsmall.svg" }} style={imageStyle} resizeMode="stretch" /> }
    </View>
}