import React,{useContext} from 'react';

// import styled from 'styled-components';

import {View, Text, Image} from 'react-native';
// @ts-ignore
import {Image as ImageS} from '../../../multiEnvComponents/Image.web';
// @ts-ignore
import {Row, Co} from '../htmlcomponents/blocktags2';
// @ts-ignore
import {H3, H4, H5, P} from '../htmlcomponents/texttags.web';
import InfoContext from '../../../InfoContext';


import rsp from "../htmlcomponents/useResponsiveBrk";

import { CLIENT_PATH } from  '../../../config/serverURLPath.web';


/**
 * Custom Bubble
 * 
 * @param {object} props
 * @param {string} props.children 
 * @param {object} [props.containerStyle]  outer box style
 * @returns JSX
 */
function BubbleMobile({children, containerStyle})
{
    const {r} =  useContext(InfoContext);

    const mobile3= rsp({sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  0,
        mtl: 0, 
        tl: 0, 
        sll: 0,
        lll: 0, 
        dl: 0 }, r);


    const txtStyle=  {"fontFamily": "Source Sans Pro",
                     "fontStyle": "normal",
                    "fontWeight": "normal",
                    "fontSize": "18px",
                    "lineHeight": "20px",
                    alignSelf: "center"}


    return <View nativeID="bubbleMobile" style={{backgroundColor: "white", 
                    borderRadius: 25, padding: 10, ...containerStyle, marginLeft: "15vw", marginRight: "15vw", marginBottom: "3vh"}}>
                <Text nativeID='bubbleMobileText'
                // @ts-ignore
      //          style={{ width: mobile3 ? "90vw" :"350px", padding: 10, ...txtStyle}}>
                    style={{ width:"350px", padding: 10, ...txtStyle}}>
                    {children}
                </Text>
            </View>
}


const fD1={sp:  "column", 
sl:  "column", 
    mp: "column",
    tp:  "column",
    mtl: "column", 
    tl: "column", 
    sll: "column", 
    lll: "row", 
    dl: "row" };

const mobile1={sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 1, 
    tl: 1, 
    sll: 1,
    lll: 0, 
    dl: 0 };


const mobile2_={sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  0,
        mtl: 0, 
        tl: 0, 
        sll: 0,
        lll: 0, 
        dl: 0 };

const alignPersons1={sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1,
    lll: 0, 
    dl: 0 };

const autScale1={sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 0, 
    tl: 0, 
    sll: 0,
    lll: 0, 
    dl: 1 }




    function AreYouInventorMobile({title, subtitle, firstperson,  secondperson, topImage, bottomImage, })
    {
        const {r} =  useContext(InfoContext);
    
        const fD= rsp(fD1, r);
        const mobile= rsp(mobile1, r);
        const mobile2= rsp(mobile2_,r);

     /*    const alignPersons= rsp(alignPersons1, r);       
        const autScale= rsp(autScale1, r);       */
    
        return <View>
                    <Co  s={{alignSelf: "center"}}>
                        <H3 st={{alignSelf: "center", marginBottom: 20, fontSize: 42, textAlign: "center"}} 
                            w={title?.length/2+"em"} >{title}</H3>
                        <H4 st={{alignSelf: "center", marginBottom: 40, textAlign: "center" }}>
                            {subtitle}</H4>
                    </Co>
                    <Row i="first person mobile" s={{alignSelf: "center" ,
                     // marginTop: 150,
                      flexDirection:fD}}>
                            <Co i="first person first column" s={{marginBottom: 20,  
                                 alignSelf: "center"  }}>
                                <ImageS n="Testimonial First person image" 
                                    source={{uri: firstperson?.link}} imgClassName="maskedByCircle" 
                                        width={firstperson?.width} 
                                        imageAlt={firstperson?.imageAlt}
                                    />
                                <Co i="firstperson.title" s={{marginTop: firstperson?.verticalpositionadjustment, 
                                                                alignSelf: "center"}}>
                                    <H5 st={{alignSelf: "center"}}>{firstperson?.title}</H5>
                                </Co>
                            </Co>
                            {
                                mobile ?
                                    <BubbleMobile>
                                        {firstperson?.bubbletext}
                                    </BubbleMobile>
                                    :
                                    <Co i="left bubble" s={{marginLeft: 50}}  w={400}>        
                                            <Image source={{uri: "images/are_you_inventor/"+(firstperson?.bubbleheight===2 
                                                        ? "bubbleleft_large.svg" : "bubbleleft.svg") }} 
                                                style={{ width: 562*0.75, height: firstperson?.bubbleheight===2 ? 169 : 169*0.75 , 
                                                    resizeMode: 'stretch'}} />                           
                                            <P st={{marginTop: firstperson?.bubbleheight===2 ? -160 :  -100, color: "black", 
                                                    zIndex: 1, fontSize:22}} 
                                                w={300}>{firstperson?.bubbletext}</P>
                                    </Co>
                            }
                            <ImageS n="first person2 additional image" source={{uri: topImage?.link}} 
                                        style={{ width: mobile2? "100vw" : "50vw",                                     
                                        resizeMode: 'stretch', 
                                        marginLeft: 0,  
                                        marginTop:  20,
                                        }} 

                                        containerStyle={{ alignSelf: "center"}}
                                        imageAlt={topImage?.imageAlt}
                                         />
                    </Row>
                    <Row i="second person" s={{marginTop:mobile ? 100: 200, alignSelf:  "center", flexDirection:fD }}>                                                
                            <Co i="right person column" s={{marginLeft: 0, alignSelf: "center" , marginTop: 20}}>
                                <ImageS   n="Testimonial Second person image"  
                                    source={{uri: secondperson?.link || ''}}  imgClassName="maskedByCircle" 
                                        width={secondperson?.width || 140} 
                                        // height={secondperson?.height} 
                                        containerStyle={ { marginLeft: 0, alignSelf: "center"}}
                                        imageAlt={secondperson?.imageAlt}
                                />   
                                <Co i="right bubble text" s={{alignSelf: "center",   marginTop: secondperson?.verticalpositionadjustment}}>
                                    <H5 n="right bubble text h5" st={{alignSelf: "center"}}>{secondperson?.title}</H5>
                                </Co>
                                <BubbleMobile containerStyle={{marginLeft: 20, marginRight: 20, marginTop: 20}} >
                                        {secondperson?.bubbletext}
                                    </BubbleMobile>   
                            </Co>
                            <ImageS n="second person additional image" source={{uri: bottomImage?.link}} 
                                style={{ width: mobile2? "100vw" : "50vw", 
                                        resizeMode: 'stretch', 
                                        marginRight: mobile? 0: 70, 
                                        marginBottom:  20,
                                        marginTop: "20px"}}  
                                        imgClassName="Image2rectangularBackgroundBlur2" 
                                        imageAlt={bottomImage?.imageAlt}
                                        containerStyle={{ alignSelf: "center"}}
                                          />    
                       
                    </Row>
    </View>
    }



function AreYouInventorDesktop({title, subtitle, firstperson,  secondperson, topImage, bottomImage})
{
    const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

    const fD= rsp(fD1, r);
    const mobile= rsp(mobile1, r);
    const alignPersons= rsp(alignPersons1, r);       
    const autScale= rsp(autScale1, r);      

    return <View>
                <Co  s={{alignSelf: "center", marginTop: 100}}>
                    <H3 st={{alignSelf: "center", marginBottom: 20, fontSize: 42, textAlign: "center"}} 
                        w={title?.length/2+"em"} >{title}</H3>
                    <H4 st={{alignSelf: "center", marginBottom: 40, textAlign: "center" }}>
                        {subtitle}</H4>
                </Co>
                <Row i="first person desktop" s={{alignSelf: mobile ? "center":"end" ,
                 marginTop: fD==="row"? 20: 0, flexDirection:fD}}>
                    <Co i="first person first column" s={{marginBottom: mobile? 10:0,  
                        marginRight: alignPersons ? 20 : 0, alignSelf: mobile? "center" : "start" }}>
                        <ImageS n="Testimonial First person image" 
                            source={{uri: firstperson?.link}} imgClassName="maskedByCircle" 
                                width={firstperson?.width} 
                                containerStyle={{marginLeft:  0} }
                                imageAlt={firstperson?.imageAlt}
                               />
                        <Co i="right bubble text" s={{marginTop: firstperson?.verticalpositionadjustment, alignSelf: "center"}}>
                            <H5>{firstperson?.title}</H5>
                        </Co>
                    </Co>
                    {
                        mobile ?
                            <BubbleMobile>
                                {firstperson?.bubbletext}
                            </BubbleMobile>
                            :
                            <Co i="left bubble" s={{marginLeft: 50}}  w={400}>        
                                    <Image source={{uri: "images/are_you_inventor/"+(firstperson?.bubbleheight===2 ? "bubbleleft_large.svg" : "bubbleleft.svg") }} 
                                        style={{ width: 562*0.75, height: firstperson?.bubbleheight===2 ? 169 : 169*0.75 , resizeMode: 'stretch'}} />                           
                                    <P st={{marginTop: firstperson?.bubbleheight===2 ? -160 :  -100, color: "black", 
                                            zIndex: 1, marginLeft: 50, fontSize:22}} 
                                        w={300}>{firstperson?.bubbletext}</P>
                            </Co>
                    }
                    <ImageS source={{uri: topImage?.link}} 
                                style={{ width: autScale ? "35vw" : 327, height: autScale ? "35vh" : 170, resizeMode: 'stretch', 
                                marginLeft: fD==="row" ? 100: 0,  
                                marginTop: fD==="row" ? 0: 100 }}   imageAlt={topImage?.imageAlt}   />
                </Row>
                <Row i="second person" s={{marginTop:200, alignSelf:  mobile ? "center": "start" , flexDirection:fD }}>
                    <ImageS n="second person additional image" source={{uri: bottomImage?.link}} 
                            style={{ width: autScale ? "35vw" : 327, 
                                     height: autScale ? "35vh" : 170, 
                                    resizeMode: 'stretch', 
                                    marginRight: mobile? 0: 70, 
                                    marginBottom: fD==="row" ? 0: 100}}  imgClassName="Image2rectangularBackgroundBlur2" 
                                    imageAlt={bottomImage?.imageAlt}
                                      />
                        {
                                mobile ?
                                <BubbleMobile containerStyle={{margin: 20}} >
                                    {secondperson?.bubbletext}
                                </BubbleMobile>
                                :
                                <Co i="right bubble"  w={400}>
                                    
                                        <Image source={{uri: CLIENT_PATH+"images/are_you_inventor/"
                                        +(secondperson?.bubbleheight===2 ? "bubbleright_large.svg" : "bubbleright.svg")}} 
                                            style={{ width: 562*0.75, height: secondperson?.bubbleheight===2 ? 169 :  169*0.75, 
                                                resizeMode: 'stretch'}}  />
                                    
                                    <P st={{marginTop: secondperson?.bubbleheight===2 ? -150 : -100, 
                                        color: "black", zIndex: 1, marginLeft:   50 , fontSize:22}} 
                                            w={300}>{secondperson?.bubbletext}</P>
                                </Co>
                        }
                        <Co i="right person column" s={{marginLeft: 50, alignSelf: mobile ? "center" : "start", 
                                      }}>
                            <ImageS   n="Testimonial Second person image"  
                                source={{uri: secondperson?.link || ''}}  imgClassName="maskedByCircle" 
                                    width={secondperson?.width || 140} 
                                 
                                    containerStyle={ { marginLeft: 0}}
                                    imageAlt={secondperson?.imageAlt}
                            />   
                            <Co i="right bubble text" s={{alignSelf: "center",   marginTop: secondperson?.verticalpositionadjustment}}>
                                <H5 st={{alignSelf: "center"}}>{secondperson?.title}</H5>
                            </Co>
                        </Co>
                   
                </Row>
</View>
}

export default function AreYouInventor({title, subtitle, firstperson,  secondperson, topImage, bottomImage})
{

    const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

    const mobile= rsp(mobile1, r);

    if(mobile)
        return <AreYouInventorMobile title={title}
                        subtitle={subtitle}
                        firstperson={firstperson}

                        secondperson={secondperson}

                        topImage={topImage}

                        bottomImage={bottomImage} />
    else
        return <AreYouInventorDesktop title={title}
                        subtitle={subtitle}
                        firstperson={firstperson}

                        secondperson={secondperson}

                        topImage={topImage}

                        bottomImage={bottomImage} />
}