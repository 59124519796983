import React, {useState} from 'react';

import SubPage from '../../widgets/subPages/widgetComponents/SubPage'
import WoATop from '../../widgets/subPages/widgets/WorldofArtSubPage/WoA_TopComponent';
import WoATopTop from '../../widgets/subPages/widgets/WorldofArtSubPage/TopTop';
import Footer from '../../widgets/subPages/widgets/Footer';
import AreYouInventor from '../../widgets/subPages/widgets/AreYouInventor';
import { CLIENT_PATH } from  '../../config/serverURLPath.web';

import InfoContext from '../../InfoContext';
import PropTypes from 'prop-types';

export default function WoAsubPage({scrollRef, scroll2State, setScroll2State})
{
    const [planetFixed, setPlanetFixed]=useState(true);
    const [scroll2, setScroll2]=useState(-1);
    let { l,  contentModel: {subpages} } = React.useContext(InfoContext);

    const scrollTpA=ch=>ch === 0 ? setPlanetFixed(true) : setPlanetFixed(false)


    return <SubPage scrollRef={scrollRef}  scrollTopAction={scrollTpA}  setScroll2State={setScroll2State}  
                    scroll2={scroll2} setScroll2={setScroll2}
                    scroll2State={scroll2State}
                    navigationFooterLeftPage="planetInventions3"
                    navigationFooterRightPage="planetEPO2"
                    navigationFooterName="wa.png"
                >
                <WoATop planetFixed={planetFixed} third={ <WoATopTop />}  scroll2State={scroll2State}  />
                <AreYouInventor  title={subpages?.worldart?.testimonials?.title?.[l]}
                      subtitle={subpages?.worldart?.testimonials?.subtitle?.[l]}
                      firstperson={{
                          link: CLIENT_PATH+subpages?.worldart?.testimonials?.firstpersonimage?.[l].link || "",
                          width: subpages?.worldart?.testimonials?.firstpersonimage?.[l]?.width || 140,
                          height: subpages?.worldart?.testimonials?.firstpersonimage?.[l]?.height || 140,
                          title: subpages?.worldart?.testimonials?.firstpersontitle?.[l],
                          subtitle: subpages?.worldart?.testimonials?.firstpersonsubtitle?.[l],
                          bubbletext: subpages?.worldart?.testimonials?.firstpersonp?.[l],
                          verticalpositionadjustment:  subpages?.worldart?.testimonials?.firstpersonverticalpositionadjustment,
                          imageAlt: subpages?.worldart?.testimonials?.firstpersonimage?.[l].imageAlt
                      }}

                      secondperson={{
                          link: CLIENT_PATH+subpages?.worldart?.testimonials?.secondpersonimage?.[l].link || "",
                          width: subpages?.worldart?.testimonials?.secondpersonimage?.[l]?.width || 140,
                          height: subpages?.worldart?.testimonials?.secondpersonimage?.[l]?.height || 140,
                          title: subpages?.worldart?.testimonials?.secondpersontitle?.[l],
                          subtitle: subpages?.worldart?.testimonials?.secondpersonsubtitle?.[l],
                          bubbletext: subpages?.worldart?.testimonials?.secondpersonp?.[l],
                          verticalpositionadjustment:  subpages?.worldart?.testimonials?.secondpersonverticalpositionadjustment,
                          imageAlt: subpages?.worldart?.testimonials?.secondpersonimage?.[l].imageAlt
                      }}

                      topImage={{
                        link:  CLIENT_PATH+subpages?.worldart?.testimonials?.topImage?.link,
                        imageAlt: subpages?.worldart?.testimonials?.topImage?.[l]?.imageAlt
                      }}

                      bottomImage={{
                        link:  CLIENT_PATH+subpages?.worldart?.testimonials?.bottomImage?.link,
                        imageAlt: subpages?.worldart?.testimonials?.bottomImage?.[l]?.imageAlt
                      }} />  
                <Footer scroll2State={scroll2State}  setScroll2={setScroll2}  
                    title={subpages?.worldart?.footer?.title?.[l]}
                    subTitle={subpages?.worldart?.footer?.subTitle?.[l]} 
                    url={subpages?.worldart?.footer?.url?.[l]}  />
            </SubPage>
}

WoAsubPage.propTypes = {
  scrollRef: PropTypes.object.isRequired,
  scroll2State: PropTypes.number.isRequired,
  setScroll2State: PropTypes.func.isRequired,
};

