import React,{useState, useContext} from 'react';
// import {Image} from 'react-native';
import { useResponsiveWidth } from "react-native-responsive-dimensions";
import InfoContext from '../../InfoContext';

// import rsp from "./htmlcomponents/useResponsiveBrk";  // ../../htmlcomponents/useResponsiveBrk

import {  Vw, C,R } from '../../widgets/subPages/htmlcomponents/blocktags';
// @ts-ignore
import { H4, P} from '../../widgets/subPages/htmlcomponents/texttags.web';

 import  { Image } from 'react-native';
// @ts-ignore
// import { Button } from 'react-native-elements';
import Button from '../../multiEnvComponents/ButtonA.web';




const useIsMobile=(test)=>false;


const l='eng';
export default function XRInfo({qr, link})
{
    const {r, contentModel: {infoboxes}, l} =  useContext(InfoContext);


   const [showing, setSHowing]=useState(true);

    const mobileR = useIsMobile( useResponsiveWidth(100));

    const s = {
   
        display: "block",
        position: "absolute",
        zIndex: 100000,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5vh",
        marginLeft: "5vw",
        right: 0,
        marginRight: mobileR ? 5 : 45,
        backgroundColor: "rgba(35, 33, 44, 1)",
        width: "90vw",
        height: "90vh",
        borderRadius: 10
    };

    const mrgns={margin: 5,  alignSelf: "start", alignContent: "center", 
                  justifyItems: "center", width: "50%",     placeItems: "center" };

    const xStyleVw={ 
      flexDirection: "row-reverse",
      width: "90vw",
      paddingTop: 10,
      paddingRight: 10
    }
    const centerText={textAlign: "center", marginBottom: 5}
    const h4w={width: "40%"}
    const marginP={marginTop: 30}

    return <>{ showing && !mobileR && <Vw n="cover" st={s}> <Vw st={{width: "90vw", alignContent: "center"}}>
            <R st={xStyleVw}>   <Image 
                      // @ts-ignore
                      nativeID="x" 
                      source={{
                                    uri: 'graphics2D/x.svg',
                                }}  
                                // @ts-ignore
                                style={{width: 15, height: 15}} onClick={()=> setSHowing(false) }   /></R>
            <R >
                <C st={mrgns}>
                    <Image  source={{uri: infoboxes?.ar?.icon}}  style={{width: 77, height: 77}}   />
                    <H4 st={{...h4w,...centerText}} >{infoboxes?.ar?.title[l]}</H4>
                    <P st={{...marginP,...centerText}}>{infoboxes?.ar?.first[l]}</P>
                    <Image source={{uri: qr}} style={{width: 121, height: 118}}  />
                    <P st={centerText}>{infoboxes?.ar?.second[l]}</P>
                    <P st={centerText}>{link}</P>
                </C>
                <C st={mrgns}>
                    <Image  source={{uri: infoboxes?.v3d?.icon}} style={{width: 77, height: 77}}   />
                    <H4 st={{...h4w,...centerText}}>{infoboxes?.v3d?.title[l]}</H4>
                    <P st={{...marginP,...centerText}}>{infoboxes?.v3d?.first[l]}</P>
                    <Button title={infoboxes?.v3d?.button[l]} onPress={() => setSHowing(false)} touchSoundDisabled={false} />
                </C>
            </R>
          </Vw> </Vw> } </>
}

