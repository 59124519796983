import React, {useContext} from 'react';
import ArtWorkHeader from './ArtWorkHeader';
import InfoContext from '../../../InfoContext';

import "./style.css";

export default function Davar()
{
      const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
    	<ArtWorkHeader  
				artistname="Katja Davar"
                title="Raven 77"
                year="2001"
                material="Screenprint, thread embroidery, nettle"
                measurements="150 x 230 cm" 
        />
  <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Dawar_phong11.text[l]}</p>
    </div>
}

/*
   <p><span>Katja Davar</span></p><p><span>Raven 77</span><span>, 2001</span></p><p><span>Screenprint, thread embroidery, nettle</span></p><p><span>150 x 230 cm</span>
     </p>
*/