import React, {useState} from 'react';
import {View,  Image as ImageRN} from 'react-native';

import styled from 'styled-components';

// import {View as View2} from './rnwComponents.web';


export const MenuBtn =styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    `

/**
 * Creates an HTML image with shadow effect
 * 
 * @export
 * @param {object} props
 * @param {string} props.src
 * @param {number | string} props.width
 * @param {number | string} [props.height]
 * @param {object} [props.style]
 * @param {object} [props.containerStyle]
 * @param {object} [props.n]
 * @param {object} [props.imageAlt]
 * @returns 
 */
export  function ImageS({src, width, height, style, containerStyle, n, imageAlt=""})
{
  //  console.log("alt "+imageAlt);

    return <View nativeID={"ImageS_"+n} style={containerStyle}>
                <View style={{position: "absolute", width: width, height: height, display:"flex", 
                        // @ts-ignore
                        placeContent: "center"
                  }}>
                        <img src={src}  style={{width:width || 100, height: height, 
                        //         "filter": "drop-shadow(0px 4px 204px rgba(174, 153, 182, 0.63))",
                                alignSelf: "center",
                                justifySelf: "center",
                                ...style}}   title={imageAlt}  alt={imageAlt} ></img>
                </View>
            </View>
}


export  function ImageS2({src, width, height, style, containerStyle, n, imageAlt=""})
{
  //  console.log("alt "+imageAlt);

    return <View nativeID={"ImageS"+n} style={containerStyle}>
                <div className="ImageS2" style={{width: width, height: height, display:"flex"}}>
                        <img src={src}  style={{width:width || 100, height: height, 
                              //   "filter": "drop-shadow(0px 4px 204px rgba(174, 153, 182, 0.63))",
                                alignSelf: "center",
                                justifySelf: "center",
                                ...style}}   title={imageAlt}   alt={imageAlt}></img>
                </div>
            </View>
}

/**
 * 
 * 
 * @export
 * @param {object} props
 * @returns 
 */
export  function Image({source: { uri}, title="", width, height, style, containerStyle, n, 
                                onClick, onMouseUp, onMouseEnter, imgClassName, hoverImageSource, imageAlt=""})
{
 //  console.log("alt "+imageAlt);

    const [hover, setHover]=useState(false);

    // @ts-ignore
    return <div nativeid={"Image_"+n} style={containerStyle}>
                <div className={"Image2"+imgClassName } 
                        style={{width: width, height: height, display:"flex"}}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseUp={onMouseUp}

                        onMouseOver={()=> { if(hoverImageSource) setHover(true);  } }  
                        onMouseOut={()=>{ if(hoverImageSource) setHover(false);}}
                        >
                        {   <img src={!hover ? uri : hoverImageSource} title={title || imageAlt} className={imgClassName}
                            style={{width:width || 100, 
                                height: height, 
                                alignSelf: "center",
                                justifySelf: "center",
                                ...style}} alt={imageAlt || title}></img>  }
                </div>
            </div>
}



/**
 * 
 * 
 * @export
 * @param {object} props
 * @returns 
 */
 export  function ImageSVG({source: { uri},  width, height, style, containerStyle, n, onClick, hoverImageSource, aria})
 {
     const [hover, setHover]=useState(false);
 

     // @ts-ignore
     return <MenuBtn nativeid={"ImageSVG_"+n} style={{...containerStyle, width: width, height: height }}   onClick={onClick} 
                        aria-haspopup={aria?.haspopup} 
                        aria-controls={aria?.controls}
                        aria-expanded={aria?.expanded}
                        aria-pressed={aria?.pressed}
                        aria-label={aria?.label}
                          >
                 <View 
                         style={{width: width, height: height, display:"flex"}}
                         // @ts-ignore
                       
                         onMouseOver={()=> {  if(hoverImageSource) setHover(true);  } }  
                         onMouseOut={()=>{ if(hoverImageSource) setHover(false);}}
                         >
                         {   <ImageRN source={{uri: !hover ? uri : hoverImageSource?.uri}} 
                             style={{width:width || 100, 
                                 height: height,  cursor: "pointer",
                
                                 ...style}}></ImageRN>  }
                 </View>
             </MenuBtn>
 }



 /**
 * 
 * 
 * @export
 * @param {object} props
 * @returns 
 */
const ImageSVG2b  
     =
        // @ts-ignore
        styled(Image).attrs({
            className: "image"
        })`
            background-image: url(${p=> p?.source?.uri});

            width: 100%;
            top: 0px;
            right: 0px;
            position: absolute;
            left: 0px;
            height: 100%;
            bottom: 0px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: rgba(0, 0, 0, 0); `;



export function ImageSVG2({source: { uri},  width, height, style, containerStyle, n, onClick, hoverImageSource, title="",onMouseUp, onMouseEnter, imgClassName, alt})
{
        return  <ImageSVG2b  source={{uri}}     
                     title={title || alt} width={width} height={height} style={style} 
                     containerStyle={containerStyle} n={n} 
                     onClick={onClick} onMouseUp={onMouseUp} onMouseEnter={onMouseEnter} imgClassName={imgClassName} 
                     hoverImageSource={hoverImageSource}  alt={alt} 
         />    
}
