import React, { Component} from 'react';

import "./progressbar.css";

class ProgressBar extends Component {
    // constructor(props) {
    //   super(props);
    // }
  
    render() {
      const parent = this.props;

   //-   console.log("progress B :  "+parent.value)
      return (
          <div className="progress-bar" style={parent.style}>
            <div className="bar">
              <div style={{'backgroundColor': parent.color, 'width': parent.value + '%'}}>
              </div>
              <div style={{'backgroundColor': '#d3d3d3', 'width': (100-parent.value) + '%'}}>
              </div>
            </div>
          </div>
      );
    }
  }
  
  export default ProgressBar;
  
  