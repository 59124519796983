import React, {useContext} from 'react';

import InfoContext from '../../../InfoContext';
import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

export default function EPOArtCollection()
{
     const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
    	<ArtWorkHeader  
                title="The EPO art collection"
                subtitle="Four decades of creativity and innovation"
        />
     <div>
    <div>
    
    </div>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Birisdottir.text[l]}</div>
</div>
}

// <p><span>The EPO art collection</span></p>