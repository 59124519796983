import {SphereGeometry, MeshPhongMaterial, Mesh, Color, DataTexture } from 'three';

export default function createFPSdetectObject(scene, fps)
{
        const width = 1024;
        const height = 1024;

        const size = width * height;
        const data = new Uint8Array( 4 * size );
        const color = new Color( 0xffffff );

        const r = Math.floor( color.r * 255 );
        const g = Math.floor( color.g * 255 );
        const b = Math.floor( color.b * 255 );

        for ( let i = 0; i < size; i ++ ) {
            const stride = i * 4;
            data[ stride ] = r;
            data[ stride + 1 ] = g;
            data[ stride + 2 ] = b;
            data[ stride + 3 ] = 255;
        }

        // used the buffer to create a DataTexture
        const texture = new DataTexture( data, width, height );
        texture.needsUpdate = true;

        const geometry = new SphereGeometry( 0.0001, 512, 512 ); 
        const material = new MeshPhongMaterial( { color: 0xffff00,
                                                  alphaMap:texture,
                                                   envMap: texture,
                                                    map: texture,
                                                    reflectivity:1,
                                                    specularMap: texture,
                                                    bumpMap: texture, 
                                                    displacementMap:  texture,
                                                    emissiveMap: texture,
                                                    refractionRatio: 0.2
                                         } ); 
        const sphere = new Mesh( geometry, material ); 
        sphere.scale.set(0.1,0.1,0.1);
        sphere.name="fpsdetect";

        scene.add( sphere );

         let maxFPS;

          setTimeout(()=>{ 
                maxFPS=+fps.getMaxFPS();  

                 if(typeof maxFPS!=="number" || isNaN(maxFPS))
                        maxFPS=3;
            }
            ,1200);   


        setTimeout(()=>{           
                let maxFPS_= +(localStorage.getItem("maxfps") || 3);
         
                if(isNaN(maxFPS_))
                    maxFPS_=3;

                // @ts-ignore
                if(maxFPS_ > maxFPS) 
                {
                    maxFPS=maxFPS_;
                }              

                localStorage.setItem("maxfps", maxFPS+"");

                scene.remove(sphere);
            }
            ,2000);               
}