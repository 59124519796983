import React, { Component } from 'react';

import './VideoMultiEnv.css';

class VideoComp extends Component {
  render() {
    return (
      <div  className="VideoComponent" style={{marginLeft: this.props.marginLeft}} id={"videoTrigg"+this.props.trigg+""}>
          <div style={{backgroundImage: this.props.poster,   zIndex: 300}}>
                 <video width={this.props.size.width} height={this.props.size.height} 
                    autoPlay loop={this.props.loop}  id="videoIntro" preload="auto" muted 
                    controls={this.props.controls} style={this.props.style}>   
                    {/* <Player autoplay  width={this.props.size.width} height={this.props.size.height} loop controls={this.props.controls}>    */}
                        <source src={this.props.src.webm} type="video/webm"/>
                        <source src={this.props.src.mp4} type="video/mp4"/>
                        <source src={this.props.src.mov} type="video/mov"/>
                        <source src={this.props.src.ogg} type="video/ogg"/>
                    {/* </Player> */}
                </video> 
          </div>
      </div>
    );
  }   
}

export default VideoComp;

// loop