import {Euler, Quaternion, Vector3 } from 'three';

// @ts-ignore
import { SCROLL_DIVIDER } from '../components3D/zoomUI';


/**
 * @param {number} scrollValue
 * @param {number} respZ Zooms to responsivness
 * @param {string} zoompage
 * @param {object} animQueue
 * @param {Map} animQueue.queue
 * @param {object} animQueue.group} animQueue
 * @param {any} objFocus
 */
export  function getCameraCoordsBasedOnScroll(scrollValue, respZ=0, zoompage, animQueue, getLookat=true, objFocus, mobile)
{
    const ZConst= 1.2+respZ; 


    let t={x: 0, y: 0, z:0};

    if(!objFocus?.setObj3DinFocus )
        objFocus= {setObj3DinFocus: ()=>{}};   

 
    t={x: -3, y: 0, z:1 };

    const planetZ= mobile ? 0.2 : 1;

    const subPagePlanetY=-0.3;

    if(zoompage==="home")
    {
        switch(scrollValue)
        {
            case 1: 
                return {pos: new Vector3(t.x+3,          t.y,       ZConst+t.z-0.3),       lookat: new Vector3(t.x,      t.y,      t.z),
                                                       jumpPositionLeft:   mobile ? new Vector3(t.x+2, t.y,   ZConst+t.z-0.3) :  
                                                                        new Vector3(t.x+7,      t.y,       ZConst+t.z-0.3),
                                                     jumpPositionRight: mobile ?   new Vector3(t.x-2, t.y,  ZConst+t.z-0.3) : 
                                                                                    new Vector3(t.x-1, t.y,  ZConst+t.z-0.3)};
            case 2:
                return {pos: new Vector3(t.x+3,          t.y,       ZConst+t.z-0.3),        lookat: new Vector3(t.x+3,     t.y,       t.z) ,
                                                                jumpPositionLeft:   mobile ? new Vector3(t.x+2, t.y,   ZConst+t.z-0.3) :  
                                                                        new Vector3(t.x+7,      t.y,       ZConst+t.z-0.3),
                                                                         jumpPositionRight: mobile ?   new Vector3(t.x-2, t.y,  ZConst+t.z-0.3) : 
                                                                                    new Vector3(t.x-1, t.y,  ZConst+t.z-0.3)
                          
                };
            case 3:   
                return {pos: new Vector3(t.x+3,            t.y,       ZConst+t.z-0.3),        lookat: new Vector3(t.x+6, t.y, t.z),     
                                                    jumpPositionLeft:   mobile ? new Vector3(t.x+2, t.y,   ZConst+t.z-0.3) :
                                                                        new Vector3(t.x+7,      t.y,       ZConst+t.z-0.3),
                                                    jumpPositionRight: mobile ?   new Vector3(t.x-2, t.y,  ZConst+t.z-0.3) :
                                                                                    new Vector3(t.x-1, t.y,  ZConst+t.z-0.3)
                                                    };
            default:
               
        }
    }   
    else
    if(zoompage==="planetInventions3")
    {
    
            switch(scrollValue+1)
            {
                case 1:  
                    return {pos: new Vector3(t.x+2, t.y+subPagePlanetY, ZConst+t.z-0.3+planetZ),       lookat: new Vector3(t.x+2, t.y, t.z)};
                case 2:
                case 3:
                case 4:
                case 5:   
                case 6:
                case 7:
                case 8:
                    return {pos: new Vector3(t.x+2, t.y+subPagePlanetY, ZConst+t.z-0.3+planetZ),       lookat: new Vector3(t.x+2, t.y, t.z),
        
                    };
                default:      
            }
    }
    else
    if(zoompage==="planetWorldofArt2")
    {
            switch(scrollValue+1)
            {
                case 1:  
               return  {pos: new Vector3(t.x+2,            t.y+subPagePlanetY,       ZConst+t.z-0.3+planetZ),   lookat: new Vector3(t.x+2, t.y, t.z)};
                case 2:
                case 3:
                case 4:
                case 5:      
                case 6:
                case 7:
                case 8:
                 return  {pos: new Vector3(t.x+2,            t.y+subPagePlanetY,       ZConst+t.z-0.3+planetZ),   lookat: new Vector3(t.x+2, t.y, t.z)};
                default:    
            }
    }
    else
    if(zoompage==="planetEPO2")
    {
            const subPagePlanetX=2;
            switch(scrollValue+1)
            {
                case 1:  
                    return {pos: new Vector3(t.x+subPagePlanetX,            t.y+subPagePlanetY,       ZConst+t.z-0.3+planetZ),        lookat: new Vector3(t.x+subPagePlanetX, t.y, t.z) };       
                case 2:
                case 3:      
                case 4:
                case 5: 
                    return {pos: new Vector3(t.x+subPagePlanetX,            t.y+subPagePlanetY,       ZConst+t.z-0.3+planetZ),        lookat: new Vector3(t.x+subPagePlanetX, t.y, t.z) };
                default:  
            }
    }
    else
    if(zoompage==="galleryentrance")
    {
            return {pos: new Vector3(-2, -1, 6),  lookat: new Vector3(0, 0, 0) };
    }
}


/**
 * @param {any} scrollValue
 */
export function getMenuFromScroll(scrollValue)
{
    switch(scrollValue)
    {
        case 1: return { footerType: 1, menuChoice: 1  }
        case 2: return { footerType: 1, menuChoice: 2  }
        case 3: return { footerType: 1, menuChoice: 3  }
        case 4: return { footerType: 1, menuChoice: 4  }
        case 5: return { footerType: 1, menuChoice: 5  }
        case 6: return { footerType: 1, menuChoice: 6  }
        case 7: return { footerType: 1, menuChoice: 7  }
        case 8: return { footerType: 1, menuChoice: 8  }
        case 9: return { footerType: 1, menuChoice: 9  }
        default: return { footerType: 0, menuChoice: 0  }
    }
}

/**
 * @param {object} scrollBarRef
 * @param {number} value
 */
function setscroll(scrollBarRef, value)
{  
    if(scrollBarRef?.current)
    {
        switch(value+1)
        {
            case 0:
                scrollBarRef.current.scrollTop=0;
                break;
            case 1:
                scrollBarRef.current.scrollTop=1*SCROLL_DIVIDER;
                break;
            case 2:
                scrollBarRef.current.scrollTop=2*SCROLL_DIVIDER;
                break;  
            case 3:
                scrollBarRef.current.scrollTop=3*SCROLL_DIVIDER;
                break;  
            case 4:
                scrollBarRef.current.scrollTop=4*SCROLL_DIVIDER;
                break;  
            default:           
        }
    }
}



export function menuAction({choice, scrollBarRef}){
    setscroll(scrollBarRef, choice);
}


export const AMOUNTSCROLLPOSITIONS=5;

export function scrollIncAction(inc, scrollBarRef){
    let currentScroll=  scrollBarRef.current.scrollTop / SCROLL_DIVIDER;

    if(currentScroll<0)
        currentScroll=AMOUNTSCROLLPOSITIONS;
    else
    if(currentScroll>AMOUNTSCROLLPOSITIONS)
        currentScroll=0;

    setscroll(scrollBarRef, currentScroll+inc);
}


/**
 * @param {any} zoompage
 * @param {{ current: { scrollTop: number; }; }} scrollBarRef
 */
export function zoompageScrollAction(zoompage, scrollBarRef)
{
    switch(zoompage)
        {
            case 'home':
               
                setscroll(scrollBarRef, 3);
                setscroll(scrollBarRef, 0);
                break; 
            case 'planetInventions3':
                setscroll(scrollBarRef, 3);
                setscroll(scrollBarRef, 0);
                break; 
            case 'planetWorldofArt2':
                setscroll(scrollBarRef, 3);
                setscroll(scrollBarRef, 0);
                break; 
            case 'planetEPO2':
                setscroll(scrollBarRef, 3);
                setscroll(scrollBarRef, 0);
                break; 
            case 'galleryentrance':
                setscroll(scrollBarRef, 1);
                setscroll(scrollBarRef, 0);
                break; 
            default:
        }
}


/**
 * @param {string} filename
 */
export function getProgressBarPostionFromList(filename)
{
    switch(filename)
    {
        case "artgallery": 
        case "galleryentrance":
            return new Vector3(12.5, 0,  0);
        default: return new Vector3(0,0,0);
    }
}


function convertToEuler(q)
{
    const qaut=new Quaternion(q?.x, q?.y, q?.z, q?.w);
    const eul=new Euler();
    eul.setFromQuaternion(qaut, "XYZ");
    return eul;
}


export function getArtworkCameraPosFromList(name, configurationModel)
{
    console.log("artwork name "+name);
    let p={x: 0, y: 0, z:0};

    const clickBoxes=configurationModel?.v3d?.clickBoxes?.galleryentrance;
    const locationObj=clickBoxes?.[name];

    if(locationObj)
         p= {...locationObj.position, r: convertToEuler(locationObj.quaternion)};

    return {x: p.x, y: p.y, z: p.z, r: p.r}
}




