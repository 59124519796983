import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";


export default function Wesely()
{
       const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
        <ArtWorkHeader  
				artistname="Michael Wesely"
                title="Maisfeld bei Kienitz"
                year="2003"
                material="C-print, acrylic glass, steel"
                measurements="110 x 140 cm" 
        />
        <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</p>
    </div>
}