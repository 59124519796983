// @ts-ignore

// @refresh reset

import React, {useState, /* useEffect, */ useRef} from 'react';


import { View} from 'react-native';

// import { Vector3 } from 'three';

// import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import InfoContext from '../../InfoContext';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
 
import { Vw, Vw2 } from '../../widgets/subPages/htmlcomponents/blocktags';


// import { Vw } from '../../multiEnvComponents/blocktags';

import Planets from '../screenshtml/planets';
import InteractiveUniverse from '../screenshtml/interactiveUniverse';
import Footer from '../screenshtml/footer2';


import VideoIntroBody from '../screenshtml/VideoIntroBody';

// import {zoompageScrollAction} from '../getCameraCoordsBasedOnScroll.web';
// import { calcLerp } from '../flycontrol/anim/calcLerp';

import FooterFooter from '../../widgets/styledComponents/footerfooter.web'; 


// let doScrollAnim=true;
// const setdoScrollAnim=val=>doScrollAnim=val;

// let currVec= new Vector3(0,0,0);
// let goal= new Vector3(0,0,0);

/**
 * @export
 * @returns 
 */
export default function HomePage({show=true, loop=true, various, scrollTopState, TheCanvas, planetMarginTop= -950})
{
    let { zoompage, r /* , animpage */ } = React.useContext(InfoContext);
    const scrollAnimRef =useRef();


    const scrollRef2 =useRef();

    const [scroll2, setScroll2]=useState(-1);

   const scrollE=(scrollRef2)=>
    {
    // @ts-ignore
        const scrllTp=  (Math.floor(scrollRef2?.current?.scrollTop));

        setScroll2(scrllTp);
    }


    const page={ width: "100vh" };
        
    const mobileR=  rsp({
      sp:  1, 
      sl:  1, 
      mp: 1,
      tp:  1,
      mtl: 0, 
      tl: 0, 
      sll: 0, 
      lll: 0, 
      dl: 0 }, r);  


    
   // scrollTopState=1
    return  <Vw2 n="Homepage_pages2" 
        // @ts-ignore
          st={{ 
                  position:   zoompage==="home" ? 'absolute' : 'relative',  
                  width:  zoompage==="home" ? "100%" :  '100vw',
                  zIndex: 9996,
                   pointerEvents:  (zoompage==="home" || zoompage==="galleryentrance")  ? 'auto': "none", 
                   height: "100vh" 
            }}>
                    <View nativeID="transf" ref={scrollAnimRef} style={{}} >
                          <main style={{color: "#282c34", overflowY:  zoompage==="home" ? "scroll" : "hidden", overflowX: "hidden"
                          ,...(   zoompage==="home" ?  {  height:"100vh" } : {} ) 
                                }}
                                onScroll={()=>scrollE(scrollRef2)} ref={scrollRef2}
                                >
                            { zoompage==="home" && <Vw s={page}><VideoIntroBody show={show} loop={loop}  /></Vw>  }
                            <Vw n="thePlanetsAndCanvas" s={page} st={{pointerEvents:  zoompage==="home" ? 'auto': "none",
                                    ...(   zoompage!=="home" ?  {  height:"100vh" } : {} ) 
                            }} >
                              { zoompage==="home" && <Planets various={various} resetScroll={()=> {            
                              } }         scrollTopState={scrollTopState}  /> }
                                {TheCanvas}
                            </Vw> 
                            { zoompage==="home" && <Vw s={page} n="InteractiveUniversePlace"><InteractiveUniverse various={various}  scrollTopState={scrollTopState} /></Vw>  }
                            { zoompage==="home" && <Vw s={page}  n="FooterPlace" overflowHidden={false}><Footer scrollTopState={scroll2} scr2={{scroll2, setScroll2}}  /></Vw>  }
                          </main>
                    </View>

       

                   {  (!mobileR && scrollTopState>=4 && zoompage==="home" ) && 
                   <View nativeID="footerBottomText" 
                      // @ts-ignore
                      style={{ marginTop: "auto",  
                        backgroundColor: "#23212C", width: (zoompage==="home" ? "100vw" : "calc(100vw - 18px)" ), 
                        height: "6ex", 
                        bottom: 0, position: "fixed"}} >
                    <FooterFooter  mainpage={true}/>                       
                </View> }    
        </Vw2>
    
}



