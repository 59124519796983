import React,{/* useState, */ useContext} from 'react';
import styled from 'styled-components';

import InfoContext from '../../InfoContext';

 import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import {  Vw, C, Vw2, R } from '../../widgets/subPages/htmlcomponents/blocktags';
// @ts-ignore
import { /* H4, */ P} from '../../widgets/subPages/htmlcomponents/texttags.web';

import  { Image } from 'react-native';


import Button from '../../multiEnvComponents/ButtonA.web';
import {CLIENT_PATH  } from '../../config/serverURLPath.web';


const TextContent  =
// @ts-ignore
styled(Vw2).attrs({
    className: "textContent"    
})`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
`;



const tR_={sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1, 
    lll: 1, 
    dl: 1 }

export default function Instructions({ infoBoxState })     //  ,
{
    const {r, l, modalCAct, contentModel } =  useContext(InfoContext);

    const infotext=contentModel?.infoboxes?.applications?.artgallery?.infotext;    

    const title= {
        "fontFamily": "Source Sans Pro",
        "fontSize": "32px",
        "fontStyle": "normal",
        "fontWeight": "600",
        "lineHeight": "36px",
        "letterSpacing": "0px",
        "textAlign": "left"
      };

    const tR=rsp(tR_,r);

            return <>{<>
                <R n="About the exhibition" st={{ flexDirection: tR? "row": "column", 
                    alignSelf: "center", marginTop: "-5vh", marginLeft: "2vw" ,  position: "absolute"}}>
                 {/*    <Image  source={{uri: CLIENT_PATH+"graphics2D/artgallery/howto_navigate/question_sign.svg"}}  
                        style={{width: 36, height: 36}} /> */}
                    <P st={{marginLeft:10}}>{infotext?.about[l]}</P>    
                </R>
                <C st={{alignContent: "center", marginTop: "2vh"}}>
                            <Vw st={{flexGrow: 0, flexShrink: 0}}>
                                    <Image source={{uri: CLIENT_PATH+"graphics2D/artgallery/about_exhibition/exhibition.png"}}  
                                            style={{width: (2.68*12)+"vw", height: (12)+"vw", alignSelf: "center"}}   />
                                        <Vw  st={{width: (2.68*12)+"vw", height: (5)+"vw", alignSelf: "center", marginTop: "-5vw", paddingLeft: "1vw",
                                                backgroundColor: "#263238A6"
                                        }} >
                                            <P st={title}>{infotext?.title[l]}</P>
                                            <P st={title}>{infotext?.subtitle[l]}</P>
                                        </Vw>
                            </Vw>
                          <TextContent n="textContent" st={{marginLeft: "2vw", marginTop: "2vw", marginRight: "2vw",  flexGrow: 1, flexShrink: 1}}>
                                <P st={{marginBottom: 10}}>{infotext?.p1[l]}</P>
                                <P st={{marginBottom: 10}}>{infotext?.p2[l]}</P>
                                <P st={{marginBottom: 10}}>{infotext?.p3[l]}</P>
                            </TextContent>
                            <Vw st={{flexGrow: 0, flexShrink: 0}}>
                            <Button title="OK" onPress={()=> 
                            {   
                                  if(modalCAct?.setModalC)
                                        modalCAct.setModalC(v=>!v);  } }
                                        buttonStyle={{ alignSelf: "center"}}
                             /> 
                            </Vw>
                    </C>
                </>  
           } </>
}
