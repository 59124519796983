import {useContext} from 'react';

import InfoContext from "../../InfoContext";

import { setURL } from '../../App.web';

import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import { C,R } from '../../widgets/subPages/htmlcomponents/blocktags';
// @ts-ignore
import { H4, P} from '../../widgets/subPages/htmlcomponents/texttags.web';
import { Image } from  '../../multiEnvComponents/Image.web'; 
import Button from '../../multiEnvComponents/ButtonA.web';
import PropTypes from 'prop-types';


const h4w={width: "40%"}
const marginP={marginTop: 30}

const tr1= {sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1, 
    lll: 1, 
    dl: 1 };


    
    export default function HoFInfo({infoBoxState})
    {
        const {r, contentModel: {infoboxes}, l, fps: {getMaxFPS}} =  useContext(InfoContext);
        const tR=rsp(tr1,r);
        
        const centerText={textAlign: "center", marginBottom: 5, width: tR ? "" : "80vw"}
        
        const mrgns={margin: 5,  alignSelf: "start", alignContent: "center", 
        justifyItems: "center", width:  tR? "99%": "100%", placeItems: "center" };
        
        
        const base_url = window.location.origin;
        
        return <R n="infoHoFchoices" st={{ flexDirection: tR? "row": "column", alignSelf: "center"}} >
    <C st={mrgns}>
        <Image  source={{uri: infoboxes?.v3d?.icon}} style={{width: 77, height: 77}}   />
        <H4 st={{...h4w,...centerText}}>{infoboxes?.v3d?.title[l]}</H4>
        <P st={{...marginP,...centerText}}>{infoboxes?.v3d?.first[l]}</P>
        <Button title={infoboxes?.radio?.button[l]} onPress={()=> {
            
            setURL(""); 
            
            const tfps = (+localStorage.getItem("maxfps"));
            
            console.log("window.location.href "+base_url+"/hallfame/?tfps="+(tfps || getMaxFPS() || 14));
            
            window.location.href=base_url+"/hallfame/?tfps="+(tfps || getMaxFPS() || 14);
            
            if(infoBoxState?.setInfoBoxState)
            infoBoxState.setInfoBoxState(false); } } 
        
        buttonStyle={{ marginTop: "3rex"}}
        />
    </C>
</R>
}

HoFInfo.propTypes = {
    infoBoxState: PropTypes.object.isRequired,
};