import {LOD, PlaneGeometry, Mesh, MeshBasicMaterial} from "three";
import { URL3D } from '../../config/serverURLPath.web.js';

/**
 * @param {{ material: { name: string; }; add: (arg0: LOD) => void; }} obj
 * @param {import("three").TextureLoader} Tloader
 * @param { object } t
 * @param { object } [color]
 * @param { object } [vr]
 */
export default function attachLODButton(obj, Tloader, t, color=0, vr=false)
{
    const geometry = new PlaneGeometry();

    const material = new MeshBasicMaterial();

    material.transparent=true;
    
    Tloader.load(                         
        color==0 ? URL3D+'textures/icons/information.png' :  URL3D+'textures/icons/information_grey.png',
        
         function(texture2)   {
                material.map=texture2;         
                material.needsUpdate = true;
         });


	const mesh = new Mesh( geometry, material );

    mesh.name= "LODButton_"+obj.material.name;

    if(t)
    {
        mesh.position.copy(t?.pos);
        mesh.rotation.copy(t?.rt);
        mesh.scale.copy(t?.s);
    }
    else
    {
        mesh.position.set(0,0.8,0);
        mesh.scale.set(0.5,0.5,0.5);
    }
           
    
    /** @type {any} */
    let lod;

    if(vr)
        lod=mesh
    else
    {
        const mesh2 = new Mesh();

        lod = new LOD();

        lod.addLevel( mesh, 0 );        
        lod.addLevel( mesh2, 10 );  
    }

    obj.add(lod);
}







export function attachLODTexture(obj, Tloader)
{
    if( obj?.parent?.type && obj.parent.type !=="LOD")
    {
            const name=obj.material.name;

            obj.material = new MeshBasicMaterial();
            obj.material.name=name;

            console.log(URL3D+'textures/LOD/'+name+'_highres.png');

            Tloader.load(                         
                URL3D+'textures/LOD/'+name+'_highres.png'
                ,
                function(texture2)   {      
                    obj.material.map=texture2;
                    obj.material.specularMap=texture2;
              
                    obj.material.needsUpdate = true;
                    obj.material.name=""
                });
    }
}