
import React, { useState, useEffect /* , useContext */} from 'react';
import { useSpring, animated } from 'react-spring';

/**
 * Fade component animation
 * 
 * @export
 * @param {object} props
 * @param {object} props.children
 * @param {object} [props.st] style
 * @param {object} [props.isVisible] trigger
 * @returns 
 */
export default function TriggerFadeInAnim({children, st, isVisible})
{
  const [showing, setShowing] = React.useState(false);

  const styles = useSpring({
    from: { opacity: 0 },
    to: isVisible ? { opacity: 1 } : { opacity: 0 },
    immediate: false,
    onStart: () => {
 //     console.log("onStart");
    },
    onChange: (animation) => {
 /*      console.log("onChange", {
        opacity: animation?.value?.opacity
      }); */

      if(animation?.value?.opacity>0.1)
          setShowing(true);
      else
      if(animation?.value?.opacity===0)
          setShowing(false);
    },
    onRest: () => {}
  });

  return <div style={{...st,  }}>   
        <animated.div id="animated div" style={styles }>
               {showing && children}
        </animated.div>
     </div>;
}

