function landscape(w,h)
{
    return w>h && w/h > 1.2
}


let debugMessage =""
let lastRes="dl";



/**
 * 
 * 
 * @export
 * @param {any} rspObj 
 * @param {any} res 
 * @param {any} [logger] 
 * @returns 
 */
export default function useResponsiveBrk(rspObj, res, logger)
{
    function conlog(msg)
    {
        if(msg!==debugMessage)
        {
        //    console.log(msg);
            debugMessage=msg;
           console.log("r : "+msg);
        }

        if(logger)
        {
            logger();

         //   console.log(rspObj.dl);
        }
    }


    var w = window.innerWidth;
    var h = window.innerHeight;
    
    if(typeof res!=='string')
        res=lastRes;
    else
        lastRes=res;

    if(landscape(w,h) && res) 
    {
        if(w <= 375)
        {         
             conlog(' sl ');
            return rspObj.sl;
        }
        else
        if(w >= 375 && w < 800) 
        {
          
                conlog(' mtl ');
            return rspObj.mtl;                         
        }
        else
        if(w >= 800 && w < 1280) 
        {
          
                conlog(' tl ');
            return rspObj.tl;                         
        }
        else 
        if(w >= 1280 && w < 1599)
        {
          
                conlog(' sll ');
            return rspObj.sll;
        }
        else
        if(w >= 1600 && w < 1920)
        {
          
                conlog(' lll ');
            return  rspObj.lll;
        }
        else
        {
       //      console.log(" w "+w);

              conlog(' dl w '+w);
              return  rspObj.dl;
        }
    }
    else
    {
        if(typeof res=='string')
        {
            if(w <= 375)
            {
            
                    conlog(' sp ');

                return rspObj.sp;
            }
            else
            if(w >= 375 && w < 800) 
            {   
                    conlog(' mp ');
            
                return rspObj.mp;                         
            }
            else
            if(w >= 800) 
            {
            
                    conlog(' tp ');

                return rspObj.tp;       
            }
        }
    }
}


// export function useResponsiveBrkHeight(rspObj, res)
// {
//     var h = window.innerHeight;

//     if(w <= 375)
//     {
      
//          conlog(' sl ');
//         return rspObj.sl;
//     }
//     else
//     if(w >= 375 && w < 800) 
//     {
      
//             conlog(' mtl ');
//         return rspObj.mtl;                         
//     }
//     else
//     if(w >= 800 && w < 1280) 
//     {
      
//             conlog(' tl ');
//         return rspObj.tl;                         
//     }
//     else 
//     if(w >= 1280 && w < 1599)
//     {
      
//             conlog(' sll ');
//         return rspObj.sll;
//     }
//     else
//     if(w >= 1600 && w < 1920)
//     {
      
//             conlog(' lll ');
//         return  rspObj.lll;
//     }
//     else
//     {
      
//           conlog(' dl ');

//           return  rspObj.dl;
//     }
// }
// else
// {
//     if(typeof res=='string')
//     {
//         if(w <= 375)
//         {
        
//                 conlog(' sp ');

//             return rspObj.sp;
//         }
//         else
//         if(w >= 375 && w < 800) 
//         {   
//                 conlog(' mp ');
        
//             return rspObj.mp;                         
//         }
//         else
//         if(w >= 800) 
//         {
        
//                 conlog(' tp ');

//             return rspObj.tp;       
//         }
//     }
// }
// }