import React, {useContext} from 'react';
import ArtWorkHeader from './ArtWorkHeader';
import InfoContext from '../../../InfoContext';

import "./style.css";

export default function Birgisdottir()
{
      const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
        <ArtWorkHeader  
				artistname="Hildigunnur Birgisdóttir"
                title="Untitled for Carl von Linné"
                year="2011"
                material="Showcase, plastics"
                measurements="120 x 126 x 60 cm" 
        />    
      <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Birisdottir.text[l]}</p>
    </div>
}