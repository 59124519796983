import React /* ,{useRef,useContext} */ from 'react';
//- import {View} from 'react-native';


/**
 * @param {object} [props]
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.n] name
 * @param {any} [props.st] style
 * @param {any} [props.className] 
 */
export function C({children, w, h, st, n, className })
{
    // @ts-ignore
    return <div nativeid={(n || "" )}  className={"css-view-rnw "+(className || "")}  style={{flexDirection:"column", 
                overflow: "hidden", width: w, height: h, ...st}} >
             {children}
        </div>
}


/**
 * @param {object} [props]
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.n]
 * @param {any} [props.st] style
 * @param {any} [props.className] 
 */
export function R({children,  w, h, st,  n="" ,  className})
{
    // @ts-ignore
    return <div nativeid={n}  className={"css-view-rnw "+(className || "")}
    style={{flexDirection:"row",  overflow: "hidden",width: w, height: h, ...st}} 
            >
            {children}
        </div>
}






// @ts-ignore
const VwF = React.forwardRef(( {children,  w, h, st, s,   n="" ,  className}, ref_) => {
    // @ts-ignore
   

    // @ts-ignore
    return <div  ref={ref_}  alt={n}  className={"css-view-rnw "+(className || "")}
                    style={{width: w, height: h,  ...s, ...st}} >
              {children}
          </div>
  });

/**
 * @param {object} props
 * @param {any} [props.children]
 * @param {any} [props.w] width
 * @param {any} [props.h] height
 * @param {any} [props.n]
 * @param {any} [props.st] style
 * @param {any} [props.className] 
 * @param {any} [props.ref_] 
 * @param {any} [props.click] 
 */
 export  function Vw({children,  w, h, st,  n="" ,  className, ref_, click})
 {
    // @ts-ignore
    return  <div  ref={ref_}  alt={n}  className={"css-view-rnw "+(className || "")}
                            style={{width: w, height: h, ...st}} onClick={click}>
                {children}
            </div>
    
    
    
    //<VwF children={children}  w={w} h={h} st={st}   n={n}  className={className} ref_={ref_} />;
 }