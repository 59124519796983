import {  Vector3,
    Mesh, BoxGeometry, MeshBasicMaterial,
} from 'three';

const clickSculptures=[
    {n: 'Birgisdottir', p: {x:-5.120981947469932,y:0.0000552304756511271,z:-3.06121608167126}}, 
    {n: 'Birgisdottir1', p: {x: -4.991402096672253, y:-1.4845134918867993, z: -3.131963306521159} },
    {n: 'sculpture', p: {x:-4.402759892141565,y:0.000014413333091923744,z:-7.186495587748954}},
    {n: 'Kintera', p: {x:-4.402759892141565,y:0.000014413333091923744,z:-7.186495587748954}},
    {n: 'TueGreenfortSculpture1', p: {x:-2.628714617970067,y:9.213609783344232e-8,z:-12.20979545798787}},
    {n: 'Panamarenko', p: {x:6.415809874385833,y:3.572663194589718e-8,z:-12.132871308132716}},
    {n: 'Artwork', p:{x:7.3068709283728746,y:3.572663194589718e-8,z:-5.850047204417086}},
    {n: 'Saraceno', p: {x:8.4532277732187,y:3.572663194589718e-8,z:0.01747750822444833}}
    ]

/** @type {any} */
let boxCreated=false;

export default function clickBoxSculpture(name_, obj, controls,  openBox, openBoxSetter, various, scene)
{
 /*          if(clickSculptures.find(n=>n.n===name_[0]))
          { */
             const cs=clickSculptures.find(n=>n.n===name_[0])

            if(cs)
            {
                          name_[1]=name_[0];
      
                          console.log("art work click"+name_[0]);
                          console.dir(obj);
                    
                          const clkBx=obj.parent.children.find(n=>n.name.indexOf("awClickbox_")!==-1);
                          if(!clkBx)
                          {
                               if(openBox)
                                    openBox.visible=false;
                
                                let boxMesh;

                               if(boxCreated)
                                    boxMesh = boxCreated;
                                else
                                {
                                        const ngeometry = new BoxGeometry( 1, 1, 1 );
                                        const nmaterial = new MeshBasicMaterial( { color: 0xffff00,
                                                                                    transparent: true,
                                                                                    opacity: 0.6                                                                                        
                                                                                } );
                                        boxMesh = new Mesh( ngeometry, nmaterial );
                                        boxCreated=boxMesh;
                
                                        scene.add( boxMesh );
                                }
                
                               boxMesh.matrixWorldNeedsUpdate =true;
                
                               boxMesh.name="awClickbox_"+name_[1];
                               
                               console.dir(boxMesh);
                               let size=new Vector3(1,1,1); 
                
                               boxMesh.position.set(cs?.p?.x, cs?.p?.y, cs?.p?.z);
                               boxMesh.scale.set(size.x+0.2, size.y+0.2, size.z+0.2);
                
                               boxMesh.visible=true;
                               
                
                               setTimeout( ()=> {
                                  
                                    openBox=boxMesh;
                
                                    setTimeout( ()=> {
                                              boxMesh.visible=false; 
                                              openBox=false}, 5000);
                                              openBoxSetter(openBox);
                                              
                               }, 200)
                }
                else
                {
                 
              }

          return true;
          } 

}
// }