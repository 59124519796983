import React,{useState,useEffect} from "react";
import { View, Image, Text } from "react-native";


import StyleSheet from 'react-native-media-query';
import { zoompageScrollAction } from "../getCameraCoordsBasedOnScroll.web";

import  Anim2dPlayer from  "../../multiEnvComponents/Anim2dPlayer.web"

import "./topmenu.css";


import {
  Menu,
  MenuOption
} from './menu/menu';


import InfoContext from '../../InfoContext';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import { CLIENT_PATH } from '../../config/serverURLPath.web';
import SiteMap from "../../widgets/styledComponents/Sitemap";



const TextOpt=({children})=>
{
  const {r} = React.useContext(InfoContext);

  const mobileR= rsp({
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 1, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 }, r);  

  const {ids, styles: s} = StyleSheet.create({
    menuText: {
      "fontFamily": "Source Sans Pro",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": 50 * (mobileR ? 0.5 : 1),
      "lineHeight": 90 * (mobileR ? 0.5 : 1),
      "color": "#FFFFFF"
    }
  });

    return <Text style={s.menuText} // @ts-ignore
        dataSet={{ media: ids.menuText }}  nativeID="menutext" >
    {children}
    </Text> 
}

/**
 * [MenuOpt description]
 *
 * @param   {object}  props
 * @param   {object}  props.children  
 * @param   {number}  props.value     
 * @param   {object}  [props.onSelect] 
 * @param   {object}  [props.first]  
 *
 * @return  {object}  JSX
 */
const MenuOpt=({children, value, onSelect, first=false})=> {
        const {various: { variousStates}, r  , vr: {hmOcculusBrowser} } = React.useContext(InfoContext);

        const mobileR= rsp({
          sp:  1, 
          sl:  1, 
          mp: 1,
          tp:  1,
          mtl: 1, 
          tl: 0, 
          sll: 0, 
          lll: 0, 
          dl: 0 }, r);  

    // @ts-ignore
    return <View nativeID="menuoption" style={{
          flexDirection:"row",
          marginLeft: (mobileR || hmOcculusBrowser) ? (first ? 20: 0) : 200,
          marginTop: (first && !hmOcculusBrowser) ? "20vh": 0
        }}>
            <MenuOption value={value}  
                customStyles={{              
                      flexDirection: "row",
                      alignItems: "center"
                }
              }
              onSelect={onSelect}
              >
              {children}
            </MenuOption> 
        </View>
}

let planetImageStyle={width: 132, height: 132};

const Trigger = ({open}) =>  { 
                     const {r,  vr: {hmOcculusBrowser}} = React.useContext(InfoContext);
                    
                     const [animDir, setAnimDir]=useState(false);

                     const mobileR= rsp({
                      sp:  1, 
                      sl:  1, 
                      mp: 1,
                      tp:  1,
                      mtl: 1, 
                      tl: 0, 
                      sll: 0, 
                      lll: 0, 
                      dl: 0 }, r);  


                      useEffect(
                        ()=>{
                          setAnimDir(dir=> !dir);
                        },
                        [mobileR]
                      );

                      return <Anim2dPlayer src={CLIENT_PATH+"graphics2D/anim2d/hamburgermenu.json"}   
                            // eslint-disable-next-line react/style-prop-object
                            autoplay={false} loop={false}  open={open}  speed="-1"  rewindTrigg={animDir}
                            // eslint-disable-next-line react/style-prop-object
                            style={(mobileR || hmOcculusBrowser) ? 'width: 32px; height: 36px;'   : 'width: 43px; height: 48px;'}
   /> 
} 


const TopMenu = ({zIndex=0,  scrollBarRef}) => {

 let {  setZoompage,   vr: {enabledVR, setEnabledVR, hmOcculusBrowser},r, animpage,
            various: { variousStates, variousStatesDispatch}
          , graphicsResolution       
        }
                           = React.useContext(InfoContext);
    useEffect(() => {
      console.log(' variousStates?.siteMapOpened ');
      console.dir(variousStates?.siteMapOpened);
    }, [variousStates?.siteMapOpened]);



      const setanimHomepage=animpage?.setanimHomepage;

      const mobileR= rsp({
        sp:  1, 
        sl:  1, 
        mp: 1,
        tp:  1,
        mtl: 1, 
        tl: 0, 
        sll: 0, 
        lll: 0, 
        dl: 0 }, r);     


        if(rsp({
          sp:  0, 
          sl:  0, 
          mp: 0,
          tp:  0,
          mtl: 1, 
          tl: 0, 
          sll: 0, 
          lll: 0, 
          dl: 0 }, r))
              planetImageStyle={width: 64, height: 64};


        const s = {
          popMenu: {
            display: "flex",
            position: "fixed",
            zIndex: "9999",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 22,
            right: 0,
            marginRight:  (mobileR || hmOcculusBrowser) ? 35 : 55
          }
      };





  return   (
    <View 
// @ts-ignore
    style={{...s.popMenu, zIndex}}  nativeID="TopMenu"  >
        
         <Menu name="topmenu"  Trigger={ Trigger } style={{   
            backgroundColor: variousStates?.siteMapOpened ? "#2D2A38" : "rgba(35, 33, 44, 0.9)",        
                margin: 0 }}  aria="main menu"  
                onClick={ ()=> variousStatesDispatch({type: 'setSiteMapClosed'})  }  
                openTrigger={variousStates?.siteMapOpened}  mobile={mobileR} > 

                {variousStates?.siteMapOpened ?  (<SiteMap />)  
                : 
                (<>
                                
                      <MenuOpt first value={0}  onSelect={() => { 
                        if(enabledVR)
                        {
                          const vrbtn= document.getElementById('VRButton');
                          vrbtn.style.display='none';
                        }
    
                        if(graphicsResolution?.setGraphicsResolution)
                              graphicsResolution.setGraphicsResolution(7);

                        setEnabledVR(false);
                          
                        setanimHomepage(2);
                        setZoompage('home');
                          
                       

                          // setTimeout(()=>{
                            zoompageScrollAction("home", scrollBarRef);
                          // }, 500);
                            
                          }}><TextOpt 
                    // @ts-ignore
                      style={{
                          "fontFamily": "Source Sans Pro",
                          "fontStyle": "normal",
                          "fontWeight": "600",
                          "fontSize": 50 * (mobileR ? 0.5 : 1),
                          "lineHeight": 90 * (mobileR ? 0.5 : 1),
                          "color": "#FFFFFF",
                          marginTop: mobileR ? 0 : 120
                      }}>Home</TextOpt></MenuOpt> 
                      <MenuOpt value={1}  onSelect={() => {
                                setEnabledVR(false);
                                
                                if(graphicsResolution?.setGraphicsResolution)
                                        graphicsResolution.setGraphicsResolution(7);

                                setanimHomepage(1);
                                setZoompage('planetInventions3');
                                zoompageScrollAction("planetInventions3", scrollBarRef);
                                }
                              }>
                        <Image 
                              // @ts-ignore
                              nativeID="hamburgermenuimage" 
                              source={{
                                            uri: 'images/TopMenu/lightblue.png',
                                        }}  
                                        style={planetImageStyle} />
                        <TextOpt>World of inventions</TextOpt>
                      </MenuOpt> 
                      <MenuOpt value={2} onSelect={() => {
                                    setEnabledVR(false);
                                    
                                    if(graphicsResolution?.setGraphicsResolution)
                                        graphicsResolution.setGraphicsResolution(7);

                                    setanimHomepage(1);
                                    setZoompage('planetWorldofArt2');
                                    zoompageScrollAction("planetWorldofArt2", scrollBarRef);
                                    }
                                  }>
                          <Image 
                                  // @ts-ignore
                                  nativeID="hamburgermenuimage" 
                                  source={{
                                                uri: 'images/TopMenu/purple.png',
                                            }}  
                                            style={planetImageStyle} />
                          <TextOpt>World of art</TextOpt>
                        </MenuOpt>
                      <MenuOpt value={3} onSelect={() => {
                                      setEnabledVR(false);
                                      
                                      if(graphicsResolution?.setGraphicsResolution)
                                          graphicsResolution.setGraphicsResolution(7);

                                      setanimHomepage(1);
                                      setZoompage('planetEPO2');
                                      zoompageScrollAction("planetEPO2", scrollBarRef);
                          }}>
                            <Image 
                                        // @ts-ignore
                                        nativeID="hamburgermenuimage" 
                                        source={{
                                                      uri: 'images/TopMenu/green.png',
                                                  }}  
                                                  style={planetImageStyle} />
                          <TextOpt>World of EPO</TextOpt>
                      </MenuOpt> 
                      </>)}
        </Menu>
    </View>
  );
};





export default TopMenu;