import React,{useEffect} from 'react';

import  { View, Text } from '../../multiEnvComponents/rnwComponents.web';

import styled from 'styled-components';

import { CLIENT_PATH } from  '../../config/serverURLPath.web';

import { ImageSVG   } from '../../multiEnvComponents/Image.web';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
import HomePageButton from '../../multiEnvComponents/ButtonA.web.js';

import {VWrspHeightVH} from '../../multiEnvComponents/styleComponents';

import InfoContext from '../../InfoContext';
import PropTypes from 'prop-types';


const Title = ({title, r}) => {    
        // @ts-ignore
        return <Text nativeID="planetsLowerTitle" style={
                {  "fontFamily": "Source Sans Pro",
        "fontStyle": "normal",
        "fontWeight": "700",
        "fontSize":  rsp({sp:  22, 
                           sl: 26, mp: 26, tp: 26, mtl: 26, tl: 32, sll: 32, lll: 32, 
                            dl: 32 }, r),
         "lineHeight": rsp({sp:  22, 
                            sl: 32, mp: 32, tp: 32, mtl: 32, tl: 32, sll: 36, lll: 36, 
                            dl: 36 }, r)+"px", 
        
        textAlign:"center",
        "color": "#FFFFFF", 
        marginBottom: 5}}>
                {title}
        </Text> };
    

    const Text1  =
        styled(Text).attrs({
        className: "Text_attr_s1"    
    })`

     width: 20em;

     @media (max-width: 925px) { 
        width: 95%;
     }

     @media (max-height: 750px) { 
        width: 80%;
     }

     @media (max-height: 450px) { 
        display: none;
     }
`    
    
    // @ts-ignore
    const SubTitle = ({title, r}) => <Text1 nativeID="LowerSubtitlePlanets"  style={
        {  
                        "fontFamily": "Source Sans Pro",
                        "fontStyle": "normal",
                        "fontWeight": "normal",
                        "fontSize": rsp({
                                        sp: 16, sl: 16, mp: 16, tp: 16, mtl: 16, tl: 16, sll: 22, lll: 22, 
                                                dl: 24 }, r, ()=> { 
                                                        // console.log("r "+r) 
                                                }) ,
                                        "lineHeight": 
                                         rsp({ sp: '24px', sl: '24px', mp: '24px', tp: '24px', mtl: '24px', tl: '28px', sll: '28px', lll: '28px', dl: '28px' }, r),
                        "color": "#FFFFFF",
                        alignSelf: "center",
                        textAlign: "center"
                }       
                }>
            {title}
    </Text1>;

export default function Planets({various, resetScroll, scrollTopState})
{
        let {r, l,  contentModel: {homepage}, setZoompage, animpage, vr} = React.useContext(InfoContext);
        
        const { choosedPlanet, setChoosedPlanet, setPlanetHorisontalScrollDirection } = various?.planet || {};
        
        const setanimHomepage=animpage?.setanimHomepage;

        const PlanetImageArray=[{
                                title1:  homepage?.woi?.title1?.[l],
                                title2:  homepage?.woi?.title2?.[l],
                                subtitle: homepage?.woi?.subtitle?.[l],
                                buttonZoomPage: 'planetInventions3'
                        }
                        , {
                                title1:  homepage?.woa?.title1?.[l],
                                title2:  homepage?.woa?.title2?.[l],
                                subtitle: homepage?.woa?.subtitle?.[l],
                                buttonZoomPage: 'planetWorldofArt2'

                        }, {
                                title1:  homepage?.eaw?.title1?.[l],
                                title2:  homepage?.eaw?.title2?.[l],
                                subtitle: homepage?.eaw?.subtitle?.[l],
                                buttonZoomPage: 'planetEPO2'
                        }];

        useEffect(
                ()=>{

                        if(choosedPlanet>=PlanetImageArray.length)
                        {
                                setChoosedPlanet(0);
                        }
                        else
                        if(choosedPlanet<0)
                        {
                                setChoosedPlanet(PlanetImageArray.length-1);
                        }
                },
                [choosedPlanet]);

        const marginBottom1= rsp({ sp: "3rem", sl: "3rem", mp: "3rem", tp: "5rem", mtl: "5rem", tl: "5rem", sll: "5rem", lll: "5rem", dl: "5rem" }, r);   

        const title1={
                "fontFamily": "Source Sans Pro",
                "fontStyle": "normal",
                "fontWeight": "600",
                "fontSize": rsp({sp: 22, sl: 24, mp: 24, tp: 28, mtl: 28, tl: 52, sll:52, lll: 52, dl: 52 }, r),
                "lineHeight":  rsp({
                                sp:  28, sl: 30, mp: 30, tp: 34, mtl: 34, tl: 56, sll: 56, lll: 56, dl: 56 }, r)+"px",
                "textAlign": "center",
                "color": "#FFFFFF",
                "width": rsp({sp:  "60%", 
                                sl: "60%", mp: "60%", tp: "60%", mtl: "60%", tl: "60%", sll: "60%", lll: "60%", dl: "60%" }, r)}

         const mobile= rsp({ sp: 1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 0, sll: 0, lll: 0, dl: 0 }, r);     
         const arrowDistance= rsp({ sp: "10vw", sl:"10vw", mp: "25vw", tp: "30vw", mtl:"5vw", tl: "25vw", sll: "35vw", lll:"35vw", dl: "35vw" }, r);  

         const zp=PlanetImageArray?.[choosedPlanet]?.buttonZoomPage.slice();

         return <View nativeID="_planets_" style={{width: "100vw",  height: mobile ? "90vh" : "100vh", zIndex: 1}}>
                                <View nativeID="left Planet button" style={{position: "absolute", top: mobile? "30vh" : "43vh" , left: arrowDistance, zIndex: 5000}}>
                                                                        <ImageSVG source={{uri: CLIENT_PATH+"graphics2D/arrowleft.svg"}}  
                                                                                onClick={ e=> {                                                                        
                                                                                        e.stopPropagation();
                                                                                        e.preventDefault();

                                                                                        setPlanetHorisontalScrollDirection('l');
                                                                                        
                                                                                        setChoosedPlanet(v => v-1);                                                                                                                                               
                                                                                } }

                                                                        containerStyle={{ width: mobile? 27/2+5+"px": "27px", height:  mobile? 54/3+5+"px" : "54px"}} 
                                                                              
                                                                        style={{ width: mobile? 27/2+"px": "27px", height:  mobile? 54/3+"px" : "54px"}}    tabIndex={scrollTopState===1 ? 1 : -1}  />                             
                                                        </View>   

                               <View nativeID="right Planet button" style={{position: "absolute", top: mobile ?  "30vh" : "43vh", right: arrowDistance, zIndex: 5000 }} >
                                                                        <ImageSVG source={{uri: CLIENT_PATH+"graphics2D/arrowright.svg"}}  
                                                                                        onClick={ e=> { 
                                                                                                
                                                                                                e.stopPropagation();
                                                                                                e.preventDefault();

                                                                                                setPlanetHorisontalScrollDirection('r');
                                                        
                                                                                                setChoosedPlanet(v => v+1);


                                                                                        } }
                                                                                
                                                                                containerStyle={{ width: mobile? 27/2+5+"px": "27px", height:  mobile? 54/3+5+"px": "54px" }}  
                                                                                style={{ width: mobile? 27/2+"px": "27px", height:  mobile? 54/3+"px" : "54px"}}
                                                                                tabIndex={scrollTopState===1 ? 1 : -1}    />
                                                        </View> 


                    <View style={{
                                         flexFlow: 'column', 
                                         alignItems: "center", 
                                         flex: mobile ? "0 0 1" :  "1 1 100%",
                                         justifyItems: "stretch", 
                                         justifyContent: "space-between",
                                         height: "90vh"
                                         }}  nativeID="PlanetworldsTop">


                                <View nativeID='planetTitle1'>          
                                                <View nativeID='planetTitle'                              
                                                        style={{ /* flex: "1", */  display: "flex",   alignItems: "center", 
                                                                // @ts-ignore
                                                                flexFlow: "column", marginBottom: "20px"}}>
                                                <Text nativeID='planetTitleText' 
                                                        // @ts-ignore
                                                                style={title1}>{ PlanetImageArray?.[choosedPlanet]?.title2 }</Text>
                                                </View>     
                                                <View  nativeID="_planetsVW_"
                                        
                                                style={{display: "flex", 
                                                // @ts-ignore
                                                        flexFlow: "row", 
                                                        alignSelf: "center",  alignItems: "center", 
                                                        flex: "20ex 1 auto", 
                                                        marginTop: 0}}>
                                        
                                                        <VWrspHeightVH nativeID="planetView" style={{ width: "40vw"}} 
                                                        // @ts-ignore
                                                        height={40} >          
                                                        </VWrspHeightVH>
                                             
                                                </View>
                                </View> 
                                <View nativeID="bottomTextPlanets" 
                                        // @ts-ignore
                                        style={{alignItems: "center", marginBottom: marginBottom1, height:  mobile ? "": "25vh"}} >
                                        <Title    title={PlanetImageArray?.[choosedPlanet]?.title1}  r={r} />
                                       <SubTitle title={PlanetImageArray?.[choosedPlanet]?.subtitle}  r={r} />
                                       <HomePageButton  title={"Fly to planet"}  
                                                onPress={ ()=>{      
                                                       
                                                        vr.setEnabledVR(false);                                                                              
                                                        setanimHomepage(1);

                                                        setZoompage(zp); 
                                                        
                                                        resetScroll();
                                                }                                               
                                        } 
                                        buttonStyle={{ marginTop: "5vh",  zIndex: 1 }} 
                                        
                                        
                                        tabIndex={scrollTopState===2 ? 1 : -1} 
                                         />
                                </View>
                        </View>        
            </View>
}

Title.propTypes = {
        title: PropTypes.string.isRequired,
        r: PropTypes.any.isRequired
};

SubTitle.propTypes = {
        title: PropTypes.string.isRequired,
        r: PropTypes.any.isRequired
};
Planets.propTypes = {
        various: PropTypes.object.isRequired,
        resetScroll: PropTypes.func.isRequired,
        scrollTopState: PropTypes.any.isRequired
};