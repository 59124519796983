import React, {useState, useEffect} from 'react';
// @ts-ignore
import {ImageS2, Image} from '../../multiEnvComponents/Image';
import { Vw,  C } from "../../widgets/subPages/htmlcomponents/blocktags";
// @ts-ignore
import { H3, P } from "../../widgets/subPages/htmlcomponents/texttags.web";
import {ImageList} from '../../widgets/subPages/widgetComponents/ImageListPart';

import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";
import InfoContext from '../../InfoContext';

import  { createModalTextContent }  from "../multiEnv/createModalContent.web";
import { ModalContext } from '../../multiEnvComponents/modalProvider.web';

export default function InteractiveUniverse({various, scrollTopState})
{
    let {r, l, contentModel: {homepage} } = React.useContext(InfoContext);

    let { handleModal, setModalStyle} = React.useContext(ModalContext);

    const [infoBox, setInfoBoxState]=useState("");


    useEffect(() => {
        let infoBoxName=""

        switch(infoBox)
        {
            case "vrinfo": 
                infoBoxName="InteractiveUniverse_vrinfo";
                break;
            case "arinfo": 
                infoBoxName="InteractiveUniverse_arinfo";
                break;
            case "v3dinfo": 
                infoBoxName="InteractiveUniverse_v3dinfo";
                break;
            default:   
        }
      
        if(infoBoxName)
        {
            setModalStyle({
                // outer1: { left: "8vw" },  
                outer2: { width: "82vw" },
                noscroll: true
            });


            handleModal(
                createModalTextContent(  infoBoxName , false, {infoBox, setInfoBoxState} ), 
                false, 2);

                setInfoBoxState("");
        }


    }, [infoBox]);



    const mobile= rsp({ sp: 1, sl: 1, mp: 1, tp: 1, mtl: 0, tl: 0, sll: 0, lll: 0, dl: 0 }, r);    
    const largew= rsp({sp: 400, sl: 400, mp: 600, tp: 600, mtl: 800, tl: 1024, sll: 1200, lll: 1200, dl: 1200 }, r);
    const marginTop1= rsp({sp: 0, sl: 0, mp: 100, tp: 20, mtl: 30, tl: 30, sll: 30, lll: 30, dl: 30 }, r);   
    const top1= rsp({sp: "9vh", sl: 0, mp: 0, tp: "20%", mtl: "20%", tl: "20%", sll: "20%", lll: "20%", dl: "20%" }, r); 
    const polaroid1width= rsp({sp: 320, sl: 360, mp: 420, tp: 450, mtl: 450, tl: 450, sll: 450, lll: 450, dl: 450 }, r);          
    const polaroid1height = rsp({sp: 205, sl: 230, mp: 269, tp: 289, mtl: 289, tl: 289, sll: 289, lll: 289, dl: 289 }, r);    

    const fntTitle= { "fontSize": rsp({sp: 22, sl: 26, mp: 26, tp: 26, mtl: 26, tl: 32, sll: 32, lll: 32, dl: 42 }, r),
                    "lineHeight": rsp({sp: 22, sl: 32, mp: 32, tp: 32, mtl: 32, tl: 32, sll: 36, lll: 36, dl: 46 }, r) }

    const fntP= {"fontSize": rsp({ sp: 16, sl: 16, mp: 16, tp: 16, mtl: 16, tl: 16, sll: 22, lll: 22, dl: 24 }, r) ,
    "lineHeight": rsp({sp: '24px', sl: '24px', mp: '24px', tp: '24px', mtl: '24px', tl: '24px', sll: '28px', lll: '28px', dl: '30px' },
    r)} 
                    

    const imageListwidth= rsp({sp: 60, sl: 78, mp: 78, tp: 78, mtl: 78, tl: 78, sll: 78, lll: 78, dl: 78 }, r);          
    const imageListheight = rsp({sp: 60, sl: 78, mp: 78, tp: 78, mtl: 78, tl: 78, sll: 78, lll: 78, dl: 78 }, r);    


    const polariodStyle = mobile ?  {alignSelf: "center", marginTop:5}  :  {position: "absolute", top: -100, left: -180}  

    return <Vw n="InteractiveUniverse" st={{ marginTop: marginTop1, height:  mobile ? "" :"100vh", width: largew, 
                                        alignSelf: "center", overflow: "visible"}}>
                <Vw n="allmostTopBox" st={{alignSelf: "center", justifySelf: "center", width: largew, top: top1, overflow: "visible"}}>
                    <C n="outerColumn" st={{width: largew, alignSelf: "center", justifySelf: "center"}}>
                        <Vw n="titleBox" st={{alignSelf: "center"}}>
                            <H3 n="title" st={{textAlign: "center", width: largew*0.5, ...fntTitle }}>
                            {homepage?.interactiveuniverse?.title?.[l]}</H3>
                        </Vw>
                        <P  n="pBox" st={{alignSelf: "center", textAlign: "center",  marginTop: 20, marginBottom: mobile ? 0: 60, width: largew*0.5, ...fntP}}>
                        {homepage?.interactiveuniverse?.subtitle?.[l]}</P>
                        <Vw n="imageListBox" st={{alignSelf: "center"}}>
                            <ImageList type={1} imageList={homepage?.interactiveuniverse?.imagelist?.[l]} 
                                width={imageListwidth}
                                height={imageListheight}
                                marginBetween={mobile ? 15 : 20}
                                transition={false}
                                setZoomPage={p=>{                
                                    setInfoBoxState(p)
                                }}
                                tabIndex={scrollTopState===3 ? 2 : -1} 
                        /></Vw>
                    </C>
            { !mobile && <ImageS2 width={140} height={140} src="images/interactiveuniverse/planetwireframe.png" 
                                                        containerStyle={{position: "absolute", top: 0, left: -255}} /> }
                                            <Image n="polaroid" width={polaroid1width} height={polaroid1height}  imgClassName="maskedByCircle" 
                                                  source={{uri: homepage?.interactiveuniverse?.freePlacedImage1?.[l]}}  
                                                  containerStyle={polariodStyle}   imageAlt="a person holding a mobile phone" /> 
                                    { !mobile && 
                                        <Image width={250} height={166} 
                                            source={{uri: homepage?.interactiveuniverse?.freePlacedImage2?.[l]}}  
                                            imgClassName="maskedByCircle" 
                                                        containerStyle={{position: "absolute", top: 340, left: 950}}  
                                                        imageAlt="vr glasses"  /> }
            { !mobile && <ImageS2 width={200} height={200} src="images/interactiveuniverse/planetwireframe.png" 
                                                    containerStyle={{position: "absolute", top: 370, left: 1150}} /> }
        </Vw>
    
    </Vw>
}