/**
 * [galleryAnimList description]
 *
 * @var { {
    position: {
        x: number;
        y: number;
        z: number;
    };
    quaternion: {
        x: number;
        y: number;
        z: number;
        w: number;
    };
}[]}
 */
export const galleryAnimList=[
    {position: {x:-2.6469078334411664,y:-1,z:6},quaternion:{x:0,y:0,z:0,w:1}},
    {position: {x:-2.6469078334411664,y:-1,z:6},quaternion:{x:0,y:0,z:0,w:1}},
    {position: {x:-2.6469078334411664,y:-1,z:6},quaternion:{x:0,y:0,z:0,w:1}},
    {position: {x:-3.0016969981652886,y:-0.6985345125552997,z:2.4837482686135814},quaternion:{x:0.044453585642424395,y:0.6040649661312308,z:-0.03377815166560039,w:0.794977000853773}},
    {position: {x:-3.0016969981652886,y:-0.6985345125552997,z:2.4837482686135814},quaternion:{x:0.044453585642424395,y:0.6040649661312308,z:-0.03377815166560039,w:0.794977000853773}},
    {position: {x:-2.411291743556045,y:-1.039061106072908,z:4.411187807125901},quaternion:{x:0.027822120743584516,y:-0.0013452880694512811,z:0.00003744329570403397,w:0.9996119839194305}},
    {position: {x:-2.411291743556045,y:-1.039061106072908,z:4.411187807125901},quaternion:{x:0.027822120743584516,y:-0.0013452880694512811,z:0.00003744329570403397,w:0.9996119839194305}},
    {position: {x:-2.3612513524570824,y:-1.1596905130866368,z:0.2673432424193736},quaternion:{x:0.009006372175268161,y:-0.01260262751684911,z:0.00011351757410941033,w:0.9998800158787424}},
    {position: {x:-2.345496819996753,y:-1.0441595726320014,z:-2.01391354715801},quaternion:{x:-0.031634297923750145,y:0.6924681521171318,z:0.030419898527900616,w:0.7201123240661225}},
    {position: {x:-2.345496819996753,y:-1.0441595726320014,z:-2.01391354715801},quaternion:{x:-0.031634297923750145,y:0.6924681521171318,z:0.030419898527900616,w:0.7201123240661225}},
    {position: {x:-2.242058521040415,y:-1.0441595726320014,z:-4.655681261695759},quaternion:{x:-0.031634297923750145,y:0.6924681521171318,z:0.030419898527900616,w:0.7201123240661225}},
    {position: {x:-2.242058521040415,y:-1.0441595726320014,z:-4.655681261695759},quaternion:{x:-0.031634297923750145,y:0.6924681521171318,z:0.030419898527900616,w:0.7201123240661225}},
    {position: {x:-2.1207418295346114,y:-1.0793209635064631,z:-4.589673810920635},quaternion:{x:0.010967568324541722,y:0.29396374196538977,z:-0.003373345759365788,w:0.9557476923294892}},
    {position: {x:-2.1207418295346114,y:-1.0793209635064631,z:-4.589673810920635},quaternion:{x:0.010967568324541722,y:0.29396374196538977,z:-0.003373345759365788,w:0.9557476923294892}},
    {position: {x:-2.962132788927141,y:-1.2129670778768957,z:-3.439090542941053},quaternion:{x:0.08262424108913234,y:0.11254804098535465,z:-0.009391604427933119,w:0.9901605784018412}},
    {position: {x:-1.5122571639745441,y:-0.9588489594931503,z:-6.531038638678886},quaternion:{x:0.05272936900577713,y:-0.3086637920443399,z:0.017140309877577333,w:0.9495538357051475}},
    {position: {x:-1.5122571639745441,y:-0.9588489594931503,z:-6.531038638678886},quaternion:{x:0.05272936900577713,y:-0.3086637920443399,z:0.017140309877577333,w:0.9495538357051475}},
    {position: {x:-1.6913153314565368,y:-0.6242946120073374,z:-5.408833432443936},quaternion:{x:0.02633119719233956,y:-0.7143061382313469,z:0.026914717960571544,w:0.6988197241753704}},
    {position: {x:-1.6913153314565368,y:-0.6242946120073374,z:-5.408833432443936},quaternion:{x:0.02633119719233956,y:-0.7143061382313469,z:0.026914717960571544,w:0.6988197241753704}},
    {position: {x:-2.1706523301237013,y:-0.866203914145716,z:-6.921652587207501},quaternion:{x:-0.04856420974962118,y:0.0013503682913897695,z:0.00006565710027930537,w:0.9988191476568787}},
    {position: {x:-2.1706523301237013,y:-0.866203914145716,z:-6.921652587207501},quaternion:{x:-0.04856420974962118,y:0.0013503682913897695,z:0.00006565710027930537,w:0.9988191476568787}},
    {position: {x:-2.335248015440981,y:-1.2686504077027572,z:-8.18874384974047},quaternion:{x:-0.0717245807124934,y:-0.0113386954598092,z:-0.0008154161280748812,w:0.9973596911863941}},
    {position: {x:-2.8665083178613218,y:-1.180631565769089,z:-17.420750036677372},quaternion:{x:-0.001440132387661372,y:0.7161117194425671,z:0.0014775377492782724,w:0.697982627418416}},
    {position: {x:-2.8665083178613218,y:-1.180631565769089,z:-17.420750036677372},quaternion:{x:-0.001440132387661372,y:0.7161117194425671,z:0.0014775377492782724,w:0.697982627418416}},
    {position: {x:-1.6885888117041068,y:-0.627244252097134,z:-18.208412984102996},quaternion:{x:-0.049159812326107895,y:-0.011010372459978301,z:-0.0005419560797167324,w:0.998730089080108}},
    {position: {x:-1.6885888117041068,y:-0.627244252097134,z:-18.208412984102996},quaternion:{x:-0.049159812326107895,y:-0.011010372459978301,z:-0.0005419560797167324,w:0.998730089080108}},
    {position: {x:-2.169880396071597,y:-0.6629617070297632,z:-17.318479214827878},quaternion:{x:0.035987567846988644,y:-0.7132112806113523,z:0.0367160142796506,w:0.6990611550247874}},
    {position: {x:-2.169880396071597,y:-0.6629617070297632,z:-17.318479214827878},quaternion:{x:0.035987567846988644,y:-0.7132112806113523,z:0.0367160142796506,w:0.6990611550247874}},
    {position: {x:-3.4985380451657226,y:-0.7181677209654008,z:-14.132589664464046},quaternion:{x:0.035987567846988644,y:-0.7132112806113523,z:0.0367160142796506,w:0.6990611550247874}},
    {position: {x:-3.4985380451657226,y:-0.7181677209654008,z:-14.132589664464046},quaternion:{x:0.035987567846988644,y:-0.7132112806113523,z:0.0367160142796506,w:0.6990611550247874}},
    {position: {x:1.584889962205101,y:-0.5173663434800627,z:-13.866322239918786},quaternion:{x:0.028618447238058557,y:-0.6662276990451556,z:0.025600761596365432,w:0.7447591815535849}},
    {position: {x:4.099615803567353,y:-0.7875558564983282,z:-18.642837523219825},quaternion:{x:-0.01800830171699911,y:-0.7918830513525671,z:-0.023379378073904982,w:0.6099592926832902}},
    {position: {x:5.58030693380807,y:-1.1464475975248969,z:-13.971570622924377},quaternion:{x:0.020727895113857007,y:-0.908961015034472,z:0.04552366448104709,w:0.41386933141254734}},
    {position: {x:8.489991558175587,y:-0.45559739424704987,z:-11.052600080142973},quaternion:{x:0.023144762836322608,y:-0.7079312422090952,z:0.02322386756610534,w:0.705519899246513}},
    {position: {x:8.489991558175587,y:-0.45559739424704987,z:-11.052600080142973},quaternion:{x:0.023144762836322608,y:-0.7079312422090952,z:0.02322386756610534,w:0.705519899246513}},
    {position: {x:8.96505118846529,y:-0.6051367678340017,z:-12.548341252180435},quaternion:{x:0.000058338833684542295,y:-0.9955244210941664,z:0.09450262385295258,w:0.0006145621281555746}},
    {position: {x:8.96505118846529,y:-0.6051367678340017,z:-12.548341252180435},quaternion:{x:0.000058338833684542295,y:-0.9955244210941664,z:0.09450262385295258,w:0.0006145621281555746}},
    {position: {x:5.847955821922095,y:-0.5977707290737794,z:-12.545903925488101},quaternion:{x:0.000046512871545699466,y:-0.997157271130355,z:0.07534583957877947,w:0.0006155701273286048}},
    {position: {x:5.847955821922095,y:-0.5977707290737794,z:-12.545903925488101},quaternion:{x:0.000046512871545699466,y:-0.997157271130355,z:0.07534583957877947,w:0.0006155701273286048}},
    {position: {x:5.940821502832156,y:-0.472506825943421,z:-9.200091545876896},quaternion:{x:0.0013982149089574944,y:-0.9981140275405598,z:0.05610418455349436,w:0.02487475622814561}},
    {position: {x:6.594433520529914,y:-0.4568472809940307,z:-4.35914587039821},quaternion:{x:0.011207278015385047,y:-0.9829702841722328,z:0.17185662755278083,w:0.06410239402723718}},
    {position: {x:6.594433520529914,y:-0.4568472809940307,z:-4.35914587039821},quaternion:{x:0.011207278015385047,y:-0.9829702841722328,z:0.17185662755278083,w:0.06410239402723718}},
    {position: {x:8.707628055854617,y:-0.3637698788969006,z:-2.0027843826862117},quaternion:{x:0.010397065132238252,y:-0.8756103559246707,z:0.018866466228764863,w:0.48253752401873956}},
    {position: {x:8.707628055854617,y:-0.3637698788969006,z:-2.0027843826862117},quaternion:{x:0.010397065132238252,y:-0.8756103559246707,z:0.018866466228764863,w:0.48253752401873956}},
    {position: {x:9.068135510990958,y:-0.8031357384301192,z:0.5715879892205026},quaternion:{x:0.029096581286612893,y:-0.9246334245364463,z:-0.07216149116079978,w:-0.37282588212310963}},
    {position: {x:5.08435020535588,y:-1.0516673266966614,z:4.827358293628865},quaternion:{x:0.0015658682202025428,y:-0.734492763776466,z:-0.001694809208243736,w:-0.6786125961389603}},
    {position: {x:2.3121001535157615,y:-1.3836461842110162,z:5.074262539249316},quaternion:{x:0.010759343358590773,y:-0.7305050434025134,z:-0.011512320526543005,w:-0.6827254825843777}},
    {position: {x:2.3121001535157615,y:-1.3836461842110162,z:5.074262539249316},quaternion:{x:0.010759343358590773,y:-0.7305050434025134,z:-0.011512320526543005,w:-0.6827254825843777}},
    {position: {x:1.3222256959032361,y:-1.5695427302476699,z:4.955711173400775},quaternion:{x:0.009810278350776245,y:-0.8335677198343665,z:-0.01388318323434219,w:-0.552155659313662}}
]