import React, {useState, useEffect } from 'react';

import {
  Text,
  View,
//  ImageBackground,
  TextInput,
  Image,
} from 'react-native';

import { Hoverable } from 'react-native-web-hover';

// - import { Btton} from "react-native-elements";
import  Button  from '../multiEnvComponents/ButtonA.web';

import bcrypt from 'bcryptjs';

import EPOSmallLogo from '../components3D/widgets/EPOSmallLogo';

const CHOOSENPASSWORD="$2a$08$EHiaahWCPuq.OiwO5Ois.OhQ3Z9T6FO3m2k9U7GXYA0B5Jjmfu8vq";

function checkPassword(w, setLoggedIn)
{
    if(bcrypt.compareSync(w, CHOOSENPASSWORD))
        setLoggedIn(true)
    else
        setLoggedIn(false);
}


function Inp({ setPassword, hovered, checkPasswordL})
{
  const [secure, setSecure]=useState(true);
  const [borderStyle, setBorderStyle]=useState("black");

  useEffect(()=>
  {
      setBorderStyle("1px solid "+(hovered ? "#616161": "#CFD8DC") );
  },[hovered]);

return <><
// @ts-ignore
WrappedInput  onChange={e=>setPassword(e.target.
    // @ts-ignore
    value)}	

style={{ padding: 0,       
    paddingLeft: "1em",
  // @ts-ignore
  "borderTop": borderStyle,
  "borderRight": borderStyle,
  "borderBottom": borderStyle,
  "borderLeft":  borderStyle,
  borderRadius: 4,            
  width: "100%",
  height: 30
  }}

containerStyle={{marginBottom:"1ex",  
      padding: 0,
      margin: "10px 0px",
                }}	 
placeholder="Enter password" secureTextEntry={secure} 



placeholderStyle={{
  "fontFamily": "Source Sans Pro",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "16px",
  "lineHeight": "24px",
  "display": "flex",
  "alignItems": "center",
  "color": "#757575"
}}

onFocus={
  e=>
  {
    // @ts-ignore
    e.target.style.boxShadow="0px 0px 0px 2px #00E5FF";
  }
}

onBlur = {
  e=>
    {
      // @ts-ignore
      e.target.style.boxShadow="none";

      checkPasswordL(); 

    }
}

nativeID="input_"
/>
{ secure ? <Image source={{uri:  "graphics2D/eye-solid.svg"}} 
                        style={{width: 16, 
                                height: 16,
                                alignSelf: "flex-end",
                                position: "relative",
                                marginTop: -22,
                                marginRight: 10}} 
                                
                                // @ts-ignore
                                onClick={()=>setSecure(false)} />:
          <Image source={{uri:  "graphics2D/eye-slash-regular.svg"}} 
                          style={{width: 16, 
                                height: 16,
                                alignSelf: "flex-end",
                                position: "relative",
                                marginTop: -22,
                                marginRight: 10}} 

                                // @ts-ignore
                                onClick={()=>setSecure(true)}  
                                />    }                  
</>
}



const WrappedInput = ({
  style,
  placeholderStyle,
  value,
  ...rest
}) => (
  <TextInput
    {...rest}
    style={!value ? [style, placeholderStyle] : style}
  />
);



export default function LoginScreen({setLoggedIn}) {
  const [password, setPassword]=useState('');

  return (<>
    <View // @ts-ignore
    style={{width: 133, height: 65,
          position: 'fixed', zIndex: 29998}}>
          <EPOSmallLogo />
  </View>
  <View nativeID="frontPage"
  //  source={{uri: "images/loginbackground.jpg"}}
            // @ts-ignore
            style={{ position: "fixed", width: "100%", height: "100%", 
                    alignContent:"center", justifyContent:"center", zIndex:900,
                     backgroundColor: '#2c2936', alignSelf: "center",
                      // @ts-ignore
                      "background": "linear-gradient(180deg, #302D3A 2.08%, #23212C 95.83%, #000000 100%)",
                                   "boxShadow": "0px 4px 204px rgba(174, 153, 182, 0.63)"
                       }}>
                <Image source={{uri: "graphics2D/lock.svg"}} 
                        style={{width: 36, 
                                height: 48,
                                alignSelf: "center",
                                position: "relative",
                                marginBottom: 36}} />
                <Text style={{  "fontFamily": "Source Sans Pro",
                                  "fontStyle": "normal",
                                  "fontWeight": "bold",
                                  "fontSize": 28,
                                  "lineHeight": 42,
                                  "textAlign": "center",
                                  "color": "#FFFFFF",
                                  marginBottom: 10
                                     }}>This site is password protected</Text>  
                <Text style={{
               "fontFamily": "Source Sans Pro",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "fontSize": 16,
                "lineHeight": 24,
                "textAlign": "center",
                "color": "#FFFFFF",
                marginBottom: 50
                }}>Enter the correct password below to access</Text>
                <View  nativeID="loginBox"  
// @ts-ignore
                style={{
                             width: "20em", 
                             // @ts-ignore
                             justifySelf: "center",
                             alignSelf: "center", 
                             paddingTop: 18,
                             paddingBottom: 18,
                             paddingLeft: 20,
                             paddingRight: 20,
                             borderRadius: 3, 
                             zIndex: 900, 
                             backgroundColor: "white",
                              // @ts-ignore
                              "border": "1px solid #CFD8DC",
                                    "boxSizing": "border-box",
                                  "boxShadow": "0px 16px 32px rgba(69, 90, 100, 0.3)",                               
                                  }}>                         
                              <View 
// @ts-ignore
                              style={{ alignSelf: "center", width: "16em",  margin: "10px 0px"}}>
                                    <Text style={{
                                      "fontFamily": "Source Sans Pro",
                                      "fontStyle": "normal",
                                      "fontWeight": "600",
                                      "fontSize": 16,
                                      "lineHeight": 22,
                                      "display": "flex",
                                      "alignItems": "center",
                                      "color": "#0B0C0C",
                                    }}>Password</Text>
                                    <Hoverable>
                                      {({ hovered }) => (
                                        <Inp setPassword={setPassword} hovered={hovered} checkPasswordL={()=> checkPassword(password,  setLoggedIn) }   />
                                      )}
                                    </Hoverable>
                                    </View>

                              <Button title="Enter" 
// @ts-ignore
                              style={{ padding: 2 }}
        buttonStyle={{
          backgroundColor: "#066AE3",
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
          marginTop: 20
        }}
        // @ts-ignore
        iconContainerStyle={{
          width: 62,
          alignSelf: "center", margin: "3px",
        }}

        titleStyle={{
          "fontFamily": "Source Sans Pro",
          "fontStyle": "normal",
          "fontWeight": "600",
          "fontSize": 16,
          "lineHeight": 22,
          "display": "flex",
          "alignItems": "center",
          "textAlign": "center",
          "color": "#FFFFFF",
        }}

        onPress={() => checkPassword(password, setLoggedIn)} touchSoundDisabled={false}                              />

                                          
                </View>
          </View>
          <Text 
                nativeID="copyright"
                // @ts-ignore
                style={{"color":"rgb(255, 255, 255)","height":"1em",
                "position":"fixed","bottom":"5vh","textAlign":"center",
                "width":"100%", marginTop: 2, zIndex: 901}}>EPO 2021 . All right reserved</Text>  
          </>);
}

