import React, {useState} from 'react';

import {useSpring, animated} from 'react-spring';

import styled  /*+ , {ThemeProvider}  */ from 'styled-components';

export const Btn =styled(animated.button).attrs({
	className: "animated_buttn"
})`
  font-size: 18px;   // dl
  min-height: 36px;
  padding-left: 14px;
  padding-right: 14px;


   @media (max-width: 1919px) { // sll
          font-size: 16px;
          lineheight: 22px;
          height: 30px;
          padding-left: 12px;
          padding-right: 12px;
      }

  @media (max-width: 800px) {  // sp, mp
          font-size: 18px;
          height: 42px;
          padding-left: 12px;
          padding-right: 12px;
      }
    `

/**
 * 
 * 
 * @param {object} props 
 * @param {string} [props.title] 
 * @param {object} [props.buttonStyle] 
 * @param {object} [props.extrastyle] 
 * @param {boolean} [props.disabled] 
 * @param {object} [props.onPress] 
 * @param {number} [props.tabIndex ] 
  * @param {object} [props.containerStyle ] 
 */
export default function ButtonA({title, buttonStyle, disabled, onPress=()=>{console.log("click")}, tabIndex, containerStyle })
{
    const [clicked, set] = useState(false)
    const [bgColor, setBgColour]=useState( "#B3E5FC");
    const { color } = useSpring({ color: clicked ? "#254a77" : "##455A64" });

    return   <Btn style={{  
    "fontFamily": "Source Sans Pro",
    "fontStyle": "normal",
    "fontWeight": "600",
    "textAlign": "center",
    "color": disabled? "#eeeeee": "black",
      border: "none", borderRadius: 4,   
              ...buttonStyle, backgroundColor: clicked ?  color :  ( disabled? "#dddddd":  bgColor), ...containerStyle
            }} 
            
              onClick={onPress }  
              onMouseEnter={() => setBgColour("#81D4FA")}
              onMouseLeave={() => setBgColour("#B3E5FC")}
              
              onMouseDown={() => 
              {
                set(true)}
              }

             onMouseUp={() => 
                set(false)}  disabled={disabled}

            tabIndex ={tabIndex }
     >{title}</Btn>
}

