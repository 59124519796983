// @ts-nocheck
// import React from 'react';
import { View } from './rnwComponents.web';
import styled  /*+ , {ThemeProvider}  */ from 'styled-components';



/**
 * Responsive height component
 *
 * @param   {object} props  
 * @param   {number} props.height  
 * 
 * @return  {[type]}        [return description]
 */
export const VWrspHeightVH =styled(View).attrs({
	className: "rResponsive_height"
})`
	@media (max-height: 900px) { 
			max-height: ${p=>p.height}vh;
	}

	@media (max-height: 750px) { 
			max-height: ${p=>p.height*0.8}vh;
	}

	@media (max-height: 650px) { 
			max-height:  ${p=>p.height*0.7}vh;
	}

	@media (max-height: 550px) { 
			max-height:${p=>p.height*0.6}vh;
	}

	@media (max-height: 450px) { 
			max-height:${p=>p.height*0.5};
	}

	@media (max-height: 350px) { 
			max-height:${p=>p.height*0.4};
	}

	@media (max-height: 250px) { 
			max-height:${p=>p.height*0.3};
	}
`;



/* 


   let sp=  window.matchMedia("(orientation: portrait) and (max-width: 375px)");  
        
        let mp=  window.matchMedia("(orientation: portrait) and (min-width: 376px) and (max-width: 800px)");  

        let tp=  window.matchMedia("(orientation: portrait) and (min-width: 801px)");

        let sl = window.matchMedia("(orientation: landscape) and (max-width: 375px)");
        let mtl=  window.matchMedia("(orientation: landscape) and (min-width: 376px) and (max-width: 800px)");
        let tl=  window.matchMedia("(orientation: landscape) and (min-width: 801px) and (max-width: 1280px)");
        let sll=  window.matchMedia("(orientation: landscape) and (min-width: 1281px) and (max-width: 1599px)");
        let lll=  window.matchMedia("(orientation: landscape) and (min-width: 1600px) and (max-width: 1920px)");
        let dl=   window.matchMedia("(orientation: landscape) and (min-width: 1921px)");

*/
