/* eslint-disable react/jsx-pascal-case */
import React,{useState, useEffect, useRef} from 'react';

import { View } from 'react-native';

// import DevelopEditTool from './components3D/widgets/DevelopEditTool';   //./widgets/DevelopEditTool

import Extra from './screens/ExtraScreen';
import WorldOfInventions from './screens/subPages/WorldOfInventorsSubPage';
import WorldOfArt from './screens/subPages/WorldsofArtSubPage';
import EPOatWork from './screens/subPages/EPOatWorkSubPage';

import AR_Radio from './screens/AR/radio';
import AR_Gallery from './screens/AR/galleryar';

import AR_Leitner from './screens/AR/leitner';
import AR_Saraceno from './screens/AR/saraceno';
import AR_Birgisdottir from './screens/AR/birgisdottir';
import AR_Panamarenko from './screens/AR/panamarenko';
import AR_Kintera from './screens/AR/kintera';



import EPOSmallLogo from './components3D/widgets/EPOSmallLogo';

import TopMenu from "./components3D/widgets/TopMenu";

// import InfoVR from './components3D/widgets/InfoVR'

// @ts-ignore
import { STATIC_PATH } from  './config/serverURLPath.web';

import { topPagespointerEventsE }  from './components3D/zoomUI.web';


 const chScroll=1000;
/**
 * A function that sets when scrollbased planet rotation is used LET IT BE! It's used by the sub pages
 * 
 * @param {any} scroll 
 * @returns 
 */
function checkScroll(scroll)
{
  if(typeof scroll==='number')
  {
    if(scroll<chScroll)
      return 0;
    else
      return chScroll-scroll;
  }
  else
    return 0;
}



function Planet({children, zoompage, scrollRef, r})
{
  const hm=navigator.userAgent.indexOf("OculusBrowser")!==-1; 

  const scr=checkScroll(scrollRef);
  const pointEv=topPagespointerEventsE(zoompage);



  return  <View nativeID="Planet" 
              // @ts-ignore
              style={ pointEv!=="auto" ? {position:"fixed", top: scr, 
              "width": "calc(50%)","zIndex": "200",  
              pointerEvents: 'none'}: 
              {backgroundColor: hm ? "none" : "#23212C"}
              }>
              {children}
    </View>  
}





function getCurrentPage(page, scrollRef, scroll2State, setScroll2State)
{
  switch(page) 
  {
    case 'planetInventions3': 
    return   <WorldOfInventions scrollRef={{scrollRef}}  
          scroll2State={scroll2State} setScroll2State={setScroll2State} />
    case 'planetWorldofArt2':
    return <WorldOfArt scrollRef={{scrollRef}} scroll2State={scroll2State} 
          setScroll2State={setScroll2State} />
    case 'planetEPO2':
      return  <EPOatWork scrollRef={{scrollRef}} scroll2State={scroll2State}  
              setScroll2State={setScroll2State} />
    case 'radio':
      return  <AR_Radio />
    case 'galleryentrancear':
        return  <AR_Gallery />
    case 'leitner':
      return <AR_Leitner />
    case 'birgisdottir':
      return <AR_Birgisdottir />
    case 'saraceno':
      return <AR_Saraceno />
    case 'panamarenko':
      return <AR_Panamarenko />
    case 'kintera':
      return  <AR_Kintera />
    default: 
      return <></>
  }      
}

let currentHistoryObject={cho: 0};








export function setURL(url)
{
  window.history.pushState(currentHistoryObject.cho++, "Title", url);
}

export default function App({scrollRef, zoompage, name, setZoompage, r})
{
  const subPageRef=useRef();

  const [scroll2State, setScroll2State]=useState(true);

  const [backgroundState, setBackgroundState]=useState(false);

  const [vrTour, setVRTour]=useState(false);





  const setCurrentPage= (name)=>
  {
  switch(name)
    {  
      case null:
      case '': 
      setZoompage('home');
        break;
      case 'worldinventions':
      setZoompage('planetInventions3');
        break;
      case 'worldart':
      setZoompage('planetWorldofArt2');
        break;
      case 'epoatwork': 
        setZoompage('planetEPO2');
          break;
      case 'galleryentrance': 
        setZoompage('galleryentrance');
          break;
      case 'galleryentrancevr': 
          setZoompage('galleryentrancevr');
            break;
      case 'galleryentrancear': 
            setZoompage('galleryentrancear');
              break;
      case 'radio': 
      //  setZoompage('radio');
          setURL(""); 
          window.location.href="ar_markerless/markerless.html?modelname=radio"; 
            break;
      case 'leitner': 
        setZoompage('leitner');
        break;
      case 'saraceno': 
        setZoompage('saraceno');
        break;
      case 'birgisdottir': 
        setZoompage('birgisdottir');
        break;
      case 'panamarenko': 
        setZoompage('panamarenko');
        break;
        case 'kintera': 
        setZoompage('kintera');
        break;
        case 'vrinfo': 
        setZoompage('vrinfo');
        break;
      case 'arinfo': 
        setZoompage('arinfo');
        break;
      case 'v3dinfo': 
        setZoompage('v3dinfo');
        break;
      
      default :
    }
  }

  window.onpopstate = event => {
    if(currentHistoryObject?.cho>event.state)
    {
        const url = new URL(document.location.href);
        const page = url.searchParams.get("p");
      
        setCurrentPage(page);
    }
  };

  useEffect( ()=> {
    if(zoompage)
    { 
      switch(zoompage)
      {  
        case 'home': setURL(STATIC_PATH);
          break;
        case 'planetInventions3': setURL(STATIC_PATH+"?p=worldinventions");
          break;
        case 'planetWorldofArt2': setURL(STATIC_PATH+"?p=worldart");
          break;
        case 'planetEPO2': setURL(STATIC_PATH+"?p=epoatwork");
          break;
        case 'galleryentrance': setURL(STATIC_PATH+"?p=galleryentrance");
          break;
        case 'galleryentrancevr': setURL(STATIC_PATH+"?p=galleryentrancevr");
          break;
        case 'galleryentrancear': setURL(STATIC_PATH+"?p=galleryentrancear");
          break;
        case 'radio': setURL(STATIC_PATH+"?p=radio");
          break;
        case 'leitner': setURL(STATIC_PATH+"?p=leitner");
          break;
        case 'saraceno': setURL(STATIC_PATH+"?p=saraceno");
          break;
        case 'birgisdottir': setURL(STATIC_PATH+"?p=birgisdottir");
          break;
        case 'panamarenko': setURL(STATIC_PATH+"?p=panamarenko");
          break;
        case 'kintera': setURL(STATIC_PATH+"?p=kintera");
          break;
        case 'vrinfo': setURL(STATIC_PATH+"?p=vrinfo");
          break;
        case 'arinfo': setURL(STATIC_PATH+"?p=arinfo");
          break;
        case 'v3dinfo': setURL(STATIC_PATH+"?p=v3dinfo");
          break;
        default : setURL(STATIC_PATH+"?p=notfound"); 
      }
    }
  },
  [zoompage]);

  useEffect( ()=>  {   
      if(!zoompage)
      {
        setCurrentPage(name);
        
      } }
  , [name]);




  // @ts-ignore
  return  <View nativeID="AppVw">
      <header id="LogoVw" 
                style={{width: 133, height: 65,
                      position: 'fixed', zIndex: 29998}}>
                      <EPOSmallLogo  />
                      <TopMenu zIndex={29999} scrollBarRef={scrollRef} />
      </header> 
     
   {/*    {
        zoompage==="galleryentrance" && <InfoVR setVRTour={setVRTour} />
      } */}
     <Planet zoompage={zoompage} scrollRef={scroll2State} r={r} 
            // @ts-ignore
              scrollBarRef={scrollRef}    >         
          <Extra inSubPage={false}  subPageRef={subPageRef}  
              backgroundState={backgroundState} setBackgroundState={setBackgroundState} scroll2State={scroll2State} 
              various={{vrTour, scrollRef}} /> 
      </Planet> 

       <View ref={subPageRef}   >
            {getCurrentPage(zoompage, scrollRef, scroll2State, setScroll2State)}
        </View>   
    </View>
}



// {process.env.NODE_ENV === 'development' && 
// <DevelopEditTool /> } 

