import React, {useContext} from 'react';
import InfoContext from '../../../InfoContext';

import ArtWorkHeader from './ArtWorkHeader';
import "./style.css";

/*
   <p><span>Roman Signer</span></p><p>
        <span>Letzter Schnee</span>
        <span>, 2009<br/>Cibachrome</span></p>
        <p><span>2 parts, each 45 x 60 cm</span></p>
*/

export default function Signer()
{
    const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>
        <ArtWorkHeader  
				artistname="Roman Signer"
                title="Letzter Schnee"
                year="2009"
                material="Cibachrome"
                measurements="2 parts, each 45 x 60 cm" 
        />
        <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Kwade.text[l]}</p>
    </div>
}