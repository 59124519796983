import { Box3 } from "three";

import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { URL3D } from '../../config/serverURLPath.web';

const loader = new GLTFLoader();

const scaleAlcohollength="scaleAlcohol".length;

export function retrieveOcculsionObjects(obj, occulsion)
{
     const occulsionLODObjects= occulsion?.occulsionLODObjects;

    if(['Artwork_Leitner', 'Saraceno', 'Panamarenko_flyingcarpet', 'Birgisdottir1', 'sculpture_simple',
        'newbench29', 'newbench30', 'newbench31','newbench32','newbench33',
    ].indexOf(obj.name)!==-1)
    {
        occulsionLODObjects.set(obj.name, obj );
    }

    const animationObjects= occulsion?.animations?.animationObjects;
    const animationScaleObjects= occulsion?.animations?.animationScaleObjects;

    if(['LeitnerBulbShine1', 'LeitnerBulbShine2', 'LeitnerBulbShine3', 'LeitnerBulbShine4', 
        'LeitnerLamp1','LeitnerLamp2', 'LeitnerLamp3', 'LeitnerLamp4'
    ].indexOf(obj.name)!==-1)
    {
        animationObjects.set(obj.name, obj );   //scaleAlcohol14
    }

    if( obj.name.substring(0, scaleAlcohollength)==="scaleAlcohol")
    {
        const inx=obj.name.substr(scaleAlcohollength)
        
        if(inx[0]!=='_')
        {
            let i=+inx;

            if(i>50)
                i=51;
   
            animationScaleObjects[i]={name: obj.name, obj }; 
        }
    }
      
}



export default function loadOcculsionBoxes(occulsion) 
{
    const occulsionBoxes= occulsion?.occulsionBoxes;

    loader.load( 
        // resource URL
        URL3D+"sculptures/occulsion_boxes.glb",
     
        function ( gltf ) {
            let found=false;

            if(gltf &&  occulsion)
            {
                const setOcculsionBoxesLoadState= occulsion.setOcculsionBoxesLoadState;

                gltf.scene.traverse(obj => { 

                        // @ts-ignore
                        if(occulsionBoxes && obj?.material?.name==="occulsionBox_phong50")
                        {
                            for(let n=1; n<=10; n++ ) {
                                if( obj?.name==="occulsionBox"+n )
                                {     
                                    let box=new Box3();
                        
                                    box.expandByObject(obj);

                                    occulsionBoxes.set(obj.name, box );
                        
                                    obj.visible=false;

                                    found=true;                                 
                                }
                            }                   
                        }
                    }
                );
                
                if(found)
                    setOcculsionBoxesLoadState(occulsion);
                else
                     setOcculsionBoxesLoadState(false);
            }
            else
                console.error(" console.error: occulsion box load error inner");
    }, prgrss=>console.log("o: "+prgrss), 
    (err)=>console.error("console.error:  occulsion box load error outer"+err));
}
    