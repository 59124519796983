import React,{/* useState, useEffect, */ useContext} from 'react';

//- import { useResponsiveWidth } from "react-native-responsive-dimensions";
import InfoContext from '../../InfoContext';

 import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import {  Vw, R } from '../../multiEnvComponents/blocktags';
// @ts-ignore
import { P} from '../../widgets/subPages/htmlcomponents/texttags.web';

//- import  { Image } from 'react-native';
import VideoMultiEnv from '../../multiEnvComponents/VideoMultiEnv.web'

import Button from '../../multiEnvComponents/ButtonA.web';
import {CLIENT_PATH} from '../../config/serverURLPath.web';

const tR_={sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1, 
    lll: 1, 
    dl: 1 }

    const tR_2={sp:  0, 
        sl:  0, 
        mp: 0,
        tp:  0,
        mtl: 0, 
        tl: 0, 
        sll: 1, 
        lll: 1, 
        dl: 1 }

export default function Instructions({setVRTour=(val)=>{console.log(val)}, infoBoxState })     //  ,
{
    const {r, modalCAct, finishedLoad: {finishedLoading}, graphicsResolution: { gr}} =  useContext(InfoContext);

    const tR=rsp(tR_,r);
    const tR2=rsp(tR_2,r);

    let t=0;
    return <>{ finishedLoading===2 && <>
                <R n="How to navigate" st={{ flexDirection: tR? "row": "column",  marginTop: "-4vh", marginLeft: "2vw", position: "absolute" }}>
                  {/*   <Image  source={{uri: CLIENT_PATH+"graphics2D/artgallery/howto_navigate/question_sign.svg"}}  style={{width: 36, height: 36}}   /> */}
                    <P st={{marginLeft:10}}>How to navigate</P>    
                </R>
                <Vw st={{alignContent: "center"}}         
                click={()=> 
                            {                             
                                if(modalCAct?.setModalC)
                                        modalCAct.setModalC(v=>!v);  } }>
                                        {gr <=2 && ( gr<=0 ?  (<P>The device 3D graphics performance is too low to be able to show the realtime Art Gallery model.</P>)  
                                                                : (<P>The nondetailed version of the art gallery is showing due to low performance graphics device</P>) )
                                        }
                                <VideoMultiEnv src={
                                                    tR ?
                                                    {
                                                    // webm: CLIENT_PATH+"images/video/video.webm",    
                                                    mp4: CLIENT_PATH+"graphics2D/artgallery/howto_navigate/Instructions.mp4",
                                                    // mov: "images/video/video.mov",
                                                    // ogg: "images/video/video.ogg"
                                                } :
                                                {
                                                    // webm: CLIENT_PATH+"images/video/video.webm",    
                                                    mp4: CLIENT_PATH+"graphics2D/artgallery/howto_navigate/Instructions_mobile_tablet.mp4",
                                                    // mov: "images/video/video.mov",
                                                    // ogg: "images/video/video.ogg"
                                                }                    
                                                }  
                                                 size={{width: tR2 ? "95%": "50%", height:  tR2 ? "95%": "50%"}} loop={true} style={{margin: "2.5%"}} 
                                                 marginLeft={tR2 ? "": "30%"}
                                                 trigg={tR2} />    
                              <Button title="OK" onPress={e=> 
                            {                                                 
                                e.stopPropagation();
                                e.preventDefault();        
                                if(modalCAct?.setModalC)
                                        modalCAct.setModalC(v=>!v);  } }
                            buttonStyle={{ alignSelf: "center"}}
                             />     
                    </Vw>
                </>  
           } </>
}

