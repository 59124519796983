import React,{/*  useState, useEffect,   */ useContext} from 'react';

import styled from 'styled-components';

import InfoContext from '../../InfoContext';

import rsp from  "../../widgets/subPages/htmlcomponents/useResponsiveBrk";

import {  Vw, C,R } from  '../../multiEnvComponents/blocktags'; //    '../../widgets/subPages/htmlcomponents/blocktags';
// @ts-ignore
import {/*  H4, */ P, Radio} from '../../multiEnvComponents//texttags.web';



import Button from '../../multiEnvComponents/ButtonA.web';
// import {CLIENT_PATH} from '../../../config/serverURLPath.web';

const tR_={sp:  0, 
    sl:  0, 
    mp: 0,
    tp:  0,
    mtl: 1, 
    tl: 1, 
    sll: 1, 
    lll: 1, 
    dl: 1 };


    // @ts-ignore
    const ButtonsRow =styled(R).attrs({
        className: "ButtonsRow"
    })`
            //    margin-top: 15ex;
                width: 26em;
                margin-left: 3vw;
             
    `;


const itemsVal= [
    {key: 0, text: "high details", val: 11 },
    {key: 1, text: "high", val: 7 },
    {key: 2, text: "high minimalistic", val: 5 },
    {key: 3, text: "middle", val: 3 },
    {key: 4, text: "low", val: 2 }     
]


export default function ArtGallerySettings({infoBoxState })     //  ,
{
    const {r, modalCAct, graphicsResolution} =  useContext(InfoContext);
 //   const [res, setRes] =useState(0);

    const tR=rsp(tR_,r);

    return <>{  <>
                <R n="Configuration settings" st={{ flexDirection: tR? "row": "column",  
                        marginTop: "-4vh", marginLeft: "2vw", position: "absolute" }}>
                    <P st={{marginLeft:10}}>Settings</P>    
                </R>
                <Vw n="spBetw" st={{alignContent: "center" }}>
                                
                        <C n="settingscol" st={{marginLeft: "2em",  justifyItems: "stretch",  
                                    justifyContent: "space-between"  }}>
                            <P>Display quality:</P>
                            <C n="settingscol2" st={{
                                    justifyItems: "stretch",  
                                    justifyContent: "space-between"  }}>
                                    <Radio st={{    display: "flex",
                                                    flexDirection: "column",
                                                    height: "25vh"}}
                                                items={ itemsVal } 
                                                itemStyle={{ marginBottom: 15, marginRight: 5}}
                                                radioGroupName="graphicsresolution"

                                                selectedIndex={graphicsResolution.ix}
                                                setSelectedIndex={graphicsResolution.setIx}
                                    />

                                    
                                    <ButtonsRow>
                                        <Button title="Cancel" onPress={()=> 
                                            {                             
                                                if(modalCAct?.setModalC)
                                                        modalCAct.setModalC(v=>!v);  } }
                                            buttonStyle={{ alignSelf: "right", marginRight: "1em"}}
                                            />    
                                        <Button title="Save Preferences" onPress={()=> 
                                            {                                                   
                                                graphicsResolution.setGraphicsResolution(graphicsResolution.ix);
                                                
                                                if(modalCAct?.setModalC)
                                                        modalCAct.setModalC(v=>!v);  } }
                                            buttonStyle={{ alignSelf: "right"}}
                                            /> 
                                    </ButtonsRow>
                            </C> 
                        </C> 
                    </Vw>
                </>  
           } </>
}

