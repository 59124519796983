import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';

import InfoContext from '../../InfoContext';
import rsp from "../../widgets/subPages/htmlcomponents/useResponsiveBrk";



/**
 * 
 * 
 * @param {object} props
 * @param {object} props.choosen
 * @param {object} [props.menuA]
 * @param {number} [props.choice] 
 * @param {boolean} [props.last] 
 * @param {number} [props.margin] 
 * @returns 
 */
 export function StandardPlanet_({choosen, menuA={scrollBarRef:[], menuAction:()=>{} }, choice, last=false, margin=0})
 {  
     const {scrollBarRef, menuAction} =menuA;
 
     return <>
        { choosen ? 
             <>                             
                 <View style={{    marginBottom: last? 0: 20 }}>
                     <Image source={{uri: "graphics2D/nav/filled_circle.svg"}} style={{ width: 16, height: 16, marginLeft: margin, marginRight: margin}} /> 
                 </View>
             </> 
             :
             <View style={{  marginBottom: last? 0:  20 }}>
                <TouchableOpacity activeOpacity = { 0.5 } onPress={ ()=>{  menuAction({type: 0, choice: choice, scrollBarRef: scrollBarRef }) }}>
                    <Image source={{uri: "graphics2D/nav/unfilled_circle.svg"}} style={{ width: 16, height: 16, marginLeft: margin,   marginRight: margin }} />  
                </TouchableOpacity>
             </View>
         }
        
     </>
 } 




 


const StandardPlanet= React.memo(StandardPlanet_);


function ExploreThePlanets({whichOne=1, scrollBarRef, menuAction})
{  
    let {r} = React.useContext(InfoContext);

    
    const left1= rsp({
        sp:  5, 
        sl:  5, 
        mp: 5,
        tp:  50,
        mtl: 50, 
        tl: 50, 
        sll: 50, 
        lll: 50, 
        dl: 50 }, r);   

    // @ts-ignore
    return <View style={{left: left1, alignSelf: "left"}}>
        <View 
            // @ts-ignore
        style={{ justifyContent: "left", flexDirection: "column" }} >
            <>
                { (whichOne===0 || whichOne===1) ? 
                    <StandardPlanet choosen={true} />
                    :
                    <StandardPlanet choosen={false}  menuA={{scrollBarRef, menuAction}} choice={0}  />
                }
            </>
            <>
                { (whichOne===2) ? 
                    <StandardPlanet choosen={true} />
                    : <StandardPlanet choosen={false}  menuA={{scrollBarRef, menuAction}} choice={1}  />
                }
            </>
            <>
                { (whichOne===3) ? 
                    <StandardPlanet choosen={true}  />
                    :
                    <StandardPlanet choosen={false} menuA={{scrollBarRef, menuAction}} choice={2} />
                }
            </>
            <>
                { (whichOne>=4 ) ? 
                    <StandardPlanet choosen={true} />
                    :
                    <StandardPlanet choosen={false}  menuA={{scrollBarRef, menuAction}} choice={3}  />
                }
            </>
      

        </View>
    </View>
}


export default React.memo(ExploreThePlanets);

