import React, { useState, useEffect} from 'react'

import TriggerFadeInAnim from '../../../multiEnvComponents/TriggerFadeIn';

import styled  /*+ , {ThemeProvider}  */ from 'styled-components';


const MenuContext = React.createContext(null);


export const MenuBtn =styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    `


const MenuBtn2=  styled.button`
        background-color: transparent;
        border: none;
        padding: 0;

        width: 50px;
        height: 50px;
        position: absolute;
        top: -60px;
        right: -10px;

            @media (max-width: 800px) {  // sp, mp
                right: 5px
            }
    `;


const menuStyle={
    position: "fixed",
    width: "100vw",
    height: "100vh",
    zIndex: 5000,
    left:0,
    top: 0
}



export function Menu({children, name, Trigger, style, aria, onClick=()=>{}, openTrigger, mobile})
{
    const [open, setOpened]= useState(false);

    useEffect(() => {
        if(openTrigger)
            setOpened(true);
        else
            setOpened(false);

    }, [openTrigger]);

    return <nav aria-label={aria}>  
            <MenuBtn2 onClick={e=> {  
                                    setOpened(o=>!o);  e.stopPropagation(); console.log("open "+open); 
                                    onClick(); }  }>
                <div id="trigg" style={{zIndex:90000, position: "fixed", 
                                               
                                                top: 10, 
                                                cursor: "pointer"}}  >{<Trigger open={open} />}</div></MenuBtn2>           
                <TriggerFadeInAnim isVisible={open}><div>
                    <div id={"menu_menu "+name}
                         style={{...menuStyle, ...style}} onClick={()=>{setOpened(false) } }>  
                        <MenuContext.Provider value={{open, setOpened}}>
                            {children}
                        </MenuContext.Provider>
                    </div>
                </div>
                </TriggerFadeInAnim>   
          
        </nav>
}




export function MenuOption(props)
{
        const  {children, customStyles, onSelect}=props;

        let {setOpened} = React.useContext(MenuContext);

        return <MenuBtn className="menuoption" style={{display: "flex", cursor: "pointer",...customStyles}}  onClick={()=>{ 
            setOpened(false);

            if(onSelect)
                onSelect() }}>{children}</MenuBtn>
}




