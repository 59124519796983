import React,{useContext} from 'react';


export default function YouTubeComponent({width, height, src, title})
{
    return <iframe width={width} 
    height={height} 
    src={"https://www.youtube.com/embed/"+src} 
    title={title} 
    // @ts-ignore
    frameBorder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
}