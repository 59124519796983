/**
 *  Just-texture shader. No light multiply.
 * 
 * @author J-O w42.se
 */


import {ShaderMaterial} from "three";

const vrtxShader=`			           
            varying vec2 vUv;

			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
			}
`


const fragmentShader=`
			#include <packing>

			varying vec2 vUv;
			uniform sampler2D tDiffuse;

			void main() {
				vec4 diffuse = texture2D( tDiffuse, vUv ).rgba;

                vec4 newdiffuse= vec4(diffuse.r, 
                                    diffuse.g, 
                                    diffuse.b, 1.0);
				gl_FragColor.rgba = newdiffuse;
			}
		`


export default function emissiveTextureShader(map)
{
    const Material1 = new ShaderMaterial( {
        vertexShader: vrtxShader,
        fragmentShader: fragmentShader,
        uniforms: {
            tDiffuse: { value: null }
        }
    } );

    Material1.uniforms.tDiffuse.value =  map;   

    return Material1;                  
  
}


   