import React,{useState, useEffect, useContext} from 'react';
import styled from 'styled-components';

import { R, Vw } from '../../multiEnvComponents/blocktags';
import { P } from '../../multiEnvComponents/texttags.web';

import InfoContext from '../../InfoContext';

const r="800px";

const R1  =
 styled(Vw).attrs({
    className: "R"    
 })`
    display: flex;
    align-self: center;
    align-items: center;

    background-color: #23212C;
  // 
  
    flex-direction:row;

     @media (max-width: ${r}) { 
        align-self: start;
   /*     ${ p=> p.
        // @ts-ignore
       mainpage  ? 'width: 100vw;': '' }       }  */
   }
`;



const P1  =
styled(P).attrs({
    className: "P"    
})`
    margin-left: 2em;
    margin-right: 2em;
    flex-wrap: nowrap;
    display: flex;
    align-self: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @media  (max-width: 920px) {
      margin-left: 1em;
      margin-right: 1em;
     }


     @media (max-width: ${r}) { 
        align-self: start;
        margin-bottom: 2ex;
     }
`;



const V1 = styled.footer.attrs({
    className: "Vw_Footer"    
})`
    display: flex;
    align-items: center;
    margin-top: 15px;



    @media (max-width: ${r}) { 
        flex-direction:column;

        height: 90vh;
        margin-top: 10vh;
        place-self: flex-start;
     }

     @media (min-width: 380px) and (max-width: 450px) {
      margin-top: 0vh;
     }

     @media  (min-width: 450px) and (max-width: 722px) {
      margin-top: 0vh;
     }

     @media  (min-width: 870px) and (max-width: 950px) {
      margin-top: 0vh;
    
     }

     @media  (min-width: 800px) and (max-width: 950px) {
        flex-direction: row;
        margin-top: 0vh;
     }
`;




export default function FooterFooter({mainpage})
{
        let {  various: { variousStatesDispatch}, zoompage, r } = React.useContext(InfoContext);

        useEffect(()=>{
                console.log("footerfooter zoompage: "+zoompage);
        }, [zoompage]);

        const sitemap=()=> {         
                    variousStatesDispatch({type: 'setSiteMapClosed'});
                    variousStatesDispatch({type: 'setSiteMapOpened'});
        }


    
        // @ts-ignore
        return <R1 fullwidth={ zoompage==="home" ? true : false } n="r1" mainpage={mainpage} >
                    <V1 
                        // @ts-ignore
                        n="v1">
                        <P1 onClick={ ()=> sitemap() } >Sitemap</P1>
                        <P1 onClick={ ()=> window.open("https://www.epo.org/footer/legal-notice.html", "_blank", "noopener") }>Legal notice</P1>
                        <P1 onClick={ ()=> window.open("https://www.epo.org/footer/terms.html", "_blank", "noopener") }>Terms of use</P1>
                        <P1 onClick={ ()=> window.open("https://www.epo.org/about-us/office/data-protection-and-privacy.html", "_blank", "noopener") }>Data protection and privacy</P1>
                        <P1 onClick={ ()=> window.open("https://www.epo.org/footer/accessibility.html", "_blank", "noopener") }>Accessibility</P1>
                        <P1 onClick={ ()=> window.open("https://www.epo.org/applying/online-services/security.html", "_blank", "noopener") }>Security</P1>
                    </V1>
            </R1>
}


    