import {Quaternion, Vector3 } from 'three';

export function allmostEqualsV(v1, v2, precison=0.0000001)
{
    if(v1 && v2)
        return Math.abs(v1.x-v2.x)<precison &&
            Math.abs(v1.y-v2.y)<precison &&
            Math.abs(v1.z-v2.z)<precison
    else
        return false
}


export function allmostEqualsQ(v1, v2, precison=0.0000001)
{
    if(v1 && v2)
        return Math.abs(v1.x-v2.x)<precison &&
            Math.abs(v1.y-v2.y)<precison &&
            Math.abs(v1.z-v2.z)<precison &&
            Math.abs(v1.w-v2.w)<precison
    else
        return false
}

/**
 * @param {{ position: { copy: (arg0: any) => void; }; }} camera
 * @param {any} pos
 */
export function calcLerp(camera, pos, reachedCllbck=()=>{}, precision= 0.05)
{
    if(allmostEqualsV(camera.position, pos, 0.01) )
    {
        // @ts-ignore
        reachedCllbck(pos, camera);
    }
    else
    {
        // @ts-ignore
        const camPos= (new Vector3()).copy(camera.position);
        // Interpolate camPos toward targetPos
        camPos.lerp(pos, precision);

        // Apply new camPos to your camera
        camera.position.copy(camPos); 
    }
}


/**
 * @param {any} camera_
 * @param {any} element  the controller goal
 */
 export function calcLerpControls(camera_, element, reachedCllbck=()=>{}, reached, precision=0.01, speed= 0.005)
 {
    const camera=camera_?.camera?.current || camera_;
    const pos=element?.position;
    const quat=element?.quaternion;

    if(element?.reachedCllbck)
    {
        reachedCllbck=element.reachedCllbck;
    }

    if(camera && allmostEqualsV(camera.position, pos,precision) && !quat)
     {
         // @ts-ignore
         reachedCllbck(pos, camera);
     }
     else
     if(camera && allmostEqualsV(camera.position, pos,precision) && allmostEqualsQ(camera.quaternion, quat, precision) && !reached)
     {
         // @ts-ignore
         reachedCllbck(pos, camera);
     }
     else
     {
         if(camera && quat && !isNaN(camera.position.x))
         {
        /*     const prev= camera.quaternion.clone();
            console.dir(prev); */

         // @ts-ignore
            const camPos= (new Vector3()).copy(camera.position);
            // Interpolate camPos toward targetPos
            camPos.lerp(pos, speed);

        //    console.log("Camera pos: ");
        //    console.table(pos);
            // @ts-ignore
     //       console.dir(camera.rotation);

            // Apply new camPos to your camera
            camera.position.set(camPos.x, camPos.y, camPos.z); 

            const camQ=camera.quaternion.clone();
            const camQaut= new Quaternion(camQ.x || 0, camQ.y || 0, camQ.z || 0, camQ.w || 0);
       
           //  const camQaut=new Quaternion();
            const qtrn= new Quaternion(quat.x, quat.y, quat.z, quat.w);

            camQaut.rotateTowards(qtrn, 0.005)

            if(camQaut.x && camQaut.y && camQaut.z && camQaut.w)
              camera.quaternion.set(camQaut.x || 0, camQaut.y || 0, camQaut.z || 0, camQaut.w || 0);   
        }
        else
        if(camera && !isNaN(camera.position.x))  // no quaternion lerp in VR
        {
            // @ts-ignore
            const camPos= (new Vector3()).copy(camera.position);
            // Interpolate camPos toward targetPos
             camPos.lerp(pos, 0.5);  // in VR

            // @ts-ignore
     //       console.dir(camera.rotation);

            // Apply new camPos to your camera
            camera.position.set(camPos.x, camPos.y, camPos.z); 
        }
     }
 }


 /**
 * @param {Array} animList
 * @return {object}
 */
 export function GetElementFromAnimList(animList)
 {
    let listNr=0,addition=1;

    return {getElement: ()=>{
        if((listNr+=addition) >animList.length-1)
        {
            listNr=0;
        }

        return animList[listNr];
    },
    forwardElement: ()=>listNr++,
    backwardElement: ()=>listNr--,
    getElmentWithoutInc: addV=>{
                        listNr = listNr + addV;
                        return  listNr;
                    },
    setAnimForward: f=> addition= addition!==1 ? 1 : 0    //  f ? 1 : 0
} 
 }