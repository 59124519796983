import React, {useContext} from 'react';
import "./style.css";
import ArtWorkHeader from './ArtWorkHeader';
import InfoContext from '../../../InfoContext';

export default function Belin()
{
    const {contentModel: {infoboxes}, l} =  useContext(InfoContext);

    return <div>  
    	<ArtWorkHeader  
				artistname="Valérie Belin"
                title="Untitled"
                year="2005"
                material="Gelatin silver print, aluminium"
                measurements="2 parts, each 166 x 125 cm" 
        />
       <p>{infoboxes.applications.artgallery.content3D.vrClickBoxes.LODButton_sign_Belin1_phong10.text[l]}</p>   
    </div>
}

