import React from 'react';

// @ts-ignore
import { URL3D } from '../../config/serverURLPath.web';
import ModelViewer from '../../components3D/multiEnv/ModelViewer.web';
import Info from './Info';


export default function AR_Birgisdottir()
{
    return <>
    <Info qr="images/QR/Birgisdottir.png" link="https://vworlds-dev01.azurewebsites.net/?p=birgisdottir" />     
    <ModelViewer src={URL3D+"AR/birgisdottir.glb"}
    iosSrc={URL3D+"AR/birgisdottir.usdz"}
    alt="model viewer"
    ar={true}
    loading="lazy"
    cameraControls={true}
    poster={URL3D+'AR/birgisdottir.png'}
    autoplay={true}
    style={"width: 100vw; height: 90vh; top: 0px;  position: sticky; background-color: black;"}
/></>
}