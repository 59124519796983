import React,{useState, useContext} from 'react';

import InfoComp from './InfoComp';
import InfoContext from '../../InfoContext';


export const clickList=[
    (setZoompage)=>setZoompage("vrinfo") ,
    (setZoompage)=>setZoompage("arinfo") ,
    (setZoompage)=>setZoompage("v3dinfo") ,
];

export default function VRInfo({infoBoxState})
{
    const {r, contentModel: {infoboxes}, l, setZoompage} =  useContext(InfoContext);

    return <InfoComp  image={infoboxes.vr.image}  icon={infoboxes?.vr?.icon} 
                    title={infoboxes?.vr?.title[l]} 
                    p1={infoboxes?.vr?.first[l]}
                        buttonTopText={infoboxes?.vr?.buttonText?.[l]}
                        buttonText={infoboxes?.vr?.button?.[l]}
                        buttonClick={()=>{setZoompage('planetWorldofArt2')}}
                        arrowLeftClick={()=>{infoBoxState.setInfoBoxState('v3dinfo')}}
                        arrowRightClick={()=>{infoBoxState.setInfoBoxState('arinfo')}}
                        whichOne={1}
                        clickList={clickList}
                        infoBoxState={infoBoxState}
                         />                        
}

