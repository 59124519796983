import React,{useContext, useState, useEffect} from 'react';
// import {Image} from 'react-native';

// import rsp from "../../htmlcomponents/useResponsiveBrk";
import InfoContext from '../../../../InfoContext';

// import {openInNewTab} from '../../../../components3D/scrollAction';

import {TopComponent, Pane} from '../../widgetComponents/TopComponent';

import { COLORS } from '../../../../components/colors';

import SideInfo from '../../widgetComponents/SideInfo'; 

import  { createModalTextContent}  from   '../../../../components3D/multiEnv/createModalContent.web'   // "../multiEnv/createModalContent.web";
import { ModalContext } from '../../../../multiEnvComponents/modalProvider.web'    //   "../../../multiEnvComponents/modalProvider";

import rsp from   "../../htmlcomponents/useResponsiveBrk";    // "../../widgets/subPages/htmlcomponents/useResponsiveBrk";


/**
 * Sideinfo for World of art
 * 
 * @returns JSX
 */
function SideInfo1() {
    const {r, contentModel: {subpages}, l, setZoompage} =  useContext(InfoContext);

    let { handleModal, setModalStyle} = React.useContext(ModalContext);
    const [infoBox, setInfoBoxState]=useState("");

    const mobile=rsp({sp:  1, sl: 1, mp: 1, tp: 1, mtl: 1, tl: 1, sll: 0, lll: 0, dl: 0 }, r);
   

            useEffect(
                ()=>{
               setModalStyle({    
                    outer1:  mobile ? { width: "92vw", maxWidth: "50rem" ,height:  "90vh"}:  {width: "50rem", height: "30rem"},  
                    outer2:  mobile ? { width: "92vw", maxWidth: "50rem", height:  "90vh"}:  {width: "50rem", height: "30rem"}, 
                    trigger: r,
                    modalnearlytop: {overflowY: "", overflowX: "hidden"}
               });
            },
                [r]
            )
            

    return <SideInfo comingsoon={false}
    title={subpages?.worldart?.p2?.SideInfo1?.title?.[l]} 
    p_1={subpages?.worldart?.p2?.SideInfo1?.p1?.[l]} 
    imagelist={{  vr:true, v3d: true}}
    buttontext={subpages?.worldart?.p2?.SideInfo1?.button?.[l]}
    buttonaction={()=>
         {
                setModalStyle({
                     outer1:  mobile ? { width: "92vw",  height:  "90vh"}:  {width: "50rem", height: "30rem"},  
                     outer2:  mobile ? { width: "92vw",  height:  "90vh"}:  {width: "50rem", height: "30rem"}, 
                     trigger: r,
                     modalnearlytop: {overflowY: "", overflowX: "hidden"}
                });

                handleModal(
                     createModalTextContent(  "info_artGallery" , false, {infoBox, setInfoBoxState} ), 
                        false, 2);      
            }  
            
  //        setZoompage('galleryentrance')
    
    }
    side="left"
    />
}


function SideInfo2() {
    const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

    return <SideInfo comingsoon={true}
                    title={subpages?.worldart?.p2?.SideInfo2?.title?.[l]} 
                    p_1={subpages?.worldart?.p2?.SideInfo2?.p1?.[l]} 
                    imagelist={{ /* vr:true, */ v3d: true}}
                    buttontext={subpages?.worldart?.p2?.SideInfo2?.button?.[l]}
                 />
}



function WoATopPane(){
    const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

    return <Pane 
                     dateInfo={subpages?.worldart?.p2?.toppane?.dateInfo?.[l]}
                    title={subpages?.worldart?.p2?.toppane?.title?.[l]} 
                    subTitle={subpages?.worldart?.p2?.toppane?.subtitle?.[l]} 
                    imageName={subpages?.worldart?.p2?.toppane?.image?.[l].link} sideInfo={<SideInfo1 />}
                    imageStyle={{width: 458,
                                height: 294,
                                marginTop: 12}} 
                    planetLeft={true}
                    imageAlt={subpages?.worldart?.p2?.toppane?.image[l]?.imageAlt}
                                 /> 
}


function WoABottomPane(){
    const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

    return <Pane title={subpages?.worldart?.p2?.bottompane?.title?.[l]} 
    subTitle={subpages?.worldart?.p2?.bottompane?.subtitle?.[l]} 
    imageName={subpages?.worldart?.p2?.bottompane?.image?.[l].link} 
    sideInfo={<SideInfo2 />}  imageStyle={{width: 458*1.1,
                                height: 294*1.1, marginLeft: -23, marginTop: -5, marginRight: 23, }} 
                        planetLeft={true} 
                        imageAlt={subpages?.worldart?.p2?.bottompane?.image[l]?.imageAlt}
                                /> 
}



export default function WoA_Top({planetFixed, third, scroll2State}) {
   const {r, contentModel: {subpages}, l} =  useContext(InfoContext);

  return  <TopComponent planetName="planetWorldofArt2" 
    planetLeft={true} 
    TopTopPane={third}
    TopPane={subpages?.worldart?.p2?.toppane && <WoATopPane />}
    BottomPane={subpages?.worldart?.p2?.bottompane && <WoABottomPane />}
    planetFixed={planetFixed}
    scroll2State={scroll2State}

    grad={"linear-gradient(180deg, rgba(216, 94, 124, 0) 0%,"+COLORS.woa+" 100%)"}
/>
} 


// outer1:  mobile ? { width: "92vw", maxWidth: "70rem" ,height:  "90vh"}:  {width: "70rem", height: "30rem"},  
// outer2:  mobile ? { width: "92vw", maxWidth: "70rem", height:  "90vh"}:  {width: "70rem", height: "30rem"}, 