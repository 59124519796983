/**
 * @author J-O Jansson w42.se
 */

 import React, { useRef,  useState,   useEffect, useMemo  } from 'react';
 import { Euler, TextureLoader, Vector3, Raycaster, Vector2,
  Quaternion, Matrix4} from 'three';
 import { useFrame, useThree } from '@react-three/fiber';
 import { XR, useXR } from '@react-three/xr';

import {View} from 'react-native';


import rsp from "../widgets/subPages/htmlcomponents/useResponsiveBrk";



import ErrorHandler from '../errorHandler';

import {Html} from './drei/drei/web/Html'; 

import GLTFLoaderComponent from './gltfLoaderComponent';
 
import { findObj3DName } from './findObj3DName';
import {FPSFeatureAdditionLOD} from './fpsLOD';


 // @ts-ignore
 import { URL3D /* , CLIENT_PATH */ } from '../config/serverURLPath.web';  // /config/serverURLPath
 
 // @ts-ignore
 import  { createModalTextContent}  from "./multiEnv/createModalContent.web";

 import InfoContext from '../InfoContext';

import clickEventAction /* , {openModelFromText} */ from "./scrollAction";

import { nameAliases } from './findObj3DName';

import handleMouseMove, {handleMouseMoveVR} from './clickControl/handleMouseMove';
//- import checkScreenBorders from './clickControl/checkBorder';

import { ModalContext } from  '../multiEnvComponents/modalProvider.web';   

import clickBox from './clickControl/clickBox';

import handleVRClickAction from './clickControl/vrClickAction';

let px=0,py=0,pz=0;

const raycaster = new Raycaster();
const mouse = new Vector2();

const mouse2 = new Vector2();
const mouse3 = new Vector2();


let mouseClicked=false;
let mouseLeftButtonDown=false;
let touchDown=false; 
let mouseUpEventIs=false;
let mouseMove=false;
let touchMove=false;
let mouseBtnClickDelay=0, mousePressMoved=1;
let isArtGAllery=false;




let runVRCllbck=()=>{};


function onMouseClick( event ) {

	// calculate mouse position in normalized device coordinates
	// (-1 to +1) for both components
	mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    mouse2.x = event.clientX 
	mouse2.y =  event.clientY 

    mouseClicked=true;

    mouseLeftButtonDown=false;
}     

const mUp=e=>{ 
    mouseLeftButtonDown=false;
 //   console.log("mouse up!");
    setTimeout( ()=> {
        mouseLeftButtonDown=false; 
        mouseUpEventIs=true;
  //      console.log("mouse up!!!!!");
    }, 50);

    if(mousePressMoved===2)
    {   mouseClicked=false;
    
        setTimeout( ()=> {
            mouseClicked=false;
            mousePressMoved=1;
        }, 500);
    }
}

  window.addEventListener( 'click', onMouseClick, false );

  window.addEventListener( 'pointermove', event=>{
    if(isArtGAllery)
    {
        // @ts-ignore
        mouseMove=event; 

        mouse3.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse3.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

     //   event.preventDefault();
    }
}, true );


//- const log=console.log;
//- let ongoingTouches=[];
//- let prevX, prevY;




  window.addEventListener( 'mousedown', e=>{
   if(isArtGAllery)
   {
   //     console.log("mouse down");
        // @ts-ignore
        mouseLeftButtonDown=e; 
        e.preventDefault();
    }
}, true );

  window.addEventListener( 'mouseup', mUp, true );
//   window.addEventListener( 'touchcancel', mUp, true );
//   window.addEventListener("touchend", mUp, false);
  window.addEventListener( 'onmouseout', mUp, true );
  window.addEventListener( 'onmouseleave', mUp, true );




function openedModal()
{
    const l=document.querySelector("#modal-win-outer");

    if(l===null)
        return false;
    else 
       return true;
}



export function InfoSprite({filename, spritePosition = new Vector3(), 
    spriteRotation=new Euler(), spriteScale= 1, gl=void 0, extraText=""})
{   
    const spriteRef=useRef();
 
    filename=nameAliases(filename);

    const texture = new TextureLoader().load( URL3D+"textures/screens/"+ filename+".png", 
    ()=> {
   
        if( texture && texture?.image && texture?.image?.width && spriteRef?.current) {
                      
                const v=new Vector3(texture?.image?.width*spriteScale, 
                    texture?.image?.height*spriteScale, 
                   1);
                // @ts-ignore
                spriteRef.current.scale.set(v.x, v.y, v.z);
            }
        } 
    );

    if(gl)
        texture.anisotropy = gl?.capabilities?.getMaxAnisotropy();

    texture.repeat.set( 1, 1 );

   return  <mesh  ref={spriteRef}   name={filename+"_infotext"+extraText} 
        position={spritePosition}
        rotation={spriteRotation}>
            <sprite name={filename+"_infotext"+extraText} >
                    <spriteMaterial  map = {texture}   />
            </sprite>
        </mesh>   
}






             
/**
 * Each position in space with 3D model & billboard and planet/ images etc
 * 
 * This is a component that can be reused in a React Three.js Fiber JSX setup
 * It handles both loading and interaction
 * 
 * 
 * @param {object} props
 * @param {string | Array} props.filename The model file
 * @param {object} [props.showmodel] Shows an attached 3D model, with corresponding filename
 * @param {object} [props.modelRotate] Rotate 3D model slowly around axis
 * @param {object} [props.showSprite] Shows an attached 3D sprite, with corresponding filename
 * @param {object} [props.camera]
 * @param {object} [props.controls]
 * @param {object} [props.handleModal] 
 * @param {object} [props.various] 
 * @param {object} [props.spriteTransform] The local transformation matrix for the text sprite
 * @param {object} [props.modelTransform] The local transformation matrix for attached 3D model
 * @param {object|boolean} [props.HtmlSprite] Use HTML Sprite
 * @param {object|boolean} [props.htmlSpriteScale] HTML Sprite scale
 * @param {number} [props.htmlWidthDivider] Set which amount of the screen with the HTML sprite will use in full zoom in
 * @param {boolean} [props.progressBarChoosen] Should the progressbar be shown for the geometry?
 * @param {boolean} [props.keepDetails] Should the geometry be FPS LOD also in mobile?
 * @param {boolean} [props.galleryFeatures] Art Gallery Features?
 * @param {number} [props.forceFocusHTML] 

 * @param {object} [props.progressBarContent] 
 * @returns JSX
 */
export default function Screen3DXR({filename, showmodel, modelRotate=true, 
        showSprite=true, camera, controls, handleModal, 
        keepDetails=false, galleryFeatures=false,
   //     unifiedLoadingState,
        progressBarContent,

            spriteTransform ={
                spritePosition:  new Vector3(0,0.1,0),  
                spriteRotation:  new Euler(0, 0,0, "XYZ"),  //rotation
                spriteScale:  1   // scale
            },  

            modelTransform = {
                modelPosition:  new Vector3(0.5,0.1,0),  
                modelRotation: new Euler( Math.PI*0.2, Math.PI*0.2, 0, 'XYZ'),  //rotation
                modelScale: new Vector3(0.08,0.08,0.08)   // scale
            },
            HtmlSprite=false,

            htmlSpriteScale=0.05,
            various,

            progressBarChoosen=true,
            forceFocusHTML
        }
    )
{   
    const htmlRef=useRef();
    const htmlRef2=useRef();

    various.setupdate=f=> runVRCllbck=f;


    let { zoompage, setZoompage, scrollBarRef, raycastEvents, vr, 
      /*   fly: { rayCastMouseDown, rayCastMouseUp }, */ 
        goal: {setCameraGoal}, r, contentModel, l , 
        subPageR: {subPageR}, pauseClickEvent,  floorHoverClick,
        fps: {setTriggerR}, zoomart: {zoomArtGetter},
        configurationModel
    } = React.useContext(InfoContext);

    let { setModalStyle, modal } = React.useContext(ModalContext);

    let homepage=contentModel?.homepage;


 //-  let scene, gl, t, threeProps;

  
    const {scene, gl} = useThree();
   
    const threeProps= useThree();
  /*   }
    else
    {
        useXREvent('squeeze', (e) => console.log('squeeze event has been triggered'))
    } */

//-    const { controllers, player } = useXR();
    
    const featureList=useMemo(()=>
    {
        if(typeof filename==="object" &&  Array.isArray(filename))
        {
            const amountOfSubModels=filename.length; // the nr of array items of array filename

            return filename.map((n,index)=>  {   
                
                    return {
                        fpsmin: n.fpsmin,
                        fpsmax: n.fpsmax,
                        feature: <GLTFLoaderComponent filename={n.filename} scene={scene} 
                                    galleryFeatures={galleryFeatures} amountOfSubModels={amountOfSubModels}
                                    gl={gl} progressBarChoosen={true}
                                    camera={camera} 
                                    progressBarContent={progressBarContent}
                                    various={various}
                                    /> 
                    } 
                
                }  )
                }
             }
            , [filename]                                         
    );

    const [objBbx, setObjBbx]=useState({});
  
   
    useEffect(()=> {
        if(zoompage==="galleryentrance" || zoompage==="galleryentrancevr")
            isArtGAllery=true
        else
            isArtGAllery=false
    }, [zoompage]);  


  const mobile= rsp({
    sp:  1, 
    sl:  1, 
    mp: 1,
    tp:  1,
    mtl: 0, 
    tl: 0, 
    sll: 0, 
    lll: 0, 
    dl: 0 }, r);    

    const clickTheLowerLayer=()=>
    {
        
        if(scrollBarRef?.current?.style)
        {
           
            scrollBarRef.current.style.display="none";

            var clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });

            const el=document.elementFromPoint(mouse2.x,mouse2.y);

      //      console.dir(el);
      
            if(el)
                el.dispatchEvent(clickEvent);

            if(scrollBarRef?.current?.style)
                scrollBarRef.current.style.display="block";
        }
    } 

    


    
    const {
    spritePosition,
    spriteRotation,
//    spriteScale
    } =spriteTransform;

    const {modelPosition,
            modelRotation,
            modelScale} =modelTransform;

    const obj3dRef= useRef();

    various.configurationModel=configurationModel;
    various.contentModel=contentModel;
    various.l=l;


    useFrame(()=>
    {
                if(!vr?.enabledVR)
                {
                            // scrollbased rotation
                        if(obj3dRef?.current && modelRotate && zoompage==="home")
                        {
                            // @ts-ignore     
                            obj3dRef.current.rotation.y+=0.001;
                        }
                        else
                        if(obj3dRef?.current && modelRotate && various)
                        {
                            if( various.scroll2State<=0 ||  various.scroll2State===true )
                                // @ts-ignore
                                obj3dRef.current.rotation.y+=0.001;
                            else
                                // @ts-ignore
                                obj3dRef.current.rotation.y= various.scroll2State/200;
                        }

                        if(zoompage==="galleryentrance")
                                checkRaycast(scene, camera, modal);                      
                }
                else  // VR
                {
            // // do something on each frame of an active XR session
            //         if(obj3dRef?.current && modelRotate)
            //         {
            //             // @ts-ignore     
            //             obj3dRef.current.rotation.y+=0.001;
            //         }

            //         const scn=player?.parent; // scene

            //         if(zoompage==="galleryentrancevr")
            //         {

            //             if(runVRCllbck)
            //                 runVRCllbck();

            //         //-  various.controls=controls;
                        
            //             handleMouseMoveVR(scn, player?.children?.[0], various?.clickMarkerRef, player,  controllers,  various);
            //         }
                } 
    });
           

    let openBox;


/**
 * Handles click with raycast
 *
 * @param   {string}  nameInput  name of the neareast object in ray
 * @param   {Vector3}  pos       position of interception
 * @param   {object}  obj       ref to the object itself
 *
 * @return  {object}            react component
 */
        const handleClickEvent=(nameInput, pos, obj)=>{
            

            if(zoompage!=="galleryentrance"  || modal>0)   //   modal>0    is modal is open
                return;

            if(various?.zoomArtSetter && !mobile)
                    various.zoomArtSetter(false);

           
            if(zoompage==="galleryentrance" && pauseClickEvent?.pause)
                return;

            if(typeof nameInput ==="string")
            {
                const name_ = nameInput.split('_');
                const name = name_[0];
    
                if(name_?.length>0 && name_[1]=== "model" || /* openModelFromText(name, zoompage) || */ name_[0]==="LODButton"   )
                {
                    // if(mobile)
                    if ( zoompage==="galleryentrance")
                    {              
                        
                       setModalStyle({
                            outer1: { /* left: "10vw", top: "10vh", */ height: "72vh" },  
                            outer2: { width: "72vw" }
                        });

                        handleModal(
                                createModalTextContent(  nameAliases(nameInput), camera), false, 1);
                    }
                }
                else

              if(clickBox(name_, obj, controls,  openBox, v=>{openBox=v}, various, scene))
               {

               } 
                else
                {
                    clickEventAction(name, zoompage, setZoompage, 
                                        scrollBarRef, raycastEvents, pos, mobile, setCameraGoal, 
                                        camera, subPageR, controls,  various?.setControllerGoal, various, r);
                }
            }
            clickEventAction("", zoompage, setZoompage, 
                scrollBarRef, raycastEvents, pos, mobile, setCameraGoal, 
                camera, subPageR, controls,  various?.setControllerGoal, various, r);
        }


     


        const getPos=obj=> obj?.uv;
  
        /**
         * [checkRaycast description]
         *
         * @param   {object}  object  [object description]
         * @param   {object}  camera  [camera description]
         * @param   {number}  modal   [modal description]
         *
         */
        const checkRaycast=(object, camera, modal) =>
        {
            if( (mouseLeftButtonDown ||  touchDown ) && (mouseMove || touchMove)  && camera?.current)  // rotates the view 
            {            
                    if(various?.setAnimation)
                        various.setAnimation(false);
                
                    if(mouseBtnClickDelay > 5 || touchMove)
                    {                    
                         if(controls?.current?.mousemove && !modal)
                         {
                            various.zoomArtSetter(false);

                            controls.current.mousemove(mouseLeftButtonDown || touchDown, true, mouseMove || touchMove);
                        }

                          mousePressMoved=2;
                    }
            }
            else
            {               
                if(mouseMove && camera?.current)
                {
                    handleMouseMove(mouse3, object, camera, various?.clickMarkerRef, floorHoverClick);  // moves the marker
                    mouseMove=false;
                }
                
                if(mouseClicked && !mouseMove && camera?.current  && mousePressMoved===1 && various.zoomArtGetter()===true)
                {                 
                    if(various?.setAnimation)
                        various.setAnimation(false);
                    
                    setTimeout(() => {
                        various.zoomArtSetter(false);
                    }, 100);               
                }
                else
                if(mouseClicked && !mouseMove && camera?.current  && mousePressMoved===1 )
                {               
                         if(various?.setAnimation)
                            various.setAnimation(false);
                    
                            clickTheLowerLayer();
                           

                            // update the picking ray with the camera and mouse position
                            if(raycaster && camera?.current)
                                raycaster.setFromCamera( mouse, camera?.current );
                
                            // calculate objects intersecting the picking ray
                            const intersects = raycaster.intersectObjects( object?.children, true  );
                                
                            const openedModal_= openedModal();
                        
                         //-   console.log("floor click intersect length... "+intersects.length);
                         
                         if(!mouseMove && mouseClicked && intersects.length>0 && 
                            (intersects[0].object.name==="floor" 
                            || intersects[0].object.name==="clickMarker")   // Floor click event!
                                 && mousePressMoved===1 
                            )  // floor click navigation
                            {                                
                                const p=intersects[0].point;

                                let currentEuler= new Euler();
                                const quat=new Quaternion();
                                const mtrx= new Matrix4();
                           //     const mtrx2= new Matrix4();
                   
                                let vct=new Vector3();
                                vct=controls.current.camera.current.getWorldDirection(vct);
                                
                                vct.projectOnPlane(new Vector3(0,1,0));
                     
                                vct.normalize(); 
                            
                                mtrx.lookAt(new Vector3(0,0,0), vct  , new Vector3(0,1,0));
              
                                quat.setFromRotationMatrix(mtrx);
                        
                                currentEuler.setFromQuaternion(quat);
                                
                                const ip=intersects[0].point;   
                
                                px=ip.x; py=ip.y; pz=ip.z;

                                if(various?.setControllerGoal && !mouseLeftButtonDown)
                                {
                                    
                                    various.setControllerGoal({position: {x: p.x, 
                                                                y:  0, 
                                                                z: p.z }
                                                            ,
                                                            quaternion: quat});

                                    if(various?.zoomArtSetter)               // zoomArt getter checks if navigation animation should run
                                        various.zoomArtSetter(true);
                                }
                            }
                            else
                            if(intersects.length>0 && !openedModal_)
                            {                    
                                      handleClickEvent(findObj3DName(intersects[0].object),   // THE REAL CODE
                                                        getPos(intersects[0]),
                                                        intersects[0].object); 


                       /*          if(controls?.current?.camera?.current)
                                    various.player=controls.current.camera.current;

                                
                               if(intersects[0].object?.name && intersects[0].object && object  &&
                                                                various?.contentModel && various?.l && various?.setupdate && various)
                                                                        handleVRClickAction(intersects[0].object?.name, intersects[0].object, object, 
                                                                                                    various?.contentModel, various?.l, various?.setupdate, various); */
                                                     

                            }

                            mouseClicked=false;
                    }
            }

            
            if(mouseLeftButtonDown)
            {          
                mouseBtnClickDelay++
                if(mouseBtnClickDelay >10)
                {
                 //   rayCastMouseDown();
                    mouseBtnClickDelay=0;   

                    if(openBox) {
                        // @ts-ignore
                        openBox.visible=false;
                        openBox=false;
                    }
                }    
            }

            if(!mouseLeftButtonDown)
            {
                mouseBtnClickDelay=0;

                mouseLeftButtonDown=false;           
                
                if(mouseUpEventIs)
                {
                   if(controls?.current?.mouseup)
                   {
                        controls.current.mouseup();

                        if(!zoomArtGetter() && setTriggerR)    // ZoomArt!
                        {                            
                            setTriggerR(r=>!r);
                        }

                        mouseUpEventIs=false;
                    }
                }
       
            }
        }     


    const responsiveZDivider= rsp({
            sp:  82, 
            sl:  82, 
            mp: 82,
            tp:  270,
            mtl: 82, 
            tl: 82, 
            sll: 82, 
            lll: 82, 
            dl: 82 }, r);

    const htmlWidth = window.innerWidth;
    const htmlHeight = window.innerHeight;

    // @ts-ignore
    return <XR>
                <group>    
                    <group name={filename+"_spriteanimGroup"}>
                       { showSprite && HtmlSprite 
                            &&   <mesh ref={htmlRef}> 
                                        <ErrorHandler>
                                        <Html 
                                            name={"HTMLsprite"+filename}
                                            ref={htmlRef2}  
                                            style={{pointerEvents: 'auto', 
                                           //         width: htmlWidth / htmlWidthDivider, height: htmlHeight,
                                                    width: htmlWidth , height: htmlHeight,                                              
                                             //     border: "solid 10px red"
                                                  //  position: "absolute"
                                                }}
        
                                        // @ts-ignore
                                      //      transform 
                                            sprite 
                                            fullscreen 
                                             
                                            occlude = {mobile ? false : true} 
                                       //      occlude
                                            // @ts-ignore
                                 

                                            position={spritePosition }  
                                            rotation={spriteRotation}
                                          

                                            scale={new Vector3(htmlSpriteScale,htmlSpriteScale,htmlSpriteScale)}
                                            // @ts-ignore
                                            threeProps={threeProps}
                                            threeUseFrame={useFrame}   
                     
                                            opacityDistanceFactor  = {mobile ? 5 : 1}  
                                            mobile= {mobile}

                                            responsiveZDivider={responsiveZDivider}

                                            forceFocusHTML={forceFocusHTML}

                                            camLerp={various?.camLerp}
                                            >
                                            <View 
                                            // @ts-ignore
                                                    nativeID={"HTMLSprite_"+ (filename?.[0]?.filename || filename) }  
                                                    style={{width: htmlWidth, height: htmlHeight}} > 
                                                <HtmlSprite homepage={homepage} l ={l} r={r} 
                                                            setZoompage={setZoompage} objBbx={objBbx} />
                                            </View>
                                        </Html>          
                                </ErrorHandler>
                            </mesh>
                            }  
                    </group>  
                   
                {showmodel && 
                  <group name={(Array.isArray(filename) ? filename[0]?.filename: filename)+"_model"} 
                        position={modelPosition}
                        rotation={modelRotation}
                        scale={modelScale.multiplyScalar(10) }  >
                        <group ref={obj3dRef} >
                            {
                                (Array.isArray(filename)) ?
                                <>
                                 <FPSFeatureAdditionLOD
                                        featureList={featureList}                                    
                                        keepDetails={keepDetails}
                                     />
                                </>
                                :
                                 <GLTFLoaderComponent filename={filename} scene={scene} gl={gl} 
                                    camera={camera}  galleryFeatures={galleryFeatures}
                                        progressBarChoosen={progressBarChoosen} 
                                  //      unifiedLoadingState={unifiedLoadingState}
                                        progressBarContent={progressBarContent}
                                            various={various}
                                         /> }
                        </group>
                </group> }
            </group>
        </XR>;
}

